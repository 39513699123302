import { Form, Input, Col, Row, message } from 'antd';
import React from 'react';
import './view-label.scss';
import BackPage from '../../../../common/backPage';
import { labelService } from '../../../../../services/label.service';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';


const { TextArea } = Input;


const formItemLayout = {
  labelCol: { xs: 24, sm: 12, md: 8, lg: 6, xl: 4},
  wrapperCol: { xs: 24, sm: 12, md: 15},
};


class ViewLabel extends React.Component {
  formRef = React.createRef();

  constructor() {
    super();
    this.state = {
      showManage: true,
      canEdit: [false, false, false],

      labelId: null,
      labelObj: null,


      labelCreateTime: '',
      labelUpdateTime: '',
      labelCode: '',
      labelName: '查看标签',

    };
  }

  goback() {
    // this.setState({
    //   canEdit: [false, false, false]
    // })
    // if (this.props.onSubmit) {
    //   var { showManage } = this.state;
    //   this.props.onSubmit({ showManage })
    // }
    // this.props.history.go(-1);
    this.props.history.push('/home/sys/label-manage');
  }

  cancel = () => {
    this.formRef.current.setFieldsValue({
      name: this.state.labelObj.name,
      code: this.state.labelObj.code,
      description: this.state.labelObj.description,
    });
  }

  componentDidMount() {
   
    if (this.props.location.state) {
      const labelId = this.props.location.state.id;
      this.setState({
        labelId: labelId
      });
      this.getLabelDetail(labelId);
      sessionStorage.setItem('labelId', labelId);
    } else {
      if (sessionStorage.getItem('labelId')) {
        const labelId = sessionStorage.getItem('labelId');
        this.setState({
          labelId: labelId
        });
        this.getLabelDetail(labelId);
      } else {
        this.goback();
      }
    }
  }
  getLabelDetail(labelId) {
    labelService.getLabelDetail(labelId).then(res => {
      if (res.code === 200) {
        const label = res.label;
        this.setState({
          labelObj: label,
          labelCreateTime: label.createTime,
          labelUpdateTime: label.updateTime,
          labelCode: label.code || '',
          labelName: '查看标签 -- ' + label.name
        });
        // this.formRef.current.setFieldsValue({
        //   name: label.name || '',
       
        //   description: label.description || '',
        // });
      } 
      // else {
      //   message.error('获取标签详情失败');
      // }
    }, error => {
      error && message.error(error);
    });
  }
  render() {
 
    return (
      <>
        <div className="common-detail-container">
          <BackPage onClick={() => this.goback()} headeTitle={this.state.labelName} itemTitle="标签管理" itemSubTitle="查看标签" />
          <div className="common-edit-card">
            <Form name="basic"
             
              labelAlign="left"
             
              {...formItemLayout} >
              {/* <Row>
                <Col span={12}>
                  <Form.Item name="code" label="标签编码" rules={[{ required: true, message: '标签编码不能为空' }]} >
                    <Input placeholder="请输入标签编码" />
                  </Form.Item>
                </Col>
              </Row> */}
              
              <Row>
                <Col span={12}>
                  <Form.Item name="name" label="标签名称"   >
                  {this.state.labelObj?.name || '--'}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item name="description" label="描述" >
                  {this.state.labelObj?.description || '--'}
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row>
                <Col span={12}>
                  <Form.Item label="标签编码" >
                    {this.state.labelCode}
                  </Form.Item>
                </Col>
              </Row> */}
              <Row>
                <Col span={12}>
                  <Form.Item label="创建时间" >
                    {this.state.labelCreateTime || '--'}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item label="更新时间"  >
                    {this.state.labelUpdateTime || '--'}
                  </Form.Item>
                </Col>
              </Row>
            
            </Form>
          </div>
        </div>
      </>
    );
  }
}

export default ViewLabel;