import React from 'react';
import { Form, Select, Button, Row, Col, Table, Space, Modal, Tabs, message, Popconfirm, Input } from 'antd';
import './../../../../../index.scss';
import { nodeService } from '../../../../services/node.service';
import { algorithmService } from '../../../../services/algorithm-manage';
import BackPage from '../../../common/backPage';
import AlgorithmAuthorizationGroup from './algorithm-authorization-group/algorithm-authorization-group';

import { MinusCircleOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
const { Option } = Select;
const { confirm } = Modal;
const { TabPane } = Tabs;
const provinceData = ['Zhejiang', 'Jiangsu'];
const cityData = {
    Zhejiang: ['Hangzhou', 'Ningbo', 'Wenzhou'],
    Jiangsu: ['Nanjing', 'Suzhou', 'Zhenjiang'],
};

const searchName = '';
const page = 1;
function Search(props) {
    const [form] = Form.useForm();
    const onFinish = (values) => {
        //   let str=''
        //   if (values.edgeName != null) {
        //     str = values.edgeName.replace(/[\^\%\\\{\}\#\&\|\+]/g, '5a0c511f-3cc8-428c-860b-f747c16b2341')
        //     str = str.replace(/[\s]/g, '')
        //     console.log(str); 
        //   }
        //   searchName = str || '';
        //   page = 1;
        //   props.onClick();
    };
    const resetForm = () => {
        //   form.resetFields();
        //   searchEdgeName = '';
        //   searchStatus = '';
        //   page = 1;
        //   pageSize = 10;
        //   props.onClick();
    };
    return (
        <>
            <div className="common-search-form">
                <Form
                    form={form}
                    name="advanced_search"
                    layout="inline"
                    style={{ justifyContent: "center" }}
                    className="ant-advanced-search-form"
                    onFinish={onFinish} >

                    <Form.Item name='alName' label='算法名称' style={{ marginTop: '8px' }}>
                        <Input placeholder="请输入应用名称" style={{ width: '240px' }} />
                    </Form.Item>
                    <Form.Item style={{ marginTop: '8px' }}>
                        <Button type="primary" htmlType="submit"> 查询 </Button>
                        <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
                    </Form.Item>

                </Form>
            </div>
        </>
    );
}
class AlgorithmAuthorization extends React.Component {

    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {        //联动名称古怪，请见谅
            provinceData: [], //形如provinceData = ['Zhejiang', 'Jiangsu'];
            cityData: {},     //形如const cityData = {Zhejiang: ['Hangzhou', 'Ningbo', 'Wenzhou'],Jiangsu: ['Nanjing', 'Suzhou', 'Zhenjiang'],};
            isAddVisible: false,
            status: '',
            nodeName: this.props?.location.state?.name,
            nodeId: this.props?.location.state?.id,
            licencesList: [],
            initCities: [],
            initSecondCity: '',
            citiesInfo: {},
            secondCityInfo: {},
            selectLicences: {}//算法授权选择
        };
    }

    goback() {
        this.props.history.push('/home/node-manage');
    }

    componentDidMount() {
        const nodeId = this.props?.location.state?.id;
        const nodeName = this.props?.location.state?.name;
        if (nodeId) {
            sessionStorage.setItem('nodeName', nodeName || '');
            sessionStorage.setItem('nodeId', nodeId || '');
            this.getLicensesInfo(nodeId);
        } else {
            if (sessionStorage.getItem('nodeId')) {
                const nodeId = sessionStorage.getItem('nodeId');
                const nodeName = sessionStorage.getItem('nodeName');
                this.getLicensesInfo(nodeId);
                this.setState({
                    nodeName: nodeName,
                    nodeId: nodeId
                })
            }
        }
        this.getTotalAlgorithms();
    }
    //授权算法查询
    getLicensesInfo(nodeId) {
        nodeService.getLicences(nodeId).then(res => {
            if (res.code === 200) {
                console.log('授权算法查询', res.licences.algorithms);
                this.setState({
                    licencesList: res.licences.algorithms
                })
            }
            // else {
            //     message.error('授权算法查询失败' + res.message);
            // }
        }, error => {
            error && message.error(error);
        })
    }
    //查询算法概览列表
    getTotalAlgorithms() {
        algorithmService.getAlgorithmOverview('', 1000, 1).then(res => {
            if (res.code === 200) {
                const data = res.namespaces;
                console.log(data);
                this.handleOverviewData(data);
                console.log('算法概览查询', res);
            }
            // else {
            //     message.error('算法概览查询失败' + res.message);
            // }
        }, error => {
            error && message.error(error);
        })
    }
    //查询算法列表
    getalgorithmsList(id, name, index) {
        algorithmService.getAlgorithm(id, '', '', 100, 1).then(res => {
            if (res.code === 200) {
                const data = res.algorithms;
                const midData = this.state.provinceData;
                if (data.length) {
                    this.handleListData(data, name);
                    midData[index].state = true;
                } else {
                    midData[index].state = false;
                }
                this.setState({
                    provinceData: midData
                })
            }
            // else {
            //     message.error('算法列表查询失败' + res.message);
            // }
        }, error => {
            error && message.error(error);
        })
    }
    handleOverviewData(data) {
        const midData = [];
        data.map((ele, index) => {
            const info = {};
            info.name = ele.name;
            midData.push(info);
            this.getalgorithmsList(ele.id, ele.name, index);
        })
        this.setState({
            provinceData: midData
        })
    }
    handleListData(data, name) {
        const arr = [];
        data.map(ele => {
            arr.push(ele?.algorithmVersion)
        })
        this.state.cityData[name] = arr;
    }
    // 删除算法处理
    handleRemove(key) {
        const selectLicences = this.state.selectLicences;
        // const citiesInfo = this.state.citiesInfo;
        // const secondCityInfo = this.state.secondCityInfo;
        delete selectLicences[key];
        // delete citiesInfo[key];
        // delete secondCityInfo[key];
        this.setState({
            selectLicences,
            // citiesInfo,
            // secondCityInfo
        })
    }

    // 添加算法处理
    handleAdd() {
        const citiesInfo = this.state.citiesInfo;
        const secondCityInfo = this.state.secondCityInfo;
        const len_1 = (Object.keys(citiesInfo)).length;
        citiesInfo[len_1] = [];
        secondCityInfo[len_1] = '';
        this.setState({
            citiesInfo,
            secondCityInfo
        })
    }
    componentWillUnmount() {
        sessionStorage.removeItem('nodeId');
        sessionStorage.removeItem('nodeName');
    }

    showAddConfirm() {
        this.setState({
            isAddVisible: true
        })
    }
    handleOk() {
        let flag = true;
        const selectInfo = this.state.selectLicences;
        // const licencesList=this.state.licencesList;
        const algorithmsArr = [];//最终传body的数据
        const judgeRepeatArr = [];//判断是否重复添加的数组
        if (!Object.keys(selectInfo).length) {//对象为空
            message.warning("请添加算法!");
        } else {
            for (let key in selectInfo) {
                if (judgeRepeatArr.find(ele => ele === selectInfo[key][0])) {
                    if(flag){
                        message.warning("请勿添加相同的算法!");
                        flag = !flag;
                    }
                } else {
                    const info = {};
                    info.algorithmName = selectInfo[key][0];
                    info.algorithmVersion = selectInfo[key][1];
                    algorithmsArr.push(info);
                    judgeRepeatArr.push(selectInfo[key][0]);
                }
            }
            // licencesList?.forEach(ele=>{//取已授权列表和待添加列表的并集
            //    if(!algorithmsArr.find(val=>val.algorithmName===ele.algorithmName)) {
            //     const info = {};
            //     info.algorithmName =ele.algorithmName;
            //     info.algorithmVersion = ele.algorithmVersion;
            //     algorithmsArr.push(info);
            //    }
            // })
            // console.log(algorithmsArr);
            if (flag) {
                confirm({
                    style: { marginTop: 35 },
                    width: 400,
                    title: '此操作会覆盖当前算法，确定添加吗?',
                    icon: <ExclamationCircleOutlined />,
                    closable: true,
                    okText: "确定",
                    cancelText: "取消",
                    onOk: () => { this.licenseAlgorithms(this.state.nodeId, algorithmsArr); },
                    onCancel() {
                    },
                });
            }
        }
    }
    //算法授权后台
    licenseAlgorithms(nodeId, algorithms) {
        nodeService.nodeLicences(nodeId, algorithms).then(res => {
            if (res.code === 200) {
                message.success('操作成功');
                this.handleCancel();
                this.getLicensesInfo(this.state.nodeId);
            }
            // else {
            //     message.error('操作失败' + res.message);
            // }
        }, error => {
            error && message.error(error);
        })
    }
    handleCancel = () => {
        this.setState({
            isAddVisible: false,
            initCities: [],
            initSecondCity: '',
            citiesInfo: {},
            secondCityInfo: {},
            selectLicences: {}
        })
    }
    // 固定第一条 选择框联动
    handleChange_1 = value => {
        const dataArr = [value, this.state.cityData[value][0]];
        const selectLicences = this.state.selectLicences;//selectLicences={}
        selectLicences.init = dataArr;
        this.setState({
            initCities: this.state.cityData[value],
            initSecondCity: this.state.cityData[value][0],
            selectLicences
        });
    }
    handleChange_2 = value => {
        const selectLicences = this.state.selectLicences;//selectLicences={}
        selectLicences.init[1] = value;
        this.setState({
            initSecondCity: value,
            selectLicences
        });
    }

    //动态 选择框联动
    handleProvinceChange = (value, num) => {
        const dataArr = [value, this.state.cityData[value][0]];//形如selectLicences={[province,city],{dataArr},...}
        const citiesInfo = this.state.citiesInfo;
        const secondCityInfo = this.state.secondCityInfo;
        const selectLicences = this.state.selectLicences;

        selectLicences[num] = dataArr;

        citiesInfo[num] = this.state.cityData[value];
        secondCityInfo[num] = this.state.cityData[value][0];
        this.setState({
            citiesInfo,
            secondCityInfo,
            selectLicences
        });
    };

    onSecondCityChange = (value, num) => {
        const selectLicences = this.state.selectLicences;
        const secondCityInfo = this.state.secondCityInfo;
        selectLicences[num][1] = value;
        secondCityInfo[num] = value;
        this.setState({
            secondCityInfo,
            selectLicences
        });
    };

    showDeleteConfirm(event) {
        const selectInfo = this.state.licencesList;
        // console.log(selectInfo);
        const algorithmsArr = [];//最终传body的数据
        selectInfo.map((ele) => {
            if (ele.id !== event.id) {
                const info = {};
                info.algorithmName = ele.algorithmName;
                info.algorithmVersion = ele.algorithmVersion;
                algorithmsArr.push(info);
            }
        })
        confirm({
            title: '确定删除吗?',
            icon: <ExclamationCircleOutlined />,
            closable: true,
            okText: "确定",
            cancelText: "取消",
            onOk: () => {
                nodeService.nodeLicences(this.state.nodeId, algorithmsArr).then(res => {
                    if (res.code === 200) {
                        message.success('操作成功');
                        this.getLicensesInfo(this.state.nodeId);
                    }
                    // else {
                    //     message.error('操作失败' + res.message);
                    // }
                }, error => {
                    error && message.error(error);
                })
            },
            onCancel() {

            },
        });
    }

    render() {
        // const onFinish = values => {
        //     console.log('Received values of form:', values);
        // };
        const Columns = [
            {
                title: '算法名称',
                dataIndex: 'algorithmName',
            },
            {
                title: '算法版本',
                dataIndex: 'algorithmVersion',
            },
            {
                title: '开始时间',
                dataIndex: 'beginTime',
            },
            {
                title: '结束时间',
                dataIndex: 'endTime',
            },
            // {
            //     title: '授权时间',
            //     dataIndex: 'validPeriod',
            //     render: (text, record) => <span>{Math.floor(text / 365) + '年' + text % 365 + '天'}</span>
            // },
            {
                title: '操作',
                dataIndex: 'action',
                render: (text, record) => <a href onClick={() => this.showDeleteConfirm(record)}>删除</a>
            }
        ];
        return (
            <>
                <div className="common-detail-container">
                    <BackPage onClick={() => this.goback()} headeTitle={"算法授权---" + this.state.nodeName} itemTitle="算法授权" itemSubTitle="算法授权" />
                    <div style={{ background: '#fff', padding: "0 20px 20px" }}>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="已授权算法" key="1">
                                {/* <Search /> */}
                                <Form>
                                    <Form.Item >
                                        <Button type="primary" style={{ color: '#fff' }} onClick={() => this.showAddConfirm()}>  新增算法  </Button>
                                    </Form.Item>
                                </Form>
                                <div className="common-center-container" style={{ padding: 0 }}>
                                    <div className="user-table-card">
                                        <Table
                                            rowKey={record => record.id}
                                            bordered={false}
                                            pagination={false}
                                            columns={Columns}
                                            // scroll={{ y: 300 }}
                                            dataSource={this.state.licencesList}
                                        />

                                    </div>
                                </div>
                                <Modal
                                    destroyOnClose//
                                    width={450}
                                    title="新增算法(全覆盖)"
                                    visible={this.state.isAddVisible}
                                    onOk={() => this.handleOk()}
                                    onCancel={() => this.handleCancel()}
                                    okText="确定"
                                    cancelText="取消"
                                    footer={null}
                                >
                                    <Form
                                        preserve={false}
                                        name="dynamic_form_nest_item"
                                        // onFinish={onFinish}
                                        autoComplete="off" >
                                        <Space key={-1} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                            <Form.Item
                                                label="算法名称"
                                                rules={[{ required: true, message: '请选择算法名称' }]}
                                            >
                                                <Select
                                                    style={{ width: 120 }}
                                                    onChange={this.handleChange_1}
                                                >

                                                    {this.state.provinceData.map(province => (
                                                        <Option key={province.name} value={province.name} disabled={!province.state}>{province.name}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                label="算法版本"
                                                rules={[{ required: true, message: '请选择算法版本' }]}
                                            >
                                                <Select
                                                    style={{ width: 120 }}
                                                    value={this.state.initSecondCity}
                                                    onChange={this.handleChange_2}
                                                >
                                                    {this.state.initCities.map(city => (
                                                        <Option key={city}>{city}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Space>
                                        <Form.List name="users"  >
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                                                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                            <Form.Item
                                                                {...restField}
                                                                label="算法名称"
                                                                // name={[name, 'first']}
                                                                // initialValue={this.state.provinceData[0]}
                                                                fieldKey={[fieldKey, 'first']}
                                                                rules={[{ required: true, message: '请选择算法名称' }]}
                                                            >
                                                                <Select
                                                                    // defaultValue={this.state.provinceData[0]}
                                                                    style={{ width: 120 }}
                                                                    onChange={(e) => this.handleProvinceChange(e, key)}
                                                                >
                                                                    {this.state.provinceData.map(province => (
                                                                        <Option key={province.name} value={province.name} disabled={!province.state}>{province.name}</Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...restField}
                                                                label="算法版本"
                                                                // initialValue={this.state.secondCity}
                                                                // name={[name, 'last']}
                                                                fieldKey={[fieldKey, 'last']}
                                                                rules={[{ required: true, message: '请选择算法版本' }]}
                                                            >
                                                                <Select
                                                                    style={{ width: 120 }}
                                                                    value={this.state.secondCityInfo[key]}
                                                                    onChange={(e) => this.onSecondCityChange(e, key)}
                                                                >
                                                                    {this.state.citiesInfo[key]?.map(city => (
                                                                        <Option key={city}>{city}</Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                            <MinusCircleOutlined onClick={() => { remove(name); this.handleRemove(key); }} />
                                                        </Space>
                                                    ))}
                                                    <Form.Item>
                                                        <Button type="dashed" onClick={() => { add(); this.handleAdd() }} block icon={<PlusOutlined />}>
                                                            添加
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                        <Form.Item style={{ marginTop: '32px', justifyContent: 'center' }}>
                                            <div style={{ textAlign: 'center' }}>
                                                <Button type="primary" htmlType="submit" style={{ marginRight: 20 }} onClick={() => this.handleOk()} > 确认 </Button>
                                                <Button style={{ margin: '0 9.46px' }} onClick={() => this.handleCancel()}> 取消</Button>
                                            </div>
                                        </Form.Item>
                                    </Form>
                                </Modal>
                            </TabPane>
                            <TabPane tab="已授权算法组" key="2">
                                <AlgorithmAuthorizationGroup
                                    // history={this.props.history} 
                                    nodeId={this.state.nodeId}
                                // nodeName={this.state.nodeObj.name} nodeUuid={this.state.nodeObj.nodeUuid} 
                                />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>

            </>
        );
    }
}

export default AlgorithmAuthorization;