import React from 'react';
import { Form, Select, Button, Row, Col, Table, Space, Modal, Tabs, message, Divider, Input } from 'antd';
import './../../../../../index.scss';
import { nodeService } from '../../../../services/node.service';
import { logConfig } from '../../../../services/log-config';
import { algorithmService } from '../../../../services/algorithm-manage';
import BackPage from '../../../common/backPage';
//import AlgorithmAuthorizationGroup from './algorithm-authorization-group/algorithm-authorization-group';

import { MinusCircleOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
const { Option } = Select;
const { confirm } = Modal;
const { TabPane } = Tabs;

const searchName = '';
const page = 1;
function Search(props) {
    const [form] = Form.useForm();
    const onFinish = (values) => {
        //   let str=''
        //   if (values.edgeName != null) {
        //     str = values.edgeName.replace(/[\^\%\\\{\}\#\&\|\+]/g, '5a0c511f-3cc8-428c-860b-f747c16b2341')
        //     str = str.replace(/[\s]/g, '')
        //     console.log(str); 
        //   }
        //   searchName = str || '';
        //   page = 1;
        //   props.onClick();
    };
    const resetForm = () => {
        //   form.resetFields();
        //   searchEdgeName = '';
        //   searchStatus = '';
        //   page = 1;
        //   pageSize = 10;
        //   props.onClick();
    };
    return (
        <>
            <div className="common-search-form">
                <Form
                    form={form}
                    name="advanced_search"
                    layout="inline"
                    style={{ justifyContent: "center" }}
                    className="ant-advanced-search-form"
                    onFinish={onFinish} >

                    <Form.Item name='alName' label='算法名称' style={{ marginTop: '8px' }}>
                        <Input placeholder="请输入应用名称" style={{ width: '240px' }} />
                    </Form.Item>
                    <Form.Item style={{ marginTop: '8px' }}>
                        <Button type="primary" htmlType="submit"> 查询 </Button>
                        <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
                    </Form.Item>

                </Form>
            </div>
        </>
    );
}
class LogConfig extends React.Component {

    formRef = React.createRef();
    formLog = React.createRef();
    constructor(props) {
        super(props);
        this.state = {        
            isAddVisible: false,
            isEditLogConfig: false,
            nodeName: this.props?.location.state?.name,
            nodeId: this.props?.location.state?.id,
            nodeUuid: this.props?.location.state?.nodeUuid,
            logConfigData: [],
            logId:-1, //编辑保存的logid
        };
    }

    goback() {
        this.props.history.push('/home/node-manage');
    }

    componentDidMount() {
        
        const nodeId = this.props?.location.state?.id;
        const nodeName = this.props?.location.state?.name;
        const nodeUuid = this.props?.location.state?.nodeUuid;
        if (nodeId) {
            sessionStorage.setItem('nodeName', nodeName || '');
            sessionStorage.setItem('nodeId', nodeId || '');
            sessionStorage.setItem('nodeUuid', nodeUuid || '');
            this.getAllLogConfigs(nodeName);
            this.setState({
                nodeName: nodeName,
                nodeId: nodeId,
                nodeUuid: nodeUuid
            })
        } else {
            if (sessionStorage.getItem('nodeId')) {
                const nodeId = sessionStorage.getItem('nodeId');
                const nodeName = sessionStorage.getItem('nodeName');
                const nodeUuid = sessionStorage.getItem('nodeUuid');
                this.getAllLogConfigs(nodeName);
                this.setState({
                    nodeName: nodeName,
                    nodeId: nodeId,
                    nodeUuid: nodeUuid
                })
            }
        }
    }

    //日志配置列表查询
    getAllLogConfigs(nodeName) {
        logConfig.getAllLogConfigs('', '', '', nodeName, '', '').then(res => {
            console.log(res);
            if (res.code === 200) {
                this.setState({
                    logConfigData: res.logTasks
                })
            }
        })
    }

    //日志配置详情查询
    getLogConfigDetial(nodeId) {
        logConfig.getLogConfigDetial(nodeId, 'a').then(res => {
            console.log(res);
            if (res.code === 200) {
                
            }
        })
    }
    
    componentWillUnmount() {
        sessionStorage.removeItem('nodeId');
        sessionStorage.removeItem('nodeName');
        sessionStorage.removeItem('nodeUuid');
    }

    showAddConfirm() {
        this.setState({
            isAddVisible: true
        })
    }
    
    handleCancel = () => {
        this.setState({
            isAddVisible: false,
        })
    }

    showDeleteConfirm(event) {
        console.log(event);
        console.log(this.state.nodeName);
        confirm({
            title: '确定删除吗?',
            icon: <ExclamationCircleOutlined />,
            closable: true,
            okText: "确定",
            cancelText: "取消",
            onOk: () => {
                logConfig.deleteLogConfig(event.id, this.state.nodeName).then(res => {
                    if (res.code === 200) {
                        message.success('操作成功');
                        this.getAllLogConfigs(this.state.nodeName);
                    }
                    // else {
                    //     message.error('操作失败' + res.message);
                    // }
                }, error => {
                    error && message.error(error);
                })
            },
            onCancel() {

            },
        });
    }

    //编辑日志配置
    showEditConfirm(record) {
        console.log(record);
        this.setState({
            isEditLogConfig: true,
            logId: record.id
        })

        this.formLog.current?.setFieldsValue({
            logAddr: record.logAddr,
            logLevel: record.logLevel,
            description: record.description,
        })
    }

    editLogConfigFinish(event) {
        console.log(event);
        console.log(this.state.logId);
        console.log(this.state.nodeUuid);
        let nodeUuids = []
        if (this.state.nodeUuid != '') {
            nodeUuids.push(this.state.nodeUuid)
        }
        logConfig.editLogConfig(this.state.logId, '', event?.logAddr, event?.logLevel, nodeUuids, event?.description).then(res => {
            if (res.code === 200) {
                message.success("修改成功！")
                this.setState({
                    isEditLogConfig: false
                })
                this.getAllLogConfigs(this.state.nodeName);
            } else {
                message.error("修改失败")
                this.setState({
                    isEditLogConfig: false
                })
            }
        }, error => {
            error && message.error(error);
        })
    }

    handleEditLogConfigCancel() {
        this.setState({
            isEditLogConfig: false
        })
    }

    getLogLevel(record) {
        if (record.logLevel === 0) {
            return <span>ALL</span>
        }
        else if (record.logLevel === 1) {
            return <span>DEBUG</span>
        }
        else if (record.logLevel === 2) {
            return <span>INFO</span>
        }
        else if (record.logLevel === 3) {
            return <span>WARN</span>
        }
        else if (record.logLevel === 4) {
            return <span>ERROR</span>
        }
        else if (record.logLevel === 5) {
            return <span>FATAL</span>
        }
        else if (record.logLevel === 99) {
            return <span>NONE</span>
        }
    }

    //数组对象去重
    getOnlyOneEleInArr(arr){
        let result = []
        for(let i of arr){
            if(result.find(ele=>ele.logAddr===i.logAddr && ele.logLevel===i.logLevel)){
                continue
            }
            result.push(i)
        }
        return result
    }

    logConfigFinish(event) {
        console.log(event);
        console.log(this.state.nodeUuid);
        let edgeLogTasks = [];
        let edgeLogTask = {};
        edgeLogTask.logAddr = event?.logAddr;
        edgeLogTask.logLevel = event?.logLevel;
        edgeLogTask.description = event?.description;
        edgeLogTasks.push(edgeLogTask)

        if (event?.users != undefined || event?.users != []) {
            edgeLogTasks.push(...event?.users??[])
        }
        console.log(edgeLogTasks);
        let nodeUuids = []
        if (this.state.nodeUuid != '') {
            nodeUuids.push(this.state.nodeUuid)
        }

        let newArr = this.getOnlyOneEleInArr(edgeLogTasks)
        console.log(newArr);
        let overFlag = 1; //0覆盖,1追加
        logConfig.bulkAddLogConfigs(newArr, nodeUuids, overFlag).then(res => {
            console.log(res);
            if (res.code === 200) {
                message.success("添加成功！")
                this.setState({
                    isAddVisible: false
                })
                this.getAllLogConfigs(this.state.nodeName);
            } else {
                message.error("添加失败")
                this.setState({
                    isAddVisible: false
                })
            }
        })
    }

    render() {
        const Columns = [
            {
                title: '日志采集路径',
                dataIndex: 'logAddr',
            },
            {
                title: '日志级别',
                dataIndex: 'logLevel',
                render: (text, record) => (
                    this.getLogLevel(record)
                ),
            },
            {
                title: '描述',
                dataIndex: 'description',
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
            },
            {
                title: '更新时间',
                dataIndex: 'updateTime',
            },
            // {
            //     title: '授权时间',
            //     dataIndex: 'validPeriod',
            //     render: (text, record) => <span>{Math.floor(text / 365) + '年' + text % 365 + '天'}</span>
            // },
            {
                title: '操作',
                dataIndex: 'action',
                render: (text, record) =>
                (
                    <>
                        <a href onClick={() => this.showEditConfirm(record)} style={{ marginRight: '5px', wordBreak: 'keep-all' }}>编辑</a>
                        <a href onClick={() => this.showDeleteConfirm(record)}>删除</a>
                    </>     
                )
                    
            }
        ];
        return (
            <>
                <div className="common-detail-container">
                    <BackPage onClick={() => this.goback()} headeTitle={"日志配置---" + this.state.nodeName} itemTitle="日志配置" itemSubTitle="日志配置" />
                    <div style={{ background: '#fff', padding: "0 20px 20px" }}>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="已配置日志" key="1">
                                {/* <Search /> */}
                                <Form>
                                    <Form.Item >
                                        <Button type="primary" style={{ color: '#fff' }} onClick={() => this.showAddConfirm()}>  新增日志  </Button>
                                    </Form.Item>
                                </Form>
                                <div className="common-center-container" style={{ padding: 0 }}>
                                    <div className="user-table-card">
                                        <Table
                                            rowKey={record => record.id}
                                            bordered={false}
                                            pagination={false}
                                            columns={Columns}
                                            // scroll={{ y: 300 }}
                                            dataSource={this.state.logConfigData}
                                        />

                                    </div>
                                </div>
                                <Modal
                                    destroyOnClose
                                    forceRender = {true} //强制渲染dom,防止formRef不生效
                                    width={750}
                                    title="新增日志"
                                    visible={this.state.isAddVisible}
                                    onOk={() => this.logConfigFinish()}
                                    onCancel={() => this.handleCancel()}
                                    okText="确定"
                                    cancelText="取消"
                                    footer={null}
                                >
                                    <Form
                                        ref={this.formRef}
                                        preserve={false}
                                        name="dynamic_form_nest_item"
                                        onFinish={(e)=>this.logConfigFinish(e)}
                                        autoComplete="off" >
                                        <Space key={-1} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                            <Form.Item
                                                name='logAddr'
                                                label="日志采集路径"
                                                rules={[{ required: true, message: '请填写日志采集路径' }]}
                                            >
                                                <Input style={{ width: 150 }}></Input>
                                            </Form.Item>
                                            <Form.Item
                                                name='logLevel'
                                                label="日志级别"
                                                rules={[{ required: true, message: '请选择日志级别' }]}
                                            >
                                                <Select style={{ width: 100 }}>
                                                    <Option value={0}>ALL</Option>
                                                    <Option value={1}>DEBUG</Option>
                                                    <Option value={2}>INFO</Option>
                                                    <Option value={3}>WARN</Option>
                                                    <Option value={4}>ERROR</Option>
                                                    <Option value={5}>FATAL</Option>
                                                    <Option value={99}>NONE</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                name='description'
                                                label="描述"
                                                 
                                            >
                                                <Input style={{ width: 180 }}></Input>
                                            </Form.Item>
                                        </Space>
                                        <Form.List name="users"  >
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                                                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                            <Form.Item
                                                                {...restField}
                                                                label="日志采集路径"
                                                                name={[name, 'logAddr']}
                                                                // initialValue={this.state.provinceData[0]}
                                                                fieldKey={[fieldKey, 'logAddr']}
                                                                rules={[{ required: true, message: '请选择日志采集路径' }]}
                                                            >
                                                                <Input style={{ width: 150 }}></Input>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...restField}
                                                                label="日志级别"
                                                                // initialValue={this.state.secondCity}
                                                                name={[name, 'logLevel']}
                                                                fieldKey={[fieldKey, 'logLevel']}
                                                                rules={[{ required: true, message: '请选择日志级别' }]}
                                                            >
                                                                <Select style={{ width: 100 }}>
                                                                    <Option value={0}>ALL</Option>
                                                                    <Option value={1}>DEBUG</Option>
                                                                    <Option value={2}>INFO</Option>
                                                                    <Option value={3}>WARN</Option>
                                                                    <Option value={4}>ERROR</Option>
                                                                    <Option value={5}>FATAL</Option>
                                                                    <Option value={99}>NONE</Option>
                                                                </Select>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...restField}
                                                                label="描述"
                                                                name={[name, 'description']}
                                                                // initialValue={provinceData[0]}
                                                                fieldKey={[fieldKey, 'description']}
                                                                 
                                                                >
                                                                <Input style={{ width: 180 }}></Input>
                                                            </Form.Item>
                                                            <MinusCircleOutlined onClick={() => { remove(name); }} />
                                                        </Space>
                                                    ))}
                                                    <Form.Item>
                                                        <Button type="dashed" onClick={() => { add(); }} block icon={<PlusOutlined />}>
                                                            添加
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                        <Form.Item style={{ marginTop: '32px', justifyContent: 'center' }}>
                                            <div style={{ textAlign: 'center' }}>
                                                <Button type="primary" htmlType="submit" style={{ marginRight: 20 }}> 确认 </Button>
                                                <Button style={{ margin: '0 9.46px' }} onClick={() => this.handleCancel()}> 取消</Button>
                                            </div>
                                        </Form.Item>
                                    </Form>
                                </Modal>
                            </TabPane>
                             
                        </Tabs>
                    </div>

                    <Modal
                        forceRender = {true} //强制渲染dom,防止formRef不生效
                        width={750}
                        title="修改日志配置"
                        visible={this.state.isEditLogConfig}
                        onOk={() => this.editLogConfigFinish()}
                        onCancel={() => this.handleEditLogConfigCancel()}
                        okText="确定"
                        cancelText="取消"
                        footer={null}
                    >
                        <Form
                            ref={this.formLog}
                            preserve={false}
                            name="dynamic_form_nest_item"
                            onFinish={(e)=>this.editLogConfigFinish(e)}
                            autoComplete="off" >
                            <Space key={-1} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                <Form.Item
                                    name='logAddr'
                                    label="日志采集路径"
                                    rules={[{ required: true, message: '请填写日志采集路径' }]}
                                >
                                    <Input style={{ width: 150 }}></Input>
                                </Form.Item>
                                <Form.Item
                                    name='logLevel'
                                    label="日志级别"
                                    rules={[{ required: true, message: '请选择日志级别' }]}
                                >
                                    <Select style={{ width: 100 }}>
                                        <Option value={0}>ALL</Option>
                                        <Option value={1}>DEBUG</Option>
                                        <Option value={2}>INFO</Option>
                                        <Option value={3}>WARN</Option>
                                        <Option value={4}>ERROR</Option>
                                        <Option value={5}>FATAL</Option>
                                        <Option value={99}>NONE</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name='description'
                                    label="描述"
                                    // rules={[{ required: true, message: '请填写描述' }]}
                                >
                                    <Input style={{ width: 180 }}></Input>
                                </Form.Item>
                            </Space>   
                            <Divider style={{marginTop: -10}}/>
                            <Form.Item style={{ marginTop: '12px', justifyContent: 'center' }}>
                                <div style={{ textAlign: 'right' }}>
                                    <Button type="primary" htmlType="submit" style={{ marginRight: 10 }}> 确认 </Button>
                                    <Button style={{ margin: '0 9.46px' }} onClick={() => this.handleEditLogConfigCancel()}> 取消</Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>

            </>
        );
    }
}

export default LogConfig;