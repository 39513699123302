import { handleResponse } from './handle-response';
import store from '../../store/index';

export const terminalService = {
    getDevices,
    getDeviceDetail,
    addDevice,
    editDevice,
    deleteDevice,
};

//查询设备
function getDevices(name, nodeName, state, userId, labelIds, type, limit, offset) {
    return fetch(`/v1/console/devices?name=${name || ''}&nodeName=${nodeName || ''}&state=${state || ''}&userId${userId || ''}&labelIds=${labelIds || ''}&type=${type || ''}&limit=${limit || 10}&offset=${offset || 1}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//查询设备详情
function getDeviceDetail(id) {
    return fetch(`/v1/console/devices/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//注册设备
function addDevice(aliasName, nodeUuid, deviceType, rtspUrl, deviceInterface) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            aliasName: aliasName|| '',
            nodeUuid: nodeUuid,
            deviceType: deviceType,
            rtspUrl: rtspUrl,
            deviceInterface: deviceInterface||'NET' ,
        })
    };
    return fetch(`/v1/console/devices`, requestOptions)
    .then(handleResponse)
    .then(res => {
        return res;
    });
}

//修改设备
function editDevice(id, aliasName, nodeUuid, deviceType, rtspUrl, deviceInterface) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            aliasName: aliasName || '',
            nodeUuid: nodeUuid ,
            deviceType: deviceType ,
            rtspUrl: rtspUrl ,
            deviceInterface: deviceInterface||'NET',
        })
    };
    return fetch(`/v1/console/devices/${id}`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

//删除设备
function deleteDevice(id) {
    return fetch(`/v1/console/devices/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}