import { CHANGE_INPUT_VALUE, ADD_LIST_ITEM, CHANGE_AUTH_TOKEN, DELETE_LIST_ITEM, INIT_LIST_ACTION, GET_INIT_LIST, CHANGE_CURRENT_USER, CHANGE_OTA_ID, CHANGE_OTA_FIRMWARE_ID, CHANGE_JOB_ID,CHANGE_NODE_ID, CHANGE_MODEL_ID } from './actionTypes';

export const getInputChangeAction = (value) => ({
    type: CHANGE_INPUT_VALUE, value
});

export const getTokenChangeAction = (value) => ({
    type: CHANGE_AUTH_TOKEN, value
});
export const getCurrentAction = (value) => ({
    type: CHANGE_CURRENT_USER, value
});
export const getOtaIdAction = (value) => ({
    type: CHANGE_OTA_ID, value
});
export const getOtaFirmwareIdAction = (value) => ({
    type: CHANGE_OTA_FIRMWARE_ID, value
});
export const getJobIdAction = (value) => ({
    type: CHANGE_JOB_ID, value
});
export const getNodeIdAction = (value) => ({
    type: CHANGE_NODE_ID, value
});


export const getModelIdAction = (value) => ({
    type: CHANGE_MODEL_ID, value
});


export const getAddItemAction = () => ({
    type: ADD_LIST_ITEM
});

export const getDeleteItemAction = (index) => ({
    type: DELETE_LIST_ITEM, index
});

export const initListAction = (value) => ({
    type: INIT_LIST_ACTION, value
});

export const getInitList = () => ({
  type: GET_INIT_LIST   
})

// export const getList = () => {
//     return (dispatch) => {
//         const requestOptions = {
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json;charset=UTF-8',
//                 Authorization: `Bearer ${store.getState().authToken}`
//             },
//         };
//         axios.get('/v1/console/users/all', requestOptions).then((res) => {
//             const data = res.data.users;
//             const action = initListAction(data);
//             dispatch(action);
//         });
//     }
// }