import React from 'react';
import { Form, Row, Col, Input, Button, Table, Pagination, ConfigProvider, Modal, message, Select } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from 'react-router-dom';
import CreateLabel from './create-label/create-label';
import { labelService } from '../../../../services/label.service';

import './../../../../../index.scss';
import './label-manage.scss';
import store from '../../../../../store';
import { userService } from '../../../../services/user.service';
import { AUTCONFIG } from '../../../../../utils/auth.config';
import { connect } from 'react-redux';
import { searchRule } from '../../../../../utils/search-util'

const { confirm } = Modal;
const { Option } = Select;


let searchData = '';
let searchTenantUser = '';
let page = 1;
let pageSize = 10;
let searchType = 'subuser';
class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = { type: 'subuser' };
  }
  form = React.createRef();

  onFinish = (values) => {
    let name = values.name || '';
    let str = searchRule(name)
    searchData = str || '';
    
    searchTenantUser = values.tenantUser;
    page = 1;
    searchType = this.state.type;
    this.props.onClick();
  };
  componentDidMount() {
    this.resetForm();
  }
  resetForm = () => {
    this.form.current.resetFields();
    searchData = '';
    searchTenantUser = '';
    page = 1;
    searchType = 'subuser';
    this.setState({
      type: 'subuser'
    })
    this.form.current.setFieldsValue({ type: 'subuser' });
    this.props.onClick();
  }

  onGenderChange = (value) => {
    if (value.type) {
      searchType = value.type;
      this.setState({
        type: value.type
      })
    }
  }
  render() {

    return (
      <>
        <div className="common-search-form">
          <Form
            ref={this.form}
            name="advanced_search_label"
            className="ant-advanced-search-form"
            layout="inline"
            onValuesChange={this.onGenderChange}
            // style={{ justifyContent: 'center' }}
            onFinish={this.onFinish} >
            <Form.Item name='name' label='标签名称' style={{ marginTop: '8px' }}>
              <Input placeholder="请输入标签名称" />
            </Form.Item>


            <Form.Item name='tenantUser' label='所属用户'
              style={{ marginTop: '8px' }}>
              <Select
                style={{ width: '200px' }}
                placeholder="请选择所属用户"
                showSearch
                filterOption={(input, option) =>
                  option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.props.tenantList}
              </Select>
            </Form.Item>

            <Form.Item style={{ marginTop: '8px' }}>
              <Button type="primary" htmlType="submit"> 查询 </Button>
              <Button style={{ margin: '0 9.46px' }} onClick={this.resetForm}>重置 </Button>
            </Form.Item>

          </Form>
        </div>
      </>
    );
  }
}

class LabelManage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      labelList: [],
      labelAmount: 0,
      actionIds: [],
      loading: false,
      showManage: true,
      showRegistered: false,
      page: 1,
      pageSize: 10,
      history: require("history").createHashHistory,

      tenantList: []
    };
  }


  showDeleteConfirm(event) {
    confirm({
      title: '确定删除吗?',
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      onOk: () => {
        labelService.removeLabel(event).then(res => {
          if (res.code === 200) {
            message.success('删除成功');
            if (this.state.labelList.length === 1) {
              page = page - 1;
              page = page ? page : 1;

            }
            this.searchList();
          } 
          // else {
          //   message.error('删除失败，' + res.message);
          // }
        }, error => {
          error && message.error(error);
        })
      },
      onCancel() {
      },
    });
  }


  showMultiDeleteConfirm() {
    if (this.state.actionIds.length === 0) {
      message.warning('请先选择要删除的标签');
      return;
    }
    confirm({
      title: '确定删除吗?',
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      onOk: () => {
        labelService.removeLabel(this.state.actionIds).then(res => {
          if (res.code === 200) {
            message.success('删除成功');
            if (this.state.actionIds.length === this.state.labelList.length) {
              page = page - 1;
              page = page ? page : 1;

            }
            this.searchList();
          } 
          // else {
          //   message.error('删除失败，' + res.message);
          // }
        }, error => {
          error && message.error(error);
        })
      },
      onCancel() {
      },
    });
  }

  searchList() {

    this.setState({
      loading: true,
      page,
      pageSize
    });
    // searchData = searchData ||'';
    labelService.getLabelList(
      searchData.replace(/(^\s*)|(\s*$)/g, ''),
      searchTenantUser,
      page,
      pageSize,
      searchType).then(res => {
        this.setState({
          loading: false
        });

        if (res.code === 200) {
          console.log(res);
          const labels = res.labels;
          labels.forEach(ele => {
            ele.key = ele.id;
          })
          this.setState({
            labelList: labels,
            labelAmount: res.dataCount
          });
        } 
        // else {
        //   message.error('获取标签列表失败，' + res.message);
        // }
      }, error => {
        this.setState({
          loading: false
        })
        error && message.error(error);
      });
  }
  getTenantList() {
    userService.getAllTenantList().then(res => {
      if (res.code === 200) {
        // console.log('getAllTenantList',res);
        const tenants = res.users;
        this.setState({
          tenantList: this.getTenantItem(tenants),
        });
      }
    });
  }
  getTenantItem(tenants) {
    return tenants.map((item, index) => {
      return (
        <Option key={item.id} >{item.realName}/{item.username}</Option>
      );
    });
  }
  componentDidMount() {
    // this.searchList();
    pageSize = 10;
    page = 1;
    this.getTenantList();

  }

  printContent(comment) {
    this.setState({
      showManage: true,
      showRegistered: false,
    });
    if (comment.showManage) {
      this.searchList();
    }
  }

  gotoDetail = (text, key) => {
    this.props.history.push({ pathname: '/home/sys/label-manage/detail', state: { id: text } });
  }
  gotoEdit = (text, key) => {
    this.props.history.push({ pathname: '/home/sys/label-manage/edit', state: { id: text } });
  }
  gotoNode = (text, key) => {
    this.props.history.push({ pathname: '/home/sys/label-manage/bind', state: { id: text } });
  }
  render() {
    const onPageChange = (pageNumber, ps) => {
      page = pageNumber;
      if (ps != pageSize) {
        page = 1
      }
      pageSize = ps
      this.searchList();
    }

    const registered = () => {
      //   this.setState({
      //     showManage: false,
      //     showRegistered: true,
      //   })
      this.props.history.push('/home/sys/label-manage/add');
    };
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          actionIds: selectedRowKeys
        })
      }
    };

    const sysColumns = [
      {
        title: '标签名称',
        dataIndex: 'name',
        render: (text, record) => <a href onClick={this.gotoDetail.bind(text, record.key)}>{text}</a>
      },
      //   {
      //     title: '标签编码',
      //     dataIndex: 'code',
      //   },
      //   {
      //     title: '类型',
      //     dataIndex: 'type',
      //   },
      {
        title: '所属用户',
        dataIndex: 'createBy', // 此处缺一个用户名
      },
      {
        title: '绑定用户',
        dataIndex: 'bindCount',
        render: (text, record) => {

          return (<a href onClick={this.gotoNode.bind(text, record.key)}>{text || 0}</a>);
        },
      },
      {
        title: '描述',
        dataIndex: 'description',
        width: '10%'
      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
      },
      {
        title: '操作',
        dataIndex: 'action',  // SYSTEM
        render: (text, record) => {

          // ****  当前登录用户是主用户  主用户不能编辑和删除 管理员和超级管理员的标签
          // ****  未实现 ~~~ 
          if (this.props.currentUser.type === AUTCONFIG.MAIN.value && (record.createBy !== this.props.currentUser.realName)) {
            return '';
          }
          // ****  当前登录用户是管理员  管理员不能编辑和删除 超级管理员的标签
          if (this.props.currentUser.type === AUTCONFIG.ADMIN.value && record.type === 'SYSTEM') {
            return '';
          }

          return (

            <span>
              <a href onClick={this.gotoEdit.bind(text, record.key)} style={{ marginRight: '5px' }}>编辑</a>
              <a href onClick={() => this.showDeleteConfirm(record.id)}>删除</a>
            </span>


          )
        }
      },

    ];



    return (
      <>
        <div className="common-pd20">
          <div style={{ display: (this.state.showManage === true) ? "block" : "none" }}>
            <h1 className="common-top-title0">标签管理</h1>
            <div className="common-center-container">
              <Search onClick={() => this.searchList()} tenantList={this.state.tenantList} />
              <div className="oper-button">
                {/* 管理禁止编辑 */}

                <Router>
                  <Button type="primary" onClick={registered}>  创建  </Button>
                </Router>

                {/* <Button style={{ marginLeft: 10 }} onClick={() => this.showMultiDeleteConfirm()}> 删除 </Button> */}
              </div>
              <div className="user-table-card">
                {/* <Table
                //   rowSelection={{
                //     ...rowSelection,
                //   }}
                  rowKey={record => record.id}
                  bordered={false}
                  pagination={false}
                  loading={this.state.loading}
                  columns={columns}
                  dataSource={this.state.labelList}
                /> */}

                <Table
                  //   rowSelection={{
                  //     ...rowSelection,
                  //   }}
                  rowKey={record => record.id}
                  bordered={false}
                  pagination={false}
                  loading={this.state.loading}
                  columns={sysColumns}
                  dataSource={this.state.labelList}
                />
              </div>
              <div>
                {
                  this.state.labelList.length > 0 &&
                  <div className="common-bottom-pagination">
                    <ConfigProvider locale={zhCN}>
                      <Pagination showSizeChanger defaultCurrent={1}
                        current={this.state.page} pageSize={this.state.pageSize} total={this.state.labelAmount} onChange={onPageChange} />
                    </ConfigProvider>
                  </div>
                }
              </div>
            </div>
          </div>
          {/* 创建 */}
          {/* <div style={{ display: (this.state.showRegistered === true) ? "block" : "none" }}>
            <CreateLabel onSubmit={this.printContent.bind(this)} />
          </div> */}
        </div>
      </>

    );
  }
}
// export default LabelManage;

export default connect(
  (state) => ({
    currentUser: state.currentUser
  })
)(LabelManage);