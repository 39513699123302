import React from 'react';
import './fringe-market.scss';
import { Divider, Input, Button } from 'antd';



let searchKey = '';
const { Search } = Input;
// let page = 1;
// let pageSize = 10;
function TopSearch(props) {
  const onSearch = (value) => {
    searchKey = value || '';
    props.onClick();
  };
  const gotoAdd = () => {
    props.gotoAdd();
  }
  return (
    <>
      <div className="market-search-form">
        <Search
          style={{ width: 800, height: 28, borderRadius: 4 }}
          placeholder="请输入关键字"
          allowClear
          enterButton="搜索"
          onSearch={onSearch}
        />
        <div className="base-btn-con">
          <Button className="base-btn" danger type="primary" onClick={gotoAdd}> 发布模型</Button>
        </div>
      </div>
    </>
  );
}



class FringeMarket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      typeItems: [],
      typeOption: [],
      searchType: null,
      cardList: [],
      resultList: [],
    };
  }
  componentDidMount() {
    this.setState({
      typeOption: [{ id: 1, name: '全部', selected: true },
      { id: 2, name: '分类一', selected: false }, { id: 3, name: '分类二', selected: false }, { id: 4, name: '分类三', selected: false }, { id: 5, name: '分类四', selected: false },
      { id: 6, name: '分类五', selected: false }, { id: 7, name: '分类七', selected: false }, { id: 8, name: '分类八', selected: false }, { id: 9, name: '分类九', selected: false },
      { id: 10, name: '分类十', selected: false }],

      resultList: [
        { id: 1, name: '人脸识别', type: { id: 2, name: '分类一' }, descrpition: '人脸识别产品利用AVS03A图像处理器，可以对人脸明暗侦测，自动调整动态曝光补偿，人脸追踪侦测，自动调整人脸。', version: 'v1.0.0', createTime: '2021-05-24 15:50:00' },
        { id: 2, name: '人脸识别', type: { id: 2, name: '分类一' }, descrpition: '人脸识别产品利用AVS03A图像处理器，可以对人脸明暗侦测，自动调整动态曝光补偿，人脸追踪侦测，自动调整人脸。', version: 'v1.0.0', createTime: '2021-05-24 15:50:00' },
        { id: 3, name: '人脸识别', type: { id: 2, name: '分类一' }, descrpition: '人脸识别产品利用AVS03A图像处理器，可以对人脸明暗侦测，自动调整动态曝光补偿，人脸追踪侦测，自动调整人脸。', version: 'v1.0.0', createTime: '2021-05-24 15:50:00' },
        { id: 4, name: '人脸识别', type: { id: 2, name: '分类一' }, descrpition: '人脸识别产品利用AVS03A图像处理器，可以对人脸明暗侦测，自动调整动态曝光补偿，人脸追踪侦测，自动调整人脸。', version: 'v1.0.0', createTime: '2021-05-24 15:50:00' },
        { id: 5, name: '人脸识别', type: { id: 2, name: '分类一' }, descrpition: '人脸识别产品利用AVS03A图像处理器，可以对人脸明暗侦测，自动调整动态曝光补偿，人脸追踪侦测，自动调整人脸。', version: 'v1.0.0', createTime: '2021-05-24 15:50:00' },
      ]
    }, function () {
      this.chooseBtn(this.state.typeOption[0]);
      this.getCardList(this.state.resultList);
    });
  }
  setTypes() {
    const arr = [];
    const typeOption = this.state.typeOption;
    for (let i = 0; i < typeOption.length; i++) {
      arr.push(<div className={typeOption[i].selected ? 'select-btn primary-btn' : 'primary-btn'} onClick={() => this.chooseBtn(typeOption[i])} >{typeOption[i].name}</div>);
    }
    this.setState({
      typeItems: arr
    })
  }
  chooseBtn(selected) {
    const typeOption = this.state.typeOption;
    typeOption.forEach(ele => {
      if (ele.id === selected.id) {
        ele.selected = true;
      } else {
        ele.selected = false;
      }
    })
    const typeItems = [];
    for (let i = 0; i < typeOption.length; i++) {
      typeItems.push(<div className={typeOption[i].selected ? 'select-btn primary-btn' : 'primary-btn'} onClick={() => this.chooseBtn(typeOption[i])} >{typeOption[i].name}</div>);
    };
    this.setState({
      typeOption: typeOption,
      typeItems: typeItems,
      searchType: selected
    }, function () {
      this.searchList();
    });
  }
  searchList() {
    console.log(this.state.searchType);
    console.log(searchKey)
  }
  gotoAdd() {
    this.props.history.push({ pathname: '/home/fringe-market/create' });
  }
  gotoDetail(result) {
    this.props.history.push({ pathname: '/home/fringe-market/detail', state: { result: result } }); // 传id即可
  }
  gotoEdit(result) {
    this.props.history.push({ pathname: '/home/fringe-market/edit', state: { result: result } }); // 传id即可
  }
  gotoBushu(id) {
    this.props.history.push({ pathname: '/home/node-manage/application/create-application', state: { id: id }  }); // 传id即可
  }
  getCardList(resultLists) {
    const arr = [];
    resultLists.forEach(ele => {
      arr.push(
        <div className="market-card">
          <div className="market-card-top">
            <img src="/images/face.jpg" alt="图标" />
            <div>
              <div className="market-card-top-title" onClick={() => this.gotoDetail(ele)}>{ele.name}</div>
              <div className="market-card-top-type">{ele.type.name}</div>
              <div className="market-card-top-desc">{ele.descrpition}</div>
            </div>
          </div>
          <Divider style={{ marginTop: 13, marginBottom: 13 }} />
          <div className="market-card-bottom">
            <div className="market-card-version">
              <div>版本: {ele.version}</div>
              <div>创建时间: {ele.createTime}</div>
            </div>
            <Button className="market-card-btn" onClick={() => this.gotoBushu(ele.id)}>部署</Button>
            <Button className="market-card-btn" type="dashed" onClick={() => this.gotoEdit(ele)} style={{ marginLeft: 10 }}>编辑</Button>
          </div>
        </div>
      )
    });
    this.setState({
      cardList: arr
    })
  }
  render() {
    return (
      <div className="common-pd20">
        <div>
          <h1 className="common-top-title0">边缘市场</h1>
          <TopSearch onClick={() => this.searchList()} gotoAdd={() => this.gotoAdd()} />
          <div className="market-type">
            <div className="market-type-title">类别：</div>
            <div className="market-type-container">
              {this.state.typeItems}
            </div>
          </div>
          <div className="market-card-container">
            {this.state.cardList}
          </div>
        </div>
      </div>
    );
  }
}

export default FringeMarket;