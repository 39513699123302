import { handleResponse } from './handle-response';
import store from '../../store/index';

export const logConfig = {
    getAllLogConfigs,
    getLogConfigDetial,
    addLogConfig,
    editLogConfig,
    deleteLogConfig,
    bulkAddLogConfigs,
}

//查询日志配置列表
function getAllLogConfigs(logType, logAddr, logLevel, nodeName, limit, offset) {
    return fetch(`/v1/console/logTasks?logType=${logType || ''}&logAddr=${logAddr || ''}&logLevel=${logLevel || ''}&nodeName=${nodeName || ''}&limit=${limit || 10}&offset=${offset || 1}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//查询日志配置详情
function getLogConfigDetial(id, name, limit, offset) {
    return fetch(`/v1/console/logTasks/${id}?name=${name || ''}&limit=${limit || 10}&offset=${offset || 1}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    }).then(handleResponse).then(res => {
        return res;
    });
}

//新增日志配置
function addLogConfig(logType, logAddr, logLevel, nodeUuids) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            logType: logType,
            logAddr: logAddr,
            logLevel: logLevel,
            nodeUuids: nodeUuids,
        })
    };
    return fetch(`/v1/console/logTasks`, requestOptions)
    .then(handleResponse)
    .then(res => {
        return res;
    });
}

//编辑日志配置
function editLogConfig(id, logType, logAddr, logLevel, nodeUuids, description) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            namlogTypee: logType,
            logAddr: logAddr,
            logLevel: logLevel,
            nodeUuids: nodeUuids,
            description: description,
        })
    };
    return fetch(`/v1/console/logTasks/${id}`, requestOptions)
    .then(handleResponse)
    .then(res => {
        return res;
    });
}

//删除日志配置
function deleteLogConfig(id, nodeName) {
    return fetch(`/v1/console/logTasks/${id}/node?nodeName=${nodeName || ''}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//批量操作日志任务
function bulkAddLogConfigs(edgeLogTasks, nodeUuids, overFlag) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            edgeLogTasks: edgeLogTasks,
            nodeUuids: nodeUuids,
            overFlag: overFlag,
        })
    };
    return fetch(`/v1/console/logTasks/batch`, requestOptions)
    .then(handleResponse)
    .then(res => {
        return res;
    });
}