import { Breadcrumb, Card, Col, Row, Form, Table, Input, Button, message } from 'antd';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import BackPage from '../../../common/backPage';
import BottomBtnGroup from '../../../common/bottomBtnGroup';
import { mirrorWarehouseService } from '../../../../services/mirror-warehouse.service';
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 20 },
};
class editMirror extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      imageName: '',
      versionNum: 0,
      totalSize: 0,
      createTime: '',
      updateTime: '',
      description: '',
      descriptionId: -1,
      showManage: true,
    };
  }
  componentDidMount() {
    const data = this.props?.location?.state?.record;
    if (data) {
      this.setState(
        {
          imageName: data.name || '',
          versionNum: data.versionNum || '',
          totalSize: data.totalSize || '',
          createTime: data.createTime || '',
          updateTime: data.updateTime || '',
        }
      )
      this.searchDescription(data.name);
      sessionStorage.setItem('imageName', data.name || '');
      sessionStorage.setItem('versionNum', data.versionNum || '');
      sessionStorage.setItem('totalSize', data.totalSize || '');
      sessionStorage.setItem('createTime', data.createTime || '');
      sessionStorage.setItem('updateTime', data.updateTime || '');

    } else {
      if (sessionStorage.getItem('imageName')) {
        const imageName = sessionStorage.getItem('imageName');
        const versionNum = sessionStorage.getItem('versionNum');
        const totalSize = sessionStorage.getItem('totalSize');
        const createTime = sessionStorage.getItem('createTime');
        const updateTime = sessionStorage.getItem('updateTime');
        this.setState(
          {
            imageName,
            versionNum,
            totalSize,
            createTime,
            updateTime,
          }
        )
        this.searchDescription(imageName);
      } else {
        this.goback();
      }
    }
  }
  componentWillUnmount() {
    sessionStorage.removeItem('imageName');
    sessionStorage.removeItem('versionNum');
    sessionStorage.removeItem('totalSize');
    sessionStorage.removeItem('updateTime');
    sessionStorage.removeItem('createTime');
  }

  searchDescription(name) {
    mirrorWarehouseService.getDescription(name).then(res => {
      this.setState({
        loading: false
      })
      if (res.code === 200) {
        console.log('镜像描述', res.description);//
        this.setState({
          description: res.description?.description,
          descriptionId: res.description?.id
        });
        this.formRef.current?.setFieldsValue({
          description: res.description.description
        });
      } 
      // else {
      //   message.error(res.message);
      // }
    }).catch(error => {
      this.setState({
        loading: false
      });
      message.error(error);
    })
  }


  goback() {

    this.props.history.push('/home/mirror-warehouse/list');
  }
  submit() {
    if (this.props.onSubmit) {
      var { showManage } = this.state;
      this.props.onSubmit({ showManage })
    }
  }
  onFinish() {

  }
  render() {
    const onFinish = (values) => {
      const name = this.props?.location?.state?.record?.name || '';
      const id = this.state.descriptionId;
      const des = values.description;
      mirrorWarehouseService.editDescription(id, des).then(res => {
        if (res.code === 200) {
          message.success("操作成功")
          this.goback();
        } 
        // else {
        //   message.error(res.message);
        // }
      }).catch(error => {
        message.error(error);
      })
    }
    return (
      <>
        <div className="common-detail-container">
          <BackPage onClick={() => this.goback()} headeTitle={'编辑镜像 -- ' + this.state.imageName} itemTitle="编辑镜像" itemSubTitle="查看任务" />
          <div style={{ background: '#fff', padding: "10px 20px 20px" }}>
            <Form name="complex-form"
              onFinish={onFinish}
              labelAlign="left"
              ref={this.formRef}
              {...formItemLayout}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="versionNum"
                    label="版本数目"
                  >
                    {this.state.versionNum || 0} 个
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="totalSize"
                    label="占用大小"
                  >
                    {this.state.totalSize || 0} G
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="createTime"
                    label="创建时间"
                  >
                    {this.state.createTime || '---'}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="updateTime"
                    label="更新时间"
                  >
                    {this.state.updateTime || '---'}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name="description" label="描述"
                  >
                    <TextArea
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      showCount maxLength={255} />
                  </Form.Item>
                </Col>
              </Row>


              <div style={{ marginLeft: "40px" }}>
                <BottomBtnGroup onClick={() => this.goback()} />
              </div>
            </Form>
          </div>
        </div>


      </>

    );
  }
}

export default editMirror;