import { createStore, applyMiddleware, compose } from 'redux';
// import thunk from 'redux-thunk'; 
import reducer from './reducer';
import createSagaMiddleware from 'redux-saga'
import listSagas from './sagas';
 import storage from 'redux-persist/lib/storage'
// import storageSession from 'redux-persist/lib/storage/session'
// import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;
const sagaMiddleware = createSagaMiddleware();

const enhancer = composeEnhancers(
  // applyMiddleware(thunk),
  applyMiddleware(sagaMiddleware)
);



const persistConfig = {
  key: 'root',
  storage: storage
}
const myPersistReducer = persistReducer(persistConfig, reducer)

const store = createStore(
  myPersistReducer, enhancer
);
sagaMiddleware.run(listSagas)
export const persistor = persistStore(store)

export default store;






// const store = createStore(myPersistReducer, applyMiddleware(thunk))
// export default store