import React from 'react';
import ReactDOM from 'react-dom';
import { Layout, Menu, Dropdown, ConfigProvider, Affix, BackTop } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import { MenuUnfoldOutlined, MenuFoldOutlined, DownOutlined,VerticalAlignTopOutlined, CaretDownOutlined, CaretUpOutlined, ToTopOutlined } from '@ant-design/icons';
import { HashRouter as Router, Link, Switch } from 'react-router-dom';

import FrontendAuth from '../../../frontend-auth';
import homeRouters from './home-routers';
import ScrollToTop from '../../../utils/scrollToTop';

import store from '../../../store/index';
import { getTokenChangeAction } from '../../../store/actionCreators';
import { getCurrentAction } from '../../../store/actionCreators';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from '../../../store/index';
import { loginService } from '../../services/login';
import { homeService } from '../../services/home.service';
import { connect } from 'react-redux'

import './home.scss'


const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const menuList = [
  {
    text: '首页',
    link: '/home/first-page',
    key: 1
  },
  {
    text: '边缘节点',
    link: '/home/node-manage',
    key: 2
  },
  {
    text: 'OTA资源库',
    link: '/home/ota-factory',
    key: 6
  },
  {
    text: '边缘市场',
    link: '/home/fringe-market',
    key: 16
  },
  {
    text: '运维管理',
    key: 14,
    children: [
      {
        text: '日志服务',
        link: '/home/operation/service',
        key: 141
      },
      {
        text: '任务日志',
        link: '/home/operation/task',
        key: 142
      },
      {
        text: '操作日志',
        link: '/home/operation/action',
        key: 143
      },
    ]
  },
  {
    text: '批量作业',
    key: 4,
    children: [
      {
        text: '批量升级',
        link: '/home/batch-job',
        key: 41
      },
    ]
  },
  {
    text: '系统管理',
    key: 8,
    children: [
      {
        text: '用户管理',
        link: '/home/sys/user-manage',
        key: 81
      },
      // {
      //   text: '角色管理',
      //   link: '/home/sys/access-policy',
      //   key: 83
      // },
      {
        text: '标签管理',
        link: '/home/sys/label-manage',
        key: 84
      }
    ]
  }
]
const menuItems = [];
for (let i = 0; i < menuList.length; i++) {
  if (menuList[i].children && menuList[i].children.length > 0) {
    const subMenuItems = []
    for (let j = 0; j < menuList[i].children.length; j++) {
      if (menuList[i].children[j].children && menuList[i].children[j].children.length > 0) {
        const subMenuItems2 = [];
        for (let z = 0; z < menuList[i].children[j].children.length; z++) {
          subMenuItems2.push(<Menu.Item key={menuList[i].children[j].children[z].key}><Link to={menuList[i].children[j].children[z].link}>{menuList[i].children[j].children[z].text}</Link></Menu.Item>)
        }
        subMenuItems.push(<SubMenu key={menuList[i].children[j].key} title={menuList[i].children[j].text}>
          {subMenuItems2}
        </SubMenu>)
      } else {
        subMenuItems.push(<Menu.Item key={menuList[i].children[j].key}><Link to={menuList[i].children[j].link}>{menuList[i].children[j].text}</Link> </Menu.Item>)
      }
    }
    // const menuItem = [];
    menuItems.push(
      <SubMenu key={menuList[i].key} icon={menuList[i].ico} title={menuList[i].text}>
        {subMenuItems}
      </SubMenu>
    )
    // this.setState({
    //   menuItems: menuItem
    // })
  } else {
    // const menuItem = [];
    menuItems.push(
      <Menu.Item key={menuList[i].key} icon={menuList[i].ico}>
        <Link to={menuList[i].link}>{menuList[i].text}</Link>
      </Menu.Item>
    )
    //  this.setState({
    //   menuItems: menuItem
    // })
  }
}


class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      menuItems: [],
      menuList: [],
      selectedKeys: []
    };
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  //   async getCurrentUser() {

  //     const response  = await fetch(`/v1/console/users/current`, {
  //         headers: {
  //             'Content-Type': 'application/json;charset=UTF-8',
  //             Authorization: `Bearer ${this.props.authToken}`
  //         }
  //     });
  //     const data = await response.json();

  //   }
  componentDidMount() {
    // console.log(store.getState().authToken)
    //console.log(this.props.currentUser);
    //   this.getMenu();
    // if (!store.getState().currentUser) {
    //   loginService.getCurrentUser().then(res => {
    //     if (res.code === 200) {
    //       const currentUser = res.users;
    //       const action2 = getCurrentAction(currentUser);
    //       store.dispatch(action2);
    //     }
    //   });
    // }
    // this.getCurrentUser();

    // console.log(window.location.hash);

    this.props.authToken && this.createMenuItems();
    const that = this;
    require("history").createBrowserHistory().listen(route => {
      const hashname = route.hash.slice(1);
      if (hashname !== '/home/user-center') {
        sessionStorage.setItem('lastPath', hashname)
      };
      const ditem = that.state.menuList.find(elx => elx.link === hashname);
      ditem && that.setState({
        selectedKeys: [ditem.key]
      })
    });
  }
  componentWillReceiveProps() {

  }

  async createMenuItems() {
    const res = await homeService.getMenuList();

    const resources = res.resources || [];
    const menuList = [];
    const hashname = window.location.hash.slice(1);
    const selectedKeys = [];
    const menuArray = [];
    const createMenus = (nodes, menus) => {
      nodes.forEach((elx) => {
        const no = {
          text: elx.name,
          key: elx.id + ''
        };
        if (elx.url) {
          no.link = elx.url
        }
        if (hashname === elx.url) {
          selectedKeys.push(elx.id + '');
        }
        if (elx.childResource && elx.childResource.length > 0) {
          no.children = no.children || [];
          createMenus(elx.childResource, no.children)
        }
        menus.push(no);
        menuArray.push({
          text: elx.name,
          key: elx.id + '',
          link: elx.url
        }
        );
      })
    };
    createMenus(resources, menuList);
    const menuItems = [];
    for (let i = 0; i < menuList.length; i++) {
      if (menuList[i].children && menuList[i].children.length > 0) {
        const subMenuItems = []
        for (let j = 0; j < menuList[i].children.length; j++) {
          if (menuList[i].children[j].children && menuList[i].children[j].children.length > 0) {
            const subMenuItems2 = [];
            for (let z = 0; z < menuList[i].children[j].children.length; z++) {
              subMenuItems2.push(<Menu.Item key={menuList[i].children[j].children[z].key}><Link to={menuList[i].children[j].children[z].link}>{menuList[i].children[j].children[z].text}</Link></Menu.Item>)
            }
            subMenuItems.push(<SubMenu key={menuList[i].children[j].key} title={menuList[i].children[j].text}>
              {subMenuItems2}
            </SubMenu>)
          } else {
            subMenuItems.push(<Menu.Item key={menuList[i].children[j].key}><Link to={menuList[i].children[j].link}>{menuList[i].children[j].text}</Link> </Menu.Item>)
          }
        }
        // const menuItem = [];
        menuItems.push(
          <SubMenu key={menuList[i].key} icon={menuList[i].ico} title={menuList[i].text}>
            {subMenuItems}
          </SubMenu>
        )
        // this.setState({
        //   menuItems: menuItem
        // })
      } else {
        // const menuItem = [];
        menuItems.push(
          <Menu.Item key={menuList[i].key} icon={menuList[i].ico}>
            <Link to={menuList[i].link} >{menuList[i].text}</Link>
          </Menu.Item>
        )
        //  this.setState({
        //   menuItems: menuItem
        // })
      }
    }




    this.setState({
      menuItems: menuItems,
      menuList: menuArray,
      selectedKeys
    })

  }
  render() {
    const loginout = () => {

      loginService.loginOut().then(res => {
        localStorage.clear();
        const action = getTokenChangeAction('');
        store.dispatch(action);
        const action2 = getCurrentAction('');
        store.dispatch(action2);
        document.cookie = '';
      }).catch(error => {
        localStorage.clear();
        const action = getTokenChangeAction('');
        store.dispatch(action);
        const action2 = getCurrentAction('');
        store.dispatch(action2);
        document.cookie = '';
      })
      //  window.location.href = '/login';
    }
    const menu = (
      <Menu>
        <Menu.Item key="1">
          {/* <span onClick={() => this.gotoUserCenter()}>个人中心</span> */}
          <Link to='/home/user-center'>个人中心</Link>
        </Menu.Item>
        <Menu.Item key="3" onClick={loginout}>退出登录</Menu.Item>
      </Menu>
    );

    const customHistory = require("history").createBrowserHistory();

    const expandIcon = (props) => {
      if (props.isOpen) {
        return (<span style={{ "position": "absolute", "right": "8px", "top": "2%" }}><CaretUpOutlined style={{ color: '#5C6173', fontSize: '12PX' }} /></span>);;
      } else {
        return (<span style={{ "position": "absolute", "right": "8px", "top": "2%" }}><CaretDownOutlined style={{ color: '#5C6173', fontSize: '12PX' }} /></span>);
      }

    }
    return (
      <ConfigProvider locale={zhCN}>
        <div>
          <Router>
            <ScrollToTop>
              <div>
                <Layout>
                  <Header className="site-layout-background" style={{ padding: 0 }}>
                    <div className="logo"><img src="images/title.png" width={117.6} height={56}/></div>
                    {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                      className: 'trigger',
                      onClick: this.toggle,
                    })}
                    <div className="help">
                      {/* <img src="images/login-bg.jpg" alt="" /> */}
                      <Dropdown overlay={menu} trigger={['click']}>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()} href style={{ color: '#fff' }}>
                          {this.props.currentUser?.realName} <DownOutlined />
                        </a>
                      </Dropdown>
                    </div>
                  </Header>

                  <Layout className="site-layout">
                    <Affix offsetTop={0}>
                      <Sider trigger={null} collapsible collapsed={this.state.collapsed} style={{ overflow: 'auto', height: '100vh' }} >
                        <Menu mode="inline" selectedKeys={this.state.selectedKeys} expandIcon={expandIcon}>
                          {this.state.menuItems}
                          {/* {menuItems} */}
                        </Menu>
                      </Sider>
                    </Affix>
                    <Layout>
                      {/* <Affix offsetTop={0} onChange={affixed => console.log(affixed)}> */}

                      <Content className="site-layout-background" style={{ minHeight: 280 }} >
                        <div id="content">
                          <Provider store={store}>
                            <PersistGate loading={null} persistor={persistor}>
                              <Router history={customHistory}>
                                <div>
                                  <Switch>
                                    <FrontendAuth routerConfig={homeRouters} />
                                  </Switch>
                                </div>
                              </Router>
                            </PersistGate>
                          </Provider>
                        </div>
                          <BackTop />
                      </Content>
                      {/* </Affix> */}
                    </Layout>
                  </Layout>
                </Layout>
              </div>
            </ScrollToTop>
          </Router>
        </div>
      </ConfigProvider>
    );
  }
}

ReactDOM.render(<Home />, document.getElementById('root'));

// export default Home;


export default connect(
  (state) => ({
    currentUser: state.currentUser,
    authToken: state.authToken
  })
)(Home);
