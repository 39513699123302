import { Card, Select, Row, Col, Spin, message, Result, Empty, Alert } from 'antd';
import React, { Component } from 'react';
import { AUTCONFIG } from '../../../../utils/auth.config';
import store from '../../../../store/index';
import { FrownOutlined } from '@ant-design/icons';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';

import './first-page.scss'

import { connect } from 'react-redux';
import { firstPage } from '../../../services/first-page.service';
import { edgeService } from '../../../services/edge.service'

const echarts = require('echarts');
const { Option } = Select;

const typeOption = ['CPU使用率Top5', '内存使用率Top5', 'GPU使用率Top5', '硬盘使用率Top5']
const nodesData = {
    'CPU使用率Top5': [],
    '内存使用率Top5': [],
    'GPU使用率Top5': [],
    '硬盘使用率Top5': []
};
const nodesIdData = {};//节点ID信息
const appsData = {
    'CPU使用率Top5': [],
    '内存使用率Top5': [],
    'GPU使用率Top5': [],
    '硬盘使用率Top5': []
};
const appsIdData = {};//应用ID信息

class FirstPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nodeOnlineCount: 0,
            nodeTotalCount: 0,
            deviceOnlineCount: 0,
            deviceTotalCount: 0,
            instanceCount: 0,
            imageCount: 0,
            userCount: 0,

            type: 0,
            nodes: [],
            ifNodeData: true,
            secondNode: '',
            nodeLoading: true,

            type_1: 0,
            appLoading: true,
            ifAppData: true,
            apps: [],
            secondApp: '',

            //用于加载优化
            ifNodeNumData: true,
            NodeNumLoading: true,

            ifDeviceNumData: true,
            DeviceNumLoading: true,

            ifAppNumData: true,
            AppNumLoading: true,

            ifImageNumData: true,
            ImageNumLoading: true,


            rate: [],
            upTime: [],
            rate_1: [],
            upTime_1: [],

            userNodes: [],
            countNodes: [],
            userDevices: [],
            countDevices: [],
            userApps: [],
            countApps: [],
            userImages: [],
            countImages: [],

            myChart1: null,
            myChart2: null,
            myChart3: null,
            myChart4: null,
            myChart5: null,
            myChart6: null,
            loading: true,
        };
    }
    componentDidMount() {
        if (this.props.currentUser && (this.props.currentUser?.type === AUTCONFIG.MAIN.value)) {
            this.getNodesMonitor(0)
            this.getAppsMonitor(0);
            this.getFirstPageStates();
        }
        else if (this.props.currentUser && (this.props.currentUser?.type === AUTCONFIG.SUB.value)) {
            this.getNodesMonitor(0)
            // this.getAppsMonitor(0);
            this.getFirstPageStates();
        }
        else if (this.props.currentUser && (this.props.currentUser?.type === AUTCONFIG.ADMIN.value ||
            this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value)) {
            this.getFirstPageStates();
            this.getFirstPageNodes();
            this.getFirstPageDevices();
            this.getFirstPageApps();
            this.getFirstPageImages();
        } else {
            //解决登录进入首页页面不刷新问题
            setTimeout(() => {
                if (this.props.currentUser && (this.props.currentUser?.type === AUTCONFIG.MAIN.value)) {
                    this.getNodesMonitor(0)
                    this.getAppsMonitor(0);
                    this.getFirstPageStates();
                }
                else if (this.props.currentUser && (this.props.currentUser?.type === AUTCONFIG.SUB.value)) {
                    this.getNodesMonitor(0)
                    // this.getAppsMonitor(0);
                    this.getFirstPageStates();
                }
                else if (this.props.currentUser && (this.props.currentUser?.type === AUTCONFIG.ADMIN.value ||
                    this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value)) {
                    this.getFirstPageStates();
                    this.getFirstPageNodes();
                    this.getFirstPageDevices();
                    this.getFirstPageApps();
                    this.getFirstPageImages();
                }
            }, 1000)
        }

        //this.getFirstPageImages();
        //this.initChart();
        this.setState({
            loading: false
        })

        // let timeOutID;
        // window.onresize = () => {
        //     if(timeOutID) clearTimeout(timeOutID);
        //     timeOutID =  setTimeout(() => {
        //         this.state.myChart1 &&  this.state.myChart1.resize(); 
        //         this.state.myChart2 &&  this.state.myChart2.resize(); 
        //         this.state.myChart3 &&  this.state.myChart3.resize(); 
        //         this.state.myChart4 &&  this.state.myChart4.resize(); 
        //         this.state.myChart5 &&  this.state.myChart5.resize(); 
        //         this.state.myChart6 &&  this.state.myChart6.resize();  
        //     }, 300);
        // }


    }

    componentWillReceiveProps() {

    }

    //查询节点监控TOP
    getNodesMonitor(type) {
        firstPage.getNodeMonitor(type).then(res => {
            if (res.code === 200) {
                console.log('节点监控TOP信息', res.nodes);
                const nodesList = res.nodes;
                const name = typeOption[type];
                nodesData[name] = [];
                nodesList.map(value => {
                    nodesData[name]?.push(value.name);
                    nodesIdData[value.name] = value.id;
                })
                this.setState({
                    type,
                    nodes: nodesData[typeOption[type]],
                    secondNode: nodesData[typeOption[type]][0]
                })
                const opt = typeOption[type];
                const selectId = nodesIdData[nodesData[opt][0]];
                if(!selectId){
                    this.setState({
                        nodeLoading: false,
                        ifNodeData: false
                    })
                   return 
                }
                this.getNodeStats(selectId, type);
            }
            // else {
            //     message.error('获取信息失败，' + res.message);
            //     this.setState({
            //         nodeLoading: false,
            //         ifNodeData: false
            //     })
            // }
        }).catch(error => {
            error && message.error(error);
            this.setState({
                nodeLoading: false,
                ifNodeData: false
            })
        })
    }
    //查询节点监控数据
    getNodeStats(id, type) {
        firstPage.getNodeStats(id).then(res => {
            if (res.code === 200) {
                console.log("返回的节点监控数据", res);
                let rateOpt = '';
                let opt = '';
                switch (type) {
                    case 0:
                        rateOpt = 'cpuRate';
                        opt = 'CPU使用率%';
                        break;
                    case 1:
                        rateOpt = 'memRate';
                        opt = '内存使用率%';
                        break;
                    case 2:
                        rateOpt = 'gpuRate';
                        opt = 'GPU使用率%';
                        break;
                    case 3:
                        rateOpt = 'diskRate';
                        opt = '硬盘使用率%';
                        break;
                }
                // //拼接相应的数组
                const rate = res.nodeStats.map(item => item[rateOpt]);
                const upTime = res.nodeStats.map(item => item.upTime)
                // console.log(rate);
                this.setState({
                    rate,
                    upTime,
                    nodeLoading: false,
                    ifNodeData: true
                })
                this.createChart1(opt);
            }
            // else {
            //     message.error('获取信息失败，' + res.message);
            //     this.setState({
            //         nodeLoading: false,
            //         ifNodeData: false
            //     })
            // }
        }).catch(error => {
            console.log('eeee');
            error && message.error(error);
            this.setState({
                nodeLoading: false,
                ifNodeData: false
            })
        })
    }
    //选择节点监控类型
    selectNodeTop(value) {
        this.getNodesMonitor(value);
        this.setState({
            nodeLoading: true
        })
    }
    // 选择节点
    selectNode(value) {
        this.setState({
            secondNode: value,
            nodeLoading: true
        })
        const selectId = nodesIdData[value];
        const type = this.state.type;
        if(!selectId){
            this.setState({
                nodeLoading: false,
                ifNodeData: false
            })
           return 
        }
        this.getNodeStats(selectId, type);
    }

    //查询应用监控TOP
    getAppsMonitor(type) {
        firstPage.getAppMonitor(type).then(res => {
            if (res.code === 200) {
                console.log('应用监控TOP信息', res.instances);
                const appsList = res.instances;
                const name = typeOption[type];
                appsData[name] = [];
                appsList.map(value => {
                    appsData[name]?.push(value.name);
                    appsIdData[value.name] = value.id;
                })
                this.setState({
                    type_1: type,
                    apps: appsData[typeOption[type]],
                    secondApp: appsData[typeOption[type]][0]
                })
                const opt = typeOption[type];
                const selectId = appsIdData[appsData[opt][0]];
                // console.log(selectId);
                if(!selectId){
                    this.setState({
                        appLoading: false,
                        ifAppData: false
                    })
                   return 
                }
                this.getAppStats(selectId, type);
            }
            // else {
            //     message.error('获取信息失败，' + res.message);
            //     this.setState({
            //         appLoading: false,
            //         ifAppData: false
            //     })
            // }
        }).catch(error => {
            error && message.error(error);
            this.setState({
                appLoading: false,
                ifAppData: false
            })
        })
    }

    //查询应用监控数据
    getAppStats(id, type) {
        firstPage.getAppStats(id).then(res => {
            if (res.code === 200) {
                console.log("返回的监控数据", res);
                let rateOpt = '';
                let opt = '';
                switch (type) {
                    case 0:
                        rateOpt = 'cpuRate';
                        opt = 'CPU使用率%';
                        break;
                    case 1:
                        rateOpt = 'memRate';
                        opt = '内存使用率%';
                        break;
                    case 2:
                        rateOpt = 'gpuRate';
                        opt = 'GPU使用率%';
                        break;
                    case 3:
                        rateOpt = 'diskRate';
                        opt = '硬盘使用率%';
                        break;
                }

                // //拼接相应的数组
                const rate = res.stats.map(item => item[rateOpt]);
                const upTime = res.stats.map(item => item.upTime)
                // console.log(rate);
                this.setState({
                    rate_1: rate,
                    upTime_1: upTime,
                    appLoading: false,
                    ifAppData: true
                })
                this.createChart2(opt);
            }
            // else {
            //     message.error('获取信息失败，' + res.message);
            //     this.setState({
            //         appLoading: false,
            //         ifAppData: false
            //     })
            // }
        }).catch(error => {
            error && message.error(error);
            this.setState({
                appLoading: false,
                ifAppData: false
            })
        })
    }
    //选择应用监控类型
    selectAppTop(value) {
        this.getAppsMonitor(value);
        this.setState({
            appLoading: true
        })
    }
    // 选择应用
    selectApp(value) {
        this.setState({
            secondApp: value,
            appLoading: true
        })

        const selectId = appsIdData[value];
        const type = this.state.type_1;
        if(!selectId){
            this.setState({
                appLoading: false,
                ifAppData: false
            })
           return
        }
         this.getAppStats(selectId, type);
    }

    //调用首页运行状态接口
    getFirstPageStates() {
        firstPage.getStatisticsStates().then(res => {
            if (res.code === 200) {
                console.log("运行情况返回的数据为", res);

                this.setState({
                    nodeOnlineCount: res.states.nodeOnlineCount,
                    nodeTotalCount: res.states.nodeTotalCount,
                    deviceOnlineCount: res.states.deviceOnlineCount,
                    deviceTotalCount: res.states.deviceTotalCount,
                    instanceCount: res.states.instanceCount,
                    imageCount: res.states.imageCount,
                    userCount: res.states.userCount,
                })
            }
        }).catch(error => {
            error && message.error(error);
        })
    }

    //调用首页节点数量接口
    getFirstPageNodes() {
        firstPage.getStatisticsNodes().then(res => {
            if (res.code === 200) {
                console.log("节点数量返回的数据为", res);
                //拼接相应的数组
                const userNodes = res.nodes.map(item => item.user)
                const countNodes = res.nodes.map(item => item.count)
                this.setState({
                    userNodes,
                    countNodes,
                    ifNodeNumData: true,
                    NodeNumLoading: false,
                })

                this.createChart3();
            }
        }).catch(error => {
            error && message.error(error);
            this.setState({
                ifNodeNumData: false,
                NodeNumLoading: false,
            })
        })
    }

    //调用首页设备数量接口
    getFirstPageDevices() {
        firstPage.getStatisticsDevices().then(res => {
            if (res.code === 200) {
                console.log("设备数量返回的数据为", res);
                //拼接相应的数组
                const userDevices = res.devices.map(item => item.user)
                const countDevices = res.devices.map(item => item.count)
                this.setState({
                    userDevices,
                    countDevices,
                    ifDeviceNumData: true,
                    DeviceNumLoading: false,
                })

                this.createChart4();
            }
        }).catch(error => {
            error && message.error(error);
            this.setState({
                ifDeviceNumData: false,
                DeviceNumLoading: false,
            })
        })
    }

    //调用首页应用数量接口
    getFirstPageApps() {
        firstPage.getStatisticsApps().then(res => {
            if (res.code === 200) {
                console.log("应用数量返回的数据为", res);
                //拼接相应的数组
                const userApps = res.apps.map(item => item.user)
                const countApps = res.apps.map(item => item.count)
                this.setState({
                    userApps,
                    countApps,
                    ifAppNumData: true,
                    AppNumLoading: false,
                })
                this.createChart5();
            }
        }).catch(error => {
            error && message.error(error);
            this.setState({
                ifAppNumData: false,
                AppNumLoading: false,
            })
        })
    }

    //调用首页镜像数量接口
    getFirstPageImages() {
        firstPage.getStatisticsImages().then(res => {
            if (res.code === 200) {
                console.log("镜像数量返回的数据为", res);
                //拼接相应的数组
                const userImages = res.images.map(item => item.user)
                const countImages = res.images.map(item => item.count)
                this.setState({
                    userImages,
                    countImages,
                    ifImageNumData: true,
                    ImageNumLoading: false,
                })
                this.createChart6();
            }
        }).catch(error => {
            error && message.error(error);
            this.setState({
                ifImageNumData: false,
                ImageNumLoading: false,
            })
        })
    }
    //     initChart() {
    //         if(this.props.currentUser && (this.props.currentUser?.type !== AUTCONFIG.ADMIN.value && 
    //                                       this.props.currentUser?.type !== AUTCONFIG.SUPERADMIN.value)) {
    //             this.createChart1();
    //             this.createChart2();
    //         } else if(this.props.currentUser && (this.props.currentUser?.type === AUTCONFIG.ADMIN.value  || 
    //                                              this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value)){
    //             this.createChart3();
    //             this.createChart4();
    //             this.createChart5();
    //             this.createChart6();
    //         }
    //    }

    createChart1(opt) {
        if (!document.getElementById('chart1')) {
            return;
        }
        const chartOption = {
            title: {
                text: '',
                subtext: `${opt}`
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: this.state.upTime
            },
            yAxis: {
                type: 'value',
                axisTick: { //y轴刻度线
                    show: true
                },
                axisLine: { //y轴
                    show: true
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            dataZoom: [{
                id: "dataZoomX",
                type: 'inside',
                xAxisIndex: [0],
                filterMode: "none",
                start: 0,
                end: 100
            }],
            series: [{
                data: this.state.rate,
                type: 'line',
                areaStyle: {},
                smooth: true,
                itemStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                            offset: 0, color: '#0070cc' // 0% 处的颜色
                        }, {
                            offset: 1, color: '#0070cc' // 100% 处的颜色
                        }],
                        global: false // 缺省为 false
                    }
                },
                lineStyle: {
                    color: '#0070cc'
                },
            }]
        }
        const myChart1 = echarts.init(document.getElementById('chart1'));
        myChart1.setOption(chartOption);
        window.addEventListener('resize', function () {
            myChart1.resize()
        })
        //    this.state = { myChart1 };
    }
    createChart2(opt) {
        if (!document.getElementById('chart2')) {
            return;
        }
        const chartOption = {
            title: {
                text: '',
                subtext: `${opt}`
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: this.state.upTime_1
            },
            yAxis: {
                type: 'value',
                axisTick: { //y轴刻度线
                    show: true
                },
                axisLine: { //y轴
                    show: true
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            dataZoom: [{
                id: "dataZoomX",
                type: 'inside',
                xAxisIndex: [0],
                filterMode: "none",
                start: 0,
                end: 100
            }],
            series: [{
                data: this.state.rate_1,
                type: 'line',
                areaStyle: {},
                smooth: true,
                itemStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                            offset: 0, color: '#0070cc' // 0% 处的颜色
                        }, {
                            offset: 1, color: '#0070cc' // 100% 处的颜色
                        }],
                        global: false // 缺省为 false
                    }
                },
                lineStyle: {
                    color: '#0070cc'
                },
            }]
        }
        const myChart2 = echarts.init(document.getElementById('chart2'));
        myChart2.setOption(chartOption);
        window.addEventListener('resize', function () {
            myChart2.resize()
        })
        //    this.state = { myChart2 };
    }

    //节点数量图
    createChart3() {
        if (!document.getElementById('chart3')) {
            return;
        }
        const chartOption = {
            color: '#0070cc',
            xAxis: {
                type: 'category',
                data: this.state.userNodes,
                axisLabel: {
                    interval: 0,
                    rotate: 30,
                    textStyle: {
                        fontSize: 12,
                    }
                },
            },
            yAxis: {
                type: 'value',
                minInterval: 1,
                boundaryGap: [0, 0.1]
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#2F80ED'
                    }
                },
                formatter: (params) => {
                    const componet = params[0];
                    if (componet) {
                        return componet.name + '<br/>' + componet.marker + `<span style="float:right">${componet.data}</span>`;
                    }
                }
            },
            series: [{
                data: this.state.countNodes,
                type: 'bar',
                barMaxWidth: '50',
                itemStyle: {
                    normal: {
                        label: {
                            show: true, //开启显示
                            position: 'top', //在上方显示
                            textStyle: { //数值样式
                                fontSize: 10
                            }
                        }
                    }
                },
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.2)'
                }
            }]
        };
        const myChart3 = echarts.init(document.getElementById('chart3'));
        //window.onresize = myChart3.resize;
        myChart3.setOption(chartOption);
        window.addEventListener('resize', function () {
            myChart3.resize()
        })
        // this.state = { myChart3 };
    }

    //设备数量图
    createChart4() {
        if (!document.getElementById('chart4')) {
            return;
        }
        const chartOption = {
            color: '#0070cc',
            xAxis: {
                type: 'category',
                data: this.state.userDevices,
                axisLabel: {
                    interval: 0,
                    rotate: 30,
                    textStyle: {
                        fontSize: 12,
                    }
                },
            },
            yAxis: {
                type: 'value',
                minInterval: 1,
                boundaryGap: [0, 0.1]
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#2F80ED'
                    }
                },
                formatter: (params) => {
                    const componet = params[0];
                    if (componet) {
                        return componet.name + '<br/>' + componet.marker + `<span style="float:right">${componet.data}</span>`;
                    }
                }
            },
            series: [{
                data: this.state.countDevices,
                type: 'bar',
                barMaxWidth: '50',
                itemStyle: {
                    normal: {
                        label: {
                            show: true, //开启显示
                            position: 'top', //在上方显示
                            textStyle: { //数值样式
                                fontSize: 10
                            }
                        }
                    }
                },
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.2)'
                }
            }]
        };
        const myChart4 = echarts.init(document.getElementById('chart4'));
        //window.onresize = myChart4.resize;
        myChart4.setOption(chartOption);
        window.addEventListener('resize', function () {
            myChart4.resize()
        })
        // this.state = { myChart4 };
    }

    //应用数量图
    createChart5() {
        if (!document.getElementById('chart5')) {
            return;
        }
        const chartOption = {
            color: '#0070cc',
            xAxis: {
                type: 'category',
                data: this.state.userApps,
                axisLabel: {
                    interval: 0,
                    rotate: 30,
                    textStyle: {
                        fontSize: 12,
                    }
                },
            },
            yAxis: {
                type: 'value',
                minInterval: 1,
                boundaryGap: [0, 0.1]
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#2F80ED'
                    }
                },
                formatter: (params) => {
                    const componet = params[0];
                    if (componet) {
                        return componet.name + '<br/>' + componet.marker + `<span style="float:right">${componet.data}</span>`;
                    }
                }
            },
            series: [{
                data: this.state.countApps,
                type: 'bar',
                barMaxWidth: '50',
                itemStyle: {
                    normal: {
                        label: {
                            show: true, //开启显示
                            position: 'top', //在上方显示
                            textStyle: { //数值样式
                                fontSize: 10
                            }
                        }
                    }
                },
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.2)'
                }
            }]
        };
        const myChart5 = echarts.init(document.getElementById('chart5'));
        //window.onresize = myChart5.resize;
        myChart5.setOption(chartOption);

        window.addEventListener('resize', function () {
            myChart5.resize()
        })
        // this.state = { myChart5 };
    }

    //镜像数量图
    createChart6() {
        if (!document.getElementById('chart6')) {
            return;
        }
        const chartOption = {
            color: '#0070cc',
            xAxis: {
                type: 'category',
                data: this.state.userImages,
                axisLabel: {
                    interval: 0,
                    rotate: 30,
                    textStyle: {
                        fontSize: 12,
                    }
                },
            },
            yAxis: {
                type: 'value',
                minInterval: 1,
                boundaryGap: [0, 0.1]
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#2F80ED'
                    }
                },
                formatter: (params) => {
                    const componet = params[0];
                    if (componet) {
                        return componet.name + '<br/>' + componet.marker + `<span style="float:right">${componet.data}</span>`;
                    }
                }
            },
            series: [{
                data: this.state.countImages,
                type: 'bar',
                barMaxWidth: '50',
                itemStyle: {
                    normal: {
                        label: {
                            show: true, //开启显示
                            position: 'top', //在上方显示
                            textStyle: { //数值样式
                                fontSize: 10
                            }
                        }
                    }
                },
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.2)'
                }
            }]
        };
        const myChart6 = echarts.init(document.getElementById('chart6'));
        //window.onresize = myChart6.resize;
        myChart6.setOption(chartOption);
        window.addEventListener('resize', function () {
            myChart6.resize()
        })
        // this.state = { myChart6 };
    }


    render() {
        return (
            <Spin spinning={this.state.loading}>
                <div className="common-pd20">
                    <div className="first-page">
                        <Card>
                            <h3 className="title">运行情况</h3>
                            <div className="number">
                                <dl>
                                    <dd>
                                        <p>
                                            <span>{this.state.nodeOnlineCount}</span>/{this.state.nodeTotalCount}
                                        </p>
                                        <p className="name">节点数量（在线/全部）</p>
                                    </dd>
                                </dl>
                                {(this.props.currentUser?.type === AUTCONFIG.ADMIN.value
                                    || this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value
                                    || this.props.currentUser?.type === AUTCONFIG.MAIN.value) ?
                                    <dl>
                                        <dd>
                                            <p>
                                                <span>{this.state.deviceOnlineCount}</span>/{this.state.deviceTotalCount}
                                            </p>
                                            <p className="name">设备数量（在线/全部）</p>
                                        </dd>
                                    </dl> : ''
                                }
                                {(this.props.currentUser?.type === AUTCONFIG.ADMIN.value
                                    || this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value
                                    || this.props.currentUser?.type === AUTCONFIG.MAIN.value) ?
                                    <dl>
                                        <dd>
                                            <p>
                                                <span>{this.state.instanceCount}</span>
                                            </p>
                                            <p className="name">应用数量</p>
                                        </dd>
                                    </dl> : ''
                                }

                                {(this.props.currentUser?.type === AUTCONFIG.ADMIN.value
                                    || this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value
                                    || this.props.currentUser?.type === AUTCONFIG.MAIN.value) ?
                                    <dl>
                                        <dd>
                                            <p>
                                                <span>{this.state.imageCount}</span>
                                            </p>
                                            <p className="name">镜像数量</p>
                                        </dd>
                                    </dl> : ''
                                }
                                {(this.props.currentUser?.type === AUTCONFIG.ADMIN.value
                                    || this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value) ?
                                    <dl>
                                        <dd>
                                            <p>
                                                <span>{this.state.userCount}</span>
                                            </p>
                                            <p className="name">用户数量</p>
                                        </dd>
                                    </dl> : ''
                                }
                            </div>
                        </Card>
                        {(this.props.currentUser?.type === AUTCONFIG.SUB.value) ?
                            <Card style={{ marginTop: 16 }}>
                                <h3>
                                    <span>节点监控</span>
                                    <div>
                                        <Select defaultValue={typeOption[0]} style={{ marginRight: 15, width: 150 }} onChange={(value) => { this.selectNodeTop(value) }}>
                                            {
                                                typeOption.map((value, i) => (
                                                    <Option value={i} key={value}>{value}</Option>
                                                )
                                                )
                                            }
                                        </Select>
                                        <Select style={{ width: 250 }} value={this.state.secondNode} onChange={(value) => { this.selectNode(value) }}>
                                            {
                                                this.state.nodes?.map(value => (
                                                    <Option key={value}>{'节点：' + value}</Option>
                                                )
                                                )
                                            }
                                        </Select>
                                    </div>

                                </h3>
                                <Spin spinning={this.state.nodeLoading} tip="加载中">
                                    {
                                        this.state.ifNodeData ?
                                            <div id="chart1" style={{ height: 320, width: '100%', overflow: 'hidden' }}></div> :
                                            <div style={{ height: 320, paddingTop: 80, width: '100%', overflow: 'hidden' }}>
                                                <Empty />
                                            </div>
                                    }
                                </Spin>
                            </Card>
                            : ''
                        }
                        {(this.props.currentUser?.type === AUTCONFIG.MAIN.value) ?
                            <Row gutter={{ xs: 8, sm: 16, md: 16 }} style={{ paddingTop: '16px' }}>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <Card>
                                        <h3>
                                            <span>节点监控</span>
                                            <div>
                                                <Select defaultValue={typeOption[0]} style={{ marginRight: 15, width: 150 }} onChange={(value) => { this.selectNodeTop(value) }}>
                                                    {
                                                        typeOption.map((value, i) => (
                                                            <Option value={i} key={value}>{value}</Option>
                                                        )
                                                        )
                                                    }
                                                </Select>
                                                <Select style={{ width: 250 }} value={this.state.secondNode} onChange={(value) => { this.selectNode(value) }}>
                                                    {
                                                        this.state.nodes?.map(value => (
                                                            <Option key={value}>{'节点：' + value}</Option>
                                                        )
                                                        )
                                                    }
                                                </Select>
                                            </div>
                                        </h3>
                                        <Spin spinning={this.state.nodeLoading} tip="加载中">
                                            {
                                                this.state.ifNodeData ?
                                                    <div id="chart1" style={{ height: 320, width: '100%', overflow: 'hidden' }}></div> :
                                                    <div style={{ height: 320, paddingTop: 80, width: '100%', overflow: 'hidden' }}>
                                                        <Empty />
                                                    </div>
                                            }
                                        </Spin>

                                    </Card>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} >
                                    <Card>
                                        <h3>
                                            <span>应用监控</span>
                                            <div>
                                                <Select defaultValue={typeOption[0]} style={{ marginRight: 15, width: 150 }} onChange={(value) => { this.selectAppTop(value) }}>
                                                    {
                                                        typeOption.map((value, i) => (
                                                            <Option value={i} key={value}>{value}</Option>
                                                        )
                                                        )
                                                    }
                                                </Select>
                                                <Select style={{ width: 250 }} value={this.state.secondApp} onChange={(value) => { this.selectApp(value) }}>
                                                    {
                                                        this.state.apps.map(value => (
                                                            <Option key={value}>{'应用：' + value}</Option>
                                                        )
                                                        )
                                                    }
                                                </Select>
                                            </div>

                                        </h3>
                                        <Spin spinning={this.state.appLoading} tip="加载中">
                                            {
                                                this.state.ifAppData ?
                                                    <div id="chart2" style={{ height: 320, width: '100%', overflow: 'hidden' }}></div> :
                                                    <div style={{ height: 320, paddingTop: 80, width: '100%', overflow: 'hidden' }}>
                                                        <Empty />
                                                    </div>
                                            }
                                        </Spin>
                                    </Card>
                                </Col>
                            </Row>
                            : ''
                        }

                        {(this.props.currentUser?.type === AUTCONFIG.ADMIN.value
                            || this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value) ?
                            <Row gutter={{ xs: 8, sm: 16, md: 16 }} style={{ paddingTop: '16px' }}>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <Card>
                                        <h3>
                                            <span>节点数量（用户）Top10</span>
                                        </h3>
                                        {/* <div id="chart3" style={{ height: 320, width: '100%', overflow: 'hidden' }}></div> */}
                                        <Spin spinning={this.state.NodeNumLoading} tip="加载中">
                                            {
                                                this.state.ifNodeNumData ?
                                                    <div id="chart3" style={{ height: 320, width: '100%', overflow: 'hidden' }}></div> :
                                                    <div style={{ height: 320, paddingTop: 80, width: '100%', overflow: 'hidden' }}>
                                                        <Empty />
                                                    </div>
                                            }
                                        </Spin>
                                    </Card>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} >
                                    <Card>
                                        <h3>
                                            <span>设备数量（用户）Top10</span>
                                        </h3>
                                        {/* <div id="chart4" style={{ height: 320, width: '100%', overflow: 'hidden' }}></div> */}
                                        <Spin spinning={this.state.DeviceNumLoading} tip="加载中">
                                            {
                                                this.state.ifDeviceNumData ?
                                                    <div id="chart4" style={{ height: 320, width: '100%', overflow: 'hidden' }}></div> :
                                                    <div style={{ height: 320, paddingTop: 80, width: '100%', overflow: 'hidden' }}>
                                                        <Empty />
                                                    </div>
                                            }
                                        </Spin>
                                    </Card>
                                </Col>
                            </Row>
                            : ''
                        }
                        {(this.props.currentUser?.type === AUTCONFIG.ADMIN.value
                            || this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value) ?
                            <Row gutter={{ xs: 8, sm: 16, md: 16 }} style={{ paddingTop: '16px' }}>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <Card>
                                        <h3>
                                            <span>应用数量（用户）Top10</span>
                                        </h3>
                                        {/* <div id="chart5" style={{ height: 320, width: '100%', overflow: 'hidden' }}></div> */}
                                        <Spin spinning={this.state.AppNumLoading} tip="加载中">
                                            {
                                                this.state.ifAppNumData ?
                                                    <div id="chart5" style={{ height: 320, width: '100%', overflow: 'hidden' }}></div> :
                                                    <div style={{ height: 320, paddingTop: 80, width: '100%', overflow: 'hidden' }}>
                                                        <Empty />
                                                    </div>
                                            }
                                        </Spin>
                                    </Card>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} >
                                    <Card>
                                        <h3>
                                            <span>镜像数量（用户）Top10</span>
                                        </h3>
                                        {/* <div id="chart6" style={{ height: 320, width: '100%', overflow: 'hidden' }}></div> */}
                                        <Spin spinning={this.state.ImageNumLoading} tip="加载中">
                                            {
                                                this.state.ifImageNumData ?
                                                    <div id="chart6" style={{ height: 320, width: '100%', overflow: 'hidden' }}></div> :
                                                    <div style={{ height: 320, paddingTop: 80, width: '100%', overflow: 'hidden' }}>
                                                        <Empty />
                                                    </div>
                                            }
                                        </Spin>
                                    </Card>
                                </Col>
                            </Row>
                            : ''
                        }
                    </div>
                </div>
            </Spin>
        );
    }
}


export default connect(
    (state) => ({
        currentUser: state.currentUser
    })
)(FirstPage);