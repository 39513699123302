import { Breadcrumb, Card } from 'antd';
import React, {Component} from 'react';
import ReactDOM from 'react-dom'; 
import BackPage from '../../../../common/backPage';

class DescriptionDetail extends React.Component {
    constructor(){
        super();
        this.state = {
          showManage: true
        };
    }
    goback() {

      this.props.history.push('/home/mirror-warehouse/view');
    }
    submit(){
        if(this.props.onSubmit){
            var {showManage} = this.state;
            this.props.onSubmit({showManage})
        }
       // this.setState({showManage});
    }
  render() {
     
    return (
      <>
       <div  className="common-pd20">
       <BackPage onClick={() => this.goback()} headeTitle='镜像描述' itemTitle="查看镜像" itemSubTitle="查看任务" />
        <Card bordered={false}>
           <p>
           　　从另一个角度来说，中国传统木结构建筑与海仿佛是有隔阂的。在实用性上，木材不耐海边的潮湿，使得它的寿命大打折扣；在文化意义上，中国传统建筑往往是与山川河流相依，它的木结构本身便是趋向于山林的一种美学趣味。中国山水画中，往往会有中国传统木结构建筑作为点睛之笔，以表现天人合一的审美境界。但这样的山水画中，却少有呈现海的。如果说山水文化是农业文明的重要篇章，那么海洋文化则是商业文明的重要篇章。而这样的海洋文明是从西方发端的，海边别墅应该算作是这种海洋文明的一部分。曾经几次去过海边旅行，到过上海外滩、厦门鼓浪屿，这些地方恰好都是此类建筑的聚集地。这些地方也都是游客的目的地，与其说游客是去这些地方看海，不如说是这些建筑给大海的无边无际提供了一个可以让想象登陆的彼岸。
           </p>
           <p>
           　　从另一个角度来说，中国传统木结构建筑与海仿佛是有隔阂的。在实用性上，木材不耐海边的潮湿，使得它的寿命大打折扣；在文化意义上，中国传统建筑往往是与山川河流相依，它的木结构本身便是趋向于山林的一种美学趣味。中国山水画中，往往会有中国传统木结构建筑作为点睛之笔，以表现天人合一的审美境界。但这样的山水画中，却少有呈现海的。如果说山水文化是农业文明的重要篇章，那么海洋文化则是商业文明的重要篇章。而这样的海洋文明是从西方发端的，海边别墅应该算作是这种海洋文明的一部分。曾经几次去过海边旅行，到过上海外滩、厦门鼓浪屿，这些地方恰好都是此类建筑的聚集地。这些地方也都是游客的目的地，与其说游客是去这些地方看海，不如说是这些建筑给大海的无边无际提供了一个可以让想象登陆的彼岸。
           </p>
           <p>
           　　从另一个角度来说，中国传统木结构建筑与海仿佛是有隔阂的。在实用性上，木材不耐海边的潮湿，使得它的寿命大打折扣；在文化意义上，中国传统建筑往往是与山川河流相依，它的木结构本身便是趋向于山林的一种美学趣味。中国山水画中，往往会有中国传统木结构建筑作为点睛之笔，以表现天人合一的审美境界。但这样的山水画中，却少有呈现海的。如果说山水文化是农业文明的重要篇章，那么海洋文化则是商业文明的重要篇章。而这样的海洋文明是从西方发端的，海边别墅应该算作是这种海洋文明的一部分。曾经几次去过海边旅行，到过上海外滩、厦门鼓浪屿，这些地方恰好都是此类建筑的聚集地。这些地方也都是游客的目的地，与其说游客是去这些地方看海，不如说是这些建筑给大海的无边无际提供了一个可以让想象登陆的彼岸。
           </p>
        </Card>
        </div>
      </>
       
    ); 
  }
}

export default DescriptionDetail;