/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Form, Input, Select, message } from 'antd';
import './../../../../../../index.scss';
import { userService } from '../../../../../services/user.service';
import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';
import { AUTCONFIG } from '../../../../../../utils/auth.config';

import {connect} from 'react-redux'
const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

class CreateUser extends React.Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      showManage: true,
      hasSubmit: false,
      selectType: AUTCONFIG.ADMIN.value
    };
  }
  goback() {
    // this.formRef.current.resetFields();
    // if (this.props.onSubmit) {
    //   var { showManage } = this.state;
    //   this.props.onSubmit({ showManage })
    // }
    this.props.history.push('/home/sys/user-manage');
  }
  onSelectTypeChange(value) {
    this.setState({
      selectType: value
    })
  
  }
  componentDidMount() {
    
      this.getCompanyList();
      setTimeout(() => {
        this.formRef.current.setFieldsValue({ type: AUTCONFIG.ADMIN.value });
      }, 300)
     
  }
  getCompanyList() {
    userService.getCompanyList().then(res => {
        const getCompanyItem =  (companys) => {
            return companys.map((item, index) => {
                return (
                  <Option key={item.id} value={item.id}>{item.name}</Option>
                );
              });
        }
        if (res.code === 200) {
          const companys = res.companys;
          this.setState({
            companyList: getCompanyItem(companys),
          });
        }
      }, error => {
        error && message.error( error);
      });
  }
  render() {
    const onFinish = (values) => {
     
        if(this.state.hasSubmit) {
            return ;
        }
        this.setState({
            hasSubmit: true
        })
      message.loading('用户创建中~~', 2500);
      if(this.props.currentUser.type === AUTCONFIG.SUPERADMIN.value) {
         if(values.type === AUTCONFIG.ADMIN.value) {
           values.type  = 3;
           values.companyId  = '';
         }
         if(values.type === AUTCONFIG.MAIN.value) {
            values.type  = 1;
        }
      }
      // 管理员创建时候
      if(this.props.currentUser.type === AUTCONFIG.ADMIN.value) {
         values.type = 1 
      }
      // 主用户创建时候
      if(this.props.currentUser.type === AUTCONFIG.MAIN.value) {
         values.type = 2 
      }
      userService.addUser(values.userName, 
                          values.name, 
                          values.password, 
                          values.mobile, 
                          values.email, 
                          values.companyId, 
                          values.description, 
                          values.address,
                          values.type).then(res => {
        message.destroy();
        this.setState({
            hasSubmit: false
        })
        if (res.code === 200) {
          message.success('创建用户成功');
          this.props.history.push('/home/sys/user-manage');
        } 
        // else {
        //   message.success('创建用户失败，' + res.message);
        // }
      }, error => {
        message.destroy();
        this.setState({
            hasSubmit: false
        })
        error && message.error( error);
      });
    };
    return (
      <>
        <div className="common-pd20">
        <BackPage onClick={() => this.goback()} headeTitle="创建用户" itemTitle="用户管理" itemSubTitle="创建用户" />
        <div className="common-create-center-container">
          <div className="common-create-page">
            <Form
                ref={this.formRef}
                name="validate_other"
                labelAlign="left"
                {...formItemLayout}
                onFinish={onFinish} >
              <Form.Item name="userName" label="账号名" rules={[{ required: true, message: '请输入账号名' },
               {max: 64, message: '账号名不允许超过64个字符'}, 
               { pattern: new RegExp(/^[0-9a-zA-Z-_\u4e00-\u9fa5]{1,}$/, "g") , message: '请输入64位以内中英文/数字/中划线/下划线'}]} >
                <Input placeholder="请输入账号名" />
              </Form.Item>
              <Form.Item name="name" label="用户名称" rules={[
                { required: true, message: '请输入用户名称' },
                {max: 64, message: '用户名称不允许超过64个字符'}, 
                { pattern: new RegExp(/^[0-9a-zA-Z-_\u4e00-\u9fa5]{1,}$/, "g") , message: '请输入64位以内中英文/数字/中划线/下划线'}]}>
                <Input placeholder="请输入用户名称" />
              </Form.Item>
              <Form.Item name="password" label="密码" rules={[{ required: true, message: '请输入密码' },
              { pattern:/^(?![a-z]+$)(?![A-Z]+$)(?!\d+$)(?![!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]{6,23}$/, 
              message: '6-23位，至少包含以下两种：大写字母、小写字母、数字、特殊字符，且不能输入中文与空格' }]} >
                <Input.Password placeholder="请输入密码" />
              </Form.Item>
              <Form.Item name="mobile" label="手机号码" rules={[{ required: true, message: '请输入手机号码' },{ pattern: /^[1][3,4,5,7,8][0-9]{9}$/, message: '请输入正确的手机号' }]}>  
                <Input placeholder="请输入手机号码" />
              </Form.Item>
              <Form.Item name="email" label="电子邮箱" rules={[{ type: 'email', message: '请输入正确的电子邮箱' }]}>
                <Input placeholder="请输入电子邮箱" />
              </Form.Item>
              { this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value ? 
              <Form.Item name='type' label='用户类型' rules={[{ required: true, message: '请选择用户类型' }]}>
                <Select style={{ width: 300 }} 
                        placeholder="请选择用户类型" 
                        onChange={(value) => {
                          this.onSelectTypeChange(value);
                        }}
                        initialvalue={AUTCONFIG.ADMIN.value}>
                  <Option value={AUTCONFIG.ADMIN.value}>管理员</Option>
                  <Option value={AUTCONFIG.MAIN.value}>主用户</Option>
                </Select>
              </Form.Item> : ''
              }
              {/* 如果是超级管理员 选择用户类型为 主用户时 出现企业选项 如果当前登录用户为管理员是 默认出现 */}
              {(this.props.currentUser?.type === AUTCONFIG.ADMIN.value || 
                 this.state.selectType === AUTCONFIG.MAIN.value) ? 
              <Form.Item name="companyId" label="企业"  rules={[{ required: true, message: '请选择企业名称' },{ pattern: /^(a-z|A-Z|0-9)*[^$%^&*;:,<>?()\""\']{0,50}$/, message: '请输入50位以内字符' }]}>
              <Select style={{ width: '300px' }} placeholder="请选择企业"  allowClear
              showSearch
                                        filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.companyList}
                                    </Select>
              </Form.Item> : ''
               }
                <Form.Item name="address" label="联系地址" >
                                        <Input placeholder="请输入联系地址" />
                  </Form.Item>
              <Form.Item name="description" label="描述"  rules={[{ pattern: /^(a-z|A-Z|0-9)*[^$%^&*;:,<>?()\""\']{0,255}$/, message: '请输入255位以内字符' }]}>
                    <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" />
                </Form.Item>
             
              <BottomBtnGroup onClick={() => this.goback()} />
              {/* <div className="common-bottom-btns-card">
                <Row>
                  <Col span={24} style={{ padding: '26px 200px 26px 560px' }}>
                    <Button type="primary" htmlType="submit">确定</Button>
                    <Button style={{ marginLeft: '9.5px' }} onClick={this.goback.bind(this)} >取消</Button>
                  </Col>
                </Row>
              </div> */}
            </Form>
          </div>
        </div>
        </div>
      </>
    );
  }
}

//export default CreateUser;


export default connect(
    (state) => ({
        currentUser: state.currentUser
    })
)(CreateUser);