import React from "react";
import {
  Form,
  Input,
  Button,
  Table,
  Pagination,
  ConfigProvider,
  message,
} from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import { userService } from "../../../../services/user.service";

import "./../../../../../index.scss";
import "./user-manage.scss";
import { connect } from "react-redux";
import store from "../../../../../store";
import { AUTCONFIG } from "../../../../../utils/auth.config";
import { searchRule } from "../../../../../utils/search-util";

let searchUsername = "";
let searchRealName = "";
let page = 1;
let pageSize = 10;

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = { type: "" };
  }
  form = React.createRef();

  onFinish = (values) => {
    let username = values.username || "";
    let str = searchRule(username);
    searchUsername = str || "";

    let realName = values.realName || "";
    let str1 = searchRule(realName);
    searchRealName = str1 || "";
    page = 1;
    this.props.onClick();
  };
  resetForm = () => {
    this.form.current.resetFields();
    searchUsername = "";
    searchRealName = "";
    this.setState({
      type: "",
    });
    this.form.current.setFieldsValue({ type: "" });
    page = 1;
    this.props.onClick();
  };

  componentDidMount() {
    this.resetForm();
  }

  render() {
    return (
      <>
        <div className="common-search-form">
          <Form
            ref={this.form}
            name="advanced_search_user"
            className="ant-advanced-search-form"
            onFinish={this.onFinish}
            layout="inline"
            onValuesChange={this.onGenderChange}
            // style={{ justifyContent: 'center' }}
          >
            <Form.Item
              name="username"
              label="账号名称"
              style={{ marginTop: "8px" }}
            >
              <Input placeholder="请输入账号名称" />
            </Form.Item>
            <Form.Item
              name="realName"
              label="用户名称"
              style={{ marginTop: "8px" }}
            >
              <Input placeholder="请输入用户名称" />
            </Form.Item>
            <Form.Item style={{ marginTop: "8px" }}>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
              <Button style={{ margin: "0 9.46px" }} onClick={this.resetForm}>
                重置
              </Button>
            </Form.Item>
          </Form>
        </div>
      </>
    );
  }
}

class UserManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      userAmount: 0,
      actionIds: [],
      loading: false,
      page: 1,
      pageSize: 10,
      tenantList: [],
    };
  }
  searchList() {
    this.setState({
      loading: true,
    });
    userService
      .getUserList(searchRealName, searchUsername, "", page, pageSize)
      .then(
        (res) => {
          if (res.code === 200) {
            const users = res.users;
            users.forEach((element) => {
              element.key = element.id;
            });
            this.setState({
              userList: users,
              loading: false,
              userAmount: res.dataCount,
            });
          }
        },
        (error) => {
          this.setState({
            loading: false,
          });
          error && message.error(error);
        }
      );
  }

  componentWillMount() {
    // this.getCurrentUser();
  }
  componentDidMount() {
    pageSize = 10;
    page = 1;
    this.searchList();
  }
  render() {
    const onPageChange = (pageNumber, ps) => {
      console.log(pageNumber, ps);
      page = pageNumber;
      if (ps != pageSize) {
        page = 1;
      }
      pageSize = ps;
      this.setState({
        page,
        pageSize,
      });
      this.searchList();
    };
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        const actionIds = this.state.actionIds;
        this.state.userList.forEach(elx => {
          actionIds.find(item => item === elx.id) !== undefined && actionIds.splice(actionIds.indexOf(elx.id), 1);
        });
        this.setState({
          actionIds: actionIds.concat(selectedRowKeys),
        });
        this.props.selectedRowKeys(selectedRowKeys)
      },
      selectedRowKeys: this.state.actionIds,

    };

    const columns = [
      {
        title: "账号名",
        dataIndex: "username",
      },
      {
        title: "用户名称",
        dataIndex: "realName",
      },
      {
        title: "手机号码",
        dataIndex: "mobile",
      },
      {
        title: "企业",
        dataIndex: "company",
        render: (text, record) => <span>{text?.name || "-"}</span>,
      },
    ];
    return (
      <>
        <Search
          onClick={() => this.searchList()}
          currentUser={this.props.currentUser}
          tenantList={this.state.tenantList}
        />
        <div className="user-table-card">
          <Table
            rowSelection={{
              ...rowSelection,
            }}
            rowKey={(record) => record.id}
            bordered={false}
            tableLayout="fixed"
            pagination={false}
            loading={this.state.loading}
            columns={columns}
            dataSource={this.state.userList}
          />
        </div>
        <div>
          {this.state.userList.length > 0 && (
            <div
              className="common-bottom-pagination"
              style={{ marginTop: "18px" }}
            >
              <ConfigProvider locale={zhCN}>
                <Pagination
                  showSizeChanger
                  defaultCurrent={1}
                  current={this.state.page}
                  pageSize={this.state.pageSize}
                  total={this.state.userAmount}
                  onChange={onPageChange}
                />
              </ConfigProvider>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default connect((state) => ({
  currentUser: state.currentUser,
}))(UserManage);
