import React from 'react';

import { Select, Form, Col, Input, Button, Table, Pagination, ConfigProvider, Modal, message, InputNumber, Badge } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from 'react-router-dom';

import { nodeService } from '../../../../../services/node.service';
import { labelService } from '../../../../../services/label.service';
import { edgeService } from '../../../../../services/edge.service';

import './../../../../../../index.scss';
import CreateApplication from '../../../application/create-application/create-application';
import { searchRule } from '../../../../../../utils/search-util'

const { confirm } = Modal;

let page = 1;
let pageSize = 10;
let searchEdgeName = '';
let searchStatus = '';

//select
const { Option } = Select;

function Search(props) {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    let str=''
    if (values.edgeName != null) {
      str = searchRule(values.edgeName)
      console.log(str); 
    }
    searchEdgeName = str || '';
    searchStatus = values.status || '';
    page = 1;
    props.onClick();
  };
  const resetForm = () => {
    form.resetFields();
    searchEdgeName = '';
    searchStatus = '';
    page = 1;
    pageSize = 10;
    props.onClick();
  };
  return (
    <>
      <div className="common-search-form">
        <Form
          form={form}
          name="advanced_search"
          layout="inline"
          // style={{justifyContent:"center"}}
          className="ant-advanced-search-form"
          onFinish={onFinish} >
         
            <Form.Item name='edgeName' label='应用名称' style={{ marginTop:'8px' }}>
              <Input placeholder="请输入应用名称" style={{ width: '240px' }} />
            </Form.Item>
            <Form.Item name='status' label='应用状态' style={{ marginTop:'8px' }}>
              <Select style={{ width: '150px' }} placeholder="请选择应用状态" >
              {props.stateList}
              </Select>
            </Form.Item>
            <Form.Item  style={{ marginTop:'8px' }}>
              <Button type="primary" htmlType="submit"> 查询 </Button>
              <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
            </Form.Item>
        
        </Form>
      </div>
    </>
  );
}

//联选镜像数组
let defineArr = [{ name: '', things: [], doings: [] },];            
let selectVersion = [];//升级的镜像对应的版本数组
let ids = [];//升级，对应镜像版本id的数组，找到所要升级的镜像id

class Application extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      stateList: [],//状态下拉框选项集合
      page: 1,
      pageSize: 10,
      edgeAmount: null,
      isModalVisible: false, //单应用升级弹窗是否可见
      configIsModalVisible: false,  //单应用调整资源弹窗是否可见
      appList: [],
      nodeAmount: 0,
      nodeUuid: this.props?.nodeUuid,

      deleteIds: [],
      showUpgrade: false,
      showQuota: false,
      history: require("history").createHashHistory,
    };
  }

  /* 升级 */
  showUpgrade = (id) => {
    this.setState({
      showUpgrade: true,
    });
  };

  hideModalUpgrade = () => {
    this.setState({
      showUpgrade: false,
    });
  };
  /* 配额 */
  showQuota = (id) => {
    this.setState({
      showQuota: true,
    });
  };

  hideModalQuota = () => {
    this.setState({
      showQuota: false,
    });
  };


  searchList() {
    this.setState({
      loading: true
    });

    edgeService.getEdgeList(searchEdgeName, searchStatus, this.props?.nodeName, '', '', page, pageSize).then(res => {
      if (res.code === 200) {
        console.log("查找的列表信息为:",res);
        const apps = res.apps;
        this.setState({
          appList: apps,
          loading: false,
          edgeAmount: res.dataCount
        })
      }
    }).catch(error => {
      error && message.error(error);
    })
  }
  componentDidMount() {
    console.log(this.props);
    searchEdgeName = '';
    searchStatus = '';
    this.getStateList();
    this.searchList();
    this.getImage()
    this.backInterval = setInterval(()=>{
      this.searchList()
    }, 30000)
  }

  //页面跳转清除定时器
  componentWillUnmount() {
    clearInterval(this.backInterval)
  }

  getLableItem(labels) {
    return labels.map((item, index) => {
      return (
        <Option key={item.id} >{item.name}</Option>
      );
    });
  }

  getStateList() {
    const arr = [
      { id: 1, state: 'RUNNING', name: '运行中' },
      { id: 2, state: 'STOPPED', name: '已停止' },
      { id: 3, state: 'FAILED', name: '异常' },
      { id: 4, state: 'WAITING', name: '等待' },
      { id: 5, state: 'UPGRADEWAITING', name: '待升级' },
      { id: 6, state: 'UPGRADING', name: '升级中' },
      { id: 7, state: 'OFFLINE', name: '不在线' },
    ];
    this.setState({
      stateList: this.getStateItem(arr)
    });
  }
  getStateItem(states) {
    return states.map((item) => {
      return (
        <Option key={item.state} >{item.name}</Option>
      );
    });
  }

  //启动的弹框提示
  showEnaConfirm(event) {
    if (event.oprCode === 25) {
        message.warning('已经启用了')
    } else {
    confirm({
      title: `确定启动${event.name}吗?`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        //调用启用接口
        this.getStart(event.id)

        console.log("25表示启动，此时的操作码为：",event.oprCode);
        message.success('操作成功！');
        this.searchList()
    },
    onCancel() {
    },
  });
  }
  
  }

  //调用启动接口
  getStart(id) {
    this.setState({
      loading: true
    });

    edgeService.getEdgeStart(id).then(res => {
      if (res.code === 200) {
        console.log("启动的反馈：",res);
      }
    })
  }
  
  //停止的弹框提示
  showDisConfirm(event) {
    if (event.oprCode === 24) {
      console.log("hdedfheufhe");
      message.warning('已经停止了')
    } else {
      confirm({
        title: `确定停止${event.name}?`,
        icon: <ExclamationCircleOutlined />,
        onOk: () => {
          //调用停止接口
          this.getStop(event.id)

          console.log("24表示停止，此时的操作码为:",event.oprCode);
          message.success('操作成功！');
          this.searchList()
        },
        onCancel() {
        },
      });
    }
  }

  //调用停止接口
  getStop(id) {
    this.setState({
      loading: true
    });
    
    edgeService.getEdgeStop(id).then(res => {
      if (res.code === 200) {
        console.log("停止的反馈：",res);
      }
    })
  }

  //删除的弹框提示
  showDeleteConfirm(event) {
    confirm({
      title: '确定删除吗?',
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      onOk: () => {
        console.log("要删除的id为：",event.id);
        this.deleteApp(event.id)
        message.success('操作成功！');
        page = 1;
        this.searchList()
      },
      onCancel() {

      },
    });
  }

  //调用删除接口
  deleteApp(id) {
    this.setState({
      loading: true
    });

    edgeService.deleteApp(id).then(res => {
      if (res.code === 200) {
        console.log("删除的反馈",res);
      }
    })
  }

  //调用升级接口
  upAPP(id, imageId) {
    edgeService.upgradeEdge(id, imageId).then(res => {
      if (res.code === 200) {
        console.log(res);
        message.success("操作成功！")
      }
    }).catch(error => {
      error && message.error(error);
    })
  }

  //调用调整资源接口
  adjustAPP(id, mem, cpu, gpu) {
    edgeService.adjustSourceEdge(id, mem, cpu, gpu).then(res => {
      if (res.code === 200) {
        console.log(res);
        message.success("操作成功！")
      }
    }).catch(error => {
      error && message.error(error);
    })
  }

  //编辑应用
  editEdge = (text, key) => {
    this.props.history.push({ pathname: '/home/effect-edge-m/edit', state: { id: text } });
  }

  //镜像数组的合并
  mergeArr(images) {
    let testArr = [];
    let resultArr = [];
    for (let i = 0; i < images.length; i++) {
      if (testArr.indexOf(images[i].name) === -1) {
        resultArr.push({
        name: images[i].name,
        things: [images[i].version],
        doings: [images[i].imageUrl],
        ids: [images[i].id],
      });
      testArr.push(images[i].name);
      }
      else {
        for (let j = 0; j < resultArr.length; j++) {
          if (resultArr[j].name == images[i].name) {
            resultArr[j].things.push(images[i].version);
            resultArr[j].doings.push(images[i].imageUrl);
            resultArr[j].ids.push(images[i].id);
            break;
          }
        }
      }
    }
    return resultArr
  }

  //镜像查询接口
  getImage() {
    edgeService.getImageList().then(res => {
      if (res.code == 200) {
        console.log("查找的镜像信息为：", res);
        if (res.images.length != 0) {
          defineArr = this.mergeArr(res.images)
        }
        
        console.log(defineArr);
        
        // this.setState({
        //   //images: res.images,
        // })
      }
    })
  }

  //升级
  showShenjiWin(event) {
    console.log("该应用信息",event);
    this.getImage()

    console.log(defineArr);

    for (let i = 0; i < defineArr.length; i++){
      if (defineArr[i].name === event.imageName) {
        selectVersion = defineArr[i].things;
        ids = defineArr[i].ids

        this.formRef.current?.setFieldsValue({
        version: event.imageVersion,
        })
        
        break;
      } else {
          selectVersion = []
          this.formRef.current?.setFieldsValue({
            version: '',
        })
      }
    }
    console.log(selectVersion);
    console.log(ids);

    console.log(event.imageVersion);


    this.setState({
      isModalVisible: true,
      id: event.id,
      imageName: event.imageName,
      imageVersion: event.imageVersion,
    })

    console.log("该应用信息",event);
  }

  //调整资源
  showAdjust(event) {
    console.log(event);

    this.formRef.current?.setFieldsValue({
      CPU: event.cpuLimit,
      GPU: event.gpuLimit,
      MEM: event.memLimit,
    })

    this.setState({
      configIsModalVisible: true,
      id: event.id,
      cpuLimit: event.cpuLimit,
      gpuLimit: event.gpuLimit,
      memLimit: event.memLimit,
    })
  }

  //取消按钮关闭弹窗
  handleOk() {
    this.setState({
        isModalVisible: false,
        configIsModalVisible: false,
        appsModalVisible: false,
    })
  }


  getOperByRecordState(text, record) {
    return (
      <>
        {
          record.state === 'STOPPED' ?
            <a href onClick={() => this.showEnaConfirm(record)} style={{ marginRight: '5px' }}>启用</a>
            : ''
        }
        
        {
          record.state === 'RUNNING' ?
            <a href onClick={() => this.showDisConfirm(record)} style={{ marginRight: '5px' }}>停止</a>
            : ''
        }
        
        {
          record.state === 'RUNNING' || record.state === 'FAILED'?
            <a href onClick={this.editEdge.bind(text, record.id)} style={{ marginRight: '5px' }}>编辑</a>
            : ''
        }
        
        {
          record.state === 'RUNNING' || record.state === 'FAILED'?
            <a href onClick={() => this.showShenjiWin(record)} style={{ marginRight: '5px' }}>升级</a>
            : ''
        }
        
        {
          record.state === 'RUNNING' || record.state === 'FAILED'?
            <a href onClick={() => this.showAdjust(record)} style={{ marginRight: '5px' }}>调整资源</a>
            : ''
        }
        {/* <a href onClick={() => this.showDeleteConfirm(record)} style={{ marginRight: '5px' }}>删除</a> */}
        {
          record.state === 'STOPPED' || record.state === 'FAILED'  ?
            <a href onClick={() => this.showDeleteConfirm(record)} style={{ marginRight: '5px' }}>删除</a>
            : ''
        }
        
      </>
    );
  }

  getState = (record) =>{
    if (record.state === 'RUNNING') {
      return <span><Badge status="success" />运行中</span>
    }
    else if (record.state === 'STOPPED') {
      return <span><Badge status="default" />已停止</span>
    }
    else if (record.state === 'FAILED') {
      return <span><Badge status="error" />异常</span>
    }
    else if (record.state === 'WAITING') {
      return <span><Badge status="warning" />等待</span>
    }
    else if (record.state === 'UPGRADEWAITING') {
      return <span><Badge status="warning" />待升级</span>
    }
    else if (record.state === 'UPGRADING') {
      return <span><Badge status="warning" />升级中</span>
    }
    else if (record.state === 'OFFLINE') {
      return <span><Badge status="error" />不在线</span>
    }
  }

  gotoDetail = (text, key) => {
    console.log(text);
    this.props.history.push({ pathname: '/home/node-manage/application/view-application', state: { id: text } });
  }

  render() {
    const onPageChange = (pageNumber) => {
      page = pageNumber;
      this.searchList();
    }
    const onShowSizeChange = (current, pageSizeNumber) => {
      pageSize = pageSizeNumber;
      page = 1;
      this.searchList();
    }
  
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          deleteIds: selectedRowKeys
        })
      }
    };

    //创建应用
    const registered = () => {
      this.props.history.push({ pathname:'/home/node-manage/application/create-application', state: { nodeUuid: this.state.nodeUuid } });
    }

    const columns = [
      {
        title: '应用名称',
        dataIndex: 'name',
        render: (text, record) => <a onClick={this.gotoDetail.bind(text, record.id)}>{text}</a>,
      },
      {
        title: '应用镜像',
        dataIndex: 'imageName',
      },
      {
        title: '镜像版本',
        dataIndex: 'imageVersion',
      },
      {
        title: '所属用户',
        dataIndex: 'createBy',
      },
      {
        title: '状态',
        dataIndex: 'state',
        render: (text, record) => (
          <>
            {
              this.getState(record)
            }
          </>
        ),
      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
      },
      {
        title: '更新时间',
        dataIndex: 'updateTime',
      },
      {
        title: '操作',
        dataIndex: 'action',
        width: '20%',
        //参数分别为当前行的值，当前行数据，行索引
        render: (text, record) => (
          <>
            {this.getOperByRecordState(text, record)}
          </>
        )
      },
    ];

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const layout1 = {
      labelCol: { span: 7 },
      wrapperCol: { span: 16 },
    };

    //升级下拉选项
    const things = selectVersion.map((item, index)=> {
      return <Option key={index} value={item}>{item}</Option>
    })

    //确定升级
    const onFinish = (e) => {
      e.imageName=this.state.imageName
      console.log(e);
      console.log(this.state.imageVersion);
      console.log(selectVersion);
      console.log(ids);
      console.log(this.state.id);

      let imageId = 0
      if (e.version === this.state.imageVersion) {
        message.warning("修改版本为当前版本！")
      } else {
        imageId = ids[selectVersion.indexOf(e.version)]
        console.log(selectVersion.indexOf(e.version));
        console.log(ids[selectVersion.indexOf(e.version)]);
        this.upAPP(this.state.id, imageId)
        this.searchList()
      }
      this.setState({
        isModalVisible: false
      })
    }

    //确定调整资源
    const adjustFinish = (e) => {
      e.CPU=parseFloat(e.CPU)
      e.GPU=parseFloat(e.GPU)
      e.MEM=parseFloat(e.MEM)
      console.log(e);
      console.log(this.state.cpuLimit);
      console.log(this.state.id);
      
      if (e.CPU === this.state.cpuLimit && e.GPU === this.state.gpuLimit && e.MEM === this.state.memLimit) {
        message.warning("未对资源进行修改！")
      } else {
        this.adjustAPP(this.state.id, e.MEM, e.CPU, e.GPU)
        this.searchList()
      }

      this.setState({
        configIsModalVisible: false,
      })
      
    }


    return (
      <>
        <div>
          <div>
            <div className="common-center-container" style={{ padding: 0 }}>
              <Search stateList={this.state.stateList} onClick={() => this.searchList()} />
              <div className="oper-button">
                  <Router>
                    <Button type="primary" onClick={registered}>创建</Button>
                  </Router>
              
              </div>
              <div className="user-table-card">
                <Table
                  rowSelection={{
                    ...rowSelection,
                  }}
                  rowKey={record => record.id}
                  bordered={false}
                  pagination={false}
                  columns={columns}
                  dataSource={this.state.appList}
                />
              </div>
              <div>
                {
                  this.state.appList.length > 0 &&
                  <div className="common-bottom-pagination">
                    <ConfigProvider locale={zhCN}>
                      <Pagination showSizeChanger onShowSizeChange={onShowSizeChange} defaultCurrent={1} current={page} pageSize={pageSize} total={this.state.edgeAmount} onChange={onPageChange} />
                    </ConfigProvider>
                  </div>
                }
              </div>
            </div>
          </div>


        </div>

        {/* 升级 */}
        <Modal title="应用升级" visible={this.state.isModalVisible} cancelText okText closable={false}>
          <Form {...layout}
            ref={this.formRef}
            name="control-ref"
            onFinish={onFinish}
            initialValues={{ imageName: "male", version: this.state.imageVersion }}
          >
             
            <Form.Item name="imageName" label="应用镜像">
                <Select disabled style={{width: 220}}>
                    <Option value="male">{this.state.imageName}</Option>
                </Select>
            </Form.Item>
            
            <Form.Item name="version" label="镜像版本" >
                <Select  
                    style={{width: 220}}>
                    {/* <Option value="male">{defineArr[1].things[0]}</Option>
                    <Option value="female">{defineArr[1].things[1]}</Option>
                    <Option value="other">{defineArr[1].things[2]}</Option> */}
                  {things}
                </Select>
            </Form.Item>
            <br/>
              <Form.Item  style={{marginLeft:250,marginBottom:-70}}>
                <Button type="primary" htmlType="submit"    > 确定 </Button>
                <Button onClick={this.handleOk.bind(this)} style={{marginLeft:10}} >取消</Button>
                </Form.Item>
                </Form>
        </Modal>

        {/* 配额 */}
        <Modal title="调整应用资源配置" visible={this.state.configIsModalVisible} cancelText okText closable={false}>
          <Form {...layout1}
            ref={this.formRef}
            name="control-refd"
            onFinish={adjustFinish}
            initialValues={{ CPU: this.state.cpuLimit, GPU: this.state.gpuLimit,  MEM: this.state.memLimit}}
          >
            <Form.Item name="CPU" label="CPU">
              <Input type="number" min={0} max={10} step="0.01"
                  //defaultValue={this.state.memory}
                  addonAfter="核"
                  style={{ width: 220 }}
                />
            </Form.Item>

            <Form.Item name="MEM" label="内存" >
                <Input type="number" min={0} max={8192} step="0.01" 
                  //defaultValue={this.state.memory}
                  addonAfter="M"
                  style={{ width: 220 }}
                />
            </Form.Item>

            <Form.Item name="GPU" label="GPU" >
                <Input type="number" min={0} max={10} disabled={true} step="0.01"
                  //defaultValue={this.state.memory}
                  addonAfter="核"
                  style={{ width: 220 }}
                />
            </Form.Item>

            <br/>
              <Form.Item  style={{marginLeft:230,marginBottom:-70}}>
                <Button type="primary" htmlType="submit" > 确定 </Button>
                <Button onClick={this.handleOk.bind(this)} style={{marginLeft:10}} >取消</Button>
                </Form.Item>
            </Form>
        </Modal>

      </>
    );
  }
}
export default Application;