
import React, { Component } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Form, Row, Col, Input, Button, Table, Pagination, ConfigProvider, Modal, message, Select } from 'antd';
import './geographical-distribution.scss'
// import { AUTCONFIG } from '../../../../utils/auth.config';
// import store from '../../../../../store';
import { geographyService } from '../../../../services/geography-distribution.service';
import icon from '../../../.././../icon.png'
const { BMap } = window;
class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = { type: 'subuser' };
    }
    form = React.createRef();

    onFinish = (values) => {
    };
    componentDidMount() {

    }
    resetForm = () => {

    }

    render() {
        return (
            <>
                <div className="common-search-form">
                    <Form
                        ref={this.form}
                        name="advanced_search_label"
                        className="ant-advanced-search-form"
                        layout="inline"
                        onValuesChange={this.onGenderChange}
                        style={{ justifyContent: 'center' }}
                        onFinish={this.onFinish} >
                        <Form.Item name='name' label='节点名称' style={{ marginTop: '8px' }}>
                            <Input placeholder="请输入节点名称" />
                        </Form.Item>


                        <Form.Item name='tenantUser' label='所属用户'
                            style={{ marginTop: '8px' }}>
                            <Select
                                style={{ width: '200px' }}
                                placeholder="请选择所属用户"
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.props.tenantList}
                            </Select>
                        </Form.Item>

                        <Form.Item style={{ marginTop: '8px' }}>
                            <Button type="primary" htmlType="submit"> 查询 </Button>
                            <Button style={{ margin: '0 9.46px' }} onClick={this.resetForm}>重置 </Button>
                        </Form.Item>

                    </Form>
                </div>
            </>
        );
    }
}
let htmlStyle = ` 
 <table id='geoTable' width="250" border="1">
<thead><tr>
<th>序号</td>
<th>位置</td>
<th>数量</td></tr>
</thead>
</table>`;
class GeographicalDistribution extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nodesList: [],
            map: {},
            infoDetail: []
        };
    }
    componentDidMount() {
        const map = new BMap.Map("container");
        this.setState({
            map,
        })
        // 设置中心点
        const point = new BMap.Point(110.404, 36.915);
        // 启用滚轮放大缩小
        map.enableScrollWheelZoom();
        // 地图初始化，同时设置地图展示级别
        map.centerAndZoom(point, 6);
        //启用自动适应容器尺寸变化，默认启用
        map.enableAutoResize();
        // 设置地图允许的最小级别。取值不得小于地图类型所允许的最小级别
        map.setMinZoom(5)
        // map.setMapStyleV2({ styleJson: styleJson });
        // 添加控件
        map.addControl(new BMap.NavigationControl());
        map.addControl(new BMap.ScaleControl());
        map.addControl(new BMap.OverviewMapControl());
        // map.addControl(new BMap.MapTypeControl()); 
        this.getNodesDistribution();
    }
    // 节点信息
    getNodesDistribution() {
        geographyService.getNodesDistribution().then(res => {
            if (res.code === 200) {
                console.log('节点信息', res);
                const nodes = res.distributions;
                this.handleMarkers(nodes);
                this.setState({
                    nodesList: nodes
                });
            }
        }, error => {
            error && message.error(error);
        });
    }
    handleMarkers(nodes) {
        for (let i = 0; i < nodes.length; i++) {
            let info = nodes[i];
            let pt = new BMap.Point(info.lng, info.lat);
            // let myIcon=new BMap.Icon(icon, new BMap.Size(32,32),{anchor: new BMap.Size(10, 25)});
            // let marker = new BMap.Marker(pt,{ icon: myIcon});
            let marker = new BMap.Marker(pt);
            this.state.map.addOverlay(marker);
            // marker.setAnimation('BMAP_ANIMATION_DROP');//坠落动画

            // 添加文本标注。
            let label = info.nums < 10 ? new BMap.Label(info.nums, { offset: new BMap.Size(5.5, 3) }) : new BMap.Label(info.nums, { offset: new BMap.Size(1.5, 3) });
            label.setStyle({
                background: 'none',
                color: '#fff',
                // fontSize : "12px",
                border: 'none'
            });
            marker.setLabel(label);
            marker.setTitle(`${info.city}${info.district}`)
            // })
            marker.addEventListener("mouseover",this.debounce(() => { this.getAdressDistribution(info, pt)}, 600));
            marker.addEventListener("mouseout",() => { this.state.map.closeInfoWindow() });
        }
    }
    // 防抖函数
    debounce(func, wait) {
        let timeout;
        return function () {
            let context = this;
            let args = arguments;

            if (timeout) clearTimeout(timeout);

            let callNow = !timeout;
            timeout = setTimeout(() => {
                timeout = null;
            }, wait)

            if (callNow) func.apply(context, args)
        }
    }

    // 节点详细信息
    getAdressDistribution(info, pt) {
        let opts = {
            width: 0,     // 信息窗口宽度
            height: 0,     // 信息窗口高度,如果指定高度为0，则信息窗口的高度将按照其内容自动调整
            title: "边缘节点", // 信息窗口标题，信息窗标题文字，支持HTML内容
            // offset:(-2,3)
        }
        const city = info.city;
        const province = info.province;
        const district = info.district;
        htmlStyle = `<p>数据加载中.....</p>`;
        let infoWindow = new BMap.InfoWindow(htmlStyle, opts);  // 创建信息窗口对象 
        this.state.map.openInfoWindow(infoWindow, pt);
        // infoWindow.enableAutoPan(); 
        geographyService.getAddressDistribution(city, province, district).then(res => {
            if (res.code === 200) {
                console.log('节点位置信息', res);
                let infoDetail = res.distributions;
                htmlStyle =
                    `<table id='geoTable' width="250" border="1">
                 <thead><tr>
                 <th width="15%">序号</td>
                 <th>位置</td>
                 <th width="15%">数量</td></tr>
                 </thead><tbody>${infoDetail.map((value, index) => `<tr><td>${index + 1}</td><td>${city}${district}${value.address}</td><td>${value.nums}</td></tr>`).join('')}</tbody>
                 </table>`;
                infoWindow.setContent(htmlStyle);
            }
        }, error => {
            error && message.error(error);
        })
    }

    render() {
        return (
            <div className="common-pd20">
                <div>
                    <h1 className="common-top-title0">地理分布</h1>

                    <div className="common-center-container">
                        {/* <Search /> */}
                        {/* 地图容器元素 */}
                        <div id="container" style={{ width: '100%', height: '1200px' }} />
                    </div>
                </div>
            </div>
        )
    }
}


export default GeographicalDistribution;