import React from 'react';
import { Select, Form, Col, Input, Button, Table, Pagination, ConfigProvider, Radio, Modal, Space, message, Cascader, Menu, Dropdown } from 'antd';
import { ExclamationCircleOutlined, ArrowUpOutlined, MinusCircleOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from 'react-router-dom';

import city from '../../../../services/cities'
import { algorithmService } from '../../../../services/algorithm-manage';
import { nodeService } from '../../../../services/node.service';
import { mirrorService } from '../../../../services/operation.service';
import { labelService } from '../../../../services/label.service';
import { userService } from '../../../../services/user.service';
import { firmwareService } from '../../../../services/firmware.service';
import { otaService } from '../../../../services/ota.service';
import { connect } from 'react-redux';

import { AUTCONFIG } from '../../../../../utils/auth.config'
import './node-recall.scss';
import './../../../../../index.scss';
import store from '../../../../../store';
import { searchRule } from '../../../../../utils/search-util'

const { confirm } = Modal;

let searchName = '';
let searchModel = '';
let searchLable = [];
let searchCompany = '';
let searchUser = '';
let searchState = '';
let searchStationId = '';
let searchProvince = '';
let searchCity = '';
let searchDistrict = '';
//select
const { Option } = Select;

function Search(props) {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    let name = values.name || '';
    let str = searchRule(name)
    searchName = str || '';

    let model = values.model || '';
    let str1 = searchRule(model)
    searchModel = str1 || '';

    searchState = values.state || '';
    searchUser = values.username || '';
    searchCompany = values.company || '';
    searchLable = values.labels || [];

    let stationId = values.stationId || '';
    let str2 = searchRule(stationId)
    searchStationId = str2 || '';
    page = 1;
    props.onClick();
  };
  const resetForm = () => {
    form.resetFields();
    searchName = '';
    searchModel = '';
    searchLable = [];
    searchUser = '';
    searchState = '';
    searchStationId = '';
    searchCompany = '';
    searchProvince = '';
    searchCity = '';
    searchDistrict = '';
    props.changeUserList('');
    page = 1;
    props.onClick();
  };

  const handleChangeLable = (value) => {
    searchLable = value;
  }
  const onGenderChange = (value) => {
    const keys = Object.keys(value);
    if (keys.find(elx => elx === 'company')) {
      props.changeUserList(value.company);
      form.setFieldsValue({ username: null })
    }
  }

  const onChange = (value, selectedOptions) => {
    if (value.length === 3) {
      searchProvince = value[0];
      searchCity = value[1];
      searchDistrict = value[2];
    } else if (value.length === 2) {
      searchProvince = '';
      searchCity = value[0];
      searchDistrict = value[1]
    } else if (value.length === 1) {
      searchProvince = '';
      searchCity = value[0];
      searchDistrict = '';
    } else if (value.length === 0) {
      searchProvince = '';
      searchCity = '';
      searchDistrict = '';
    }
  }

  const filter = (inputValue, path) => {
    return path.some(option => option.label.indexOf(inputValue) > -1);
  }

  return (
    <>
      <div className="common-search-form node-mm">
        <Form
          form={form}
          name="advanced_search"
          layout="inline"
          // style={{ justifyContent: 'center' }}
          className="ant-advanced-search-form"
          onValuesChange={onGenderChange}
          onFinish={onFinish} >

          <Form.Item name='name' label='节点名称' style={{ marginTop: '8px' }}>
            <Input placeholder="请输入节点名称" />
          </Form.Item>
          {/* <Form.Item name='model' label='产品型号' style={{ marginTop: '8px' }}>
            <Input placeholder="请输入产品型号" />
          </Form.Item> */}
          <Form.Item name='stationId' label='序列号' style={{ marginTop: '8px' }}>
            <Input placeholder="请输入序列号" />
          </Form.Item>
          {/* <Form.Item name='state' label='节点状态' style={{ marginTop: '8px' }}>
            <Select style={{ width: '200px' }} placeholder="请选择节点状态" allowClear >
              {props.stateList}
            </Select>
          </Form.Item> */}
          <Form.Item name='labels' label='节点标签' style={{ marginTop: '8px' }}>
            <Select mode="multiple" allowClear style={{ width: '200px', }} placeholder="请选择节点标签" onChange={(value) => handleChangeLable(value)} showArrow>
              {props.labelList}
            </Select>
          </Form.Item>
          {
            (store.getState().currentUser.type === AUTCONFIG.ADMIN.value ||
              store.getState().currentUser.type === AUTCONFIG.SUPERADMIN.value) ?
              <Form.Item name='company' label='企业' style={{ marginTop: '8px' }}>
                <Select style={{ width: '200px' }} placeholder="请选择企业" showSearch allowClear
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {props.companyList}
                </Select>
              </Form.Item>
              : ''
          }
          {
            store.getState().currentUser.type !== AUTCONFIG.SUB.value ?
              <Form.Item name='username' label='所属用户' style={{ marginTop: '8px' }}>
                <Select style={{ width: '200px' }} placeholder="请选择所属用户" showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {props.tenantList}
                </Select>
              </Form.Item>
              : ''
          }

          <Form.Item name='city' label='地理位置' style={{ marginTop: '8px' }}>
            <Cascader
              options={city}
              onChange={onChange}
              placeholder="请选择省市区"
              showSearch={filter}
            />
          </Form.Item>

          <Form.Item style={{ marginTop: '8px' }} label=''>
            <div style={{ width: '262px' }}>
              <Button type="primary" htmlType="submit"> 查询 </Button>
              <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
            </div>

          </Form.Item>

        </Form>
      </div>
    </>
  );
}
// 选择epack弹窗
let searchFirmwareVersion = '';
let searchFirmwareOta = null;
let firmwaresList = [];
let firmLoading = true;
let firmwareAction = null;
function FirmwareModal(props) {
  const [form] = Form.useForm();
  const firmwareColumns = [
    {
      title: 'epack名称',
      dataIndex: 'firmwareName',
      key: 'firmwareName',
    },
    {
      title: 'epack版本',
      dataIndex: 'firmwareVersion',
      key: 'firmwareVersion',
    },
    {
      title: '描述',
      dataIndex: 'description',
      key: 'description',
    },
  ];
  const onFinish = (values) => {
    searchFirmwareVersion = values.searchName || '';
    searchFirmwareOta = values.searchOta;
    props.onClick();
  };
  const resetForm = () => {
    console.log(props.otaList);
    form.resetFields();
    searchFirmwareVersion = '';
    searchFirmwareOta = props.defaultSelectValue;
    form.setFieldsValue({ searchOta: props.defaultSelectValue });
    props.onClick();
  }
  const onValuesChange = (value) => {
    const keys = Object.keys(value);
    if (keys.find(elx => elx === 'searchOta')) {
      searchFirmwareVersion = form.getFieldValue('searchName') || '';
      searchFirmwareOta = value.searchOta || '';
      console.log(searchFirmwareOta);
      props.onClick();
    }
  }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRows);
      // id: selectedRowKeys
      // obj : selectedRows
      firmwareAction = selectedRows;
    },
  };
  return (
    <>
      {/* <div className="common-search-form">
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
          initialValues={{ 'searchOta': props.defaultSelectValue }}
          onValuesChange={onValuesChange} >
          <Col span={24} offset={2}>
            <Form.Item name='searchOta' label='所属产品'
              style={{ float: 'left', marginRight: '7.14px' }}
            >
              <Select style={{ width: '150px' }} placeholder="请选择所属产品"
              >
                {props.otaList}
              </Select>
            </Form.Item>
            <Form.Item name='searchName' label='epack版本' style={{ float: 'left', marginRight: '7.14px' }}>
              <Input style={{ width: 150 }} placeholder="请输入关键字" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit"> 查询 </Button>
              <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
            </Form.Item>
          </Col>
        </Form>
      </div> */}
      <Table
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
        rowKey={record => record.id}
        pagination={false}
        loading={props.firmLoading}
        scroll={{ y: 360 }}
        columns={firmwareColumns} dataSource={firmwaresList} />
    </>
  );
}













let page = 1;
let pageSize = 10;
let provinceData = [];
const cityData = {};
let IsUpgrading = false;
class NodeRecall extends React.Component {

  constructor(props) {
    super(props)
    this.state = {

      isAddVisible: false,//批量新增算法授权
      initCities: [],
      initSecondCity: '',
      citiesInfo: {},
      secondCityInfo: {},
      selectLicences: {},//算法授权选择

      isinstallVisible: false, //推送升级
      OtadefaultSelectValue: null,
      firmLoading: false,
      otaList: [],
      firmwareList: [],

      nodeList: [],
      nodeAmount: 0,
      // deleteIds: [],
      showManage: true,
      showCreate: false,
      showRegistered: false,
      loading: true,
      isModalVisible: false,
      page: 1,
      pageSize: 10,
      labelList: [],
      actionIds: [],
      actionRows: [],
      currentkeys: [],
      // selectedLable: [],
      history: require("history").createHashHistory,

      tenantList: [],
      companyUserList: [],
      companyList: [],
      stateList: []
    };
  }
  form = React.createRef();
  getTenantList() {
    userService.getAllTenantList().then(res => {
      if (res.code === 200) {
        const tenants = res.users;

        this.setState({
          tenantList: this.getTenantItem(tenants),
          companyUserList: this.getTenantItem(tenants),
        });
        console.log(this.getTenantItem(tenants));
      }
    }, error => {
      message.error(error);
    });
  }
  getCompanyList() {
    userService.getCompanyList().then(res => {
      const getCompanyItem = (companys) => {
        return companys.map((item, index) => {
          return (
            <Option key={item.id} >{item.name}</Option>
          );
        });
      }
      if (res.code === 200) {
        const companys = res.companys;

        this.setState({
          companyList: getCompanyItem(companys),
        });
      }
    }, error => {
      error && message.error(error);
    });
  }
  /**
   * 检索
   */
  getUserListByCompanyForSearch(cid) {

    if (cid) {
      userService.getAllTenantList().then(res => {

        if (res.code === 200) {
          const tenants = res.users.filter(elx => elx.company && elx.company.id == cid);
          this.setState({
            tenantList: this.getTenantItem(tenants),
          });
        }
      }, error => {
        error && message.error(error);
      });
    } else {
      userService.getAllTenantList().then(res => {
        if (res.code === 200) {
          const tenants = res.users;
          this.setState({
            tenantList: this.getTenantItem(tenants),
          });
        }
      }, error => {
        message.error(error);
      });
    }

  }
  /**
   * 分配用户
   */
  getUserListByCompany(cid) {
    if (cid) {
      userService.getAllTenantList().then(res => {

        if (res.code === 200) {
          const tenants = res.users.filter(elx => elx.company && elx.company.id == cid);
          this.setState({
            companyUserList: this.getTenantItem(tenants),
          });
        }
      }, error => {
        error && message.error(error);
      });
    } else {
      userService.getAllTenantList().then(res => {
        if (res.code === 200) {
          const tenants = res.users;
          this.setState({
            companyUserList: this.getTenantItem(tenants),
          });
        }
      }, error => {
        message.error(error);
      });
    }

  }

  assignUser() {

    if (this.state.actionIds.length === 0) {
      message.warn('请先选择节点!');
      return;
    }
    this.setState({
      isModalVisible: true
    })
    this.form.current?.resetFields();
  }
  onFinish(values) {
    const type = values.type;

    message.loading('节点分配中~~', 2000);
    nodeService.assignNode(this.state.actionIds, type).then(res => {
      message.destroy();
      message.success('节点分配成功');
      this.setState({
        isModalVisible: false,
        actionIds: []
      })
      this.searchList();
    }).catch(error => {
      this.setState({
        isModalVisible: false,
        actionIds: []
      })
      this.searchList();
      message.destroy();
      message.error(error);
    })
  }
  getTenantItem(tenants) {
    return tenants.map((item, index) => {
      return (
        <Option key={item.id} >{item.realName}/{item.username}</Option>
      );
    });
  }

  getStateList() {
    const arr = [
      { id: 1, state: 'UNCONNECTED', name: '未纳管' },
      { id: 2, state: 'RUNNING', name: '运行中' },
      { id: 3, state: 'UPGRADEWAITING', name: '待升级' },
      { id: 4, state: 'UPGRADING', name: '升级中' },
      { id: 6, state: 'DISABLED', name: '禁用' },
      { id: 5, state: 'OFFLINE', name: '不在线' },
      // { id: 7, state: 'DELETED', name: '已删除' },
    ];
    this.setState({
      stateList: this.getStateItem(arr)
    });
  }
  getStateItem(states) {
    return states.map((item) => {
      return (
        <Option key={item.state} >{item.name}</Option>
      );
    });
  }
  showDeleteConfirm(event) {
    confirm({
      title: '确定删除吗?',
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      onOk: () => {
        message.loading('节点删除中~~', 2500);
        nodeService.removeNodes(event).then(res => {
          message.destroy();
          if (res.code === 200) {
            message.success('删除成功');
            if (this.state.nodeList.length === 1) {
              page = page - 1;
              page = page ? page : 1;
            }
            this.searchList();
          }
          // else {
          //   message.error('删除失败，' + res.message);
          // }
        }, error => {
          message.destroy();
          message.error(error);
        })
      },
      onCancel() {

      },
    });
  }

  searchList() {
    this.setState({
      loading: true,
      page,
      pageSize
    })

    console.log(searchProvince);
    console.log(searchCity);
    console.log(searchDistrict);

    mirrorService.nodeDeleted(searchName,
      searchModel,
      searchLable.toLocaleString(),
      searchUser,
      searchCompany,
      searchStationId, searchProvince, searchCity, searchDistrict, pageSize, page).then(res => {
        this.setState({
          loading: false
        })
        if (res.code === 200) {
          console.log('已删节点',res);
          const nodes = res.nodes;
          nodes.forEach(element => {
            element.key = element.id;
          });
          this.setState({
            nodeList: nodes,
            nodeAmount: res.dataCount
          });
        }
        // else {
        //   message.error(res.message);
        // }
      }, error => {
        this.setState({
          loading: false
        })
        error && message.error(error);
      });
  }
  componentDidMount() {
    searchName = '';
    searchModel = '';
    searchLable = [];
    searchUser = '';
    searchState = '';
    searchCompany = '';
    searchProvince = '';
    searchCity = '';
    searchDistrict = '';
    page = 1;
    pageSize = 10;
    this.searchList();
    this.getLabelList();
    this.getTenantList();
    this.getCompanyList();
    this.getStateList();
    // if (this.props.currentUser && (this.props.currentUser?.type === AUTCONFIG.ADMIN.value ||
    //   this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value)) {
    //   this.getOtaList();
    // }


  }
  getLableItem(labels) {
    return labels.map((item, index) => {
      return (
        <Option key={item.id} >{item.name}</Option>
      );
    });
  }
  getLabelList() {
    labelService.getAllLabels().then(res => {
      if (res.code === 200) {
        const labels = res.labels;
        this.setState({
          labelList: this.getLableItem(labels)
        });
      }
    }, error => {
      error && message.error(error);
    });
  }


  gotoDetail = (record, key) => {
    this.props.history.push({ pathname: '/home/operation/nodeRecall/detail', state: { id: record.key } });
  }

  printContent(comment) {
    this.setState({
      showManage: true,
      showCreate: false,
      showRegistered: false,
    });
    if (comment.showManage) {
      this.searchList();
    }
  }

  gotoEdit = (text, key) => {
    this.props.history.push({ pathname: '/home/node-manage/edit', state: { id: text } });
  }



  showResetConfirm(event) {//FIXME:
    confirm({
      title: `确定重置节点${event.name}吗?`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        mirrorService.resetNode(event.id).then(res => {
          if (res.code === 200) {
            message.success('操作成功');
            this.searchList();
          }
          // else {
          //   message.error('启用节点失败，' + res.message);
          // }
        }, error => {
          error && message.error(error);
        });
      },
      onCancel() {
      },
    });
  }
  showDisConfirm(event) {
    confirm({
      title: `确定恢复${event.name}?`,
      icon: <ExclamationCircleOutlined />,
      closable: true,
      onOk: () => {
        mirrorService.recoverNode(event.id).then(res => {
          if (res.code === 200) {
            this.searchList();
            message.success('恢复节点成功');
          }
          // else {
          //   message.error(res.message);
          // }
        }, error => {
          error && message.error(error);
        });
      },
      onCancel() {
      },
    });
  }
  gotoAlgorithmInstall(record) {
    this.props.history.push({ pathname: '/home/node-manage/algorithm-install', state: { name: record.name, id: record.id, status: record.state, firmwareVersion: record.firmwareVersion } });
  }
  gotoAlgorithmAuthorization(record) {
    this.props.history.push({ pathname: '/home/node-manage/algorithm-authorization', state: { name: record.name, id: record.id } });
  }
  gotoRemoteOperation(record) {
    this.props.history.push({ pathname: '/home/node-manage/remote-operation', state: { name: record.name, id: record.id } });
  }
  gotoLog(id) {
    this.props.history.push({ pathname: '/home/node-manage/log', state: { id: id } });
  }
  gotoMonitor(id) {
    this.props.history.push({ pathname: '/home/node-manage/monitor', state: { id: id } });
  }
  gotoUpdate(id) {
    this.props.history.push({ pathname: '/home/node-manage/update', state: { id: id } });
  }
  // gotoCertificate(id) {
  //   this.props.history.push({ pathname: '/home/node-manage/certificate', state: { id: id } });
  // }

  getState(record) {
    // f (record.state === 'WAITING') {
    //     return <div><div className="common-yellow"></div> <span>待升级</span></div>
    //   } 
    if (record.state === 'UNCONNECTED') {
      return <div><div className="common-deep-gray"></div> <span>未纳管</span></div>
    } else if (record.state === 'RUNNING') {
      return <div><div className="common-green"></div> <span>运行中</span></div>
    } if (record.state === 'UPGRADEWAITING') {
      return <div><div className="common-yellow"></div> <span>待升级</span></div>
    } else if (record.state === 'UPGRADING') {
      return <div><div className="common-yellow"></div> <span>升级中</span></div>
    } else if (record.state === 'DISABLED') {
      return <div><div className="common-gray"></div> <span>禁用</span></div>
    } else if (record.state === 'OFFLINE') {
      return <div><div className="common-red"></div> <span>不在线</span></div>
    } else if (record.state === 'DELETED') {
      return <div><div className="common-red"></div> <span>已删除</span></div>
    } else {
      return '';
    }
  }

  //拼接位置
  getPosition(record) {
    let position = null
    //没有省市区，返回空
    if (!record?.province && !record.city && !record.district) {
      return position
    }
    //没有省，返回市区
    else if (!record?.province) {
      position = `${record?.city}/${record?.district}`
    }
    else {
      position = `${record?.province}/${record?.city}/${record?.district}`
    }

    return position
  }

  getOperByRecordState(text, record) {
    return (
      <>
            <a onClick={() => this.showResetConfirm(record)} style={{ marginRight: '5px', wordBreak: 'keep-all' }}>重置</a>
            <a onClick={() => this.showDisConfirm(record)} style={{  wordBreak: 'keep-all' }}>恢复</a>
      

      </>
    );
  }

  // 推送升级开始
  installAlgorithmShow() {
    if (this.state.actionIds.length === 0) {
      message.warn('请先选择节点!');
      return;
    }
    const nodeInfo = this.state.actionRows[0];
    if (this.state.actionRows.findIndex((ele) => ele.firmwareVersion !== nodeInfo.firmwareVersion) !== -1) {
      message.warn('请选择相同的epack版本!');
      return;
    }
    IsUpgrading = false;
    this.state.actionRows.forEach((ele, index) => { this.getUpgradeHistory(ele, index) });
  }

  getUpgradeHistory(ele, index) {//节点推送升级记录查询
    const length = this.state.actionIds.length;
    nodeService.getNodeAlgorithmsUpgradeHistory(ele.id).then(res => {
      if (res.code === 200) {
        if (res.nodeUpgrades[0]?.state == 'UPGRADING') {
          IsUpgrading = true;
          message.warn(`节点 ${ele.name} 正在升级epack版本，无法操作！`);
        } else if (index === length - 1 && !IsUpgrading) {
          this.getNodeUpdate(ele.id);
        }
      }
      // else {
      //   message.error('节点推送升级记录查询失败，' + res.message);
      // }
    }, error => {
      error && message.error(error);
    });
  }
  handleInstallOk() {
    this.handleAlgBathUp();
    // firmwareAction
  }
  handleAlgBathUp() {
    const actionIds = this.state.actionIds;
    nodeService.addNodeAlgorithmsBathUpgrade(actionIds, firmwareAction[0].id).then(res => {
      if (res.code === 200) {
        message.success('操作成功');
        this.handleInstallCancel();
      }
      // else {
      //   message.error('操作失败，' + res.message);
      // }
    }, error => {
      error && message.error(error);
    })
  }

  handleInstallCancel() {
    searchFirmwareOta = null;
    this.setState({
      isinstallVisible: false
    })
  }

  getOtaList() {
    otaService.getAllOtaList().then(res => {
      if (res.code === 200) {
        const products = res.products;
        this.setState({
          otaList: this.getOtaItem(products),
          OtadefaultSelectValue: products[0]?.id,
        });

        // this.getFirmwareList(products[0]?.id);
      }
    }, error => {
      error && message.error(error);
    });
  }
  getOtaItem(products) {
    return products.map((item, index) => {
      return (
        <Option key={item.id} value={item.id}>{item.productName}</Option>
      );
    });
  }
  // getFirmwareList() {
  //   if (this.state.OtadefaultSelectValue) {
  //     this.setState({
  //       firmLoading: true,
  //     })
  //     firmwareService.getAllFirmwareByOta(searchFirmwareOta || this.state.OtadefaultSelectValue, '', searchFirmwareVersion).then(res => {
  //       this.setState({
  //         firmLoading: false
  //       })
  //       if (res.code === 200) {
  //         firmwaresList = res.firmwares;
  //         this.setState({
  //           isinstallVisible: true,
  //         });
  //       } else {
  //         message.error('获取epack列表失败，' + res.message);
  //       }
  //     }, error => {
  //       this.setState({
  //         firmLoading: false
  //       })
  //       error && message.error(error);
  //     });
  //   } else {
  //     message.info('没有可选择epack')
  //   }
  // }
  getNodeUpdate(nodeId) {
    this.setState({
      isinstallVisible: true,
      firmLoading: true,
    });
    nodeService.getUpgradeFirmwares(nodeId).then(res => {
      if (res.code === 200) {
        firmwaresList = res.firmwares;
        this.setState({
          firmLoading: false,
        });
      }
      //  else {
      //   message.error('获取epack列表失败，' + res.message);
      //   this.setState({
      //     firmLoading: false,
      //   });
      // }
    }, error => {
      this.setState({
        firmLoading: false,
      });
      error && message.error(error);
    });
  }

  // 推送升级结束

  //算法授权开始
  showAddConfirm() {
    if (this.state.actionIds.length === 0) {
      message.warn('请先选择节点!');
      return;
    }
    this.getTotalAlgorithms();//查询算法处理
  }

  handleOk() {
    let flag = true;
    const selectIds = this.state.actionIds;//批量授权节点
    const selectInfo = this.state.selectLicences;
    const algorithmsArr = [];//最终传body的数据
    const judgeRepeatArr = [];//判断是否重复添加的数组
    if (!Object.keys(selectInfo).length) {//对象为空
      message.warning("请添加算法!");
    } else {
      for (let key in selectInfo) {
        if (judgeRepeatArr.find(ele => ele === selectInfo[key][0])) {
          flag = false;
          message.warning("请勿添加相同的算法!");
        } else {
          const info = {};
          info.algorithmName = selectInfo[key][0];
          info.algorithmVersion = selectInfo[key][1];
          algorithmsArr.push(info);
          judgeRepeatArr.push(selectInfo[key][0])
        }
      }
      if (flag) {
        confirm({
          style: { marginTop: 35 },
          width: 400,
          title: '此操作会覆盖当前算法，确定添加吗?',
          icon: <ExclamationCircleOutlined />,
          closable: true,
          okText: "确定",
          cancelText: "取消",
          onOk: () => {
            this.nodeLicencesBath(selectIds, algorithmsArr);
          },
          onCancel() {
          },
        });
      }
    }
  }

  //批量授权
  nodeLicencesBath(nodeIds, algorithms) {
    nodeService.nodeLicencesBath(nodeIds, algorithms).then(res => {
      if (res.code === 200) {
        message.success('操作成功');
        this.handleAddCancel();
      }
      // else {
      //   message.error('操作失败' + res.message);
      // }
    }, error => {
      error && message.error(error);
    })
  }
  handleAddCancel = () => {
    this.setState({
      isAddVisible: false,
      initCities: [],
      initSecondCity: '',
      citiesInfo: {},
      secondCityInfo: {},
      selectLicences: {}
    })
  }
  //授权算法查询
  // getLicensesInfo(nodeId) {
  //   nodeService.getLicences(nodeId).then(res => {
  //     if (res.code === 200) {
  //       console.log('授权算法查询', res);
  //     } else {
  //       message.error('启动运维失败' + res.message);
  //     }
  //   }, error => {
  //     error && message.error(error);
  //   })
  // }
  //查询算法概览列表
  getTotalAlgorithms() {
    algorithmService.getAlgorithmOverview('', 100, 1).then(res => {
      if (res.code === 200) {
        const data = res.namespaces;
        this.handleOverviewData(data);
        this.setState({
          isAddVisible: true
        })
        console.log('算法概览查询', res);
      }
      // else {
      //   message.error('算法概览查询失败' + res.message);
      // }
    }, error => {
      error && message.error(error);
    })
  }
  handleOverviewData(data) {
    provinceData = [];
    data.map(ele => {
      provinceData.push(ele.name);
      this.getalgorithmsList(ele.id, ele.name);
    })
  }
  //查询算法列表
  getalgorithmsList(id, name) {
    algorithmService.getAlgorithm(id, '', '', 100, 1).then(res => {
      if (res.code === 200) {
        const data = res.algorithms;
        this.handleListData(data, name);
      }
      // else {
      //   message.error('算法列表查询失败' + res.message);
      // }
    }, error => {
      error && message.error(error);
    })
  }
  handleListData(data, name) {
    const arr = [];
    data.map(ele => {
      arr.push(ele?.algorithmVersion)
    })
    cityData[name] = arr;
  }
  // 删除算法处理
  handleRemove(key) {
    const selectLicences = this.state.selectLicences;
    // const citiesInfo = this.state.citiesInfo;
    // const secondCityInfo = this.state.secondCityInfo;
    delete selectLicences[key];
    // delete citiesInfo[key];
    // delete secondCityInfo[key];
    this.setState({
      selectLicences,
      // citiesInfo,
      // secondCityInfo
    })
  }

  // 添加算法处理
  handleAdd() {
    const citiesInfo = this.state.citiesInfo;
    const secondCityInfo = this.state.secondCityInfo;
    const len_1 = (Object.keys(citiesInfo)).length;
    citiesInfo[len_1] = [];
    secondCityInfo[len_1] = '';
    this.setState({
      citiesInfo,
      secondCityInfo
    })
  }
  componentWillUnmount() {
    sessionStorage.removeItem('nodeId');
    sessionStorage.removeItem('nodeName');
  }



  // 固定第一条 选择框联动
  handleChange_1 = value => {
    const dataArr = [value, cityData[value][0]];
    const selectLicences = this.state.selectLicences;//selectLicences={}
    selectLicences.init = dataArr;
    this.setState({
      initCities: cityData[value],
      initSecondCity: cityData[value][0],
      selectLicences
    });
  }
  handleChange_2 = value => {
    const selectLicences = this.state.selectLicences;//selectLicences={}
    selectLicences.init[1] = value;
    this.setState({
      initSecondCity: value,
      selectLicences
    });
  }

  //动态 选择框联动
  handleProvinceChange = (value, num) => {
    const dataArr = [value, cityData[value][0]];//形如selectLicences={[province,city],{dataArr},...}
    const citiesInfo = this.state.citiesInfo;
    const secondCityInfo = this.state.secondCityInfo;
    const selectLicences = this.state.selectLicences;

    selectLicences[num] = dataArr;

    citiesInfo[num] = cityData[value];
    secondCityInfo[num] = cityData[value][0];
    this.setState({
      citiesInfo,
      secondCityInfo,
      selectLicences
    });
  };

  onSecondCityChange = (value, num) => {
    const selectLicences = this.state.selectLicences;
    const secondCityInfo = this.state.secondCityInfo;
    selectLicences[num][1] = value;
    secondCityInfo[num] = value;
    this.setState({
      secondCityInfo,
      selectLicences
    });
  };
  //算法授权结束



  render() {
    const onPageChange = (pageNumber, ps) => {
      page = pageNumber;
      if (ps != pageSize) {
        page = 1
      }
      pageSize = ps
      this.searchList();
    }

    const handleCancel = () => {
      this.setState({
        isModalVisible: false,
      })
    }

    const registered = () => {
      //   this.setState({
      //     showManage: false,
      //     showCreate: true,
      //     showRegistered: true,
      //   })
      this.props.history.push('/home/node-manage/add');
    };
    // const rowSelection = {
    //   onChange: (selectedRowKeys, selectedRows) => {
    //     this.setState({
    //       deleteIds: selectedRowKeys
    //     })
    //   }
    // };


    // 批量算法授权
    const menu = (
      <Menu>
        {/* <Menu.Item key="1" onClick={() => this.assignUser()}>
          分配用户
        </Menu.Item>
        <Menu.Item key="2" onClick={() => this.showAddConfirm()}>
          算法授权
        </Menu.Item>
        <Menu.Item key="3" onClick={() => this.installAlgorithmShow()}>
          推送升级
        </Menu.Item> */}
      </Menu>
    )
    const onFinish = values => {
      console.log('Received values of form:', values);
    };
    const { cities } = this.state;
    const Columns = [
      {
        title: '算法名称',
        dataIndex: 'variableName',
      },
      {
        title: '算法版本',
        dataIndex: 'variableValue',
      },
      {
        title: '算法描述',
        dataIndex: 'variableValue',
      },
      {
        title: '授权时间',
        dataIndex: 'variableValue',
      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record) => <a href
        // onClick={() => this.showDeleteEnv(record)}
        >删除</a>
      }
    ];




    const sysColumns = [{
      title: '节点名称',
      dataIndex: 'name',
      width: '8%',
      render: (text, record) => <a href onClick={this.gotoDetail.bind(text, record)}>{text}</a>,
    },
    {
      title: '产品序列号',
      dataIndex: 'seriesNumber',
    },
    // {
    //   title: '产品型号',
    //   dataIndex: 'productModel',
    // },
    // {
    //   title: 'epack版本',
    //   dataIndex: 'firmwareVersion',
    // },
    {
      title: '地理位置',
      width: '12%',
      render: (text, record) => (
        this.getPosition(record)
      ),
    },
    {
      title: '应用实例',
      dataIndex: 'instanceNum',
    },
    {
      title: '设备',
      dataIndex: 'deviceNum',
    },
    {
      title: '状态',
      dataIndex: 'state',
      render: (text, record) => (
        <>
          {
            this.getState(record)
          }
        </>
      ),
    },
    {
      title: '所属用户',
      dataIndex: 'realName',
    },
    // {
    //   title: '上报时间',
    //   dataIndex: 'upTime',
    // },
    {
      title: '创建时间',
      dataIndex: 'createTime',
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: '16%',
      render: (text, record) => (
        <>
          {this.getOperByRecordState(text, record)}
        </>
      ),
    },];
    const columns = [
      {
        title: '节点名称',
        dataIndex: 'name',
        width: '8%',
        render: (text, record) => <a href onClick={this.gotoDetail.bind(text, record)}>{text}</a>,
      },
      {
        title: '产品序列号',
        dataIndex: 'seriesNumber',
      },
      // {
      //   title: '产品型号',
      //   dataIndex: 'productModel',
      // },
      {
        title: '地理位置',
        width: '12%',
        render: (text, record) => (
          this.getPosition(record)
        ),
      },
      {
        title: '应用实例',
        dataIndex: 'instanceNum',
      },
      {
        title: '设备',
        dataIndex: 'deviceNum',
      },
      {
        title: '状态',
        dataIndex: 'state',
        render: (text, record) => (
          <>
            {
              this.getState(record)
            }
          </>
        ),
      },
      // {
      //   title: '上报时间',
      //   dataIndex: 'upTime',
      // },
      {
        title: '创建时间',
        dataIndex: 'createTime',
      },
      {
        title: '操作',
        dataIndex: 'action',
        width: '15%',
        render: (text, record) => (
          <>
            {this.getOperByRecordState(text, record)}
          </>
        ),
      },
    ];
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        const actionIds = this.state.actionIds;
        const actionRows = this.state.actionRows;
        this.state.nodeList.forEach(elx => {
          actionIds.find(item => item === elx.id) !== undefined && actionIds.splice(actionIds.indexOf(elx.id), 1);
          actionRows.find(item => item.id === elx.id) !== undefined && actionRows.splice(actionRows.findIndex((val) => val.id === elx.id), 1);
        });


        this.setState({
          actionIds: actionIds.concat(selectedRowKeys),
          actionRows: actionRows.concat(selectedRows)
        })
      },
      selectedRowKeys: this.state.actionIds
    }
    const onGenderChange = (value) => {
      const keys = Object.keys(value);
      if (keys.find(elx => elx === 'company')) {

        this.getUserListByCompany(value.company);
        this.form.current.setFieldsValue({ type: null });
      }
    }
    return (

      <>
        <div className="common-pd20">
          <div style={{ display: (this.state.showManage === true) ? "block" : "none" }}>
            <h1 className="common-top-title0">节点找回</h1>
            <div className="common-center-container no-search">
              <Search stateList={this.state.stateList}
                tenantList={this.state.tenantList}
                companyList={this.state.companyList}
                labelList={this.state.labelList} onClick={() => this.searchList()}
                changeUserList={($event) => this.getUserListByCompanyForSearch($event)} />
              {/* <div className="oper-button">
                {
                  store.getState().currentUser.type !== AUTCONFIG.SUB.value ?
                    <Router>
                      <Button type="primary" onClick={registered}>注册</Button>
                    </Router>
                    : ''
                }
                {
                  store.getState().currentUser.type !== AUTCONFIG.SUB.value ?

                    <Button type="primary" style={{ marginLeft: '10px', width: '78px', textAlign: 'center' }} onClick={() => this.assignUser()}> 分配用户 </Button>
                    : ''
                }
                {
                  store.getState().currentUser.type === AUTCONFIG.ADMIN.value || store.getState().currentUser.type === AUTCONFIG.SUPERADMIN.value ?
                    // <Dropdown trigger={['click']}  overlay={menu}>
                    //   <Button   style={{marginLeft:10,lineHeight:-1}} type="primary" >
                    //     批量 <DownOutlined  />
                    //   </Button>

                    // </Dropdown>
                    // : ''

                    <Button type="primary" style={{ marginLeft: '10px', width: '78px', textAlign: 'center' }} onClick={() => this.showAddConfirm()}>  算法授权 </Button>
                    : ''
                }
                {
                  <Button type="primary" style={{ marginLeft: '10px', width: '78px', textAlign: 'center' }} onClick={() => this.installAlgorithmShow()}> 推送升级 </Button>
                }


              </div> */}
              <div className="user-table-card">
                {/* 
                    rowSelection={{
                    ...rowSelection,
                  }} */}
                {
                  store.getState().currentUser.type !== AUTCONFIG.SUB.value ?

                    <Table
                      // rowSelection={{
                      //   ...rowSelection,
                      // }}
                      rowKey={record => record.id}
                      bordered={false}
                      pagination={false}
                      tableLayout="fixed"
                      columns={sysColumns}
                      loading={this.state.loading}
                      dataSource={this.state.nodeList}
                    />
                    :
                    <Table

                      rowKey={record => record.id}
                      bordered={false}
                      tableLayout="fixed"
                      loading={this.state.loading}
                      pagination={false}
                      columns={columns}
                      dataSource={this.state.nodeList}
                    />
                }
              </div>
              <div>
                {
                  this.state.nodeList.length > 0 &&
                  <div className="common-bottom-pagination">
                    <ConfigProvider locale={zhCN}>
                      <Pagination showSizeChanger defaultCurrent={1}
                        current={this.state.page} pageSize={this.state.pageSize} total={this.state.nodeAmount}
                        onChange={onPageChange} />
                    </ConfigProvider>
                  </div>
                }
              </div>
            </div>
          </div>
          <Modal title="选择用户"
            visible={this.state.isModalVisible}
            footer={null}
            onCancel={handleCancel}>
            <Form
              ref={this.form}
              name="advanced_search_user"
              className="ant-advanced-search-assign"
              onFinish={this.onFinish.bind(this)}
              layout="horizontal"
              onValuesChange={onGenderChange}
              style={{ justifyContent: 'center', marginLeft: 64 }}>
              {
                store.getState().currentUser.type !== AUTCONFIG.MAIN.value ?
                  <Form.Item name='company' label='企业'
                    style={{ marginTop: '12px', justifyContent: 'center', paddingLeft: 40 }}
                  >
                    <Select
                      style={{ width: '200px' }}
                      placeholder="企业名称" showSearch
                      allowClear
                    >
                      {this.state.companyList}
                    </Select>
                  </Form.Item> : ''}

              <Form.Item name='type' label='用户名称' style={{ marginTop: '12px', justifyContent: 'center' }}
                rules={[{ required: true, message: '请选择用户名' }]}>

                <Select
                  style={{ width: '200px' }}
                  placeholder="用户名称" showSearch allowClear
                  filterOption={(input, option) =>
                    option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {this.state.companyUserList}
                </Select>
              </Form.Item>

              <Col span={24}>
                <Form.Item style={{ marginTop: '12px', justifyContent: 'center' }}>
                  <div style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit"> 确认 </Button>
                    <Button style={{ margin: '0 9.46px' }} onClick={handleCancel}> 取消</Button>
                  </div>
                </Form.Item>
              </Col>


            </Form>
          </Modal>
          {/* 创建 */}
          {/* <div style={{ display: (this.state.showRegistered === true) ? "block" : "none" }}>
            <CreateNode onSubmit={this.printContent.bind(this)} labelList={this.state.labelList} />
          </div> */}

          <Modal
            destroyOnClose//打开新内容
            width={450}
            title="新增算法"
            visible={this.state.isAddVisible}
            onOk={() => this.handleOk()}
            onCancel={() => this.handleAddCancel()}
            footer={null}
          >

            <Form
              preserve={false}
              name="dynamic_form_nest_item"
              onFinish={onFinish}
              autoComplete="off" >
              <Space key={-1} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                <Form.Item
                  label="算法名称"
                  rules={[{ required: true, message: '请选择算法名称' }]}
                >
                  <Select
                    style={{ width: 120 }}
                    onChange={this.handleChange_1}
                  >
                    {provinceData.map(province => (
                      <Option key={province} value={province}>{province}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="算法版本"
                  rules={[{ required: true, message: '请选择算法版本' }]}
                >
                  <Select
                    style={{ width: 120 }}
                    value={this.state.initSecondCity}
                    onChange={this.handleChange_2}
                  >
                    {this.state.initCities.map(city => (
                      <Option key={city}>{city}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Space>
              <Form.List name="users"  >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <Form.Item
                          {...restField}
                          label="算法名称"
                          // name={[name, 'first']}
                          // initialValue={provinceData[0]}
                          fieldKey={[fieldKey, 'first']}
                          rules={[{ required: true, message: '请选择算法名称' }]}
                        >
                          <Select
                            // defaultValue={provinceData[0]}
                            style={{ width: 120 }}
                            onChange={(e) => this.handleProvinceChange(e, key)}
                          >
                            {provinceData.map(province => (
                              <Option key={province} value={province}>{province}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label="算法版本"
                          // initialValue={this.state.secondCity}
                          // name={[name, 'last']}
                          fieldKey={[fieldKey, 'last']}
                          rules={[{ required: true, message: '请选择算法版本' }]}
                        >
                          <Select
                            style={{ width: 120 }}
                            value={this.state.secondCityInfo[key]}
                            onChange={(e) => this.onSecondCityChange(e, key)}
                          >
                            {this.state.citiesInfo[key]?.map(city => (
                              <Option key={city}>{city}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => { remove(name); this.handleRemove(key); }} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => { add(); this.handleAdd() }} block icon={<PlusOutlined />}>
                        添加
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Form.Item name="type" label="已授权算法" >
                {/* <Radio.Group defaultValue={"b"} style={{ marginLeft: 15 }}>
                  <Radio disabled value="a" style={{ marginRight: 60 }}>追加新增</Radio>
                  <Radio value="b">覆盖替换</Radio>
                </Radio.Group> */}
                <Radio defaultChecked style={{ marginLeft: 20 }}>
                  覆盖替换
                </Radio>
              </Form.Item>
              <Form.Item style={{ marginTop: '12px', justifyContent: 'center' }}>
                <div style={{ textAlign: 'right' }}>
                  <Button type="primary" htmlType="submit" onClick={() => this.handleOk()} > 确认 </Button>
                  <Button style={{ margin: '0 9.46px' }} onClick={() => this.handleAddCancel()}> 取消</Button>
                </div>
              </Form.Item>
            </Form>
          </Modal>


          <Modal
            destroyOnClose//打开新内容
            width={800}
            title="推送升级"
            visible={this.state.isinstallVisible}
            onOk={() => this.handleInstallOk()}
            onCancel={() => this.handleInstallCancel()}
          // footer={null}
          >
            <FirmwareModal otaList={this.state.otaList}
              defaultSelectValue={this.state.OtadefaultSelectValue}
              firmLoading={this.state.firmLoading}
              onClick={() => this.getFirmwareList()} />
          </Modal>




        </div>
      </>
    );
  }
}
// export default NodeManage;

export default connect(
  (state) => ({
    currentUser: state.currentUser
  })
)(NodeRecall);

