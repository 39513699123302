import React from 'react';
import { Form, Input, message, Select, Tag } from 'antd';
import './../../../../../index.scss';
import { nodeService } from '../../../../services/node.service';
import BackPage from '../../../common/backPage';
import BottomBtnGroup from '../../../common/bottomBtnGroup';
import { labelService } from '../../../../services/label.service';
import { userService } from '../../../../services/user.service';
import { CloseCircleOutlined } from '@ant-design/icons';
import { AUTCONFIG } from '../../../../../utils/auth.config'
const { TextArea } = Input;
 const { Option } = Select;

const formItemLayout = {
    labelCol: { xs: 24, sm: 12, md: 8, lg: 6, xl: 3},
    wrapperCol: { xs: 24, sm: 12, md: 15},
};

class CreateNode extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      showManage: true,
      labelList: [],
      tenantList: []
    };

  }
  getTenantList() {
    userService.getAllTenantList().then(res => {
      if (res.code === 200) {
        const tenants = res.users;
        this.setState({
          tenantList: this.getTenantItem(tenants),
        });
      }
    }, error => {
      error && message.error(error);
    });
  }
getTenantItem(tenants) {
    return tenants.map((item, index) => {
      return (
        <Option key={item.id} >{item.realName}/{item.username}</Option>
      );
    });
  }
  goback() {
    // this.formRef.current.resetFields();
    // if (this.props.onSubmit) {
    //   var { showManage } = this.state;
    //   this.props.onSubmit({ showManage })
    // }
    this.props.history.push('/home/node-manage');
  }
  componentDidMount() {
      this.getLabelList();
      this.getTenantList();
  }
  handleChange(value) {
    console.log(`selected ${value}`);
  }
  getLabelList() {
    labelService.getAllLabels().then(res => {
      if (res.code === 200) {
        const labels = res.labels;
        this.setState({
          labelList: this.getLableItem(labels)
        });
      }
    }, error => {
      error && message.error(error);
    });
  }
  getLableItem(labels) {
    return labels.map((item, index) => {
      return (
        <Option key={item.id} >{item.name}</Option>
      );
    });
  }
  tagRender(props) {
    const { label, value, closable, onClose } = props;
    console.log(props)
    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color='blue'
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        closeIcon= {
        (
          <CloseCircleOutlined style={{background: '#0070CC', borderRadius:'50%', color: 'white'}} />
        )
        }
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  }
  render() {
    const onFinish = (values) => {
      const arr = values.labels || [];
      const labels = [];
      // // let labels = arr.toLocaleString();
      arr.forEach(ele => {
        labels.push(parseInt(ele))
      })
      nodeService.addNode(values.name, values.description, values.seriesNumber, labels, values.user).then(res => {
        if (res.code === 200) {
          message.success('注册节点成功');
          this.goback();
        } 
        // else {
        //   message.success('注册节点失败，' + res.message);
        // }
      }, error => {
        error && message.error( error);
      });
    };
    return (
      <>
       <div className="common-pd20">
        <BackPage onClick={() => this.goback()} headeTitle="注册节点" itemTitle="节点" itemSubTitle="创建节点" />
        <div className="common-create-center-container">
          <div className="common-create-page">
            <Form
              ref={this.formRef}
              name="validate_other"
              labelAlign="left"
              {...formItemLayout}
              onFinish={onFinish}>
              <Form.Item name="name" label="节点名称" rules={[
                { required: true, message: '请输入节点名称' },
                { max: 64, message: '任务名称不允许超过64个字符'}, 
                { pattern: new RegExp(/^[0-9a-zA-Z-_\u4e00-\u9fa5]{1,}$/, "g") , message: '请输入64位以内中英文/数字/中划线/下划线'}]}  >
                <Input placeholder="请输入节点名称" />
              </Form.Item>
              <Form.Item name="description" label="描述" >
                <TextArea autoSize={{ minRows: 3, maxRows: 5 }} style={{ width: 300 }} placeholder="请输入描述" />
              </Form.Item>
              <Form.Item name="seriesNumber" label="产品序列号" rules={[
                { required: true, message: '请输入产品序列号' },
                { max: 64, message: '产品序列号不允许超过64个字符'}, 
                { pattern: new RegExp(/^[0-9a-zA-Z-_]{1,}$/, "g") , message: '请输入64位以内英文/数字/中划线/下划线'}]}  >
                <Input placeholder="请输入产品序列号" />
              </Form.Item>
              <Form.Item name="labels" label="节点标签"   >
                <Select tagRender={this.tagRender} mode="multiple" showArrow="true" allowClear style={{ width: '300px' }} placeholder="请选择节点标签" onChange={(value) => this.handleChange(value)} >
                  {this.state.labelList}
                </Select>
              </Form.Item>
              <Form.Item name="user" label="所属用户">
                    <Select  allowClear placeholder="请选择所属用户"
                      style={{ width: '300px' }}   
                      showSearch
                      filterOption={(input, option) =>
                        option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      >
                        {this.state.tenantList}
                    </Select>
              </Form.Item>
              <BottomBtnGroup onClick={() => this.goback()} />
            </Form>
          </div>
        </div>
        </div>
      </>
    );
  }
}

export default CreateNode;