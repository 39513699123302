import React from 'react';
import ReactDOM from 'react-dom';
import './registered.scss'
import { Row, Col, Form, Input, Button, message, Select } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { withRouter, BrowserRouter as Router } from 'react-router-dom';
import { loginService } from '../../../services/login';

const { Option } = Select;

class Registered extends React.Component {
  codeTime = 60;
  timerC;

  constructor(props) {
    super(props)
    this.state = {
      type: 1,
      phoneNumber: '',
      codeTitle: "获取验证码"
    };

    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    console.log(value)
    if (name === 'phoneNumber') {
      this.setState({ phoneNumber: value });
    }
  }
  typeChange(i) {
    this.setState(prevState => ({
      type: i
    }))
  }
  timerChange() {
    console.log(999)
    const that = this;
    if (this.codeTime > 0) {
      this.setState({
        codeTitle: that.codeTime + "s后重新获取"
      })
      this.codeTime--;
    } else {
      this.setState({
        codeTitle: "获取验证码"
      })
      clearInterval(this.timerC)
    }
  }

  getPhoneCode() {
    if (this.state.phoneNumber.trim() === '') {
      message.warning('请输入手机号码');
      return;
    } else {
      if (this.state.codeTitle === "获取验证码") {
        loginService.getPhoneCode(this.state.phoneNumber).then(res => {
          if (res.code === 200) {
            message.success("已发送验证码，请注意查看短信");
            this.timerC = setInterval(() => this.timerChange(), 1000);
          } 
          // else {
          //   message.error("发送验证码失败，" + res.message);
          // }
        }, error => {
          message.error('失败');
        });
      } else {
        return
      }
    }
  }

  render() {
    const onFinish = (values) => {
      console.log('Received values of form: ', values);
      if (values.password.trim() !== values.confirmPass.trim()) {
        message.error('两次密码不一致');
        return;
      }
      loginService.regist(values.username, values.phone, values.password, values.code).then(res => {
        if (res.code === 200) {
          message.error("注册成功，即将跳转登录");
          window.location.href = "/login";
        }
        //  else {
        //   message.error("注册出错，" + res.message);
        // }
      }, error => {
        message.error('失败');
      });
    };

    return (
      <Router>
        <div className="regist-container">
          <Row className="regist-head-title"><Col span={24}><h2>Metis Cloud</h2></Col></Row>
          <Row className="regist-content">
            <Col span={24}>
              <div className="regist-right-container">
                <div className="regist-box">
                  <div className="regist-card-title">
                    <span className="fs20" style={{ float: 'left' }}>设备云账号注册</span>

                    <span className="fs14" style={{ float: 'right' }}>
                      已有账号， <a href='/login'> 去登录 <RightOutlined /></a>
                    </span>
                  </div>
                  <div style={{ padding: '48px 280px' }}>
                    <Form
                      name="normal_login"
                      className="regist-form"
                      initialValues={{ remember: true }}
                      onFinish={onFinish} >
                      <Form.Item name="username" rules={[{ required: true, message: '请输入用户名!' }]} >
                        <Input className="regist-input" placeholder="请输入用户名" />
                      </Form.Item>
                      <Form.Item name="password" rules={[{ required: true, message: '请输入密码!' }]} >
                        <Input className="regist-input" type="password" placeholder="请输入密码 6~16位 区分大小写" />
                      </Form.Item>
                      <Form.Item name="confirmPass" rules={[{ required: true, message: '请输入确认密码!' }]} >
                        <Input className="regist-input" type="password" placeholder="确认密码" />
                      </Form.Item>
                      <Form.Item name="phone" rules={[{ required: true, message: '请输入手机号!' }]}  >
                        <Input addonBefore="+86(中国)" name="phoneNumber" size="large"
                          value={this.state.phoneNumber} onChange={this.handleChange} placeholder="11位手机号" />
                      </Form.Item>
                      <Form.Item>
                        <Row gutter={8}>
                          <Col span={18}>
                            <Form.Item name="code" noStyle rules={[{ required: true, message: '请输入验证码' }]} >
                              <Input className="regist-input" placeholder="请输入验证码" />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Button onClick={() => this.getPhoneCode()} style={{ height: '42px' }}>{this.state.codeTitle}</Button>
                          </Col>
                        </Row>
                      </Form.Item>
                      <Form.Item>
                        <Button className="regist-btn" type="primary" htmlType="submit" block>
                          注册
                      </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="regist-footer"><Col span={24}></Col></Row>
        </div>
      </Router>
    )
  }
}
ReactDOM.render(<Registered />, document.getElementById('root'));
export default withRouter(Registered);