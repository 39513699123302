import { handleResponse } from './handle-response';
import store from '../../store/index';

export const configService = {
    getConfigs,
    getConfigDetail,
    addConfig,
    editConfig,
    deleteConfig,
};

//查询配置
function getConfigs(type, configKey, description, limit, offset) {
    return fetch(`/v1/console/system/configs?type=${type || ''}&configKey=${configKey || ''}&description=${description || ''}&limit=${limit || 10}&offset=${offset || 1}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//查询配置详情
function getConfigDetail(id) {
    return fetch(`/v1/console/system/configs/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//添加配置
function addConfig(type, description, configKey, configValue) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            type: type || 0,
            description: description,
            configKey: configKey,
            configValue: configValue,
        })
    };
    return fetch(`/v1/console/system/configs`, requestOptions)
    .then(handleResponse)
    .then(res => {
        return res;
    });
}

//修改配置
function editConfig(id, description, configKey, configValue) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            description: description,
            configKey: configKey,
            configValue: configValue,
        })
    };
    return fetch(`/v1/console/system/configs/${id}`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

//删除配置
function deleteConfig(id) {
    return fetch(`/v1/console/system/configs/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}