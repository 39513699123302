
import React, { Component, message } from 'react';
import ReactDOM from 'react-dom';

// import { AUTCONFIG } from '../../../../utils/auth.config';
// import store from '../../../../../store';
//import geoJson from 'echarts/map/json/china.json';

import { mirrorService } from '../../../../services/operation.service'

import * as echarts from 'echarts';
import 'echarts/extension/bmap/bmap';

let array = []
let a = []
class GeographicalDistribution2 extends React.Component {
    state = {
        data: [],
        geoCoordMap: {},
        distributions: [],

    }

    componentDidMount() {
        //this.getDetailPositionData("陕西省", "西安市", "雁塔区")
        this.getPositionData()
        console.log(this.state);
        
    }

    //详情接口
    getDetailPositionData(province, city, district) {
        
        mirrorService.getDetailPosition(province, city, district).then(res => {
            if (res.code === 200) {
                console.log("返回的详情数据", res);
                this.setState({
                    distributions: res.distributions
                })
                array = res.distributions
                a = res.distributions
                // console.log(a);
            }
        }).catch(error => {
            error && message.error(error);
        })

        // return a
    }

    //地图数据接口
    getPositionData() {
        mirrorService.getPosition().then(res => {
            if (res.code === 200) {
                console.log("返回的数据为", res);
                //拼接相应的数组
                //let newArr = res.distributions.slice(2,)
                let newArr = res.distributions
                let data = []
                newArr.map(item => {
                    data.push({name:item.district, value: item.nums, province: item.province, city: item.city})
                })

                let geoCoordMap = {}
                newArr.map(item => {
                    geoCoordMap[item.district]=[item.lng, item.lat]
                })

                this.setState({
                    data,
                    geoCoordMap,
                })
                console.log(this.state);
                var chartDom = document.getElementById('main');
        var myChart = echarts.init(chartDom);
        var option;
        option = {
            title: {
                text: '全国主要城市',
                subtext: '设备分布',
                //sublink: 'http://www.pm25.in',
                left: 'center'
            },
            tooltip: {
                trigger: 'item',
                //formatter: this.showMessage(this.state.data)
                // formatter: function (params) {
                //     console.log(params.data);
                //        //this.getDetailPositionData(params.province, params.city, params.name)
                //     return params.data.province+params.data.city+params.data.name
                // }
                // formatter: function (params, ticket, callback) {
                //     console.log(params);
                //     mirrorService.getDetailPosition(params.data[0].province, params.data[0].city, params.data[0].district).then(res => {
                //         if (res.code === 200) {
                //             console.log("返回的详情数据", res);
                //             // this.setState({
                //             //     distributions: res.distributions
                //             // })
                //             // array = res.distributions
                //             // a = res.distributions
                //             // console.log(a);
                //             let context = `${params.name}的负责人是${res.distributions[0].address}</p>`;
                //             callback(ticket, context);
                //         }
                //     })
        
                //     return "数据查询中……";
                //   },
            },
            bmap: {
                center: [125.114129, 35.550339],
                zoom: 5,
                roam: true,
                mapStyle: {
                    styleJson: [{
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "on",
                            "color": "#ccd6d7ff"
                        }
                    }, {
                        "featureType": "green",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "on",
                            "color": "#dee5e5ff"
                        }
                    }, {
                        "featureType": "building",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "on"
                        }
                    }, {
                        "featureType": "building",
                        "elementType": "geometry.topfill",
                        "stylers": {
                            "color": "#d1dbdbff"
                        }
                    }, {
                        "featureType": "building",
                        "elementType": "geometry.sidefill",
                        "stylers": {
                            "color": "#d1dbdbff"
                        }
                    }, {
                        "featureType": "building",
                        "elementType": "geometry.stroke",
                        "stylers": {
                            "color": "#aab6b6ff"
                        }
                    }, {
                        "featureType": "subwaystation",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off",
                            "color": "#888fa0ff"
                        }
                    }, {
                        "featureType": "education",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "on",
                            "color": "#e1e7e7ff"
                        }
                    }, {
                        "featureType": "medical",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "on",
                            "color": "#d1dbdbff"
                        }
                    }, {
                        "featureType": "scenicspots",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off",
                            "color": "#d1dbdbff"
                        }
                    }, {
                        "featureType": "highway",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off",
                            "weight": 4
                        }
                    }, {
                        "featureType": "highway",
                        "elementType": "geometry.fill",
                        "stylers": {
                            "color": "#ffffffff"
                        }
                    }, {
                        "featureType": "highway",
                        "elementType": "geometry.stroke",
                        "stylers": {
                            "color": "#cacfcfff"
                        }
                    }, {
                        "featureType": "highway",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "highway",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#999999ff"
                        }
                    }, {
                        "featureType": "highway",
                        "elementType": "labels.text.stroke",
                        "stylers": {
                            "color": "#ffffffff"
                        }
                    }, {
                        "featureType": "highway",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "on"
                        }
                    }, {
                        "featureType": "arterial",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off",
                            "weight": 2
                        }
                    }, {
                        "featureType": "arterial",
                        "elementType": "geometry.fill",
                        "stylers": {
                            "color": "#fbfffeff"
                        }
                    }, {
                        "featureType": "arterial",
                        "elementType": "geometry.stroke",
                        "stylers": {
                            "color": "#cacfcfff"
                        }
                    }, {
                        "featureType": "arterial",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "arterial",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#999999ff"
                        }
                    }, {
                        "featureType": "arterial",
                        "elementType": "labels.text.stroke",
                        "stylers": {
                            "color": "#ffffffff"
                        }
                    }, {
                        "featureType": "local",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off",
                            "weight": 1
                        }
                    }, {
                        "featureType": "local",
                        "elementType": "geometry.fill",
                        "stylers": {
                            "color": "#fbfffeff"
                        }
                    }, {
                        "featureType": "local",
                        "elementType": "geometry.stroke",
                        "stylers": {
                            "color": "#cacfcfff"
                        }
                    }, {
                        "featureType": "local",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "local",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#999999ff"
                        }
                    }, {
                        "featureType": "local",
                        "elementType": "labels.text.stroke",
                        "stylers": {
                            "color": "#ffffffff"
                        }
                    }, {
                        "featureType": "railway",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off",
                            "weight": 1
                        }
                    }, {
                        "featureType": "railway",
                        "elementType": "geometry.fill",
                        "stylers": {
                            "color": "#9494941a"
                        }
                    }, {
                        "featureType": "railway",
                        "elementType": "geometry.stroke",
                        "stylers": {
                            "color": "#ffffff1a"
                        }
                    }, {
                        "featureType": "subway",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off",
                            "weight": 1
                        }
                    }, {
                        "featureType": "subway",
                        "elementType": "geometry.fill",
                        "stylers": {
                            "color": "#c3bed433"
                        }
                    }, {
                        "featureType": "subway",
                        "elementType": "geometry.stroke",
                        "stylers": {
                            "color": "#ffffff33"
                        }
                    }, {
                        "featureType": "subway",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "subway",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#979c9aff"
                        }
                    }, {
                        "featureType": "subway",
                        "elementType": "labels.text.stroke",
                        "stylers": {
                            "color": "#ffffffff"
                        }
                    }, {
                        "featureType": "continent",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "on"
                        }
                    }, {
                        "featureType": "continent",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "on"
                        }
                    }, {
                        "featureType": "continent",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#333333ff"
                        }
                    }, {
                        "featureType": "continent",
                        "elementType": "labels.text.stroke",
                        "stylers": {
                            "color": "#ffffffff"
                        }
                    }, {
                        "featureType": "city",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "on"
                        }
                    }, {
                        "featureType": "city",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "on"
                        }
                    }, {
                        "featureType": "city",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#262626ff"
                        }
                    }, {
                        "featureType": "city",
                        "elementType": "labels.text.stroke",
                        "stylers": {
                            "color": "#ffffffff"
                        }
                    }, {
                        "featureType": "town",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "on"
                        }
                    }, {
                        "featureType": "town",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "on"
                        }
                    }, {
                        "featureType": "town",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#262626ff"
                        }
                    }, {
                        "featureType": "town",
                        "elementType": "labels.text.stroke",
                        "stylers": {
                            "color": "#ffffffff"
                        }
                    }, {
                        "featureType": "road",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "road",
                        "elementType": "geometry.fill",
                        "stylers": {
                            "color": "#fbfffeff"
                        }
                    }, {
                        "featureType": "poilabel",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "districtlabel",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "poilabel",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#999999ff"
                        }
                    }, {
                        "featureType": "districtlabel",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#262626ff"
                        }
                    }, {
                        "featureType": "transportation",
                        "elementType": "geometry",
                        "stylers": {
                            "color": "#d1dbdbff",
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "companylabel",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "on"
                        }
                    }, {
                        "featureType": "restaurantlabel",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "lifeservicelabel",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "carservicelabel",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "financelabel",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "otherlabel",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "village",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "district",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "on"
                        }
                    }, {
                        "featureType": "land",
                        "elementType": "geometry",
                        "stylers": {
                            "color": "#e7eaedff"
                        }
                    }, {
                        "featureType": "nationalway",
                        "elementType": "geometry.stroke",
                        "stylers": {
                            "color": "#cacfcfff"
                        }
                    }, 
                    {
                        "featureType": "provincialway",
                        "elementType": "geometry.stroke",
                        "stylers": {
                            "color": "#cacfcfff"
                        }
                    }, 
                    {
                        "featureType": "cityhighway",
                        "elementType": "geometry.stroke",
                        "stylers": {
                            "color": "#cacfcfff"
                        }
                    }, {
                        "featureType": "road",
                        "elementType": "geometry.stroke",
                        "stylers": {
                            "color": "#cacfcfff"
                        }
                    }, {
                        "featureType": "subwaylabel",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "subwaylabel",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "tertiarywaysign",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "tertiarywaysign",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "provincialwaysign",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "provincialwaysign",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "nationalwaysign",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "nationalwaysign",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "highwaysign",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "highwaysign",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "nationalway",
                        "elementType": "geometry.fill",
                        "stylers": {
                            "color": "#fbfffeff"
                        }
                    }, {
                        "featureType": "nationalway",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#999999ff"
                        }
                    }, {
                        "featureType": "provincialway",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#999999ff"
                        }
                    }, {
                        "featureType": "cityhighway",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#999999ff"
                        }
                    }, {
                        "featureType": "cityhighway",
                        "elementType": "labels.text.stroke",
                        "stylers": {
                            "color": "#ffffffff"
                        }
                    }, {
                        "featureType": "highway",
                        "stylers": {
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "6"
                        }
                    }, {
                        "featureType": "highway",
                        "stylers": {
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "7"
                        }
                    }, {
                        "featureType": "highway",
                        "stylers": {
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "8"
                        }
                    }, {
                        "featureType": "highway",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off",
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "6"
                        }
                    }, {
                        "featureType": "highway",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off",
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "7"
                        }
                    }, {
                        "featureType": "highway",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off",
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "8"
                        }
                    }, {
                        "featureType": "highway",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off",
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "6"
                        }
                    }, {
                        "featureType": "highway",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off",
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "7"
                        }
                    }, {
                        "featureType": "highway",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off",
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "8"
                        }
                    }, {
                        "featureType": "nationalway",
                        "stylers": {
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "6"
                        }
                    }, {
                        "featureType": "nationalway",
                        "stylers": {
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "7"
                        }
                    }, {
                        "featureType": "nationalway",
                        "stylers": {
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "8"
                        }
                    }, {
                        "featureType": "nationalway",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off",
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "6"
                        }
                    }, {
                        "featureType": "nationalway",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off",
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "7"
                        }
                    }, {
                        "featureType": "nationalway",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off",
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "8"
                        }
                    }, {
                        "featureType": "nationalway",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off",
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "6"
                        }
                    }, {
                        "featureType": "nationalway",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off",
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "7"
                        }
                    }, {
                        "featureType": "nationalway",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off",
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "8"
                        }
                    }, {
                        "featureType": "provincialway",
                        "stylers": {
                            "curZoomRegionId": "0",
                            "curZoomRegion": "8,8",
                            "level": "8"
                        }
                    }, {
                        "featureType": "provincialway",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off",
                            "curZoomRegionId": "0",
                            "curZoomRegion": "8,8",
                            "level": "8"
                        }
                    }, {
                        "featureType": "provincialway",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off",
                            "curZoomRegionId": "0",
                            "curZoomRegion": "8,8",
                            "level": "8"
                        }
                    }, {
                        "featureType": "cityhighway",
                        "stylers": {
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "6"
                        }
                    }, {
                        "featureType": "cityhighway",
                        "stylers": {
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "7"
                        }
                    }, {
                        "featureType": "cityhighway",
                        "stylers": {
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "8"
                        }
                    }, {
                        "featureType": "cityhighway",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off",
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "6"
                        }
                    }, {
                        "featureType": "cityhighway",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off",
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "7"
                        }
                    }, {
                        "featureType": "cityhighway",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off",
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "8"
                        }
                    }, {
                        "featureType": "cityhighway",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off",
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "6"
                        }
                    }, {
                        "featureType": "cityhighway",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off",
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "7"
                        }
                    }, {
                        "featureType": "cityhighway",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off",
                            "curZoomRegionId": "0",
                            "curZoomRegion": "6,8",
                            "level": "8"
                        }
                    }, {
                        "featureType": "cityhighway",
                        "elementType": "geometry.fill",
                        "stylers": {
                            "color": "#fbfffeff"
                        }
                    }, {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#8f5a33ff"
                        }
                    }, {
                        "featureType": "water",
                        "elementType": "labels.text.stroke",
                        "stylers": {
                            "color": "#ffffffff"
                        }
                    }, {
                        "featureType": "country",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#8f5a33ff"
                        }
                    }, {
                        "featureType": "country",
                        "elementType": "labels.text",
                        "stylers": {
                            "fontsize": 28
                        }
                    }, {
                        "featureType": "manmade",
                        "elementType": "geometry",
                        "stylers": {
                            "color": "#dfe7e7ff"
                        }
                    }, {
                        "featureType": "provincialway",
                        "elementType": "geometry.fill",
                        "stylers": {
                            "color": "#fbfffeff"
                        }
                    }, {
                        "featureType": "tertiaryway",
                        "elementType": "geometry.fill",
                        "stylers": {
                            "color": "#fbfffeff"
                        }
                    }, {
                        "featureType": "manmade",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#999999ff"
                        }
                    }, {
                        "featureType": "manmade",
                        "elementType": "labels.text.stroke",
                        "stylers": {
                            "color": "#ffffffff"
                        }
                    }, {
                        "featureType": "scenicspots",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#999999ff"
                        }
                    }, {
                        "featureType": "scenicspots",
                        "elementType": "labels.text.stroke",
                        "stylers": {
                            "color": "#ffffffff"
                        }
                    }, {
                        "featureType": "airportlabel",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#999999ff"
                        }
                    }, {
                        "featureType": "airportlabel",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "scenicspotslabel",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#999999ff"
                        }
                    }, {
                        "featureType": "scenicspotslabel",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "educationlabel",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#999999ff"
                        }
                    }, {
                        "featureType": "educationlabel",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "medicallabel",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#999999ff"
                        }
                    }, {
                        "featureType": "medicallabel",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "companylabel",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "on"
                        }
                    }, {
                        "featureType": "restaurantlabel",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "hotellabel",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "hotellabel",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "shoppinglabel",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "shoppinglabel",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "lifeservicelabel",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "carservicelabel",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "transportationlabel",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "transportationlabel",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "financelabel",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "entertainment",
                        "elementType": "geometry",
                        "stylers": {
                            "color": "#d1dbdbff",
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "estate",
                        "elementType": "geometry",
                        "stylers": {
                            "color": "#d1dbdbff",
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "shopping",
                        "elementType": "geometry",
                        "stylers": {
                            "color": "#d1dbdbff",
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "education",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#999999ff"
                        }
                    }, {
                        "featureType": "education",
                        "elementType": "labels.text.stroke",
                        "stylers": {
                            "color": "#ffffffff"
                        }
                    }, {
                        "featureType": "medical",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#999999ff"
                        }
                    }, {
                        "featureType": "medical",
                        "elementType": "labels.text.stroke",
                        "stylers": {
                            "color": "#ffffffff"
                        }
                    }, {
                        "featureType": "transportation",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#999999ff"
                        }
                    }, {
                        "featureType": "transportation",
                        "elementType": "labels.text.stroke",
                        "stylers": {
                            "color": "#ffffffff"
                        }
                    }, {
                        "featureType": "scenicspotslabel",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "road",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "nationalway",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "nationalway",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "provincialway",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "provincialway",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "cityhighway",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "cityhighway",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "scenicspots",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "transportation",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "playground",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "parkinglot",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "arterial",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "tertiaryway",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "tertiaryway",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "tertiaryway",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "fourlevelway",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "fourlevelway",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "fourlevelway",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "local",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "scenicspotsway",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "universityway",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "vacationway",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "roadarrow",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "footbridge",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "crosswalk",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "underpass",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "parkingspace",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "laneline",
                        "elementType": "geometry",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "airportlabel",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "on"
                        }
                    }, {
                        "featureType": "district",
                        "elementType": "labels.text.fill",
                        "stylers": {
                            "color": "#262626ff"
                        }
                    }, {
                        "featureType": "estatelabel",
                        "elementType": "labels",
                        "stylers": {
                            "visibility": "off"
                        }
                    }, {
                        "featureType": "estatelabel",
                        "elementType": "labels.icon",
                        "stylers": {
                            "visibility": "off"
                        }
                    }]
                }
            },
            // graphic:{
            //     type:"text",
            //     //left:"center",
            //     //top:"40%",
            //     style:{
            //         text:this.showNumber(this.convertData(this.state.data)),
            //         //textAlign:"center",
            //         fill:"#333",
            //         fontSize:10,
            //         fontWeight:700
            //     }
            // },
            series: [
                {
                    name: '设备',
                    type: 'scatter',
                    coordinateSystem: 'bmap',
                    data: this.convertData(this.state.data),
                    symbolSize: function (val) {
                        return 15;
                    },
                    encode: {
                        value: 2
                    },
                    label: {
                        normal: {
                            show: true,
                            formatter: this.showNumber(this.state.data)
                        },
                        formatter: '{c}',
                        position: 'right',
                        show: false
                    },
                    emphasis: {
                        label: {
                            show: true
                        }
                    },
                    
                },
                {
                    name: 'Top 5',
                    type: 'effectScatter',
                    coordinateSystem: 'bmap',
                    data: this.convertData(
                        this.state.data
                            .sort(function (a, b) {
                                return b.value - a.value;
                            })
                            .slice(0, 5)
                    ),
                    symbolSize: function (val) {
                        return 20;
                    },
                    encode: {
                        value: 2
                    },
                    showEffectOn: 'render',
                    rippleEffect: {
                        brushType: 'stroke'
                    },
                    label: {
                        normal: {
                            show: true,
                            formatter: this.showNumber(this.state.data),
                        },
                        formatter: '{b}',
                        position: 'right',
                        show: true
                    },
                    itemStyle: {
                        shadowBlur: 10,
                        shadowColor: '#333'
                    },
                    emphasis: {
                        scale: true
                    },
                    zlevel: 1
                }
            ]
        };
        option && myChart.setOption(option);

            }
        }).catch(error => {
            error && message.error(error);
        })
    }

    //显示圆点数字
    showNumber(data) {
        let numbers = []
        if (data.length != 0) {
            numbers = data.map(item => item.value)  
        }
        return numbers
    }

    //悬浮显示信息
    showMessage(data) {
        console.log(data);
        //this.getDetailPositionData()
        console.log(this.getDetailPositionData(data[0].province, data[0].city, data[0].name));
        let str = [];
        str = this.getDetailPositionData(data[0].province, data[0].city, data[0].name)
        		//str += `${item.name}: ${item.city}`
        console.log(a);
        		//str += idx === data.length -1? '': '<br/>

        return data[0].city
        
    }
    // showMessage(data) {

    //     return data
        
    // }

    
  
    convertData(data) {
      console.log(data);
    var res = [];
    for (var i = 0; i < data.length; i++) {
    var geoCoord = this.state.geoCoordMap[data[i].name];
    if (geoCoord) {
      res.push({
        name: data[i].name,
          value: geoCoord.concat(data[i].value),
          city: data[i].city,
          province: data[i].province
      });
    }
  }
  return res;
};



    
    render() {
        console.log(a);
        return (
            <div className="map">
                {/* 地图容器元素 */}
                <div id="main" style={{ width: '1800px', height: '1200px' }} />
            </div>
        )
    }
}


export default GeographicalDistribution2;