import { handleResponse } from './handle-response';
import store from '../../store/index';

export const edgeService = {
    getEdgeList,
    getEdgeDetail,
    getEdgeStart,
    getEdgeStop,
    deleteApp,
    getEdgePorts,
    getEdgeEnvs,
    getEdgeInstances,
    getEdgeLogs,
    getEdgeStats,
    addEdge,
    editEdge,
    getImageList,
    addEdgePort,
    deleteEdgePort,
    addEdgeEnv,
    deleteEdgeEnv,
    upgradeEdge,
    adjustSourceEdge,
    deleteApps,
    startApps,
    stopApps,
    upgradeApps,
};

//列表展示
function getEdgeList(name, state, nodeName, nodeLabels, userId, offset, limit) {
    return fetch(`/v1/console/apps?name=${name || ''}&state=${state || ''}&nodeName=${nodeName || ''}&nodeLabels=${nodeLabels || ''}&userId=${userId || ''}&offset=${offset || 1}&limit=${limit || 20}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//详情查看
function getEdgeDetail(id) {
    return fetch(`/v1/console/apps/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//启用
function getEdgeStart(id) {
    return fetch(`/v1/console/apps/${id}/start`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//停止
function getEdgeStop(id) {
    return fetch(`/v1/console/apps/${id}/stop`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//删除
function deleteApp(id) {
    return fetch(`/v1/console/apps/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//查询端口映射列表
function getEdgePorts(id) {
    return fetch(`/v1/console/apps/${id}/ports`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//添加端口映射
function addEdgePort(id, port) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            type: port.type,
            containerPort: port.containerPort,
            hostPort: port.hostPort,
        })
    };
    return fetch(`/v1/console/apps/${id}/ports`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

//删除端口映射
function deleteEdgePort(id, portId) {
    return fetch(`/v1/console/apps/${id}/ports/${portId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}


//查询环境变量列表
function getEdgeEnvs(id) {
    return fetch(`/v1/console/apps/${id}/envs`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}


//添加环境变量
function addEdgeEnv(id, env) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            name: env.name,
            value: env.value,
        })
    };
    return fetch(`/v1/console/apps/${id}/envs`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

//删除环境变量
function deleteEdgeEnv(id, envId) {
    return fetch(`/v1/console/apps/${id}/envs/${envId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//查询应用实例列表
function getEdgeInstances(id) {
    return fetch(`/v1/console/apps/${id}/instances`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//查询应用实例日志
function getEdgeLogs(id, startTime, endTime) {
    return fetch(`/v1/console/apps/instances/${id}/logs?startTime=${startTime || ''}&endTime=${endTime || ''}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}


//查询应用实例监控
function getEdgeStats(id, startTime, endTime) {
    return fetch(`/v1/console/apps/instances/${id}/stats?startTime=${startTime || ''}&endTime=${endTime || ''}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//创建应用
function addEdge(uuidList, appName, description, version, instanceNum, imageUrl, imageName, imageVersion, cpuRequest, memRequest,
    diskRequest, gpuRequest, cpuLimit, memLimit, diskLimit, gpuLimit, cmd, envs, ports, volumes, networkType) {
    
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${store.getState().authToken}`
            },
            body: JSON.stringify({
                uuidList: uuidList,
                appName: appName,
                description: description || '',
                version: version || '',
                instanceNum: instanceNum || 1,
                imageUrl: imageUrl,
                imageName: imageName,
                imageVersion: imageVersion,

                cpuRequest: cpuRequest || '',
                memRequest: memRequest || '',
                diskRequest: diskRequest || '',
                gpuRequest: gpuRequest || '',

                cpuLimit: cpuLimit,
                memLimit: memLimit,
                diskLimit: diskLimit,
                gpuLimit: gpuLimit,

                cmd: cmd || '',
                envs: envs || [],
                ports: ports || [],
                volumes: volumes || [],
                networkType: networkType || 1,

            })
        };
    return fetch(`/v1/console/apps`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

//修改应用
function editEdge(id, name, description, version, command, networkType) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            name: name,
            description: description,
            version: version,
            command: command,
            networkType: networkType,
        })
    };
    return fetch(`/v1/console/apps/${id}`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}


//获取镜像信息列表
function getImageList() {
    return fetch(`/v1/console/docker/imageList`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//升级应用版本
function upgradeEdge(id, imageId) {
    return fetch(`/v1/console/apps/${id}/upgrade?imageId=${imageId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//调整应用资源
function adjustSourceEdge(id, mem, cpu, gpu) {
    return fetch(`/v1/console/apps/${id}/change?mem=${mem}&cpu=${cpu}&gpu=${gpu}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//批量删除
function deleteApps(ids) {
    return fetch(`/v1/console/apps/batch?ids=${ids}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//批量启动
function startApps(ids) {
    return fetch(`/v1/console/apps/batch/start?ids=${ids}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//批量停止
function stopApps(ids) {
    return fetch(`/v1/console/apps/batch/stop?ids=${ids}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

//批量升级
function upgradeApps(ids, imageId) {
    return fetch(`/v1/console/apps/batch/upgrade?ids=${ids}&imageId=${imageId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}