import React from 'react';
import { Form, Input, Button, Col, Row, Table, Modal, message, ConfigProvider, Pagination, Upload, Select } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { UploadOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';

import { otaService } from '../../../../../services/ota.service';
import { firmwareService } from '../../../../../services/firmware.service';
import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';
import { AUTCONFIG } from '../../../../../../utils/auth.config';
import { connect } from "react-redux";
import './view-ota.scss';
import { getOtaIdAction } from '../../../../../../store/actionCreators';
import store from '../../../../../../store';

const { TextArea } = Input;
const {Option} = Select;
const { confirm } = Modal;
const formItemLayout = {
    labelCol: { xs: 24, sm: 12, md: 8, lg: 6, xl: 4 },
    wrapperCol: { xs: 24, sm: 12, md: 15 },
};
const formAddFirItemLayout = {
    labelCol: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 },
    wrapperCol: { xs: 24, sm: 12, md: 15 },
};
//上传组件
class UploadOta extends React.Component {
    state = {
        fileList: [],
        uploading: false,
    };

    handleUpload = () => {
        console.log('选中的文件', this.state.fileList)
        const { fileList } = this.state;
        const formData = new FormData();
        fileList.forEach(file => {
            formData.append('files[]', file);
        });
        // console.log(fileList);
        this.setState({
            uploading: true,
        });

        // You can use any AJAX library you like
        //   reqwest({
        //     url: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        //     method: 'post',
        //     processData: false,
        //     data: formData,
        //     success: () => {
        //       this.setState({
        //         fileList: [],
        //         uploading: false,
        //       });
        //       message.success('upload successfully.');
        //     },
        //     error: () => {
        //       this.setState({
        //         uploading: false,
        //       });
        //       message.error('upload failed.');
        //     },
        //   });
    };

    render() {
        const { uploading, fileList } = this.state;
        const props = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            // progress: {
            //     strokeColor: {
            //       '0%': '#108ee9',
            //       '100%': '#87d068',
            //     },
            //     strokeWidth: 3,
            //     format: percent => `${parseFloat(percent.toFixed(2))}%`,
            //   },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };

        return (
            <>
                <Upload {...props}>
                    <Button icon={<UploadOutlined />}>选择文件</Button>
                </Upload>
                <Button
                    type="primary"
                    onClick={this.handleUpload}
                    disabled={fileList.length === 0}
                    loading={uploading}
                    style={{ marginTop: 16 }}
                >
                    {uploading ? '上传中' : '开始上传'}
                </Button>
            </>
        );
    }
}




class ViewOta extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            showManage: true,
            otaId: null,
            otaObj: null,

            otaCreateTime: '',
            otaUpdateTime: '',
            otaName: '查看产品',

            searchFirwarePageSize: 10,
            firmwareList: [],
            firmwareAmout: 0,

            isAddFirmwareModalVisible: false,
            history: require("history").createHashHistory,
        };
    }

    // 发布epack
    showReleaseConfirm(event) {
        confirm({
            title: '确定发布此epack吗?',
            icon: <ExclamationCircleOutlined />,
            // content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
            closable: true,
            onOk: () => {
                firmwareService.releaseFirmware(event).then(res => {
                    if (res.code === 200) {
                        message.success('发布成功');
                        searchFirwarePage = 1;
                        this.getFirmwareList();
                    } 
                    // else {
                    //     message.error('发布失败，' + res.message);
                    // }
                }, error => {
                    error && message.error(error);
                });
            },
            onCancel() {
            },
        });
    }
    showDeleteConfirm(event) {
        confirm({
            title: '确定删除吗?',
            icon: <ExclamationCircleOutlined />,
            content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
            closable: true,
            onOk: () => {
                firmwareService.removeFirmware(event).then(res => {
                    if (res.code === 200) {
                        message.success('删除成功');
                        searchFirwarePage = 1;
                        this.getFirmwareList();
                    } 
                    // else {
                    //     message.error('删除失败，' + res.message);
                    // }
                }, error => {
                    error && message.error(error);
                });
            },
            onCancel() {

            },
        });
    }
    showEnaConfirm(event) {
        confirm({
            title: '确定启用吗?',
            icon: <ExclamationCircleOutlined />,
            onOk: () => {
                firmwareService.startFirwareVersion(event).then(res => {
                    if (res.code === 200) {

                        this.getFirmwareList();
                        message.success('启用epack成功');
                    } 
                    // else {
                    //     message.error('启用epack失败，' + res.message);
                    // }
                }, error => {
                    error && message.error(error);
                });
            },
            onCancel() {
            },
        });
    }
    //下架
    showRemoveConfirm(event) {
        confirm({
            title: '确定下架吗?',
            icon: <ExclamationCircleOutlined />,
            onOk: () => {
                firmwareService.removeFirwareVersion(event).then(res => {
                    if (res.code === 200) {
                        this.getFirmwareList();
                        message.success('下架epack成功');
                    } 
                    // else {
                    //     message.error('下架epack失败，' + res.message);
                    // }
                }, error => {
                    error && message.error(error);
                });
            },
            onCancel() {
            },
        });
    }
    // 禁用
    showDisConfirm(event) {
        confirm({
            title: '确定禁用吗?',
            icon: <ExclamationCircleOutlined />,
            onOk: () => {
                firmwareService.stopFirwareVersion(event).then(res => {
                    if (res.code === 200) {
                        this.getFirmwareList();
                        message.success('禁用epack成功');
                    } 
                    // else {
                    //     message.error('禁用epack失败，' + res.message);
                    // }
                }, error => {
                    error && message.error(error);
                });
            },
            onCancel() {
            },
        });
    }
    goback() {
        this.props.history.push('/home/ota-factory');
    }
    showAddFirmware() {
        console.log(this);
        this.setState({ isAddFirmwareModalVisible: true })
    }
    handleCancel() {
        this.setState({ isAddFirmwareModalVisible: false });
    }
    handleAddFirOk() {
        this.setState({ isAddFirmwareModalVisible: false });
        this.getFirmwareList();
    }
    cancel = () => {
        this.formRef.current.setFieldsValue({
            name: this.state.otaObj.name || '',
            model: this.state.otaObj.model || '',
            description: this.state.otaObj.description || '',
        });
    }
    componentDidMount() {
        searchFirwareVersion = '';
        searchFirwareName = '';
        searchFirwarePage = 1;
        searchFirwarePageSize = 10;
        searchFirwareType = '';
        if (this.props.location.state) {
            const otaId = this.props.location.state.id;
            if (otaId) {
                const action = getOtaIdAction(otaId);
                store.dispatch(action);
                this.setState({
                    otaId: otaId,
                });
                this.getOtaDetail(otaId);
                this.getFirmwareList(otaId);
            }
        } else {
            const otaId = store.getState().otaId;
            if (otaId) {
                this.setState({
                    otaId: otaId,
                });
                this.getOtaDetail(otaId);
                this.getFirmwareList(otaId);
            }
        }

    }
    // getVerisonList() {
    //     versionList = [{ id: 1, name: 'v1.0.0', key: 1 }, { id: 2, name: 'v2.0.0', key: 2 }];
    // }
    getFirmwareList(otaId) {
        firmwareService.getFirmwareByOta(otaId || this.state.otaId, searchFirwareName, searchFirwareVersion, searchFirwarePage, searchFirwarePageSize, searchFirwareType).then(res => {
            if (res.code === 200) {
                const lists = res.firmwares;
                console.log('epack列表', lists);
                lists.forEach(element => {
                    element.key = element.id;
                });
                this.setState({
                    firmwareList: lists,
                    firmwareAmout: res.dataCount
                });
            } 
            // else {
            //     message.error('获取epack列表失败，' + res.message);
            // }
        }, error => {
            error && message.error(error);
        });
    }
    getOtaDetail(otaId) {
        otaService.getOtaDetail(otaId).then(res => {
            if (res.code === 200) {
                const ota = res.product;
                this.setState({
                    otaObj: ota,
                    otaName: '查看产品 -- ' + ota.productName,
                    otaCreateTime: ota.createTime,
                    otaUpdateTime: ota.updateTime
                });
                console.log(this.formRef)
                console.log(this.formRef.current)
                this.formRef.current?.setFieldsValue({
                    name: ota.productName || '',
                    model: ota.productModel || '',
                    description: ota.description || ''
                });
            } 
            // else {
            //     message.error('获取产品详情失败,' + res.message);
            // }
        }, error => {
            error && message.error(error);
        });
    }
    gotoFirmwareDetail(text, key, detail) {
        this.props.history.push({ pathname: '/home/ota-factory/view/firmware', state: { id: key, mode: detail } });
    }

    gotoEpackManage(text,record){
        const {firmwareName,firmwareVersion,productName,productModel}={...record,...this.state?.otaObj}
        const obj={firmwareName,firmwareVersion,productName,productModel};
        this.props.history.push({ pathname: '/home/epack-exclusive-manage', state: obj});
    }
    getState(record) {
        if (record.state === '1' || record.state === 1) {
            return <div><div className="common-green"></div> <span>已发布</span></div>
        } else if (record.state === '0' || record.state === 0) {
            return <div><div className="common-red"></div> <span>禁用</span></div>
        } else if (record.state === '-1' || record.state === -1) {
            return <div><div className="common-red"></div> <span>未发布</span></div>
        } else {
            return '';
        }
    }

    getButton(record) {
        if (record.state === 0 || record.state === '0') {
            return <a href onClick={() => this.showEnaConfirm(record.id)} style={{ marginRight: '5px' }}>启用</a>
        } else if (record.state === '1' || record.state === 1) {
            return <span>
                <a href onClick={() => this.showRemoveConfirm(record.id)} style={{ marginRight: '5px' }}>下架</a>
                <a href onClick={() => this.showDisConfirm(record.id)} style={{ marginRight: '5px' }}>禁用</a>
            </span>
        } else if (record.state === '-1' || record.state === -1) {
            return <a href onClick={() => this.showReleaseConfirm(record.id)} style={{ marginRight: 5 }}>发布</a>
        } else {
            return '';
        }
    }
    render() {
        const onFinish = (values) => {
            otaService.editOta(this.state.otaId, values.name, values.model, values.description).then(res => {
                if (res.code === 200) {
                    message.success('修改成功');
                    this.getOtaDetail(this.state.otaId);
                } 
                // else {
                //     message.error('修改失败，' + res.message);
                // }
            }, error => {
                error && message.error(error);
            });
        };
        const columnsFirmware = [
            {
                title: 'epack名称',
                dataIndex: 'firmwareName',
                key: 'firmwareName',
                render: (text, record) => <a href onClick={() => this.gotoFirmwareDetail(text, record.key, 'detail')}>{text}</a>
            },
            {
                title: '版本',
                dataIndex: 'firmwareVersion',
                key: 'firmwareVersion'
            },
            {
                title: 'URL',
                dataIndex: 'url',
                key: 'url',
                width: '10%'
            },
            {
                title: '描述',
                dataIndex: 'description',
                key: 'description',
                width: '15%',
                render: (text, record) => <span style={{ 'word-break': 'break-all' }}>{text}</span>
            },
            {
                title: '类型',
                dataIndex: 'isPrivate',
                key: 'isPrivate',
                render: (text, record) => {
                    return text===0?'公共版':'专属版'
                }
            },
            {
                title: '状态',
                dataIndex: 'state',
                render: (text, record) => (
                    <>
                        {
                            this.getState(record)
                        }
                    </>
                ),
            },

            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime'
            },
            {
                title: '操作',
                dataIndex: 'time',
                key: 'time',
                render: (text, record) => (
                    <>

                        {
                            this.getButton(record)
                        }

                        <a href onClick={() => this.gotoFirmwareDetail(text, record.key)} style={{ marginRight: 5 }}>编辑</a>
                        <a href onClick={() => this.showDeleteConfirm(record.id)} style={{ marginRight: 5 }}>删除</a>
                        {
                           record.isPrivate? <a href onClick={() => this.gotoEpackManage(text, record)} >专属管理</a>:''
                        }
                        
                    </>
                ),
            }

        ];
        const columnsFirmwareAdmin = columnsFirmware.slice(0, columnsFirmware.length - 1);
        const onPageChange = (pageNumber) => {
            searchFirwarePage = pageNumber;
            this.getFirmwareList();
        }
        const onShowSizeChange = (current, pageSizeNumber) => {
            searchFirwarePageSize = pageSizeNumber;
            searchFirwarePage = 1;
            this.setState({
                searchFirwarePageSize: pageSizeNumber
            })
            this.getFirmwareList();
        }
        return (
            <>
                <div className="ota-detail-container">
                    <BackPage onClick={() => this.goback()} headeTitle={this.state.otaName} itemTitle="产品管理" itemSubTitle="查看产品" />
                    <div className="common-edit-card">
                        <Form name="basic"
                            onFinish={onFinish}
                            labelAlign="left"
                            ref={this.formRef}
                            {...formItemLayout} >
                            <Row>
                                <Col span={24}>
                                    <Form.Item name="name" label="产品名称">
                                        {this.state.otaObj?.productName || '暂无数据'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item name="description" label="描述" >
                                        {this.state.otaObj?.description || '暂无数据'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item name="model" label="产品型号" >
                                        {this.state.otaObj?.productModel || '暂无数据'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col span={24}>
                                    <Form.Item name="model" label="产品型号" >
                                        {this.state.otaObj?.productModel || '暂无数据'}
                                    </Form.Item>
                                </Col>
                            </Row> */}
                            {this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value ?
                                <Row>
                                    <Col span={24}>
                                        <Form.Item name="model" label="管理员" >
                                            {this.state.otaObj?.username || '暂无数据'}
                                        </Form.Item>
                                    </Col>
                                </Row> : ''}
                            <Row>
                                <Col span={24}>
                                    <Form.Item label="创建时间" >
                                        {this.state.otaCreateTime || '暂无数据'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item label="更新时间"  >
                                        {this.state.otaUpdateTime || '暂无数据'}
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Form>
                    </div>

                    <div className="ota-bottom-card">
                        <Row className="ota-bottom-card-title">
                            epack列表
                        </Row>
                        <div className="ota-bottom-content">
                            <Search onClick={() => this.getFirmwareList()} />
                            {this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value ?
                                <Row style={{ marginBottom: 16 }}>
                                    <Button type="primary" onClick={() => this.showAddFirmware()}> 新增epack </Button>
                                </Row> : ''}
                            <Row>
                                <Col span={24}>
                                    <div className="user-table-card">
                                        {this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value ?
                                            <Table
                                                style={{ marginBottom: 20 }}
                                                rowKey={record => record.id}
                                                bordered={false}
                                                pagination={false}
                                                columns={columnsFirmware}
                                                dataSource={this.state.firmwareList}
                                            /> :
                                            <Table
                                                style={{ marginBottom: 20 }}
                                                rowKey={record => record.id}
                                                bordered={false}
                                                pagination={false}
                                                columns={columnsFirmwareAdmin}
                                                dataSource={this.state.firmwareList}
                                            />}
                                    </div>
                                    <div>
                                        {
                                            this.state.firmwareList.length > 0 &&
                                            <div className="common-bottom-pagination">
                                                <ConfigProvider locale={zhCN}>
                                                    <Pagination showSizeChanger onShowSizeChange={onShowSizeChange} defaultCurrent={1} current={searchFirwarePage}
                                                        pageSize={this.state.searchFirwarePageSize} total={this.state.firmwareAmout} onChange={onPageChange} />
                                                </ConfigProvider>
                                            </div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                {/* */}
                <AddFir
                    isAddFirmwareModalVisible={this.state.isAddFirmwareModalVisible}
                    handleAddFirOk={() => this.handleAddFirOk()}
                    handleCancel={() => this.handleCancel()}
                    otaId={this.state.otaId} />

                {/* 
                <Modal title="新增epack" visible={this.state.isAddFirmwareModalVisible} onOk={() => this.handleAddFirOk()} onCancel={() => this.handleCancel()}>
                </Modal> */}
            </>
        );
    }
}

// export default ViewOta;

export default connect(
    (state) => ({
        currentUser: state.currentUser
    })
)(ViewOta);


let searchFirwarePage = 1;
let searchFirwarePageSize = 20;



let addFirName = '';
let addFirModel = '';
let addFirDescription = '';
let addIsPrivate = '';
//let addURL = '';

class AddFir extends React.Component {
    form = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            uploading: false,
        };
    }

    handleUpload = () => {
        console.log('选中的文件', this.state.fileList)
        const { fileList } = this.state;
        const formData = new FormData();
        fileList.forEach(file => {
            formData.append('files[]', file);
        });
        // console.log(fileList);
        this.setState({
            uploading: true,
        });
        console.log(formData);
        // You can use any AJAX library you like
        //   reqwest({
        //     url: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        //     method: 'post',
        //     processData: false,
        //     data: formData,
        //     success: () => {
        //       this.setState({
        //         fileList: [],
        //         uploading: false,
        //       });
        //       message.success('upload successfully.');
        //     },
        //     error: () => {
        //       this.setState({
        //         uploading: false,
        //       });
        //       message.error('upload failed.');
        //     },
        //   });

    }
    // onChange=(values)=>{
    //     console.log(values.fileList[0].name);
    // }
    onFinish = (values) => {
        console.log('新增epack信息', values);
        this.handleUpload();
        addFirName = values.firName;
        addFirModel = values.firModel;
        addFirDescription = values.firDescription;
        addIsPrivate = values.isPrivate;
        //addURL = values.url;
        /* if (!addFirName||!addFirModel||!addURL) {
            message.warn('必填项不能为空');
            return
        } */
        firmwareService.addFirmware(this.props.otaId, addFirName, addFirModel, addFirDescription, addIsPrivate).then(res => {
            if (res.code === 200) {
                message.success("创建成功");
                this.form.current.resetFields();
                this.props.handleAddFirOk();
            } 
            // else {
            //     message.success("创建失败，" + res.message);
            // }
        }, error => {
            error && message.error(error);
        });
    };
    resetForm = () => {
        this.form.current.resetFields();
        addFirName = '';
        addFirModel = '';
        addFirDescription = '';
        addIsPrivate = '';
        this.props.handleCancel();
        this.setState({
            fileList: [],
            uploading: false,
        });
    }
    render() {
        const { uploading, fileList } = this.state;
        const props = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            // progress: {
            //     strokeColor: {
            //       '0%': '#108ee9',
            //       '100%': '#87d068',
            //     },
            //     strokeWidth: 3,
            //     format: percent => `${parseFloat(percent.toFixed(2))}%`,
            //   },
            beforeUpload: file => {
                // console.log(file);
                let name = file.name;
                name = name.split('.')[0];
                this.form.current?.setFieldsValue({
                    firName: name
                })
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };
        return (
            <>
                <Modal title="新增epack" visible={this.props.isAddFirmwareModalVisible} onCancel={this.resetForm}
                    footer={null}>
                    <Form
                        style={{ marginBottom: 30, marginLeft: 40 }}
                        ref={this.form}
                        name="validate_other"
                        labelAlign="left"
                        {...formAddFirItemLayout}
                        onFinish={this.onFinish} >
                        {/* <Form.Item
                            name="upload"
                            label="文件上传"
                            rules={[
                                { required: true, message: '请上传文件' },
                            ]}
                        >
                            <Upload {...props} >
                                <Button type="primary" loading={uploading} icon={<UploadOutlined />}> {uploading ? '上传中' : '选择文件'}</Button>
                            </Upload>
                        </Form.Item> */}
                        <Form.Item name='firName' label='epack名称' rules={[
                            { required: true, message: '请输入epack名称' },
                            { max: 64, message: 'epack名称不允许超过64个字符' },
                            { pattern: new RegExp(/^[0-9a-zA-Z-_\u4e00-\u9fa5]{1,}$/, "g"), message: '请输入64位以内中英文/数字/中划线/下划线' }]}>
                            <Input name='firName' placeholder="请输入epack名称" />
                        </Form.Item>
                        <Form.Item name='firModel' label='epack版本' rules={[
                            { required: true, message: '请输入epack版本' },
                            { pattern: /^((\d+)\.){1,}(\d+)$/, message: 'epack版本格式不对,例如123.123.123' }]}>
                            <Input name='firModel' placeholder="请输入epack版本" />
                        </Form.Item>
                        <Form.Item name='isPrivate' label='epack类型' rules={[
                             
                        ]}> 
                            <Select defaultValue={0}>
                                <Option value={0}>公共版</Option>
                                <Option value={1}>专属版</Option>
                            </Select>
                        </Form.Item>
                        {/* <Form.Item name='url' label='URL' rules={[
                            { required: true, message: '请输入URL' },
                            { max: 99, message: 'URL不允许超过99个字符' },
                        ]}>
                            <Input name='url' placeholder="请输入URL" />
                        </Form.Item> */}


                        <Form.Item name="firDescription" label="描述" >
                            <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" showCount={true} maxLength={255} />
                        </Form.Item>
                        <div style={{ textAlign: 'center' }}>
                            <Button type="primary" htmlType="submit"> 新增 </Button>
                            <Button style={{ margin: '0 9.46px' }} onClick={this.resetForm}>取消 </Button>
                        </div>
                    </Form>
                </Modal>

            </>
        );
    }
}
let searchFirwareName = '';
let searchFirwareVersion = '';
let searchFirwareType = '';
// let versionList = [];

// const {Option} = Select;

function Search(props) {
    const [form] = Form.useForm();
    const onFinish = (values) => {
        searchFirwareName = values.name || '';
        searchFirwareVersion = values.version || '';
        //searchFirwareType = values.isPrivate || 0;
        searchFirwarePage = 1;
        props.onClick();
    };
    const resetForm = () => {
        form.resetFields();
        searchFirwareName = '';
        searchFirwareVersion = '';
        searchFirwareType = '';
        props.onClick();
    }
    const changeType = (e) => {
        console.log(e);
        searchFirwareType = e
    }
    // const onGenderChange = (value) => {
    //     searchFirwareVersion = value;
    // }
    // const getVersions = () => {
    //     return versionList.map((item, index) => {
    //         return (
    //             <Option value={item.id}>{item.name}</Option>
    //         );
    //     });
    // }
    return (
        <>
            <div className="common-search-form">
                <Form
                    form={form}
                    layout="inline"
                    style={{ justifyContent: 'center' }}
                    name="search_form"
                    className="ant-advanced-search-form"
                    onFinish={onFinish} >

                    <Form.Item name='name' label='epack名称' style={{ marginBottom: '8px' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='version' label='版本' style={{ marginBottom: '8px' }}>
                        <Input />
                        {/* <Select
                                placeholder="请选择版本"
                                onChange={onGenderChange} >
                                {getVersions()}
                            </Select> */}
                    </Form.Item>
                    <Form.Item name='isPrivate' label='epack类型' style={{ marginBottom: '8px' }}>
                        <Select  placeholder="请选择" onChange={(e)=>changeType(e)}>
                            <Option value={0}>公共版</Option>
                            <Option value={1}>专属版</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item style={{ marginBottom: '8px' }}>
                        <Button type="primary" htmlType="submit"> 查询 </Button>
                        <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
                    </Form.Item>

                </Form>
            </div>
        </>
    );
}