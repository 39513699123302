import React from 'react';
import { Form, Col, Input, Button, Table, Pagination, ConfigProvider, Modal, message, Select } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from 'react-router-dom';
import { jobService } from '../../../services/job.service';
import { userService } from '../../../services/user.service';
import { AUTCONFIG } from '../../../../utils/auth.config';
import { connect }  from 'react-redux'

import '../../../../index.scss';
import store from '../../../../store';
import { searchRule } from '../../../../utils/search-util'

const { confirm } = Modal;
const { Option } = Select;

let searchName = '';
let searchUser = '';
let searchState = '';
let searchType = "";
let page = 1;
let pageSize = 10;

function Search(props) {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    let name = values.name || "";
    let str = searchRule(name);
    searchName = str || "";
    searchUser = values.username;
    searchState = values.state;
    searchType = values.type;
    page = 1;
    props.onClick();
  };
  const resetForm = () => {
    form.resetFields();
    searchName = "";
    searchUser = "";
    searchState = "";
    searchType="";
    page = 1;
    props.onClick();
  };

  return (
    <>
      <div className="common-search-form">
        <Form
          form={form}
          name="advanced_search"
          layout="inline"
          // style={{ justifyContent: 'center' }}
          className="ant-advanced-search-form"
          onFinish={onFinish}
        >
          <Form.Item name="name" label="任务名称" style={{ marginTop: "8px" }}>
            <Input style={{ width: "150px" }} placeholder="请输入关键字" />
          </Form.Item>
          <Form.Item name="type" label="任务类型" style={{ marginTop: "8px" }}>
            <Select style={{ width: "150px" }} placeholder="请选择任务类型">
              <Option key="0">epack</Option>
              <Option key="1">用户软件包</Option>
            </Select>
          </Form.Item>
          <Form.Item name="state" label="任务状态" style={{ marginTop: "8px" }}>
            <Select style={{ width: "150px" }} placeholder="请选择任务状态">
              {props.stateList}
            </Select>
          </Form.Item>
          {store.getState().currentUser.type !== AUTCONFIG.SUB.value ? (
            <Form.Item
              name="username"
              label="所属用户"
              style={{ marginTop: "8px" }}
            >
              <Select
                style={{ width: "150px" }}
                placeholder="请选择所属用户"
                showSearch
                filterOption={(input, option) =>
                  option.children
                    .join("")
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {props.tenantList}
              </Select>
            </Form.Item>
          ) : (
            ""
          )}
          <Form.Item style={{ marginTop: "8px" }}>
            <Button type="primary" htmlType="submit">
              {" "}
              查询{" "}
            </Button>
            <Button style={{ margin: "0 9.46px" }} onClick={resetForm}>
              重置{" "}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

class BatchJob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobList: [],
      jobAmount: 0,
      // actionIds: [],
      showManage: true,
      showRegistered: false,
      loading: true,
      page: 1,
      pageSize: 10,
      history: require("history").createHashHistory,

      isShowNodeUpdateState: false,
      stateList: [], // select下拉
      nodeList: [], // modal table列表
    };
  }

  showDeleteConfirm(event) {
    confirm({
      title: "确定删除吗?",
      icon: <ExclamationCircleOutlined />,
      content: (
        <p style={{ color: "#ffb051" }}>删除之后不可恢复，请谨慎操作！</p>
      ),
      closable: true,
      onOk: () => {
        console.log(event);
        jobService.removeJob(event).then(
          (res) => {
            if (res.code === 200) {
              message.success("删除成功");
              this.searchList();
            }
            // else {
            //   message.error('删除失败，' + res.message);
            // }
          },
          (error) => {
            error && message.error(error);
          }
        );
      },
      onCancel() {},
    });
  }

  // showMultiDeleteConfirm() {
  //   if (this.state.actionIds.length === 0) {
  //     message.warning('请先选择要删除的任务');
  //     return;
  //   }
  //   confirm({
  //     title: '确定删除吗?',
  //     icon: <ExclamationCircleOutlined />,
  //     content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
  //     onOk: () => {
  //       jobService.removeJob(this.state.actionIds).then(res => {
  //         if (res.code === 200) {
  //           message.success('删除成功');
  //           this.searchList();
  //         } else {
  //           message.error('删除失败，' + res.message);
  //         }
  //       }, error => {
  //         message.error('删除失败');
  //       });
  //     },
  //     onCancel() {
  //     },
  //   });
  // }

  searchList() {
    this.setState({
      loading: true,
      page,
      pageSize,
    });
    jobService
      .getJobList(searchName, searchUser, searchState,searchType, page, pageSize)
      .then(
        (res) => {
          this.setState({
            loading: false,
          });
          console.log("批量升级任务信息：", res);
          if (res.code === 200) {
            const jobs = res.upgradeTasks;
            jobs.forEach((ele) => {
              ele.key = ele.id;
            });
            this.setState({
              jobList: jobs,
              jobAmount: res.dataCount,
            });
          }
          // else {
          //   message.error('获取升级任务列表失败，' + res.message);
          // }
        },
        (error) => {
          this.setState({
            loading: false,
          });
          error && message.error(error);
        }
      );
  }
  componentDidMount() {
    searchName = "";
    searchUser = "";
    searchType = "";
    page = 1;
    pageSize = 10;
    this.searchList();
    this.getTenantList();
    this.getStateList();
  }
  getTenantList() {
    userService.getAllTenantList().then(
      (res) => {
        if (res.code === 200) {
          const tenants = res.users;
          this.setState({
            tenantList: this.getTenantItem(tenants),
          });
        }
      },
      (error) => {
        error && message.error(error);
      }
    );
  }
  getTenantItem(tenants) {
    return tenants.map((item, index) => {
      return (
        <Option key={item.id}>
          {item.realName}/{item.username}
        </Option>
      );
    });
  }
  printContent(comment) {
    this.setState({
      showManage: true,
      showRegistered: false,
    });
    if (comment.showManage) {
      this.searchList();
    }
  }
  getState(record) {
    if (record.state === "WAITING") {
      return (
        <div>
          <div className="common-yellow"></div>
          <span>待升级</span>
        </div>
      );
    } else if (record.state === "UPGRADING") {
      return (
        <div>
          <div className="common-yellow"></div>
          <span>升级中</span>
        </div>
      );
    } else if (record.state === "SUCCESS") {
      return (
        <div>
          <div className="common-green"></div>
          <span>升级成功</span>
        </div>
      );
    } else if (record.state === "PARTIAL") {
      return (
        <div>
          <div className="common-purple"></div>
          <span>部分成功</span>
        </div>
      );
    } else if (record.state === "FAILED") {
      return (
        <div>
          <div className="common-red"></div>
          <span>升级失败</span>
        </div>
      );
    } else if (record.state === "EXCEPTION") {
      return (
        <div>
          <div className="common-yellow"></div>
          <span>异常</span>
        </div>
      );
    } else {
      return "";
    }
  }
  getStateList() {
    const arr = [
      { id: 1, state: "WAITING", name: "待升级" },
      { id: 2, state: "UPGRADING", name: "升级中" },
      { id: 3, state: "SUCCESS", name: "升级成功" },
      { id: 4, state: "FAILED", name: "升级失败" },
      { id: 5, state: "EXCEPTION", name: "异常" },
    ];

    this.setState({
      stateList: this.getStateItem(arr),
    });
  }
  getStateItem(states) {
    return states.map((item) => {
      return <Option key={item.state}>{item.name}</Option>;
    });
  }
  gotoEdit = (text, record) => {
    //console.log(record);
    this.props.history.push({
      pathname: "/home/batch-job/edit",
      state: { id: record.key,  upgradeType: record.upgradeType},
    });
  };
  gotoDeatil = (text, key) => {
    this.props.history.push({
      pathname: "/home/batch-job/detail",
      state: { id: key },
    });
  };
  showNodeState(text, key) {
    searchUpgradeId = key;
    this.getNodeStateList();
    // this.setState({
    //   isShowNodeUpdateState: true
    // });
  }

  getNodeStateList() {
    jobService
      .getUpdateState(
        searchUpgradeId,
        searchNodeName,
        searchNodeState,
        searchNodePage,
        searchNodePageSize
      )
      .then(
        (res) => {
          if (res.code === 200) {
            const nodes = res.nodes || [];
            nodes.reverse();
            this.setState({
              nodeList: nodes,
            });
            if (!this.state.isShowNodeUpdateState) {
              this.setState({
                isShowNodeUpdateState: true,
              });
            }
          }
          // else {
          //   message.success("创建失败，" + res.message);
          // }
        },
        (error) => {
          error && message.error(error);
        }
      );
  }
  handleCancel() {
    this.setState({
      isShowNodeUpdateState: false,
    });
  }

  render() {
    const onPageChange = (pageNumber, ps) => {
      page = pageNumber;
      if (ps != pageSize) {
        page = 1;
      }
      pageSize = ps;
      this.searchList();
    };
    const registered = () => {
      this.props.history.push({ pathname: "/home/batch-job/create" });
      // this.setState({
      //   showManage: false,
      //   showRegistered: true,
      // })
    };
    // const rowSelection = {
    //   onChange: (selectedRowKeys, selectedRows) => {
    //     this.setState({
    //       actionIds: selectedRowKeys
    //     })
    //   }
    // };
    const columnsSub = [
      {
        title: "任务名称",
        dataIndex: "name",
        render: (text, record) => (
          <a href onClick={() => this.gotoDeatil(text, record.key)}>
            {text}
          </a>
        ),
      },
      {
        title: "描述",
        dataIndex: "description",
      },
      {
        title: "升级对象",
        dataIndex: "nodeNum",
        render: (text, record) => {
          // { id: 3, state: 'SUCCESS', name: '成功' },
          //     { id: 4, state: 'FAILED', name: '失败' },
          if (
            record.state === "FAILED" ||
            record.state === "SUCCESS" ||
            record.state === "EXCEPTION"
          ) {
            return (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                style={{ padding: "0 8px" }}
                onClick={() => this.showNodeState(text, record.key)}
              >
                {text}
              </a>
            );
          } else {
            return <span>{text}</span>;
          }
        },
        // tenantId: "2"
      },
      {
        title: "更新epack",
        dataIndex: "firmwareVersion",
      },
      {
        title: "状态",
        dataIndex: "state",
        render: (text, record) => (
          <>
            <a href onClick={() => this.showNodeState(text, record.key)}>
              {this.getState(record)}
            </a>
          </>
        ),
      },

      {
        title: "运行时间",
        dataIndex: "executeTime",
      },
      {
        title: "操作",
        dataIndex: "action",
        render: (text, record) => {
          //   升级中不可删除
          if (record.state !== "UPGRADING") {
            return (
              <a href onClick={() => this.showDeleteConfirm(record.key)}>
                删除
              </a>
            );
          } else {
            return "";
          }
        },
      },
    ];
    const columns = [
      {
        title: "任务名称",
        dataIndex: "name",
        render: (text, record) => (
          <a href onClick={() => this.gotoDeatil(text, record.key)}>
            {text}
          </a>
        ),
      },
      {
        title: "描述",
        dataIndex: "description",
      },
      {
        title: "升级对象",
        dataIndex: "nodeNum",
        render: (text, record) => {
          // { id: 3, state: 'SUCCESS', name: '成功' },
          //     { id: 4, state: 'FAILED', name: '失败' },
          if (
            record.state === "FAILED" ||
            record.state === "SUCCESS" ||
            record.state === "EXCEPTION"
          ) {
            return (
              <a
                href
                style={{ padding: "0 8px" }}
                onClick={() => this.showNodeState(text, record.key)}
              >
                {text}
              </a>
            );
          } else {
            return <span>{text}</span>;
          }
        },
        // tenantId: "2"
      },
      {
        title: "升级软件",
        dataIndex: "firmwareVersion",
      },
      {
        title: "类型",
        dataIndex: "upgradeType",
        render: (text, record) => <>{text === 0 ? "Epack" : "用户软件包"}</>,
      },
      {
        title: "状态",
        dataIndex: "state",
        render: (text, record) => (
          <>
            <a href onClick={() => this.showNodeState(text, record.key)}>
              {this.getState(record)}
            </a>
          </>
        ),
      },
      {
        title: "所属用户",
        dataIndex: "realName",
        // render: (text, record) => <a href onClick={this.gotoNode.bind(text, record.key)}>{text}</a>,
      },
      {
        title: "运行时间",
        dataIndex: "executeTime",
      },
    ];
    //   this.props.currentUser.type === 'MAIN' &&
    columns.push({
      title: "操作",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <span>
            {/* 只有待升级可以编辑 */}
            {record.state === "WAITING" ? (
              <a
                href
                onClick={() => this.gotoEdit(text, record)}
                style={{ marginRight: "5px" }}
              >
                编辑
              </a>
            ) : (
              ""
            )}
            {/* <a href onClick={() => this.gotoEdit(text, record.key)} style={{marginRight: '5px'}}>编辑</a>  */}
            {/* 只有升级中不可以删除 */}
            {record.state !== "UPGRADING" ? (
              <a href onClick={() => this.showDeleteConfirm(record.key)}>
                删除
              </a>
            ) : (
              ""
            )}
          </span>
        );
      },
    });

    return (
      <>
        <div className="common-pd20">
          <div
            style={{
              display: this.state.showManage === true ? "block" : "none",
            }}
          >
            <h1 className="common-top-title0">批量升级</h1>
            <div className="common-center-container">
              <Search
                onClick={() => this.searchList()}
                tenantList={this.state.tenantList}
                stateList={this.state.stateList}
              />
              <div className="oper-button">
                <Router>
                  <Button type="primary" onClick={registered}>
                    {" "}
                    创建{" "}
                  </Button>
                </Router>
                {/* <Button style={{ marginLeft: 10 }} onClick={() => this.showMultiDeleteConfirm()}> 删除 </Button> */}
              </div>
              <div className="user-table-card">
                {/* 
                    rowSelection={{
                    ...rowSelection,
                  }} */}
                <Table
                  rowKey={(record) => record.id}
                  bordered={false}
                  pagination={false}
                  columns={
                    this.props.currentUser?.type === AUTCONFIG.SUB.value
                      ? columnsSub
                      : columns
                  }
                  loading={this.state.loading}
                  dataSource={this.state.jobList}
                />
              </div>
              <div>
                {this.state.jobList.length > 0 && (
                  <div className="common-bottom-pagination">
                    <ConfigProvider locale={zhCN}>
                      <Pagination
                        showSizeChanger
                        defaultCurrent={1}
                        current={this.state.page}
                        pageSize={this.state.pageSize}
                        total={this.state.jobAmount}
                        onChange={onPageChange}
                      />
                    </ConfigProvider>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Modal
            width={800}
            title="升级对象"
            visible={this.state.isShowNodeUpdateState}
            onOk={() => this.handleCancel()}
            onCancel={() => this.handleCancel()}
            okText="确定"
            cancelText="取消"
            footer={null}
          >
            <ViewNodeUpdate
              getNodeStateList={() => this.getNodeStateList()}
              stateList={this.state.stateList}
              nodeList={this.state.nodeList}
            />
          </Modal>
        </div>
      </>
    );
  }
}
// export default BatchJob;
export default connect(
    (state) => ({
      currentUser: state.currentUser
    })
  )(BatchJob);


let searchUpgradeId = '';
let searchNodeName = '';
let searchNodeState = [];
let searchNodePage = 1;
let searchNodePageSize = 10;

function ViewNodeUpdate(props) {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    searchNodeName = values.name;

    // searchUpgradeId, searchNodeName, searchNodeState, searchNodePage, searchNodePagesize
    console.log(values.name)
    console.log(searchNodeState)
    props.getNodeStateList();
  };
  const resetForm = () => {
    form.resetFields();
    searchNodeName = '';
    searchNodeState = [];
    searchNodePage = 1;
    searchNodePageSize = 10;
    props.getNodeStateList();
  }
  const handleChangeNodeState = (value) => {
    
    searchNodeState = [value];
  }
  const getNodeState = (record) => {
    if (record.taskState === 'WAITING') {
      return <div><div className="common-blue"></div> <span>待升级</span></div>
    }  else if (record.taskState === 'SUCCESS') {
      return <div><div className="common-green"></div> <span>成功</span></div>
    } else if (record.taskState === 'FAILED') {
      return <div><div className="common-red"></div> <span>失败</span></div>
    } else if(record.taskState === 'UPGRADING'){ 
      return <div><div className="common-yellow"></div> <span>升级中</span></div>
    } else {
      return '';
    }

  }
  const columns = [{
    title: '节点名称',
    dataIndex: 'name',
  },
  {
    title: '描述',
    dataIndex: 'description',
  },
  {
    title: '升级结果',
    dataIndex: 'state',
    render: (text, record) => (
      <>
        {
          getNodeState(record)
        }
      </>
    ),
  },
  {
    title: '失败原因',
    dataIndex: 'taskReason',
  },
  {
    title: '更新时间',
    dataIndex: 'updateTime',
  }];
  const onPageChange = (pageNumber, ps) => {
    searchNodePage = pageNumber;
    if (ps!=searchNodePageSize) {
      searchNodePage = 1
    }
    searchNodePageSize = ps
    props.getNodeStateList();
  }
  
  const nodeAmount = props.nodeList.length || 0;
  console.log(props.stateList)



const getStateItem = (states) => {
    return states.map((item) => {
    return (
        <Option key={item.state} >{item.name}</Option>
    );
    });
}
const stateList = getStateItem(  [{ id: 3, state: 'SUCCESS', name: '成功' },
{ id: 4, state: 'FAILED', name: '失败' },
])
  return (
    <>
      <div className="common-search-form">
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          layout="inline"
          style={{justifyContent:'center'}}
          onFinish={onFinish} >
         
            <Form.Item name='name' label='节点名称' style={{ marginTop:8 }}>
              <Input placeholder="请输入节点名称" />
            </Form.Item>
            <Form.Item name='state' label='升级结果' style={{ marginTop:8 }}>
              <Select  allowClear style={{ width: '150px' }} placeholder="请选择升级结果" onChange={(value) => handleChangeNodeState(value)} >
                {stateList}
              </Select>
            </Form.Item>
            <Form.Item style={{ marginTop:8 }}>
              <Button type="primary" htmlType="submit"> 查询 </Button>
              <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
            </Form.Item>
        
        </Form>
      </div>
      <div className="user-table-card">
        <Table
          rowKey={record => record.id}
          bordered={false}
          pagination={false}
          columns={columns}
          scroll={{ y: 240 }}
          dataSource={props.nodeList}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        {
          nodeAmount > 0 &&
          <div className="common-bottom-pagination" style={{paddingTop: 8}}>
            <ConfigProvider locale={zhCN}>
              <Pagination showSizeChanger defaultCurrent={1} current={searchNodePage} pageSize={searchNodePageSize} total={props.nodeList.length} onChange={onPageChange} />
            </ConfigProvider>
          </div>
        }
      </div>
    </>

  );
}
