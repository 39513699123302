import React from 'react';
import { Select, Form, Col, Input, Button, Table, Pagination, Badge, InputNumber, ConfigProvider, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from 'react-router-dom';
import { configService } from '../../../../services/config-manage';

import '../../../../../index.scss';
import { connect } from "react-redux";
import { searchRule } from '../../../../../utils/search-util'

const { Option } = Select;


const { confirm } = Modal;


let page = 1;
let pageSize = 10;
let searchConfigKey = '';
let searchDescription = '';
let searchType = '';

class Search extends React.Component {
  constructor(props) {
    super(props);

  }
  form = React.createRef();

  onFinish = (values) => {
    console.log(values);
    let name = values.configKey || '';
    let str = searchRule(name)
    searchConfigKey = str || '';

    let description = values.description || '';
    let str2 = searchRule(description)
    searchDescription = str2 || ''
    
    searchType = values.type || ''
    page = 1;
    this.props.onClick();
  };
  resetForm = () => {
    this.form.current.resetFields();
    searchConfigKey = '';
    searchDescription = '';
    searchType = '';
    page = 1;
    pageSize = 10;
    this.props.onClick();
  }

//   componentDidMount() {
//     this.resetForm();
//   }
  render() {
    return (
      <>
        <div className="common-search-form">
          <Form
            ref={this.form}
            name="advanced_search_user"
            className="ant-advanced-search-form"
            onFinish={this.onFinish}
            layout="inline"
          >

            <Form.Item name='configKey' label='&nbsp;&nbsp;&nbsp;系统配置项' style={{ marginTop: '8px' }}>
              <Input placeholder="请输入系统配置项" />
            </Form.Item>
            <Form.Item name='type' label='&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;配置类型' style={{ marginTop: '8px' }}>
              <Select allowClear
                placeholder="请选择配置类型" >
                <Option value="0">云端配置</Option>
                <Option value="1">盒子端配置</Option>
              </Select>
            </Form.Item>
            <Form.Item name='description' label='系统配置描述' style={{ marginTop: '8px' }}>
              <Input placeholder="请输入系统配置描述" />
            </Form.Item>

            <Form.Item style={{ marginTop: '8px' }}>
              <Button type="primary" htmlType="submit"> 查询 </Button>
              <Button style={{ margin: '0 9.46px' }} onClick={this.resetForm}>重置 </Button>
            </Form.Item>

          </Form>
        </div>
      </>
    );
  }
}

class ConfigManage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      configsList: [],
      loading: true,
      page: 1,
      dataCount: null,
      pageSize: 10,
      actionIds: [],
    };
  }

  changeType() {
    this.setState({
      page,
      pageSize
    })

    this.setState({
      loading: true
    });

    //查找
    this.getConfigs()
  }

  componentDidMount() {
    pageSize = 10;
    page = 1;
    
    this.changeType();
    
  }

  //调用查询接口
  getConfigs() {
    configService.getConfigs(searchType, searchConfigKey, searchDescription, pageSize, page).then(res => {
      if (res.code === 200) {
        console.log(res);
        this.setState({
          configsList: res.configs,
          dataCount: res.dataCount,
          loading: false
        })
      }
    //   else {
    //     message.error('获取设备列表失败');
    //     this.setState({
    //         loading: false
    //     })
    // }
  }, error => {
    error && message.error(error);
    this.setState({
        loading: false
    })
});
  }

  //调用删除接口
//   deleteConfig(id) {
//     configService.deleteConfig(id).then(res => {
//       if (res.code === 200) {
//         message.success("删除成功！")
//         this.changeType();
//       }
//     }).catch(error => {
//       error && message.error(error);
//     })
//   }


  gotoEdit = (text) => {
    this.props.history.push({ pathname: '/home/sys/config-manage/edit', state: { id: text.id } });
  }
  gotoDetail = (text, record) => {
    console.log(text);
    console.log(record);
    this.props.history.push({ pathname: '/home/sys/config-manage/view', state: { id: text.id } });
  }

  getConfigType = (record) => {
    if (record.type === 0) {
      return <span>云端配置</span>
    } else if (record.type === 1) {
      return <span>盒子端配置</span>
    } 
  }

  render() {
    const onPageChange = (pageNumber) => {

      page = pageNumber;

      this.changeType();
    }
    const onShowSizeChange = (current, pageSizeNumber) => {
      pageSize = pageSizeNumber;
      page = 1;

      this.changeType();
    }
    // const registered = () => {

    //   this.props.history.push('/home/terminal-equ/add');
    // };
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          actionIds: selectedRowKeys
        })
      },

    };

    const columns = [
      {
        title: '系统配置ID',
        dataIndex: 'id',
        width: '10%',
        render: (text, record) => <a href onClick={this.gotoDetail.bind(text, record)}>{text}</a>,
      },
      {
        title: '配置类型',
        dataIndex: 'type',
        render: (text, record) => (
          <>
            {
              this.getConfigType(record)
            }
          </>
        ),
      },
      {
        title: '系统配置项',
        dataIndex: 'configKey',
      },
      {
        title: '系统配置值',
        dataIndex: 'configValue',
        ellipsis: true,
        //width: '30%',
      },
      {
        title: '系统配置描述',
        dataIndex: 'description',
      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
      },
      {
        title: '更新时间',
        dataIndex: 'updateTime',
      },
      {
        title: '操作',
        dataIndex: 'action',
        //width: '20%',
        render: (text, record) => (
          <>
            
            <a href onClick={() => this.gotoEdit(record)} style={{ marginRight: '5px' }}>编辑</a>

          </>
        )
      },
    ];
    
    return (
      <>
        <div className="common-pd20">
          <div >
            <h1 className="common-top-title0">配置管理</h1>
            <div className="common-center-container">
              <Search onClick={() => this.changeType()}/>
              <div className="oper-button">
                {/* <Router>
                  <Button type="primary" onClick={registered} >  添加  </Button>
                </Router> */}
              </div>
              <div className="user-table-card">
                <Table
                  // rowSelection={{
                  //   ...rowSelection,
                  // }}
                  rowKey={record => record.id}
                  bordered={false}
                  tableLayout="fixed"
                  pagination={false}
                  loading={this.state.loading}
                  columns={columns}
                  dataSource={this.state.configsList}
                />
              </div>
              <div>
                {
                  this.state.configsList.length > 0 &&
                  <div className="common-bottom-pagination">
                    <ConfigProvider locale={zhCN}>
                      <Pagination
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        defaultCurrent={1}
                        current={this.state.page}
                        pageSize={this.state.pageSize}
                        total={this.state.dataCount}
                        onChange={onPageChange} />
                    </ConfigProvider>
                  </div>
                }
              </div>
            </div>
          </div>


        </div>
      </>

    );
  }
}


export default connect(
  (state) => ({
    currentUser: state.currentUser
  })
)(ConfigManage);
