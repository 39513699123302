import React from 'react';
import { Form, Input, message, Select} from 'antd';
import '../../../../../index.scss';
import { nodeService } from '../../../../services/node.service';
import { terminalService } from '../../../../services/terminal-equ';
import BackPage from '../../../common/backPage';
import BottomBtnGroup from '../../../common/bottomBtnGroup';

const { TextArea } = Input;
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

class CreateEquipment extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      showManage: true,
      nodeUuid:this.props.location.state
    };

  }
  goback() {
    this.formRef.current.resetFields();
    if (this.props.onSubmit) {
      var { showManage } = this.state;
      this.props.onSubmit({ showManage })
    }
  }
  handleChange(value) {
    console.log(`selected ${value}`);
  }
  goback() {
    console.log(this.props);
    this.props.history.go(-1);
  }
   //创建设备接口
   addDevice(aliasName, nodeUuid, deviceType, rtspUrl, deviceInterface) {
    terminalService.addDevice(aliasName, nodeUuid, deviceType, rtspUrl, deviceInterface).then(res => {
      if (res.code === 200) {
        message.success("创建成功！")
        this.goback();
      }
      // else {
      //   message.success('创建失败，' + res.message);
      // }
    }).catch(error => {
      error && message.error(error);
    })
  }

  render() {
    const onFinish = (values) => {
      this.addDevice(values.aliasName, this.state.nodeUuid,
         values.deviceType, values.deviceUrl);
    };

    return (
      <>
        <div style={{ margin: 24 }}>
          <BackPage onClick={() => this.goback()} headeTitle="注册设备" itemTitle="设备管理" itemSubTitle="注册设备" />
          <div className="common-create-center-container">
            <div className="common-create-page">
              <Form
                ref={this.formRef}
                name="validate_other"
                labelAlign="left"
                {...formItemLayout}
                onFinish={onFinish}>
                {/* <Form.Item name="name" label="设备名称" rules={[{ required: true, message: '请输入设备名称' }]} >
                  <Input placeholder="请输入设备名称" />
                </Form.Item> */}
                <Form.Item name="aliasName" label="设备别名" rules={[{ required: true, message: '请输入设备别名' }]} >
                  <Input placeholder="请输入设备别名" />
                </Form.Item>
                <Form.Item name='deviceType' label='设备类型' rules={[{ required: true, message: '请选择设备类型' }]} style={{ marginTop: '8px' }}>
                  <Select allowClear
                    placeholder="请选择设备类型" >
                    <Option value="0">视频摄像头</Option>
                    {/* <Option value="1">音频</Option>
                    <Option value="2">屏幕</Option>
                    <Option value="3">麦克风</Option>
                    <Option value="4">其他</Option> */}
                  </Select>
                </Form.Item>
                <Form.Item name="deviceUrl" label="RTSP地址" rules={[{ required: true, message: '请输入设备访问地址' }]}>
                  <Input placeholder="请输入设备访问地址" />
                </Form.Item>
                {/* <Form.Item name="serialNo" label="设备序列号" >
                  <Input placeholder="请输入设备序列号" />
                </Form.Item> */}
                {/* <Form.Item name="description" label="描述" >
                  <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" />
                </Form.Item> */}
                <BottomBtnGroup onClick={() => this.goback()} />
              </Form>
            </div>
          </div>

        </div>
      </>
    );
  }
}

export default CreateEquipment;