import React, {Component} from 'react';

import { Select, Form, Col, Input, Button, Table, Pagination, ConfigProvider, Modal, message, InputNumber, Row } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from 'react-router-dom';

import { edgeService } from '../../../../../services/edge.service';

import '../../../../../../index.scss';

export default class EquipmentDemo extends Component {

  state = {
    appEnvs: [],
    loading: false,
  }

  getAppEnvs(id) {
    edgeService.getEdgeEnvs(id).then(res => {
      if (res.code === 200) {
          console.log("环境变量数组信息",res.envs);
        
          this.setState({
            appEnvs: res.envs,
            loading: true
          })
      }  
      // else {
      //   this.setState({
      //     loading: false
      //   })
      // }
  }).catch(error => {
    this.setState({
      loading: false
    })
    error && message.error(error);
  })
  }

  componentDidMount() {
    this.getAppEnvs(this.props.id)
  }

  render() {
    //console.log(this.props);
    const portColumn = [
      {
         title: '变量名',
         dataIndex: 'name',
       },
       {
         title: '变量值',
         dataIndex: 'value',
       },
      //  {
      //    title: '操作',
      //    dataIndex: 'action',
       
      //    render: (text, record) =>  <a href onClick={() => this.showDeleteConfirm(record.key)} >删除</a>
      //  }
    ];
    
    const layout = {
      labelCol: { xs: 24, sm: 12, md: 8, lg: 2, xl:2},
      wrapperCol: { xs: 24, sm: 12, md: 15},
    };

    return (
      <div>
        <div>

          <Form {...layout} name="basic" labelAlign="left">
              <Form.Item label="运行命令"  style={{marginBottom: 12}} >
                  {this.props.command}
              </Form.Item>
            
              <Form.Item >
              {/* <Button type="danger"   style={{color: '#fff'}}>  新增  </Button> */}
                  环境变量
              </Form.Item>
          </Form>
        </div>

        <div>
          <Table
            rowKey={record => record.id}
            pagination={false}
            columns={portColumn}
            dataSource={this.state.appEnvs}
            bordered={true}
        />
        </div>
        
      </div>
    )
  }
} 