import React from 'react';
import { Form, Select, Button, Row, Col, Table, Space, Modal, Tabs, message, Popconfirm, Input } from 'antd';
import './../../../../../../index.scss';
import { nodeService } from '../../../../../services/node.service';
import { algorithmService } from '../../../../../services/algorithm-manage';
import BackPage from '../../../../common/backPage';

import { MinusCircleOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
const { Option } = Select;
const { confirm } = Modal;
const { TabPane } = Tabs;


const searchName = '';
const page = 1;
function Search(props) {
    const [form] = Form.useForm();
    const onFinish = (values) => {
        //   let str=''
        //   if (values.edgeName != null) {
        //     str = values.edgeName.replace(/[\^\%\\\{\}\#\&\|\+]/g, '5a0c511f-3cc8-428c-860b-f747c16b2341')
        //     str = str.replace(/[\s]/g, '')
        //     console.log(str); 
        //   }
        //   searchName = str || '';
        //   page = 1;
        //   props.onClick();
    };
    const resetForm = () => {
        //   form.resetFields();
        //   searchEdgeName = '';
        //   searchStatus = '';
        //   page = 1;
        //   pageSize = 10;
        //   props.onClick();
    };
    return (
        <>
            <div className="common-search-form">
                <Form
                    form={form}
                    name="advanced_search"
                    layout="inline"
                    style={{ justifyContent: "center" }}
                    className="ant-advanced-search-form"
                    onFinish={onFinish} >

                    <Form.Item name='alName' label='算法组名称' style={{ marginTop: '8px' }}>
                        <Input placeholder="请输入应用名称" style={{ width: '240px' }} />
                    </Form.Item>
                    <Form.Item style={{ marginTop: '8px' }}>
                        <Button type="primary" htmlType="submit"> 查询 </Button>
                        <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
                    </Form.Item>

                </Form>
            </div>
        </>
    );
}
class AlgorithmAuthorizationGroup extends React.Component {

    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            isAddVisible: false,
            algorithmGroupData: [],
            algorithmGroupId: {},
            licencesGroupList: []
        };
    }

    goback() {
        this.props.history.push('/home/node-manage');
    }
    showAddConfirm() {
        this.setState({
            isAddVisible: true
        })
    }
    showDeleteConfirm(record) {
        const selectInfo = this.state.licencesGroupList;
        const algorithmsGroupArr = [];//最终传body的数据
        selectInfo.map((ele) => {
            if (ele.id !== record.id) {
                const info = {};
                info.id = ele.algorithmGroupId;
                info.algorithmGroupName = ele.algorithmGroupName;
                algorithmsGroupArr.push(info);
        
            }
        })
        this.licenseAlgorithmGroups(this.props.nodeId, algorithmsGroupArr);
    }
    handleOk() {

    }
    handleCancel() {
        this.setState({
            isAddVisible: false,
        })
    }
    componentDidMount() {
        // console.log(this.props);
        this.getAlgorithmGroups();
        this.getLicensesInfo(this.props.nodeId);
    }
    //授权算法查询
    getLicensesInfo(nodeId) {
        nodeService.getLicences(nodeId).then(res => {
            if (res.code === 200) {
                console.log('授权算法查询', res.licences);
                this.setState({
                    licencesGroupList: res.licences.algorithmGroups
                })
            }
            // else {
            //     message.error('授权算法查询失败' + res.message);
            // }
        }, error => {
            error && message.error(error);
        })
    }
    //算法组列表查询
    getAlgorithmGroups() {
        algorithmService.getAlgorithmGroups('', 1000, '').then(res => {
            console.log('算法组', res.groups);
            if (res.code === 200) {
                const data = res.groups;
                this.handleAlgorithmGroups(data);
            }
        })
    }
    handleAlgorithmGroups(data) {
        const midData=[];
        data.map(ele => {
            midData.push(ele.algorithmGroupName);
            this.state.algorithmGroupId[ele.algorithmGroupName] = ele.id;
        })
        this.setState({
            algorithmGroupData:midData
        })
    }
     //算法组授权后台
     licenseAlgorithmGroups (nodeId, algorithmGroups){
        nodeService.algorithmGroupLicences(nodeId, algorithmGroups).then(res => {
            if (res.code === 200) {
                message.success('操作成功');
                this.handleCancel();
                this.getLicensesInfo(nodeId);
            }
            // else {
            //     message.error('操作失败' + res.message);
            // }
        }, error => {
            error && message.error(error);
        })
    }
    render() {
        const onFinish = values => {
            let flag = true;
            const algorithmGroupId = this.state.algorithmGroupId;
            const algorithmsGroup = [];//最终传body的数据
            const judgeRepeatArr = [];//判断是否重复添加的数组
            if (values.first) {
                const info = {};
                info.id = algorithmGroupId[values.first];
                info.algorithmGroupName = values.first;
                judgeRepeatArr.push(values.first);
                algorithmsGroup.push(info);
            }
            let res = values.last?.find(ele => ele.info !== undefined)
            if (!values.first && !res) {
                message.warning("请添加算法!");
            } else {
                values.last?.forEach(val => {
                    if (judgeRepeatArr.find(ele => ele === val.info)) {
                        if(flag){
                            message.warning("请勿添加相同的算法!");
                            flag = !flag;
                        }
                    } else if (val.info) {
                        const info = {};
                        info.id = algorithmGroupId[val.info];
                        info.algorithmGroupName = val.info;
                        judgeRepeatArr.push(val.info);
                        algorithmsGroup.push(info);
                    }
                });
                if (flag) {
                    this.licenseAlgorithmGroups(this.props.nodeId, algorithmsGroup);
                }
            }

        };
       
        const Columns = [
            {
                title: '算法组名称',
                dataIndex: 'algorithmGroupName',
            },

            {
                title: '算法数量',
                dataIndex: 'algorithmNum',
            },
            // {
            //     title: '描述',
            //     dataIndex: 'describe',
            // },
            {
                title: '开始时间',
                dataIndex: 'beginTime',
                // render: (text, record) => <span>{Math.floor(text / 365) + '年' + text % 365 + '天'}</span>
            },
            {
                title: '结束时间',
                dataIndex: 'endTime',
                // render: (text, record) => <span>{Math.floor(text / 365) + '年' + text % 365 + '天'}</span>
            },
            {
                title: '操作',
                dataIndex: 'action',
                render: (text, record) => <a href onClick={() => this.showDeleteConfirm(record)}>删除</a>
            }
        ];
        return (
            <>
                {/* <Search /> */}
                <Form>
                    <Form.Item>
                        <Button type="primary" style={{ color: '#fff' }} onClick={() => this.showAddConfirm()}>  新增算法组  </Button>
                    </Form.Item>
                </Form>
                <div className="common-center-container" style={{ padding: 0 }}>
                    <div className="user-table-card">
                        <Table
                            rowKey={record => record.id}
                            bordered={false}
                            pagination={false}
                            columns={Columns}
                            // scroll={{ y: 300 }}
                            dataSource={this.state.licencesGroupList}
                        />

                    </div>
                </div>
                <Modal
                    destroyOnClose//
                    width={400}
                    title="选择算法组"
                    visible={this.state.isAddVisible}
                    onOk={() => this.handleOk()}
                    onCancel={() => this.handleCancel()}
                    okText="确定"
                    cancelText="取消"
                    footer={null}
                >
                    <Form
                        preserve={false}
                        name="dynamic_form_nest_item"
                        onFinish={onFinish}
                        autoComplete="off" >
                        <Space key={-1} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                            <Form.Item
                                name='first'
                                label="算法组名称"
                            // rules={[{ required: true, message: '请选择算法组名称' }]}
                            >
                                <Select
                                    style={{ width: 240 }}
                                // onChange={this.handleChange_1}
                                >
                                    {this.state.algorithmGroupData.map(ele => (
                                        <Option key={ele} value={ele}>{ele}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Space>
                        <Form.List name="last"  >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                            <Form.Item
                                                {...restField}
                                                label="算法组名称"
                                                name={[name, 'info']}
                                            // fieldKey={[fieldKey, 'first']}
                                            // rules={[{ required: true, message: '请选择算法组名称' }]}
                                            >
                                                <Select
                                                    style={{ width: 240 }}
                                                // onChange={(e) => this.handleProvinceChange(e, key)}
                                                >
                                                    {this.state.algorithmGroupData.map(ele => (
                                                        <Option key={ele} value={ele}>{ele}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => { remove(name) }} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => { add() }} block icon={<PlusOutlined />}>
                                            添加
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        <Form.Item style={{ marginTop: '32px', justifyContent: 'center' }}>
                            <div style={{ textAlign: 'center' }}>
                                <Button type="primary" htmlType="submit" style={{ marginRight: 20 }}
                                // onClick={() => this.handleOk()} 
                                > 确认 </Button>
                                <Button style={{ margin: '0 9.46px' }} onClick={() => this.handleCancel()}> 取消</Button>
                            </div>
                        </Form.Item>

                    </Form>
                </Modal>
            </>
        );
    }
}

export default AlgorithmAuthorizationGroup;