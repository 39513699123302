import React from 'react';
import { Row, Col, Button } from 'antd';


class BottomBtnGroup extends React.Component {
    goBack = () => {
        this.props.onClick();
    }
    render() {
        return (
            <>
                <div className="common-bottom-btns-card">
                    <Row>
                        <Col span={24} style={{ padding: '26px 200px 26px 0' , textAlign: 'center'}}>
                            <Button type="primary" htmlType="submit">确定</Button>
                            <Button style={{ marginLeft: '9.5px' }} onClick={this.goBack.bind(this)} >取消</Button>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
export default BottomBtnGroup