import React from "react";

import {
  Form,
  Input,
  Tabs,
  Button,
  Select,
  ConfigProvider,
  Pagination,
  Table,
  Space,
  Modal,
  message,
} from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import {  ExclamationCircleOutlined } from "@ant-design/icons";
import { mirrorWarehouseService } from "../../../../services/mirror-warehouse.service";
import "./../../../../../index.scss";
import BackPage from "../../../common/backPage";
import { AUTCONFIG } from "../../../../../utils/auth.config";
import store from "../../../../../store";
import { userDebService } from "../../../../services/user-deb.service";
import { searchRule } from "../../../../../utils/search-util";
const { confirm } = Modal;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

//列表查询
let debName = "";
let pageSize = 10;
let page = 1;
function Search(props) {
  const form = React.createRef();
  const onFinish = (values) => {
    let name = "";
    if (values.debName != null) {
      name = searchRule(values.debName);
    }
    debName = name || "";
    props.onClick();
  };
  const resetForm = () => {
    form.current.resetFields();
    debName = "";
    pageSize = 10;
    page = 1;
    props.onClick();
  };

  return (
    <div>
      <div className="common-search-form">
        <Form
          ref={form}
          name="advanced_search_user"
          className="ant-advanced-search-form"
          onFinish={onFinish}
          layout="inline"
          // style={{ justifyContent: 'center' }}
        >
          <Form.Item name="debName" label="名称" style={{ marginTop: "8px" }}>
            <Input placeholder="请输入名称" />
          </Form.Item>
          <Form.Item style={{ marginTop: "8px" }}>
            <Button type="primary" htmlType="submit">
              查询
            </Button>
            <Button style={{ margin: "0 9.46px" }} onClick={resetForm}>
              重置
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
class ViewUserDeb extends React.Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      type:"",
      username: "",
      isFlag:true,
      page: 1,
      pageSize: 10,
      UserDebAmount: 0,
      isAddFirmwareModalVisible: false,
    };
  }

  componentDidMount() {
    if (store.getState().currentUser.type === AUTCONFIG.MAIN.value||
    store.getState().currentUser.type === AUTCONFIG.SUB.value) {
      this.setState({
        isFlag:false,
      })
      page = 1;
      pageSize = 10;
      this.searchList();
    } else if (
      store.getState().currentUser.type === AUTCONFIG.ADMIN.value ||
      store.getState().currentUser.type === AUTCONFIG.SUPERADMIN.value
    ) {
      this.setState({
        isFlag:true
      })
      page = 1;
      pageSize = 10;
      const data = this.props?.location?.state?.record;
      if (data) {
        this.setState({
          username: data.username || "",
          type:data.type || ""
        });
        this.searchList(data.username);
        // this.searchDescription(data.name);
        sessionStorage.setItem("username", data.username || "");
        sessionStorage.setItem("type", data.type || "");
      } else {
        if (sessionStorage.getItem("username")) {
          const username = sessionStorage.getItem("username");
          const type = sessionStorage.getItem("type");
          this.setState({
            username,
            type
          });
          this.searchList(username);
          // this.searchDescription(username);
        } else {
          this.goback();
        }
      }
    }
  }
  searchList(name = this.state.username) {
    this.setState({
      loading: true,
      page,
      pageSize,
    });
    userDebService
      .getUserDebList(name, debName, pageSize, page)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.code === 200) {
          console.log("用户软件包列表", res.debs); //
          this.setState({
            UserDebList: res.debs,
            UserDebAmount: res.dataCount,
          });
        }
        //  else {
        //   message.error(res.message);
        // }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        message.error(error);
      });
  }

  goback() {
    this.props.history.push("/home/userDeb");
  }

  showAddFirmware() {
    this.setState({ isAddFirmwareModalVisible: true });
  }

  showRemoveConfirm(record) {
    confirm({
      title: "确定下架该软件包吗?",
      icon: <ExclamationCircleOutlined />,
      closable: true,
      okText: "确定",
      cancelText: "取消",
      onOk: () => {
        userDebService
          .removeUserDeb(record.id)
          .then((res) => {
            if (res.code === 200) {
              message.success("操作成功");
              this.searchList();
            }
            //  else {
            //   message.error(res.message);
            // }
          })
          .catch((error) => {
            message.error(error);
          });
      },
      onCancel() {},
    });
  }

  showReleaseConfirm(record) {
    confirm({
      title: "确定发布该软件包吗?",
      icon: <ExclamationCircleOutlined />,
      closable: true,
      okText: "确定",
      cancelText: "取消",
      onOk: () => {
        userDebService
          .releaseUserDeb(record.id)
          .then((res) => {
            if (res.code === 200) {
              message.success("操作成功");
              this.searchList();
            }
            //  else {
            //   message.error(res.message);
            // }
          })
          .catch((error) => {
            message.error(error);
          });
      },
      onCancel() {},
    });
  }
  showDeleteConfirm(record) {
    confirm({
      title: "确定删除该软件包吗?",
      icon: <ExclamationCircleOutlined />,
      closable: true,
      okText: "确定",
      cancelText: "取消",
      onOk: () => {
        userDebService
          .deleteUserDeb(record.id)
          .then((res) => {
            if (res.code === 200) {
              message.success("操作成功");
              this.searchList();
            }
            //  else {
            //   message.error(res.message);
            // }
          })
          .catch((error) => {
            message.error(error);
          });
      },
      onCancel() {},
    });
  }
  toEdit(record) {
    console.log("history传递的数据", record);
    this.props.history.push({
      pathname: "/home/userDeb/view/edit",
      state: { record },
    });
  }
  resetForm = () => {
    this.formRef.current.resetFields();
    // addFirName = '';
    // addFirModel = '';
    // addFirDescription = '';
    // this.props.handleCancel();
    this.setState({
      isAddFirmwareModalVisible: false,
    });
  };
  onFinish = (values) => {
    console.log("新增epack信息", values);
    let addFirName = values.firName;
    let addFirModel = values.firModel;
    let addFirDescription = values.firDescription;
    let addURL = values.firUrl;
    /* if (!addFirName||!addFirModel||!addURL) {
      message.warn('必填项不能为空');
      return
  } */
    userDebService
      .addUserDeb(addFirName, addFirModel, addFirDescription, addURL)
      .then(
        (res) => {
          if (res.code === 200) {
            message.success("创建成功");
            this.formRef.current.resetFields();
            this.setState({
              isAddFirmwareModalVisible: false,
            });
          }
          this.searchList();
          // else {
          //     message.success("创建失败，" + res.message);
          // }
        },
        (error) => {
          error && message.error(error);
        }
      );
  };

  // componentWillUnmount() {
  //   sessionStorage.removeItem("username");
  // }
  render() {
    const formAddFirItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
    };
    const toViewDeb = (record) => {
      this.props.history.push({
        pathname: "/home/userDeb/view/firmware",
        state: { record },
      });
    };
    const onChange = (pageNumber) => {
      console.log("pageNumber", pageNumber);
      page = pageNumber;
      this.searchList();
    };
    const onShowSizeChange = (current, pageSizeNumber) => {
      console.log("pageSizeNumber", pageSizeNumber);
      pageSize = pageSizeNumber;
      page = 1;
      this.searchList();
    };

    const confColumns = [
      {
        title: "名称",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <a onClick={() => toViewDeb(record)}>{text}</a>
        ),
      },
      {
        title: "版本",
        dataIndex: "version",
        key: "version",
      },
      {
        title: "软件数目",
        dataIndex: "softwareNum",
        key: "softwareNum",
      },
      {
        title: "描述",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "状态",
        dataIndex: "state",
        key: "state",
        render: (text, record) => (
          <>
            {text === 0 || text === "0" ? (
              <div>
                <div className="common-red"></div> <span>未发布</span>
              </div>
            ) : (
              <div>
                <div className="common-green"></div> <span>已发布</span>
              </div>
            )}
          </>
        ),
      },
      {
        title: "创建时间",
        dataIndex: "createTime",
        key: "createTime",
      },
      {
        title: "更新时间",
        dataIndex: "updateTime",
        key: "updateTime",
      },
      {
        title: "操作",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            {record.state ? (
              <a onClick={() => this.showRemoveConfirm(record)}>下架</a>
            ) : (
                <a onClick={() => this.showReleaseConfirm(record)}>发布</a>
            )}
            <a onClick={() => this.toEdit(record)}>编辑</a>
            <a onClick={() => this.showDeleteConfirm(record)}>删除</a>
          </Space>
        ),
      },
    ];
    return (
      <>
        <div className="common-detail-container">
          {this.state.isFlag ? (
            <BackPage
              onClick={() => this.goback()}
              headeTitle={"查看用户---" + this.state.username}
            />
          ) : (
            <h1 className="common-top-title0">用户软件包</h1>
          )}
          <div className="common-center-container">
            <Search onClick={() => this.searchList()} />
            {this.state.type === store.getState().currentUser.type ||
            store.getState().currentUser.type === AUTCONFIG.MAIN.value ||
            store.getState().currentUser.type === AUTCONFIG.SUB.value ? (
              <Button
                type="primary"
                onClick={() => this.showAddFirmware()}
                style={{ width: "90px", marginBottom: "16px" }}
              >
                新增软件包
              </Button>
            ) : (
              ""
            )}

            <div className="job-bottom-content">
              <Table
                columns={confColumns}
                rowKey={(record) => record.id}
                pagination={false}
                dataSource={this.state.UserDebList}
              />
            </div>
            <div className="common-bottom-pagination">
              <ConfigProvider locale={zhCN}>
                <Pagination
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={1}
                  current={this.state.page}
                  pageSize={this.state.pageSize}
                  total={this.state.UserDebAmount}
                  onChange={onChange}
                />
              </ConfigProvider>
            </div>

            <Modal
              title="新增软件包"
              visible={this.state.isAddFirmwareModalVisible}
              onCancel={this.resetForm}
              footer={null}
            >
              <Form
                // style={{ marginBottom: 10, marginLeft: 22 }}
                ref={this.formRef}
                name="validate_other"
                // labelAlign="left"
                {...formAddFirItemLayout}
                onFinish={this.onFinish}
              >
                <Form.Item
                  name="firName"
                  label="名称"
                  rules={[
                    { required: true, message: "请输入软件包名称" },
                    { max: 64, message: "epack名称不允许超过64个字符" },
                    {
                      pattern: new RegExp(
                        /^[0-9a-zA-Z-_\u4e00-\u9fa5]{1,}$/,
                        "g"
                      ),
                      message: "请输入64位以内中英文/数字/中划线/下划线",
                    },
                  ]}
                >
                  <Input name="firName" placeholder="请输入软件包名称" />
                </Form.Item>
                <Form.Item
                  name="firModel"
                  label="版本"
                  rules={[
                    { required: true, message: "请输入软件包版本" },
                    {
                      pattern: /^((\d+)\.){1,}(\d+)$/,
                      message: "软件包版本格式不对,例如123.123.123",
                    },
                  ]}
                >
                  <Input name="firModel" placeholder="请输入软件包版本" />
                </Form.Item>
                <Form.Item name="firUrl" label="链接">
                  <Input name="firName" placeholder="请输入软件包链接" />
                </Form.Item>
                <Form.Item name="firDescription" label="描述">
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    placeholder="请输入描述"
                    showCount={true}
                    maxLength={255}
                  />
                </Form.Item>
                <div style={{ textAlign: "center" }}>
                  <Button type="primary" htmlType="submit">
                    新增
                  </Button>
                  <Button
                    style={{ marginLeft: "12px" }}
                    onClick={this.resetForm}
                  >
                    取消
                  </Button>
                </div>
              </Form>
            </Modal>
          </div>
        </div>
      </>
    );
  }
}

export default ViewUserDeb;
