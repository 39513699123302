/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Form, Input, Select, message, Row, Col, ConfigProvider, Divider, Pagination, Button, Table, Modal, Badge } from 'antd';
import './../../../../../../index.scss';
import './create.scss'
import zhCN from 'antd/lib/locale/zh_CN';
import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';
import { nodeService } from '../../../../../services/node.service';
import { edgeService } from '../../../../../services/edge.service';
import { CloseCircleOutlined } from '@ant-design/icons';

import { connect } from 'react-redux'
const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 14 },
};

//联选镜像数组
let defineArr = [{ name: '', things: [], doings: [] },];

let flag = false;

let page = 1;
let pageSize = 10;
let dataCount = null;
let searchNodeName = ''
let searchLabel = [];
let nodeList = [];
let nodeActions = [];

function NodeModal(props) {

  //const [form] = Form.useForm();
  const form = React.createRef();
  const nodeColumns = [
    {
      title: '节点名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'epack版本',
      dataIndex: 'firmwareVersion',
      key: 'firmwareVersion',
    },
    {
      title: '产品型号',
      dataIndex: 'productModel',
      key: 'productModel',
    },
    {
      title: '描述',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  const onFinish = (values) => {
    const str = values?.searchNodeName?.split("\\")
    //console.log(...str);
    let newStr = ''
    for (let i = 0; i < str?.length; i++) {
      newStr += str[i]
    }

    searchNodeName = newStr;
    searchLabel = values.label || [];
    console.log(searchNodeName);
    page = 1;
    props.onClick();
  };
  const resetForm = () => {
    //form.resetFields();
    form.current.resetFields();
    searchNodeName = '';
    searchLabel = [];
    nodeActions = [];
    flag = true;
    page = 1;
    pageSize = 10;
    props.reform();
  }

  //对象变量，用来保存对行数据的选中
  const rowSelection = {
    selectedRowKeys: props.selectedNodeIds,

    onChange: (selectedRowKeys, selectedRows) => {
      nodeActions = selectedRows;
      console.log(nodeActions);
      props.changeSelected();
    },
  };

  const onPageChange = (pageNumber) => {

    page = pageNumber;

    props.onClick();
  }
  const onShowSizeChange = (current, pageSizeNumber) => {
    pageSize = pageSizeNumber;
    page = 1;

    props.onClick();
  }
  
  return (
    <>
      <div className="common-search-form">
        <Form
          //form={form}
          ref={form}
          name="advanced_search"
          layout="inline"
          className="ant-advanced-search-form"
          style={{ justifyContent: 'center', marginBottom: 12 }}
          onFinish={onFinish} >

          <Form.Item name='searchNodeName' label='节点名称' style={{ marginTop: 0 }}>
            <Input placeholder="请输入关键字" />
          </Form.Item>

          <Form.Item label='标签' name="label" style={{ marginTop: 0 }}>
            <Select mode="multiple" allowClear style={{ width: 200 }} placeholder="请选择标签" showArrow>
              {props.labelList}
            </Select>
          </Form.Item>
          <Form.Item style={{ marginTop: 0 }}>
            <Button type="primary" htmlType="submit"> 查询 </Button>
            <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
          </Form.Item>

        </Form>
      </div>
      <Table
        rowSelection={{
          ...rowSelection,
        }}
        rowKey={record => record.id}
        columns={nodeColumns}
        dataSource={nodeList}
        pagination={false}
        scroll={{ y: 300 }}
      />
      {
        nodeList.length > 0 &&
        <div className="common-bottom-pagination" style={{ marginTop: '10px' }}>
          <ConfigProvider locale={zhCN}>
            <Pagination
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={1}
              current={page}
              pageSize={pageSize}
              total={dataCount}
              onChange={onPageChange} />
          </ConfigProvider>
        </div>
      }
    </>
  );
}


class CreateEdgeEffectManagementDemo extends React.Component {
  formRef = React.createRef();

  constructor() {
    super();
    this.state = {
      nodeList: [],
      portList: [], //2
      highList: [],  //3

      nodeVisible: false,
      portVisible: false,//新增端口的弹框
      variableVisible: false,//新增环境变量的弹框

      //selectName: defineArr[0].name,//二级联选镜像名称
      selectName: '',//二级联选镜像名称
      selectThing: defineArr[0].things[0],//二级联选镜像版本
      selectDoing: defineArr[0].doings[0],//

      selectedNodeIds: [],//选择的节点
      chooseNodeList: [],//展示选择的节点信息  

      uuidList: [],//节点集合 1

      showManage: true,
      hasSubmit: false,

      nodes: [],//所有节点列表

      dev: '', //运行命令 4
      type: 'TCP',
    };
  }

  goback() {
    this.props.history.push('/home/effect-edge-m/list');
  }

  //匹配端口状态
  getPortType = (record) => {
    if (record.type === 0) {
      return <span>TCP</span>
    } else if (record.type === 1) {
      return <span>UDP</span>
    }
  }

  //改变选择的状态
  onSelectTypeChange(value) {
    //console.log(value);
    this.setState({
      type: value
    })

  }


  changeSelected() {
    const selectedNodeIds = [];
    nodeActions.forEach(ele => {
      selectedNodeIds.push(ele.id)
    });
    this.setState({
      selectedNodeIds: selectedNodeIds,
    })

  }

  //获取节点信息
  // getNodeList() {
  //   nodeService.getNodeListCreate('', '', '', '', '', '', '', '', '', '', 1000, 1).then(res => {
  //     if (res.code === 200) {
  //       console.log("查找的所有节点信息为:", res);
  //       nodeList = res.nodes;
  //       this.setState({
  //         nodes: res.nodes,
  //       })
  //     }
  //   })
  // }

  //获取节点列表信息
  getSearchNodeList() {
    if (flag) {
      this.setState({
        selectedNodeIds: [],
      })
      flag = false
    }

    nodeService.getNodeListCreate(searchNodeName, '', '', '', '', '', '', '', '', '', pageSize, page).then(res => {
      if (res.code === 200) {
        console.log("查找的节点信息为:", res);
        nodeList = res.nodes;
        dataCount = res.dataCount;
        this.setState({
          nodes: res.nodes,
        })
      }
    })
  }

  //镜像数组的合并
  mergeArr(images) {
    let testArr = [];
    let resultArr = [];
    for (let i = 0; i < images.length; i++) {
      if (testArr.indexOf(images[i].name) === -1) {
        resultArr.push({
          name: images[i].name,
          things: [images[i].version],
          doings: [images[i].imageUrl]
        });
        testArr.push(images[i].name);
      }
      else {
        for (let j = 0; j < resultArr.length; j++) {
          if (resultArr[j].name == images[i].name) {
            resultArr[j].things.push(images[i].version);
            resultArr[j].doings.push(images[i].imageUrl);
            break;
          }
        }
      }
    }
    return resultArr
  }


  //获取镜像信息
  getImage() {
    edgeService.getImageList().then(res => {
      if (res.code == 200) {
        console.log("查找的镜像信息为：", res);
        if (res.images.length != 0) {
          defineArr = this.mergeArr(res.images)
        }

        console.log(defineArr);
      }
    }).catch(error => {
      error && message.error(error);
    })
  }

  componentDidMount() {
    this.getImage();
    this.getSearchNodeList()

  }

  //弹窗的取消设置
  handleOk(e) {
    console.log(e);
    this.setState({
      nodeVisible: false,
      portVisible: false,
      variableVisible: false
    })
  }

  //节点部署成功
  handleNodeOk = () => {
    //const arr = [...this.state.chooseNodeList, ...nodeActions]
    //const newArr = Array.from(new Set(arr))

    const { chooseNodeList } = this.state;

    // const newArr1 = chooseNodeList.filter(item => {
    //   let arrlist = nodeActions.map(item2 => item2.id)
    //   return !arrlist.includes(item.id)
    // })
    // const newArr = [...newArr1, ...nodeActions]

    console.log(chooseNodeList);
    console.log(nodeActions);

    let newArr1 = [...chooseNodeList]
    let newArr2 = [...nodeActions]
    let filter = newArr1.filter(item => !newArr2.some(ele => ele.id === item.id))
    const newArr = [...filter, ...newArr2]

    console.log("chooseNodeList的值为", newArr);

    const newUuidList = newArr.map(item => item.nodeUuid)
    console.log("添加后的节点集合", newUuidList);

    this.setState({
      chooseNodeList: newArr,
      uuidList: newUuidList,
    })
    this.handleOk();
  }

  //将类型转换为数字
  changType(obj) {
    if (obj.type === 'TCP') {
      obj.type = 0
    } else if (obj.type === 'UDP') {
      obj.type = 1
    }
  }

  //对添加的端口进行判重
  filterPort(portList, e) {
    let newPortList = []
    if (portList.length === 0) {
      newPortList = [e]
    } else {
      for (let i = 0; i < portList.length; i++) {
        if (portList[i].containerPort === e.containerPort || portList[i].hostPort === e.hostPort) {
          message.warning("容器端口或主机端口已存在")
          newPortList = portList
        } else {
          newPortList = [...portList, e]
        }
      }
    }

    return newPortList
  }

  //保存新增的端口
  handleSubmit1 = (e) => {
    console.log(e);
    e.type = this.state.type
    this.changType(e)
    console.log("添加的端口的信息", e);
    const { portList } = this.state
    //const newPortList = [...portList, e]

    // const arr = [...portList, e];
    // const newPortList = this.deteleObject(arr)

    let newPortList = this.filterPort(portList, e)
    console.log(this.state.portList);
    this.setState({
      portVisible: false,
      portList: newPortList,
    })
  }


  //对添加的环境变量进行判重
  filterVariable(highList, e) {
    let newHighList = []
    if (highList.length === 0) {
      newHighList = [e]
    } else {
      for (let i = 0; i < highList.length; i++) {
        if (highList[i].name === e.name) {
          message.warning("该变量名已存在")
          newHighList = highList
        } else {
          newHighList = [...highList, e]
        }
      }
    }

    return newHighList
  }


  //保存新增的环境变量
  handleSubmit2 = (e) => {
    console.log(e);
    const { highList } = this.state
    //const newHighList = [...highList,e]

    // const arr = [...highList, e]
    // const newHighList = this.deteleObject(arr)

    let newHighList = this.filterVariable(highList, e)
    this.setState({
      variableVisible: false,
      highList: newHighList,
    })
  }

  //保存删除后的节点数据
  deleteNode = (text, record) => {
    console.log("要删除的信息", record);
    const { chooseNodeList } = this.state
    const newChooseNodeList = chooseNodeList.filter(todoNode => {
      return !(todoNode.name === record.name)
    })
    console.log(newChooseNodeList);

    const newUuidList = newChooseNodeList.map(item => item.nodeUuid)
    console.log("删除后的节点集合", newUuidList);
    this.setState({
      chooseNodeList: newChooseNodeList,
      uuidList: newUuidList,
    })
  }

  //保存删除后的端口数据
  deletePort = (text, record) => {
    console.log("要删除的信息", record);
    const { portList } = this.state
    const newPortList = portList.filter(todoPort => {
      return !(todoPort.containerPort === record.containerPort && todoPort.hostPort === record.hostPort)
    })
    console.log(newPortList);
    this.setState({ portList: newPortList })
  }
  //保存删除后的环境变量数据
  deleteV = (text, record) => {
    console.log("要删除的信息", record);
    const { highList } = this.state
    const newHighList = highList.filter(todoV => {
      return !(todoV.name === record.name && todoV.value === record.value)
    })
    console.log(newHighList);
    this.setState({ highList: newHighList })
  }

  //弹窗添加节点
  addNode() {
    this.setState({
      nodeVisible: true,
      selectedNodeIds: []
    })
  }

  //弹窗添加端口
  addPort() {
    this.setState({
      portVisible: true
    })
  }

  //弹窗添加变量
  addVariable() {
    this.setState({
      variableVisible: true
    })
  }

  //二级联选改变名称，改变对应的版本
  changeName(e) {
    //console.log("烫烫烫烫", e);
    this.setState({ selectName: e });
    defineArr.map((item, index) => {
      if (e === item.name) {
        this.setState({
          selectThing: item.things[0],
          selectDoing: item.doings[0],
        });
      }
      return true;
    })
  }

  //选择版本
  changeThings(e) {
    console.log(e);
    console.log(defineArr);
    //this.setState({ selectThing: e });
    defineArr.map((item, index) => {
      if (this.state.selectName === item.name)
        this.setState({
          selectThing: item.things[e],
          selectDoing: item.doings[e],
        });
    })
  }

  //添加应用接口
  addApp(uuidList, name, description, version, instanceNum, imageUrl, imageName, imageVersion, cpuRequest, memRequest,
    diskRequest, gpuRequest, cpuLimit, memLimit, diskLimit, gpuLimit, command, appEnvs, appPorts, appVolumes, networkType) {

    edgeService.addEdge(uuidList, name, description, version, instanceNum, imageUrl, imageName, imageVersion, cpuRequest, memRequest,
      diskRequest, gpuRequest, cpuLimit, memLimit, diskLimit, gpuLimit, command, appEnvs, appPorts, appVolumes, networkType).then(res => {
        if (res.code === 200) {
          console.log("添加的信息为:", res);
          message.success('创建应用成功');
        }
      }).catch(error => {
        error && message.error(error);
      })
  }

  //状态匹配
  getState(record) {
    if (record.state === 'UNCONNECTED') {
      return <span><Badge status="warning" />未纳管</span>
    }
    else if (record.state === 'RUNNING') {
      return <span><Badge status="success" />运行中</span>
    }
    else if (record.state === 'UPGRADEWAITING') {
      return <span><Badge status="warning" />待升级</span>
    }
    else if (record.state === 'UPGRADING') {
      return <span><Badge status="warning" />升级中</span>
    }
    else if (record.state === 'DISABLED') {
      return <span><Badge status="error" />禁用</span>
    }
    else if (record.state === 'OFFLINE') {
      return <span><Badge status="error" />不在线</span>
    }
    else if (record.state === 'DELETED') {
      return <span><Badge status="default" />已删除</span>
    }
    else {
      return '';
    }
  }

  render() {
    console.log("state中dev的值", this.state.selectThing);
    console.log(this.state.selectDoing);

    const onFinish = (values) => {
      console.log(values);
      console.log(this.state.selectThing);
      values.imageVersion = this.state.selectThing;
      console.log(this.state.selectDoing);

      if (this.state.uuidList.length == 0) {
        message.error("请选择节点")
      } else {
        this.addApp(this.state.uuidList, values.userName, values.description, '', '', this.state.selectDoing, values.imageName, values.imageVersion, '', '', '', '',
          values.cpuLimit, values.memLimit, values.diskLimit, values.gpuLimit, this.state.dev, this.state.highList, this.state.portList, '', '');

        // this.addApp(['fc2fc4da-313e-4012-b853-3c5aeffc94f0'], 'asas', 'ddd', '0.4.0', '', "https://pypi.tuna.tsinghua.edu.cn/simple", "ubuntu", "14.0", '', '', '', '',
        // 1,1,1,1, 'eee', [], [],[],'')
        this.goback();
      }

    };

    //设置dev
    const setDev = (e) => {
      console.log(e.target.value);
      this.setState({ dev: e.target.value })
    }

    //部署节点的列
    const columsOne = [
      {
        title: '节点名称',
        dataIndex: 'name',
      },
      {
        title: '描述',
        dataIndex: 'description',
      },
      {
        title: '状态',
        dataIndex: 'state',
        render: (text, record) => (
          this.getState(record)
        )
      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record) => {
          return (
            <span>

              <a onClick={() => this.deleteNode(text, record)}>删除</a>
            </span>
          )
        }
      }
    ]

    //端口映射的列
    const columsTwo = [
      {
        title: '容器端口',
        dataIndex: 'containerPort',
      },
      {
        title: '主机端口',
        dataIndex: 'hostPort',
      },
      {
        title: '类型',
        dataIndex: 'type',
        render: (text, record) => (
          this.getPortType(record)
        )
      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record) => {
          return (
            <span>

              <a onClick={() => this.deletePort(text, record)}>删除</a>
            </span>
          )
        }
      }
    ]

    //环境变量的列
    const columsThree = [
      {
        title: '变量名',
        dataIndex: 'name',
      },
      {
        title: '变量值',
        dataIndex: 'value',
      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record) => {
          return (
            <span>

              <a onClick={() => this.deleteV(text, record)}>删除</a>
            </span>
          )
        }
      }
    ]

    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 15 },
    };

    //二级联选的名称下拉选择
    const names = defineArr.map((item, index) => {
      return <Option key={index} value={item.name}>{item.name}</Option>
    });
    //二级联选的版本下拉选择
    const things = defineArr.map((item, index) => {
      console.log(this.state.selectThing);
      if (this.state.selectName == item.name) {
        return item.things.map((item, index) =>
          <Option key={index} value={item.things}>{item}</Option>
        )
      }

      return true;
    });

    // const doings = defineArr.map((item, index) => {
    //   console.log(this.state.selectDoing);
    //   if (this.state.selectName == item.name) {
    //     return item.doings.map((item, index) =>
    //       <Option key={index} value={item.doings}>{item}</Option>
    //     )
    //   }
    // });

    return (

      <>
        <div className="common-pd20">

          <BackPage onClick={() => this.goback()} headeTitle="创建应用" itemTitle="应用管理" itemSubTitle="创建用户" />

          <div className="common-create-center-container" >

            <div className="common-create-page">

              <div style={{ fontSize: 15, fontWeight: 'bold', marginLeft: -30, marginBottom: 20 }}>基本信息</div>
              <Form

                ref={this.formRef}
                name="validate_other"
                labelAlign="left"
                {...formItemLayout}
                onFinish={onFinish}
                initialValues={{ cpuLimit: 1, memLimit: 100, gpuLimit: 0, diskLimit: 0 }}
              >
                <Form.Item name="userName" label="应用名称" rules={[{ required: true, message: '请输入应用名称' }, { pattern: /(^[a-z0-9][-a-z0-9]{0,62}[a-z0-9]$)|(^[a-z0-9]$)/, message: '请输入64位以内小写字母/数字/英文格式的中划线，且须以小写字母/数字开头、结尾' }]} >
                  <Input placeholder="请输入应用名称" />
                </Form.Item>
                <Form.Item name="description" label="描述" rules={[{ required: false, message: '请输入描述信息' }]}>
                  <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" showCount={true} maxLength={255} />
                </Form.Item>

                <Form.Item label="资源配置" rules={[{ required: true, message: '请输入资源配置' }]}>
                  <Row gutter={5}>

                    <Col span={6}>
                      <Form.Item name="cpuLimit" noStyle rules={[{ required: true, message: 'cpu与内存必填' }]}>
                        <Input type="number" min={0} max={10} addonBefore="CPU" addonAfter="核" step="0.01" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="memLimit" noStyle rules={[{ required: true, message: 'cpu与内存必填' }]}>
                        <Input type="number" min={0} max={8192} addonBefore="内存" addonAfter="M" step="0.01" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="gpuLimit" noStyle rules={[{ required: false, message: '请输入配置' }]}>
                        <Input type="number" disabled min={0} max={10} addonBefore="GPU" addonAfter="核" step="0.01" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="diskLimit" noStyle rules={[{ required: false, message: '请输入配置' }]}>
                        <Input type="number" disabled min={0} max={8192} addonBefore="存储" addonAfter="M" step="0.01" />
                      </Form.Item>
                    </Col>

                  </Row>
                </Form.Item>

                <Form.Item name="imageName" label="应用镜像" rules={[{ required: true, message: '请选择应用镜像' }]}>
                  <Select placeholder="请选择应用镜像" onChange={this.changeName.bind(this)}>{names}</Select>
                </Form.Item>

                <Form.Item name="imageVersion" label="镜像版本" rules={[{ required: true, message: '请选择镜像版本' }]}>
                  <Select placeholder="请选择镜像版本" onChange={this.changeThings.bind(this)}>{things}</Select>
                </Form.Item>

                {/* <Form.Item name="imageUrl" label="镜像地址" rules={[{ required: false, message: '请选择镜像地址' }]}>
                  {this.state.selectDoing}
                </Form.Item> */}

                {/* <Form.Item   label="镜像地址" rules={[{ required: true, message: '请输入镜像地址' }]}>
                <Select
                placeholder="请选择镜像地址"
                      initialvalue="https://pypi.tuna.tsinghua.edu.cn/simple"  >
                    <Option value="https://pypi.tuna.tsinghua.edu.cn/simple">https://pypi.tuna.tsinghua.edu.cn/simple</Option>
                </Select>
                </Form.Item> */}

                <BottomBtnGroup onClick={() => this.goback()} />

              </Form>

            </div>
          </div>

          <div className="common-create-center-container" style={{ marginTop: -50 }}>
            <div className="common-create-page">
              <div style={{ fontSize: 15, fontWeight: 'bold', marginLeft: -30, marginBottom: 20 }}>部署配置</div>
              <span style={{ color: 'red', fontSize: 20, fontWeight:'bold',border:'14px',position:'relative', top:'5px'}}>*</span><span style={{ fontSize: 13, marginBottom: 20, marginRight: 10 }}> 部署节点 :</span>
              <Button onClick={() => this.addNode()} type="primary" style={{ fontSize: 13, marginBottom: 20 }}>选择节点</Button>
              <Table columns={columsOne}  //列
                rowKey={record => record.id}
                dataSource={this.state.chooseNodeList}  //行数据
                pagination={false}   //不展示分页
                scroll={{ y: 250 }}  //滚动
                bordered={true}
              />
            </div>
          </div>

          <div className="common-create-center-container" style={{ marginTop: -50 }}>
            <div className="common-create-page">
              <div style={{ fontSize: 15, fontWeight: 'bold', marginLeft: -30, marginBottom: 20 }}>访问配置</div>
              <span style={{ fontSize: 13, marginBottom: 20, marginRight: 10 }}>端口映射 :</span>
              <Button onClick={() => this.addPort()} type="primary" style={{ fontSize: 13, marginBottom: 20 }}>新增</Button>
              <Table columns={columsTwo}  //列
                rowKey={record => record.id}
                dataSource={this.state.portList}  //行数据
                pagination={false}   //不展示分页
                scroll={{ y: 250 }}  //滚动
                bordered={true}
              />
            </div>
          </div>

          <div className="common-create-center-container" style={{ marginTop: -50 }}>
            <div className="common-create-page">
              <div style={{ fontSize: 15, fontWeight: 'bold', marginLeft: -30, marginBottom: 20 }}>高级配置</div>

              <div style={{ width: '50%' }}>
                <Form
                  // {...formItemLayout}
                  size='large'
                  labelAlign="left"
                >
                  <Form.Item label="运行命令"  >
                    <Input placeholder="请输入" maxLength={255} onChange={setDev} />
                  </Form.Item>
                </Form>
              </div>


              <span style={{ marginBottom: 20, marginRight: 10 }}>环境变量 :</span>
              <Button onClick={() => this.addVariable()} type="primary" style={{ fontSize: 13, marginBottom: 20 }}>新增</Button>
              <Table columns={columsThree}  //列
                rowKey={record => record.id}
                dataSource={this.state.highList}  //行数据
                pagination={false}   //不展示分页
                scroll={{ y: 250 }}  //滚动
                bordered={true}
              />
            </div>
          </div>


          {/* 弹窗 */}
          <Modal width={800} title="选择节点" visible={this.state.nodeVisible} onOk={() => this.handleNodeOk()} onCancel={() => this.handleOk()} okText="确定" cancelText="取消">
            <NodeModal changeSelected={() => this.changeSelected()}
              selectedNodeIds={this.state.selectedNodeIds}
              chooseNodeList={this.state.chooseNodeList} onClick={() => this.getSearchNodeList()} reform={() => this.getSearchNodeList()} />
          </Modal>

          <Modal title="添加端口映射" visible={this.state.portVisible} cancelText okText closable={false}>
            <Form {...layout}
              name="control-ref"
              initialValues={{ type: this.state.type }}
              onFinish={this.handleSubmit1}>
              <Form.Item name="containerPort" label="容器端口" rules={[{ required: true, message: '容器端口不能为空,请输入0-65535范围的数字' }]} >
                <Input placeholder="请输入容器端口,0-65535范围的数字" type="number" max={65535} min={0} />
              </Form.Item>

              <Form.Item name="hostPort" label="主机端口" rules={[{ required: true, message: '主机端口不能为空,请输入0-65535范围的数字' }]} >
                <Input placeholder="请输入主机端口,0-65535范围的数字" type="number" max={65535} min={0} />
              </Form.Item>

              <Form.Item name="type" label="类型"   >
                <Select
                  placeholder="请选类型"
                  onChange={(value) => {
                    this.onSelectTypeChange(value);
                  }}
                >
                  <Option value="TCP">TCP</Option>
                  <Option value="UDP">UDP</Option>
                </Select>
              </Form.Item>
              <br />
              <Form.Item style={{ marginLeft: 260, marginBottom: -70 }}>
                <Button type="primary" htmlType="submit"  > 确定 </Button>
                <Button onClick={this.handleOk.bind(this)} style={{ marginLeft: 10 }} >取消</Button>
              </Form.Item>
            </Form>
          </Modal>

          <Modal title="添加环境变量" visible={this.state.variableVisible} cancelText okText closable={false}>
            <Form {...layout}
              name="control-ref"
              onFinish={this.handleSubmit2}>
              <Form.Item name="name" label="变量名" rules={[{ required: true, message: '变量名不能为空' }, { pattern: /^[-a-zA-Z0-9_]{0,32}$/, message: '请输入长度32以内的英文字母、数字、中划线、下划线' }]} >
                <Input placeholder="格式为英文字母/数字/中划线/下划线" />
              </Form.Item>

              <Form.Item name="value" label="变量值" rules={[{ required: true, message: '变量值不能为空' }, { pattern: /^[a-zA-Z0-9`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]{0,32}$/, message: '请输入长度32以内的英文字母、数字、中划线、下划线、特殊字符' }]} >
                <Input placeholder="格式为英文字母/数字/中划线/下划线/特殊字符" />
              </Form.Item>
              <br />
              <Form.Item style={{ marginLeft: 260, marginBottom: -70 }}>
                <Button type="primary" htmlType="submit"  > 确定 </Button>
                <Button onClick={this.handleOk.bind(this)} style={{ marginLeft: 10 }} >取消</Button>
              </Form.Item>
            </Form>
          </Modal>

        </div>
      </>
    );
  }
}

//export default CreateEdgeEffectManagementDemo;


export default connect(
  (state) => ({
    currentUser: state.currentUser
  })
)(CreateEdgeEffectManagementDemo);