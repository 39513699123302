import React from "react";
import {
  Form,
  Input,
  message,
  Select,
  Row,
  Button,
  Table,
  Radio,
  DatePicker,
  Modal,
  Col,
  Tag,
} from "antd";
import moment from "moment";
import BackPage from "../../../common/backPage";
import BottomBtnGroup from "../../../common/bottomBtnGroup";
import { labelService } from "../../../../services/label.service";
import { nodeService } from "../../../../services/node.service";
import { jobService } from "../../../../services/job.service";
import { otaService } from "../../../../services/ota.service";
import { firmwareService } from "../../../../services/firmware.service";
import { userDebService } from "../../../../services/user-deb.service";
import "moment/locale/zh-cn";
import locale from "antd/es/date-picker/locale/zh_CN";
import { CloseCircleOutlined } from "@ant-design/icons";

import "./create-job.scss";
import frontendAuth from "../../../../../frontend-auth";
import store from "../../../../../store";

const { Option } = Select;

const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

let radioValue = 1;
let radioDateValue = "";

function RadioCheck() {
  const [value, setValue] = React.useState(1);
  // let isCanPickDate = true;
  const onRadioChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
    radioValue = e.target.value;
    if (e.target.value === 2) {
      // isCanPickDate = true;
    } else {
      // isCanPickDate = false;
      radioDateValue = "";
    }
    // disabled={isCanPickDate}
  };
  const onDatePickerChange = (value, dateString) => {
    console.log("Formatted Selected Time: ", dateString);
    radioDateValue = dateString;
  };
  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDate = (current) => {
    return current < moment().subtract(1, "day");
  };

  const disabledDateTime = (date) => {
    if (date && date.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
      return {
        disabledHours: () => range(0, 24).splice(0, moment().hour()),
        disabledMinutes: () => range(0, moment().minute() + 1),
        disabledSeconds: () => [0, 60],
      };
    }
  };

  return (
    <Radio.Group onChange={onRadioChange} value={value}>
      <Radio value={1}>立即</Radio>
      <Radio value={2}>
        <span style={{ marginRight: 14 }}>定时</span>
      </Radio>
      <DatePicker
        showTime
        showNow={false}
        disabledDate={disabledDate}
        disabledTime={disabledDateTime}
        placeholder="请选择时间"
        onChange={onDatePickerChange}
        locale={locale}
      />
    </Radio.Group>
  );
}

let searchNodeName = "";
let searchLabel = [];
let nodeList = [];
let nodeActions = [];
function NodeModal(props) {
  const [form] = Form.useForm();
  const nodeColumns = [
    {
      title: "节点名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "epack版本",
      dataIndex: "firmwareVersion",
      key: "firmwareVersion",
    },
    {
      title: "产品型号",
      dataIndex: "productModel",
      key: "productModel",
    },
    {
      title: "描述",
      dataIndex: "description",
      key: "description",
    },
  ];
  const onFinish = (values) => {
    searchNodeName = values.searchNodeName;
    searchLabel = values.label || [];
    props.onClick();
  };
  const resetForm = () => {
    form.resetFields();
    searchNodeName = "";
    searchLabel = [];
    props.onClick();
  };
  const rowSelection = {
    selectedRowKeys: props.selectedNodeIds,
    onChange: (selectedRowKeys, selectedRows) => {
      nodeActions = selectedRows;
      props.changeSelected();
    },
  };
  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    console.log(props);
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color="blue"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        closeIcon={
          <CloseCircleOutlined
            style={{
              background: "#0070CC",
              borderRadius: "50%",
              color: "white",
            }}
          />
        }
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };
  return (
    <>
      <div className="common-search-form">
        <Form
          form={form}
          name="advanced_search"
          layout="inline"
          className="ant-advanced-search-form"
          style={{ justifyContent: "center", marginBottom: 12 }}
          onFinish={onFinish}
        >
          <Form.Item
            name="searchNodeName"
            label="节点名称"
            style={{ marginTop: 0 }}
          >
            <Input placeholder="请输入关键字" />
          </Form.Item>

          <Form.Item label="标签" name="label" style={{ marginTop: 0 }}>
            <Select
              tagRender={tagRender}
              mode="multiple"
              allowClear
              style={{ width: 200 }}
              placeholder="请选择标签"
              showArrow
            >
              {props.labelList}
            </Select>
          </Form.Item>
          <Form.Item style={{ marginTop: 0 }}>
            <Button type="primary" htmlType="submit">
              {" "}
              查询{" "}
            </Button>
            <Button style={{ margin: "0 9.46px" }} onClick={resetForm}>
              重置{" "}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Table
        rowSelection={{
          ...rowSelection,
        }}
        rowKey={(record) => record.id}
        columns={nodeColumns}
        dataSource={nodeList}
        pagination={false}
        scroll={{ y: 240 }}
      />
    </>
  );
}

let searchFirmwareVersion = "";
let searchFirmwareOta = null;
let firmwaresList = [];
//let firmLoading = true;
let firmwareAction = null;
function FirmwareModal(props) {
  const [form] = Form.useForm();
  const firmwareColumns = [
    {
      title: "epack版本",
      dataIndex: "firmwareVersion",
      key: "firmwareVersion",
    },
    {
      title: '类型',
      dataIndex: 'isPrivate',
      key: 'isPrivate',
      render: (text, record) => {
          return text===0?'公共版':'专属版'
      }
    },
    {
      title: "描述",
      dataIndex: "description",
      key: "description",
    },
  ];
  const onFinish = (values) => {
    searchFirmwareVersion = values.searchName || "";
    searchFirmwareOta = values.searchOta;
    props.onClick();
  };
  const resetForm = () => {
    form.resetFields();
    searchFirmwareVersion = "";
    searchFirmwareOta = props.defaultSelectValue;
    form.setFieldsValue({ searchOta: props.defaultSelectValue });
    props.onClick();
  };
  const onValuesChange = (value) => {
    console.log(value);
    const keys = Object.keys(value);
    if (keys.find((elx) => elx === "searchOta")) {
      searchFirmwareVersion = form.getFieldValue("searchName") || "";
      searchFirmwareOta = value.searchOta || "";
      props.onClick();
    }
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // id: selectedRowKeys
      // obj : selectedRows
      firmwareAction = selectedRows;
    },
  };
  return (
    <>
      <div className="common-search-form">
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
          initialValues={{ searchOta: props.defaultSelectValue }}
          onValuesChange={onValuesChange}
        >
          <Col span={24} offset={2}>
            <Form.Item
              name="searchOta"
              label="所属产品"
              style={{ float: "left", marginRight: "7.14px" }}
            >
              <Select style={{ width: "150px" }} placeholder="请选择所属产品">
                {props.otaList}
              </Select>
            </Form.Item>
            <Form.Item
              name="searchName"
              label="epack版本"
              style={{ float: "left", marginRight: "7.14px" }}
            >
              <Input style={{ width: 150 }} placeholder="请输入关键字" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {" "}
                查询{" "}
              </Button>
              <Button style={{ margin: "0 9.46px" }} onClick={resetForm}>
                重置{" "}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
      <Table
        rowSelection={{
          type: "radio",
          ...rowSelection,
        }}
        rowKey={(record) => record.id}
        pagination={false}
        loading={props.firmLoading}
        scroll={{ y: 360 }}
        columns={firmwareColumns}
        dataSource={firmwaresList}
      />
    </>
  );
}

let searchUserDebName = "";
let searchUserDebOta = null;
let userDebList = [];
let userDebAction = null;
function UserDebModal(props) {
  const [form] = Form.useForm();
  const userDebColumns = [
    {
      title: "软件包名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "软件版本",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "描述",
      dataIndex: "description",
      key: "description",
    },
  ];
  const onFinish = (values) => {
    searchUserDebName = values.searchUserDebName || "";
    searchUserDebOta = values.searchUserDebOta;
    props.onClick();
  };
  const resetForm = () => {
    form.resetFields();
    searchUserDebName = "";
    searchUserDebOta = props.defaultSelectValue;
    form.setFieldsValue({ searchUserDebOta: props.defaultSelectValue });
    props.onClick();
  };
  const onValuesChange = (value) => {
    console.log(value);
    const keys = Object.keys(value);
    if (keys.find((elx) => elx === "searchUserDebOta")) {
      searchUserDebName = form.getFieldValue("searchUserDebName") || "";
      searchUserDebOta = value.searchUserDebOta || "";
      props.onClick();
    }
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // id: selectedRowKeys
      // obj : selectedRows
      userDebAction = selectedRows;
    },
  };
  return (
    <>
      <div className="common-search-form">
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
          initialValues={{ searchUserDebOta: props.defaultSelectValue }}
          onValuesChange={onValuesChange}
        >
          <Col span={24} offset={2}>
            <Form.Item
              name="searchUserDebOta"
              label="所属用户"
              style={{ float: "left", marginRight: "7.14px" }}
            >
              <Select style={{ width: "150px" }} placeholder="请选择所属产品">
                {props.userList}
              </Select>
            </Form.Item>
            <Form.Item
              name="searchUserDebName"
              label="软件包名称"
              style={{ float: "left", marginRight: "7.14px" }}
            >
              <Input style={{ width: 150 }} placeholder="请输入关键字" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {" "}
                查询{" "}
              </Button>
              <Button style={{ margin: "0 9.46px" }} onClick={resetForm}>
                重置{" "}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
      <Table
        rowSelection={{
          type: "radio",
          ...rowSelection,
        }}
        rowKey={(record) => record.id}
        pagination={false}
        loading={props.userDebLoading}
        scroll={{ y: 360 }}
        columns={userDebColumns}
        dataSource={userDebList}
      />
    </>
  );
}

class CreateJob extends React.Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      showManage: true,
      firmLoading: true,
      userDebLoading: true,
      labelList: [],
      chooseNodeList: [],
      selectedNodeIds: [],
      chooseFirmwareList: [],
      //chooseUserDebList: [], //弹窗选择的用户软件包
      chooseLabelList: [],
      isNodeVisible: false,
      isFirmwareVisible: false,
      upgradeType: 0,
      isUserFirmwareVisible:false,
      nodeList: [],
      firmwareList: [],
      otaList: [],
      userList: [], //所属用户（ota软件）
      OtadefaultSelectValue: null,
      UserDebdefaultSelectValue: null, //所属用户选择默认值（ota软件）
      selectFirmwareVersion: '',//展示选择的固件
    };
  }

  goback() {
    this.formRef.current.resetFields();
    this.setState({
      chooseFirmwareList: [],
      chooseLabelList: [],
      chooseNodeList: [],
      selectedNodeIds: [],
    });
    if (this.props.onSubmit) {
      var { showManage } = this.state;
      this.props.onSubmit({ showManage });
    }
    firmwareAction = null;
    userDebAction = null;
    this.props.history.go(-1);
  }

  //3.26.3.查询用户软件包列表（不带分页）
  getUserDebAll() {
    if (this.state.UserDebdefaultSelectValue) {
      this.setState({
        userDebLoading: true
      });
      userDebService.getUserDebAll(searchUserDebOta || this.state.UserDebdefaultSelectValue, searchUserDebName).then(
        res => {
          this.setState({
            userDebLoading: false,
          });
          if (res.code === 200) {
            console.log("当前用户软件包信息", res);
            userDebList = res?.debs;
            this.setState({
              isUserFirmwareVisible: true,
            });
          }
        },
        (error) => {
          this.setState({
            userDebLoading: false,
          });
          error && message.error(error);
        }
      );
    } else {
      message.info("没有可选择软件包");
    }
  }

  getFirmwareList() {
    if (this.state.OtadefaultSelectValue) {
      this.setState({
        firmLoading: true,
      });
      firmwareService
        .getAllFirmwareByOta(
          searchFirmwareOta || this.state.OtadefaultSelectValue,
          "",
          searchFirmwareVersion
        )
        .then(
          (res) => {
            this.setState({
              firmLoading: false,
            });
            if (res.code === 200) {
              console.log("epack信息", res.firmwares);
              firmwaresList = res.firmwares;
              this.setState({
                isFirmwareVisible: true,
              });
            }
            // else {
            //   message.error('获取epack列表失败，' + res.message);
            // }
          },
          (error) => {
            this.setState({
              firmLoading: false,
            });
            error && message.error(error);
          }
        );
    } else {
      message.info("没有可选择epack");
    }
  }
  getLabelList() {
    labelService.getAllLabels().then(
      (res) => {
        if (res.code === 200) {
          const labels = res.labels;
          this.setState({
            labelList: this.getLableItem(labels),
          });
        }
      },
      (error) => {
        error && message.error(error);
      }
    );
  }
  getNodeList() {
    if (this.state.chooseFirmwareList.length === 0) {
      message.warn("请先选择epack版本！");
      return;
    }
    
    console.log('upgradeType', this.state.upgradeType);
    if (this.state.upgradeType === 0) { 
      nodeService.getAllNodeList(searchNodeName, "", searchLabel.toString(), this.state.chooseFirmwareList[0].id)
        .then((res) => {
            if (res.code === 200) {
              nodeList = res.nodes;
              this.setState({
                isNodeVisible: true,
                selectedNodeIds: []
              });
            }
          },
          (error) => {
            error && message.error(error);
          }
      );
    }else if(this.state.upgradeType === 1) { 
      nodeService.getUserDebNodes(searchNodeName, "", searchLabel.toString(), this.state.chooseFirmwareList[0].id)
        .then((res) => {
            if (res.code === 200) {
              nodeList = res.nodes;
              this.setState({
                isNodeVisible: true,
                selectedNodeIds: []
              });
            }
          },
          (error) => {
            error && message.error(error);
          }
      );
    }
  }

  getLableItem(labels) {
    return labels.map((item, index) => {
      return <Option key={item.id}>{item.name}</Option>;
    });
  }
  handleChangeLabel(value) {
    nodeService.getAllNodeList("", "", value).then(
      (res) => {
        if (res.code === 200) {
          const nodeList = res.nodes;
          const selectedNodeIds = [];
          nodeList.forEach((ele) => {
            selectedNodeIds.push(ele.id);
          });
          this.setState(
            {
              chooseNodeList: nodeList,
              selectedNodeIds: selectedNodeIds,
            },
            function () {}
          );
        }
      },
      (error) => {
        error && message.error(error);
      }
    );
    this.setState(
      {
        chooseLabelList: [...value],
      },
      function () {}
    );
  }

  componentDidMount() {
    console.log(store.getState().currentUser);
    this.getLabelList();
    this.getOtaList(); // 选择所属产品
    if (store.getState().currentUser.type === "ADMIN" || store.getState().currentUser.type === "SUPERADMIN") {
      this.getUserDebOverview();
    } else {
      let arr = [{userId:store.getState().currentUser.id, username:store.getState().currentUser.username}]
      this.setState({
        userList: this.getUserItem(arr),
        UserDebdefaultSelectValue: arr[0]?.userId,
      })
    }
    
  }
  componentWillUnmount() {
    searchFirmwareOta = null;
    searchUserDebOta = null;
  }
  getOtaList() {
    //FIXME:
    otaService.getAllOtaList().then(
      (res) => {
        if (res.code === 200) {
          console.log("产品列表", res.products);
          const products = res.products;
          this.setState({
            otaList: this.getOtaItem(products),
            OtadefaultSelectValue: products[0]?.id,
          });
        }
      },
      (error) => {
        error && message.error(error);
      }
    );
  }
  getOtaItem(products) {
    return products.map((item, index) => {
      return (
        <Option key={item.id} value={item.id}>
          {item.productName}
        </Option>
      );
    });
  }

  //3.26.1.获取用户软件包概览（管理员）
  getUserDebOverview() {
    userDebService.getUserDebOverview().then(
      res => {
        if (res.code === 200) {
          console.log("软件用户列表", res);
          const debs = res.debs
          //const debs = [{userId:432,username:'testOta'},{userId:2,username:'b'}]
          this.setState({
            userList: this.getUserItem(debs),
            UserDebdefaultSelectValue: debs[0]?.userId,
          })
        }
      }
    )
  }
  getUserItem(debs) {
    return debs.map((item, index) => {
      return (
        <Option key={item.userId} value={item.userId}>
          {item.username}
        </Option>
      )
    })
  }

  showNodeModal() {
    this.getNodeList();
  }
  
  handleFirmwareModal() {
    if(this.state.upgradeType === 0){
      this.showFirmwareModal();
    }
    else {
      this.showUserDebModal();
    }
  }

  showFirmwareModal() {
    this.getFirmwareList();
  }
  showUserDebModal() {
    this.getUserDebAll();
  }

  handleCancel = () => {
    this.setState({ isNodeVisible: false, isFirmwareVisible: false, isUserFirmwareVisible: false });
  };
  changeSelected() {
    const selectedNodeIds = [];
    nodeActions.forEach((ele) => {
      selectedNodeIds.push(ele.id);
    });
    this.setState({
      selectedNodeIds: selectedNodeIds,
    });
  }

  handleNodeOk = () => {
    this.setState({
      chooseNodeList: nodeActions,
    });
    this.handleCancel();
  };
  removeNodeFromList(id) {
    const list = [...this.state.chooseNodeList];
    const arr = [];
    list.forEach((ele) => {
      if (ele.id !== id) {
        arr.push(ele);
      }
    });
    this.setState({
      chooseNodeList: arr,
    });
  }

  handleFirmwareOk = () => {
    if (firmwareAction) {
      console.log("firmwareAction", firmwareAction);
      this.setState({
        chooseFirmwareList: firmwareAction,
        selectFirmwareVersion: firmwareAction[0].firmwareVersion,
      });
      // this.formRef.current.setFieldsValue({
      //   selectFirmwareVersion: firmwareAction[0].firmwareVersion,
      // });
      this.handleCancel();
    }
  };

  handleUserDebOk = () => {
    if (userDebAction) {
      console.log("userDebAction", userDebAction);
      this.setState({
        chooseFirmwareList: userDebAction,
        selectFirmwareVersion: userDebAction[0].version
      });
      this.handleCancel();
    }
  }

  render() {
    const handleVersionSelect=(values)=>{
      console.log(values);
         this.setState({ 
          upgradeType: values.value,
           selectFirmwareVersion: '',
          })
    }
    const onFinishFailed = (values) => {
      console.log(values);
    };
    const onFinish = (values) => {
      let type = 0;
      if (radioValue === 1) {
        // 立即
        type = 0;
      } else if (radioValue === 2) {
        type = 1;
      }
      if (this.state.chooseFirmwareList.length < 1) {
        message.warning("请选择epack版本");
        return;
      }
      if (radioValue === 2 && radioDateValue === "") {
        message.warning("请选择执行时间");
        return;
      }
      if (this.state.chooseNodeList.length < 1) {
        message.warning("请选择升级节点");
        return;
      }
      if (radioValue === 2 && radioDateValue !== "") {
        if (new Date(radioDateValue).getTime() < new Date().getTime()) {
          message.warning("执行时间不能早于当前时间");
          return;
        }
      }
      const firmwareId = this.state.chooseFirmwareList[0].id; // epack
      const nodeUuids = [];
      this.state.chooseNodeList.forEach((ele) => {
        nodeUuids.push(ele.nodeUuid);
      });
      // console.log('values.name',values.name);
      // console.log('values.description',values.description);
      // console.log('upgradeType',this.state.upgradeType);
      // console.log('type',type);
      // console.log('radioDateValue',radioDateValue);
      // console.log('nodeUuids',nodeUuids);
      // console.log('firmwareId',firmwareId);
      jobService
        .addJob(
          values.name,
          values.description,
          this.state.upgradeType,
          type,
          radioDateValue,
          nodeUuids,
          firmwareId
        )
        .then(
          (res) => {
            if (res.code === 200) {
              message.success("创建任务成功");
              this.goback();
            }
            // else {
            //   message.success('创建任务失败，' + res.message);
            // }
          },
          (error) => {
            message.error(error);
          }
        );
    };

    const nodeListColumns = [
      {
        title: "节点名称",
        dataIndex: "name",
        key: "name",
        render: (text) => <span>{text}</span>,
      },
      {
        title: "epack版本",
        dataIndex: "firmwareVersion",
        key: "firmwareVersion",
      },
      {
        title: "产品型号",
        dataIndex: "productModel",
        key: "productModel",
      },
      {
        title: "描述",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "操作",
        key: "action",
        render: (text, record) => (
          <a href onClick={() => this.removeNodeFromList(record.id)}>
            移除
          </a>
        ),
      },
    ];

    return (
      <>
        <div className="common-pd20">
          <BackPage
            onClick={() => this.goback()}
            headeTitle="创建任务"
            itemTitle="批量升级"
            itemSubTitle="创建任务"
          />
          <Form
            ref={this.formRef}
            name="validate_other"
            labelAlign="left"
            {...formItemLayout}
            initialValues={{firmwareVersion: 0}}
            onFinishFailed={onFinishFailed}
            onFinish={onFinish}
          >
            <div className="common-create-page">
              <div className="job-box">
                <Form.Item
                  name="name"
                  label="任务名称"
                  rules={[
                    { required: true, message: "请输入任务名称" },
                    { max: 64, message: "任务名称不允许超过64个字符" },
                    {
                      pattern: new RegExp(
                        /^[0-9a-zA-Z-_\u4e00-\u9fa5]{1,}$/,
                        "g"
                      ),
                      message: "请输入64位以内中英文/数字/中划线/下划线",
                    },
                  ]}
                >
                  <Input placeholder="请输入任务名称" style={{ width: 300 }} />
                </Form.Item>
                <Form.Item name="description" label="描述">
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    placeholder="请输入描述信息"
                  />
                </Form.Item>
                <Form.Item
                  label="版本选择"
                  name="firmwareVersion"
                  rules={[{ required: true, message: "请选择epack或用户软件包版本" }]}
                >
                  {/* {this.state.chooseFirmwareList[0]?.firmwareVersion ? (
                    <span style={{ marginRight: 10 }}>
                      {this.state.chooseFirmwareList[0]?.firmwareVersion}
                    </span>
                  ) : (
                    ""
                  )} */}
                  <Select
                    labelInValue
                    defaultValue={{ value: 0 }}
                    style={{ width: 120 }}
                    onChange={handleVersionSelect}
                  >
                    <Option value={0}>Epack</Option>
                    <Option value={1}>用户软件包</Option>
                  </Select>
                  <Button size="small" style={{marginLeft:'10px'}} onClick={() => this.handleFirmwareModal()}>
                    选择固件
                  </Button>
                  {this.state.selectFirmwareVersion ? <span style={{marginLeft:'10px'}}>{this.state.selectFirmwareVersion}</span>:''}
                  
                  {/* <Button onClick={() => this.showFirmwareModal()}>
                    选择epack
                  </Button> */}
                </Form.Item>
                <BottomBtnGroup onClick={() => this.goback()} />
              </div>

              <div className="job-bottom-card">
                <Row className="job-bottom-card-title">
                  {" "}
                  <span className="common-star-red">*</span>升级对象{" "}
                </Row>
                <div className="job-bottom-content">
                  <div style={{ marginBottom: "17px", overflow: "hidden" }}>
                    <Button
                      style={{
                        border: "1px solid #0070CC",
                        color: "#0070CC",
                        float: "left",
                      }}
                      onClick={() => this.showNodeModal()}
                    >
                      选择节点
                    </Button>
                    {/* <Form.Item label='标签' style={{ marginRight: '7.14px', float: 'right' }}>
                      <Select mode="multiple" allowClear style={{ width: '300px' }} defaultValue={this.state.chooseLabelList} placeholder="请选择标签" onChange={(value) => this.handleChangeLabel(value)} >
                        {this.state.labelList}
                      </Select>
                    </Form.Item> */}
                  </div>
                  <Table
                    columns={nodeListColumns}
                    dataSource={this.state.chooseNodeList}
                    pagination={false}
                    scroll={{ y: 240 }}
                  />
                </div>
              </div>

              <div className="job-box" style={{ marginBottom: "100px" }}>
                <div className="job-box-star">
                  {/* <Form.Item label="epack版本" >
                    <span style={{ marginRight: 10 }}>{this.state.chooseFirmwareList[0]?.firmwareVersion}</span>
                    <Button onClick={() => this.showFirmwareModal()}>选择epack</Button>
                  </Form.Item> */}
                  <Form.Item label="执行方式">
                    <RadioCheck />
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>

        <Modal
          width={800}
          title="选择升级节点"
          visible={this.state.isNodeVisible}
          onOk={() => this.handleNodeOk()}
          onCancel={() => this.handleCancel()}
          okText="确定"
          cancelText="取消"
        >
          <NodeModal
            changeSelected={() => this.changeSelected()}
            labelList={this.state.labelList}
            selectedNodeIds={this.state.selectedNodeIds}
            chooseNodeList={this.state.chooseNodeList}
            onClick={() => this.getNodeList()}
          />
        </Modal>

        <Modal
          title="选择升级epack"
          visible={this.state.isFirmwareVisible}
          width={800}
          onOk={() => this.handleFirmwareOk()}
          onCancel={() => this.handleCancel()}
          okText="确定"
          cancelText="取消"
        >
          <FirmwareModal
            otaList={this.state.otaList}
            defaultSelectValue={this.state.OtadefaultSelectValue}
            firmLoading={this.state.firmLoading}
            onClick={() => this.getFirmwareList()}
          />
        </Modal>
        <Modal
          title="选择升级用户软件包"
          visible={this.state.isUserFirmwareVisible}
          width={800}
          onOk={() => this.handleUserDebOk()}
          onCancel={() => this.handleCancel()}
          okText="确定"
          cancelText="取消"
        >
          <UserDebModal
            userList={this.state.userList}
            defaultSelectValue={this.state.UserDebdefaultSelectValue}
            userDebLoading={this.state.userDebLoading}
            onClick={() => this.getUserDebAll()}
          />
        </Modal>
      </>
    );
  }
}

export default CreateJob;
