/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Form, Input, Select, message, Badge, Row, Col, InputNumber,     Card , Divider  } from 'antd';
import '../../../../../index.scss';

import BackPage from '../../../common/backPage';
import BottomBtnGroup from '../../../common/bottomBtnGroup';

import { terminalService } from '../../../../services/terminal-equ';
import {connect} from 'react-redux'
const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

class ViewTerminalEquManagement extends React.Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      id: 0,
      deviceId: '',
      deviceName: '',
      state: '',
      serialNo: "",
      deviceUrl: '',
      nodeName: '',
      deviceType: -1,
      createTime: '',
      updateTime: '',
      upTime: '',
      description: '',
    }
  }
  goback() {
   
    this.props.history.go(-1);
  }
  onSelectTypeChange(value) {
    console.log(value)
  }
  componentDidMount() {
    if (this.props.location.state) {
      const id = this.props.location.state.id;
      console.log("查看设备详情的id为：", id);
      this.getDeviceDetail(id)
      sessionStorage.setItem('deviceId', id)
    } else {
      if(sessionStorage.getItem('deviceId')) {
        const id = sessionStorage.getItem('deviceId');
        this.setState({
            id,
        });
        this.getDeviceDetail(id)
      } else {
        this.goback();
      }
    }
    
  }

  //查询设备详情接口
  getDeviceDetail(id) {
    terminalService.getDeviceDetail(id).then(res => {
      if (res.code === 200) {
        console.log(res);
        const device = res.device
        this.setState({
          deviceId: device.deviceId,
          deviceName: device.deviceName,
          state: device.deviceStatus,
          serialNo: device.serialNo,
          deviceUrl: device.rtspUrl,
          nodeName: device.nodeName,
          deviceType: device.deviceType,
          createTime: device.createTime,
          updateTime: device.updateTime,
          upTime: device.upTime,
          description: device.description
        })
      }
      }).catch(error => {
        error && message.error(error);
      })
  }

  getDeviceState = (state) => {
    if (state === 'RUNNING') {
      return <span> <Badge status="success" />运行中</span>
    } else if (state === 'FAILED') {
      return <span> <Badge status="error" />异常</span>
    } else if (state === 'DISABLED') {
      return <span><Badge status="error" />禁用</span>
    } else if (state === 'WATING') {
      return <span><Badge status="processing" />操作中</span>
    } else if (state === 'STOPPED') {
      return <span><Badge status="default" />关闭</span>
    } 
  }

  getDeviceType = (deviceType) => {
    if (deviceType === 0) {
      return <span>视频摄像头</span>
    } else if (deviceType === 1) {
      return <span>音频</span>
    } else if (deviceType === 2) {
      return <span>屏幕</span>
    } else if (deviceType === 3) {
      return <span>麦克风</span>
    } else if (deviceType === 4) {
      return <span>其他</span>
    }
  }
  
  render() {
    const onFinish = (values) => {
        message.success('创建应用成功');
        this.goback();
    };
    return (
      <>
        <div className="common-pd20">
        <BackPage onClick={() => this.goback()} headeTitle="查看设备" itemTitle="应用管理" itemSubTitle="创建用户" />
        <div className="common-create-center-container">
           
          <div className="common-create-page">
          
       
            <Form
              ref={this.formRef}
              name="validate_other"
              labelAlign="left"
              {...formItemLayout}
             >
                <Form.Item name="deviceId" label="设备ID" >
                   {this.state.deviceId || '--'}
                </Form.Item>

                <Form.Item name="deviceName" label="设备名称" >
                   {this.state.deviceName || '--'}
                </Form.Item>

                <Form.Item name="state" label="状态" >
                    {this.getDeviceState(this.state.state)}
                </Form.Item>

                <Form.Item name="deviceUrl" label="设备访问地址">  
                    {this.state.deviceUrl || '--'}
                </Form.Item>

                <Form.Item name="nodeName" label="所属节点" >
                     {this.state.nodeName}
                </Form.Item>

                <Form.Item name="deviceType" label="设备类型" >
                     {this.getDeviceType(this.state.deviceType)}
                </Form.Item>

                <Form.Item name="createTime" label="创建时间">
                     {this.state.createTime || '--'}
                </Form.Item>

                <Form.Item name="updateTime" label="更新时间" >
                     {this.state.updateTime || '--'}
                </Form.Item>
                
                <Form.Item name="upTime" label="检查时间" >
                     {this.state.upTime || '--'}
                </Form.Item>
            </Form>
        
       
          </div>
        </div>
        </div>
      </>
    );
  }
}

//export default CreateUser;


export default connect(
    (state) => ({
        currentUser: state.currentUser
    })
)(ViewTerminalEquManagement);