export const CHANGE_INPUT_VALUE = 'change_input_value';
export const CHANGE_AUTH_TOKEN = 'change_auth_token';
export const ADD_LIST_ITEM = 'add_list_item';
export const DELETE_LIST_ITEM = 'delete_list_item';
export const INIT_LIST_ACTION = 'init_list_action';
export const GET_INIT_LIST = 'get_init_list';
export const CHANGE_CURRENT_USER = 'change_current_user';
export const CHANGE_OTA_ID = 'change_ota_id';
export const CHANGE_OTA_FIRMWARE_ID = 'change_ota_firmware_id';
export const CHANGE_JOB_ID = 'change_job_id';
export const CHANGE_NODE_ID = 'change_node_id';
export const CHANGE_MODEL_ID = 'change_model_id';
