import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import 'antd/dist/antd.css';
import FrontendAuth from './frontend-auth';
import routerMap from './routerMap';
import { Provider } from 'react-redux'
import store from './store/index';
import { PersistGate } from 'redux-persist/integration/react';
import {persistor} from './store/index';
// import persistStore from 'redux-persist/es/persistStore';
// import { persistor } from './redux/store'
// import { PersistGate } from 'redux-persist/lib/integration/react'


ReactDOM.render(
  // <Provider store={store}>
  //   <PersistGate loading={null} persistor={persistor}>
  //     <Router>
  //       <Switch>
  //         <FrontendAuth routerConfig={routerMap} />
  //         {/* <Route path='/register' component={Rigester}></Route>
  //             <Route path='/login' component={Login}></Route>
  //             <Route component={Main}></Route> */}
  //       </Switch>
  //     </Router>
  //   </PersistGate>
  // </Provider>,
  <Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
    {/* <PersistGate persistor={persistStore(store)}> */}
      <Router>
        <div>
          <Switch>
            <FrontendAuth routerConfig={routerMap} />
          </Switch>
        </div>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);