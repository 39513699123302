import React from 'react';
import './../../../../index.scss';
import { Form,Input, Button, Table, Space, Pagination, ConfigProvider, Modal, Select, message } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import { AUTCONFIG } from "../../../../utils/auth.config";
import store from '../../../../store';
import { BrowserRouter as Router } from "react-router-dom";
import { userDebService } from "../../../services/user-deb.service";
import { searchRule } from "../../../../utils/search-util";
const { TextArea } = Input;

//列表查询
let userName = "";
let pageSize = 10;
let page = 1;
function Search(props) {
  const form = React.createRef();
  const onFinish = (values) => {
    let name = "";
    if (values.userName != null) {
      name = searchRule(values.userName);
    }
    userName = name || "";
    props.onClick();
  };
  const resetForm = () => {
    form.current.resetFields();
    userName = "";
    pageSize = 10;
    page = 1;
    props.onClick();
  };

  return (
    <div>
      <div className="common-search-form">
        <Form
          ref={form}
          name="advanced_search_user"
          className="ant-advanced-search-form"
          onFinish={onFinish}
          layout="inline"
          // style={{ justifyContent: 'center' }}
        >
          <Form.Item
            name="userName"
            label="用户名"
            style={{ marginTop: "8px" }}
          >
            <Input placeholder="请输入用户名" />
          </Form.Item>

          <Form.Item style={{ marginTop: "8px" }}>
            <Button type="primary" htmlType="submit">
              查询
            </Button>
            <Button style={{ margin: "0 9.46px" }} onClick={resetForm}>
              重置
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

class UserDeb extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      userInfoList: [],
      actionIds: [],
      loading: false,
      page: 1,
      pageSize: 10,
      imagesAmount: 0,
      isAddFirmwareModalVisible: false,
    };
  }
  componentDidMount() {
    if (
      store.getState().currentUser.type === AUTCONFIG.MAIN.value||
      store.getState().currentUser.type === AUTCONFIG.SUB.value
    ) {
      this.props.history.push({ pathname: "/home/userDeb/view" });
    } else if (
      store.getState().currentUser.type === AUTCONFIG.ADMIN.value ||
      store.getState().currentUser.type === AUTCONFIG.SUPERADMIN.value
    ) {
      page = 1;
      pageSize = 10;
      this.searchList();
    }
  }
  searchList() {
    this.setState({
      loading: true,
      page,
      pageSize,
    });
    userDebService
      .getUserDebOverview(userName, pageSize, page)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.code === 200) {
          console.log("获取用户软件包概览（管理员）:", res);
          this.setState({
            userInfoList: res.debs,
            imagesAmount: res.dataCount,
          });
        }
        // else {
        //   message.error(res.message);
        // }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        message.error(error);
      });
  }
    componentWillUnmount() {
      sessionStorage.removeItem('userDebId');
      sessionStorage.removeItem('username');
      sessionStorage.removeItem('type');
    }
    showAddFirmware() {
      this.setState({ isAddFirmwareModalVisible: true });
    }
    resetForm = () => {
      this.formRef.current.resetFields();
      // addFirName = '';
      // addFirModel = '';
      // addFirDescription = '';
      // this.props.handleCancel();
      this.setState({
        isAddFirmwareModalVisible: false,
      });
    };
    onFinish = (values) => {
      console.log("新增epack信息", values);
      let addFirName = values.firName;
      let addFirModel = values.firModel;
      let addFirDescription = values.firDescription;
      let addURL = values.firUrl;
      /* if (!addFirName||!addFirModel||!addURL) {
        message.warn('必填项不能为空');
        return
    } */
      userDebService
        .addUserDeb(addFirName, addFirModel, addFirDescription, addURL)
        .then(
          (res) => {
            if (res.code === 200) {
              message.success("创建成功");
              this.formRef.current.resetFields();
              this.setState({
                isAddFirmwareModalVisible: false,
              });
            }
            this.searchList();
            // else {
            //     message.success("创建失败，" + res.message);
            // }
          },
          (error) => {
            error && message.error(error);
          }
        );
    };
  render() {
    const formAddFirItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
    };
    const onChange = (pageNumber) => {
      console.log("pageNumber", pageNumber);
      page = pageNumber;
      this.searchList();
    };
    const onShowSizeChange = (current, pageSizeNumber) => {
      console.log("pageSizeNumber", pageSizeNumber);
      pageSize = pageSizeNumber;
      page = 1;
      this.searchList();
    };
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          actionIds: selectedRowKeys,
        });
      },
    };

    const toView = (record) => {
      console.log("history传递的数据", record);
      this.props.history.push({
        pathname: "/home/userDeb/view",
        state: { record },
      });
    };
   

    const columns = [
      {
        title: "用户名",
        dataIndex: "username",
        render: (text, record) => <a onClick={() => toView(record)}>{text}</a>,
      },
      {
        title: "软件包数目",
        dataIndex: "debNum",
      },
      {
        title: "发布数目",
        dataIndex: "releaseNum",
      },
      {
        title: "未发布数目",
        dataIndex: "unreleaseNum",
      },
      {
        title: "更新时间",
        dataIndex: "updateTime",
      },
      // {
      //   title: '操作',
      //   dataIndex: 'action',
      //   render: (text, record) => (
      //     <Space size="middle">
      //       <a onClick={() =>this.showConfirm(record)}>删除</a>
      //       <a onClick={() => toEdit(record)}>编辑</a>
      //     </Space>
      //   )

      // },
    ];

    return (
      <div className="common-pd20">
        <div>
          <h1 className="common-top-title0">用户软件包</h1>
          <div className="common-center-container">
            <Search onClick={() => this.searchList()} />
            <Button
              type="primary"
              onClick={() => this.showAddFirmware()}
              style={{ width: "90px" }}
            >
              新增软件包
            </Button>
            <div className="user-table-card">
              <Table
                // rowSelection={{
                //   ...rowSelection,
                // }}
                rowKey={(record) => record.username}
                bordered={false}
                pagination={false}
                columns={columns}
                dataSource={this.state.userInfoList}
                loading={this.state.loading}
              />
            </div>
            <div className="common-bottom-pagination">
              <ConfigProvider locale={zhCN}>
                <Pagination
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={1}
                  current={this.state.page}
                  pageSize={this.state.pageSize}
                  total={this.state.imagesAmount}
                  onChange={onChange}
                />
              </ConfigProvider>
            </div>
          </div>
          <Modal
              title="新增软件包"
              visible={this.state.isAddFirmwareModalVisible}
              onCancel={this.resetForm}
              footer={null}
            >
              <Form
                // style={{ marginBottom: 10, marginLeft: 22 }}
                ref={this.formRef}
                name="validate_other"
                // labelAlign="left"
                {...formAddFirItemLayout}
                onFinish={this.onFinish}
              >
                <Form.Item
                  name="firName"
                  label="名称"
                  rules={[
                    { required: true, message: "请输入软件包名称" },
                    { max: 64, message: "epack名称不允许超过64个字符" },
                    {
                      pattern: new RegExp(
                        /^[0-9a-zA-Z-_\u4e00-\u9fa5]{1,}$/,
                        "g"
                      ),
                      message: "请输入64位以内中英文/数字/中划线/下划线",
                    },
                  ]}
                >
                  <Input name="firName" placeholder="请输入软件包名称" />
                </Form.Item>
                <Form.Item
                  name="firModel"
                  label="版本"
                  rules={[
                    { required: true, message: "请输入软件包版本" },
                    {
                      pattern: /^((\d+)\.){1,}(\d+)$/,
                      message: "软件包版本格式不对,例如123.123.123",
                    },
                  ]}
                >
                  <Input name="firModel" placeholder="请输入软件包版本" />
                </Form.Item>
                <Form.Item name="firUrl" label="链接">
                  <Input name="firName" placeholder="请输入软件包链接" />
                </Form.Item>
                <Form.Item name="firDescription" label="描述">
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    placeholder="请输入描述"
                    showCount={true}
                    maxLength={255}
                  />
                </Form.Item>
                <div style={{ textAlign: "center" }}>
                  <Button type="primary" htmlType="submit">
                    新增
                  </Button>
                  <Button
                    style={{ marginLeft: "12px" }}
                    onClick={this.resetForm}
                  >
                    取消
                  </Button>
                </div>
              </Form>
            </Modal>
        </div>
      </div>
    );
  }
}
export default UserDeb;