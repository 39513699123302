import { Form, Input, Col, Row, message } from 'antd';
import React from 'react';
import './edit-label.scss';
import BackPage from '../../../../common/backPage';
import { labelService } from '../../../../../services/label.service';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';


const { TextArea } = Input;


const formItemLayout = {
  labelCol: { xs: 24, sm: 12, md: 8, lg: 6, xl: 4},
  wrapperCol: { xs: 24, sm: 12, md: 15},
};


class EditLabel extends React.Component {
  formRef = React.createRef();

  constructor() {
    super();
    this.state = {
      showManage: true,
      canEdit: [false, false, false],

      labelId: null,
      labelObj: null,


      labelCreateTime: '',
      labelUpdateTime: '',
      labelCode: '',
      labelName: '编辑标签',

    };
  }

  goback() {
    this.props.history.push('/home/sys/label-manage');
  }

  cancel = () => {
    this.formRef.current.setFieldsValue({
      name: this.state.labelObj.name,
      code: this.state.labelObj.code,
      description: this.state.labelObj.description,
    });
  }

  componentDidMount() {
   
    if (this.props.location.state) {
      const labelId = this.props.location.state.id;
      this.setState({
        labelId: labelId
      });
      this.getLabelDetail(labelId);
      sessionStorage.setItem('labelId', labelId);
    } else {
      if (sessionStorage.getItem('labelId')) {
        const labelId = sessionStorage.getItem('labelId');
        this.setState({
          labelId: labelId
        });
        this.getLabelDetail(labelId);
      } else {
        this.goback();
      }
    }
  }
  getLabelDetail(labelId) {
    labelService.getLabelDetail(labelId).then(res => {
      if (res.code === 200) {
        const label = res.label;
        this.setState({
          labelObj: label,
          labelCreateTime: label.createTime,
          labelUpdateTime: label.updateTime,
          labelCode: label.code || '',
          labelName: '编辑标签 -- ' + label.name
        });
        this.formRef.current.setFieldsValue({
          name: label.name || '',
       
          description: label.description || '',
        });
      } 
      // else {
      //   message.error('获取标签详情失败');
      // }
    }, error => {
      error && message.error(error);
    });
  }
  render() {
    const onFinish = (values) => {
        message.loading('信息保存中~~', 2500);
      labelService.editLabel(this.state.labelId, values.name, values.code, values.description).then(res => {
        message.destroy();
        if (res.code === 200) {
          message.success('修改标签成功');
         // this.getLabelDetail(this.state.labelId);
         this.props.history.push('/home/sys/label-manage')
        } 
        // else {
        //   message.error('修改标签失败，' + res.message);
        // }
      }, error => {
        message.destroy();
        error && message.error(error);
      });
    };
    return (
      <>
        <div className="common-detail-container">
          <BackPage onClick={() => this.goback()} headeTitle={this.state.labelName} itemTitle="标签管理" itemSubTitle="查看标签" />
          <div className="common-edit-card">
            <Form name="basic"
              onFinish={onFinish}
              labelAlign="left"
              ref={this.formRef}
              {...formItemLayout} >
              {/* <Row>
                <Col span={12}>
                  <Form.Item name="code" label="标签编码" rules={[{ required: true, message: '标签编码不能为空' }]} >
                    <Input placeholder="请输入标签编码" />
                  </Form.Item>
                </Col>
              </Row> */}
              
              <Row>
                <Col span={12}>
                  <Form.Item name="name" label="标签名称" rules={[
                    { required: true, message: '请输入标签名称' },
                    {max: 64, message: '标签名称不允许超过64个字符'}, 
                    { pattern: new RegExp(/^[0-9a-zA-Z-_\u4e00-\u9fa5]{1,}$/, "g") , message: '请输入64位以内中英文/数字/中划线/下划线'}]}>
                    <Input placeholder="请输入标签名称" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item name="description" label="描述" >
                    <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" />
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row>
                <Col span={12}>
                  <Form.Item label="标签编码" >
                    {this.state.labelCode}
                  </Form.Item>
                </Col>
              </Row> */}
              <Row>
                <Col span={12}>
                  <Form.Item label="创建时间" >
                    {this.state.labelCreateTime || '--'}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item label="更新时间"  >
                    {this.state.labelUpdateTime || '--'}
                  </Form.Item>
                </Col>
              </Row>
              <BottomBtnGroup onClick={() => this.goback()} />
            </Form>
          </div>
        </div>
      </>
    );
  }
}

export default EditLabel;