import React from 'react';
import { Form, Input, message, Space, Select, Button, Modal, Divider } from 'antd';
import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';
import { algorithmService } from '../../../../../services/algorithm-manage';
import { MinusCircleOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

const { Option } = Select;
const { confirm } = Modal;
const { TextArea } = Input;
const formItemLayout1 = {
    labelCol: { span: 3 },
    wrapperCol: { span: 13 },
};

class EditAlgorithmGroup extends React.Component{
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            id: -1,
            title: '编辑算法组',
            algorithmName: '',
            algorithmVersion: '',
            provinceData: [], //形如provinceData = ['Zhejiang', 'Jiangsu'];//算法名称
            //provinceData : ['Zhejiang', 'Jiangsu'],
            cityData: {},     //形如const cityData = {Zhejiang: ['Hangzhou', 'Ningbo', 'Wenzhou'],Jiangsu: ['Nanjing', 'Suzhou', 'Zhenjiang'],};
            //cityData: {Zhejiang: ['Hangzhou', 'Ningbo', 'Wenzhou'],Jiangsu: ['Nanjing', 'Suzhou', 'Zhenjiang'],},
            isAddVisible: false,
            status: '',
            nodeName: this.props?.location.state?.name,
            nodeId: this.props?.location.state?.id,
            licencesList: [],
            initCities: [], //第一个算法版本下拉选择项
            initSecondCity: '',  //第一个算法版本选择的值
            citiesInfo: {},  //动态算法版本下拉选择项
            secondCityInfo: {},  //动态算法版本选择的值
            selectLicences: {},//算法授权选择
            users: [],
            dynamicKey: [], //动态算法名称的初始值
            selectDynamicValue:{},//暂存初始化动态下拉框的值
        };
    }

    goback() {
        this.props.history.push('/home/algorithm-manage');
    }

    componentDidMount() {
        this.getTotalAlgorithms();
        if (this.props.location.state) {
            const id = this.props.location.state.id;      
            console.log("修改算法组详情的id为：", id);
            this.getAlgorithmGroupDetail(id)
            sessionStorage.setItem('editAlgorithmGroupId', id)
            this.setState({    
                id: id,
            })
          } else {
            if(sessionStorage.getItem('editAlgorithmGroupId')) {
              const id = sessionStorage.getItem('editAlgorithmGroupId');   
              this.setState({
                  id,
              });
              this.getAlgorithmGroupDetail(id)
            } else {
              this.goback();
            }
          } 
    }

    //查询算法组详情接口
    getAlgorithmGroupDetail(id) {
        algorithmService.getAlgorithmGroupDetail(id).then(res => {
            if (res.code === 200) {
                console.log(res);
                const group = res.group

                let algorithmName = group?.algorithms[0]?.algorithmName
                let algorithmVersion = group?.algorithms[0]?.algorithmVersion
                let tempArr = group?.algorithms.slice(1)

                //给selectLicences设置初始值
                const selectLicences = this.state.selectLicences;//selectLicences={}
                const dataArr = [algorithmName, algorithmVersion]
                selectLicences.init = dataArr;
                
                
                let tempDynamicKey = [] //动态算法名称的初始值
                let tempDynamicValue = [] //动态算法版本的初始值
                let initDynamicValue = {} //获取原来动态版本的原始值
                let selectDynamicValue = {}
                tempArr.forEach(item => {
                    tempDynamicKey.push(item.algorithmName)
                    tempDynamicValue.push(item.algorithmVersion)
                })
                for (let i = 0; i < tempDynamicValue.length; i++){
                    initDynamicValue[i] = tempDynamicValue[i]
                }

                console.log(this.state.cityData);
                for (let i = 0; i < tempDynamicKey.length; i++){
                    selectDynamicValue[i] = this.state.cityData[i]
                }

                //给selectLicences设置初始值
                for (let i = 0; i < tempDynamicKey.length; i++){
                    selectLicences[i] = [tempDynamicKey[i], tempDynamicValue[i]]
                }


                this.setState({
                    algorithmName,
                    initSecondCity: algorithmVersion,
                    dynamicKey: tempDynamicKey,
                    secondCityInfo: initDynamicValue,
                    selectLicences: selectLicences,
                })
                this.formRef.current.setFieldsValue({
                    algorithmGroupName: group.algorithmGroupName,
                    description: group.description,
                    algorithmName,
                    initSecondCity:algorithmVersion,
                    users: tempArr,
                });
            }
        }).catch(error => {
            error && message.error(error);
          })
    }

    getValue(key) {
        
        return this.state.dynamicKey[key]
    }
    
    //给第一个联动的算法名称初始化值
    getValueFirst = () => {
        if (this.props.location.state) {
            const algorithmName = this.props.location.state.algorithmName;
            sessionStorage.setItem('editAlgorithmGroupName', algorithmName)
            console.log(algorithmName);

            return algorithmName
          } else {
            if(sessionStorage.getItem('editAlgorithmGroupName')) {
                const algorithmName = sessionStorage.getItem('editAlgorithmGroupName');
                console.log(algorithmName);

                return algorithmName
            } 
          } 
    }


    //查询算法列表
    getalgorithmsList(id, name, index) {
        algorithmService.getAlgorithm(id, '', '', 100, 1).then(res => {
            if (res.code === 200) {
                const data = res.algorithms;
                console.log('算法列表查询', res);

                let provinceData = this.state.provinceData 
                if (data.length === 0) {
                    provinceData[index].has = true
                    this.setState({
                        provinceData: provinceData
                    })
                }

                this.handleListData(data, name);

                let selectDynamicValue = {}
                for (let i = 0; i < this.state.dynamicKey.length; i++){
                    selectDynamicValue[i] = this.state.cityData[this.state.dynamicKey[i]]
                    console.log(selectDynamicValue);
                }
                console.log(selectDynamicValue);
                this.setState({
                    citiesInfo: selectDynamicValue,
                });
            }
        }, error => {
            error && message.error(error);
        })
    }
    handleListData(data, name) {
        const arr = [];
        data.map(ele => {
            let obj = {}
            obj.algorithmVersion = ele?.algorithmVersion
            obj.id = ele?.id
            arr.push(obj)
        })
        this.state.cityData[name] = arr;

        console.log(this.state.cityData[this.state.algorithmName]);
        this.setState({
            initCities: this.state.cityData[this.state.algorithmName]
        })
    }

    //查询算法概览列表
    getTotalAlgorithms() {
        algorithmService.getAlgorithmOverview('', 100, 1).then(res => {
            if (res.code === 200) {
                const data = res.namespaces;
                this.handleOverviewData(data);
                console.log('算法概览查询', res);
            }
        }, error => {
            error && message.error(error);
        })
    }
    handleOverviewData(data) {
        let tempData = []
        data.map((ele, index) => {
            let obj = {}
            obj.name = ele.name
            obj.id = ele.id
            obj.has = false
            tempData.push(obj);
            this.getalgorithmsList(ele.id, ele.name, index);
        })
        this.setState({
            provinceData:tempData
        })
        console.log(this.state.provinceData);
    }

    // 删除算法处理
    handleRemove(key) {
        const selectLicences = this.state.selectLicences;
        delete selectLicences[key];
  
        this.setState({
            selectLicences,
        })
    }

    // 添加算法处理
    handleAdd() {
        const citiesInfo = this.state.citiesInfo;
        const secondCityInfo = this.state.secondCityInfo;
        const len_1 = (Object.keys(citiesInfo)).length;
        citiesInfo[len_1] = [];
        secondCityInfo[len_1] = '';
        this.setState({
            citiesInfo,
            secondCityInfo
        })
    }

    // 固定第一条 选择框联动
    handleChange_1 = value => {
        console.log(value);
        const dataArr = [value, this.state.cityData[value][0]?.algorithmVersion];
        const selectLicences = this.state.selectLicences;//selectLicences={}
        selectLicences.init = dataArr;
        this.setState({
            initCities: this.state.cityData[value],
            initSecondCity: this.state.cityData[value][0]?.algorithmVersion,
            selectLicences
        });
    }
    handleChange_2 = value => {
        const selectLicences = this.state.selectLicences;//selectLicences={}
        const algorithmName = this.state.algorithmName
        //用于初始化时对第一条算法名称的联动
        if ((Object.keys(selectLicences)).length === 0) {
            const dataArr = [algorithmName, this.state.cityData[algorithmName][0]?.algorithmVersion];
            selectLicences.init = dataArr;
            selectLicences.init[1] = value;
        } else {
            selectLicences.init[1] = value;
        }
    
        this.setState({
            initSecondCity: value,
            selectLicences
        });
    }

    //动态 选择框联动
    handleProvinceChange = (value, num) => {
        const dataArr = [value, this.state.cityData[value][0]?.algorithmVersion];//形如selectLicences={[province,city],{dataArr},...}
        const citiesInfo = this.state.citiesInfo;
        const secondCityInfo = this.state.secondCityInfo;
        const selectLicences = this.state.selectLicences;

        selectLicences[num] = dataArr;

        citiesInfo[num] = this.state.cityData[value];
        secondCityInfo[num] = this.state.cityData[value][0]?.algorithmVersion;
        this.setState({
            citiesInfo,
            secondCityInfo,
            selectLicences
        });
    };

    onSecondCityChange = (value, num) => {
        const selectLicences = this.state.selectLicences;
        const secondCityInfo = this.state.secondCityInfo;
        //用于初始化时动态算法名称的联动
        console.log(selectLicences);
        console.log(secondCityInfo);

        selectLicences[num][1] = value;
        secondCityInfo[num] = value;
        this.setState({
            secondCityInfo,
            selectLicences
        });
    };

    render() {
        const onFinish = (values) => {
            let flag = true
            const selectInfo = this.state.selectLicences;
            const cityDataInfo = this.state.cityData;
            const algorithmsArr = [];//最终的算法与版本
            const judgeRepeatArr = [];//判断是否重复添加的数组
            const judgeRepeatArr1 = [];//最终传body的数据
            if (!Object.keys(selectInfo).length) {//对象为空
                message.warning("请添加算法!");
            } else {
                for (let key in selectInfo) {
                    if (judgeRepeatArr.find(ele => ele === selectInfo[key][0])) {
                        flag = false;
                        //message.warning("请勿添加相同的算法!");
                    } else {
                        const info = {};
                        info.algorithmName = selectInfo[key][0];
                        info.algorithmVersion = selectInfo[key][1];
                        algorithmsArr.push(info);
                        judgeRepeatArr.push(selectInfo[key][0])
                    }
                }
                if (flag) {
                    //this.licenseAlgorithms(this.state.nodeId, algorithmsArr); 
                    console.log(algorithmsArr);
                    for (let i = 0; i < algorithmsArr.length; i++){
                        for (let key in cityDataInfo) {
                            if (key === algorithmsArr[i].algorithmName) {
                                cityDataInfo[key].forEach(item => {
                                    if(item.algorithmVersion===algorithmsArr[i].algorithmVersion)
                                    judgeRepeatArr1.push(item.id)
                                })
                            }
                        }
                    }

                    console.log(judgeRepeatArr1);
                    let algorithmGroup = {}
                    algorithmGroup.algorithmGroupName = values.algorithmGroupName
                    algorithmGroup.description = values.description || ''
                    algorithmGroup.algorithms = judgeRepeatArr1

                    if (this.props.location.state) {
                        console.log(this.props.location.state.id);
                        console.log(values.algorithmGroupName);
                        algorithmService.editAlgorithmGroup(this.props.location.state.id, values.algorithmGroupName, values.description, judgeRepeatArr1).then(res => {
                            if (res.code === 200) {
                                message.success('修改算法组成功！');
                                this.goback();
                            } 
                            }, error => {
                            error && message.error(error);
                        });
                    } else {
                        console.log(this.state.id);
                        console.log(values.algorithmGroupName);
                        algorithmService.editAlgorithmGroup(this.state.id, values.algorithmGroupName, values.description, judgeRepeatArr1).then(res => {
                            if (res.code === 200) {
                                message.success('修改算法组成功！');
                                this.goback();
                            } 
                            }, error => {
                            error && message.error(error);
                        });
                    }
                    
                } else {
                    message.warning("请勿添加相同的算法!");
                }
            }
        }

        return (
            <div className="common-pd20">
                <BackPage onClick={() => this.goback()} headeTitle={this.state.title} itemTitle="算法" itemSubTitle="算法管理" />
                <div className="common-create-center-container">
                    <div className="common-create-page">
                        <Form
                            //layout={formItemLayout2}
                            //{...formItemLayout1}
                            ref={this.formRef}
                            labelAlign="left"
                            preserve={false}
                            name="dynamic_form_nest_item"
                            onFinish={onFinish}
                            autoComplete="off"
                            initialValues={{
                                algorithmName: this.state.algorithmName,
                                algorithmVersion: this.state.algorithmVersion,
                            }}
                        >
                            {/* <Row gutter={5}>
                                <Col span={16}> */}
                                    <Form.Item name="algorithmGroupName" label="分组名称" {...formItemLayout1}
                                        rules={[
                                            { required: true, message: '请输入算法名称' },
                                            { max: 64, message: '算法名称不允许超过64个字符'}, 
                                            { pattern: new RegExp(/^[0-9a-zA-Z-_\u4e00-\u9fa5]{1,}$/, "g") , message: '请输入64位以内中英文/数字/中划线/下划线'}]}  >
                                        <Input placeholder="请输入分组名称" />
                                    </Form.Item>
                                {/* </Col>
                                <Col span={16}> */}
                                    <Form.Item name="description" label="描述" {...formItemLayout1}> 
                                        <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" showCount={true} maxLength={255}/>
                                    </Form.Item>

                                {/* </Col>
                            </Row> */}
                            <Divider/>
                            
                            <Space key={-1} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                <span style={{fontSize: 12 }}>
                                    算法名称:
                                </span>
                                    <Select
                                        defaultValue={this.getValueFirst}
                                        //defaultValue='ss'
                                        style={{ width: 180, marginLeft:20 }}
                                        // value={this.state.algorithmName}
                                        onChange={this.handleChange_1}
                                    >
                                        {this.state.provinceData?.map(province => (
                                            <Option key={province.name} value={province.name} disabled={province.has}>{province.name}</Option>
                                        ))}
                                    </Select>
                                
                                <span style={{fontSize: 12, marginLeft: 20}}>
                                    算法版本:
                                 </span>
                                 
                                    <Select
                                        //defaultValue='1.2.3'
                                        style={{ width: 120, marginLeft:20 }}
                                        value={this.state.initSecondCity}
                                        onChange={this.handleChange_2}
                                    >
                                        {this.state.initCities?.map(city => (
                                            <Option key={city?.algorithmVersion}>{city?.algorithmVersion}</Option>
                                        ))}
                                    </Select>
                                 
                            </Space>

                            <Form.List name="users"  >
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                        <span style={{fontSize: 12}}>
                                            算法名称:
                                        </span>
                                                    <Select
                                                        // defaultValue={this.state.provinceData[0]}
                                                        style={{ width: 180, marginLeft:20 }}
                                                        defaultValue={this.getValue(key)}
                                                        onChange={(e) => this.handleProvinceChange(e, key)}
                                                    >
                                                        {this.state.provinceData.map(province => (
                                                            <Option key={province.name} value={province.name} disabled={province.has}>{province.name}</Option>
                                                        ))}
                                                    </Select>
                                        <span style={{fontSize: 12, marginLeft: 20}}>
                                            算法版本:
                                        </span>
                                                    <Select
                                                        style={{ width: 120, marginLeft:20 }}
                                                        value={this.state.secondCityInfo[key]}
                                                        //value='1'
                                                        onChange={(e) => this.onSecondCityChange(e, key)}
                                                    >
                                                        {this.state.citiesInfo[key]?.map(city => (
                                                            <Option key={city?.algorithmVersion}>{city?.algorithmVersion}</Option>
                                                        ))}
                                                    </Select>
                                                 
                                                <MinusCircleOutlined onClick={() => { remove(name); this.handleRemove(key); }} />
                                            </Space>
                                        ))}
                                        
                                            <Button type="dashed" style={{width:100}} onClick={() => { add(); this.handleAdd() }} block icon={<PlusOutlined />}>
                                                添加
                                            </Button>
                                        
                                    </>
                                )}
                            </Form.List>
                            <BottomBtnGroup onClick={() => this.goback()} />
                        </Form>
                    </div> 
                </div>
            </div>
        )
    }
}

export default EditAlgorithmGroup;