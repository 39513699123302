import React from 'react';
import { Form, Input, Col, Row, Tabs, Button, Table, Divider, message, Select, Spin, Tag } from 'antd';
import BackPage from '../../../common/backPage';
import BottomBtnGroup from '../../../common/bottomBtnGroup';
import { nodeService } from '../../../../services/node.service';
import { labelService } from '../../../../services/label.service';
import { userService } from '../../../../services/user.service';
import './edit-node.scss';
import Application from './application/application';
import Equipment from './equipment/equipment';
import { AUTCONFIG } from '../../../../../utils/auth.config';
import { connect } from 'react-redux';

const { TabPane } = Tabs;

const { TextArea } = Input;
const { Option } = Select;
const formItemLayout = {
    labelCol: { xs: 24, sm: 12, md: 8, lg: 6, xl: 4 },
    wrapperCol: { xs: 24, sm: 12, md: 15 },
};
const formItemLayout2 = {
    labelCol: { span: 4 },
    wrapperCol: { span: 18 },
};
let dfLabel = []

class EditNode extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            showManage: true,
            nodeId: null,
            nodeName: '编辑节点',
            labelList: [],
            tagList: [],
            allLabels: [],
            loading: true,
            logSwitch: '',
            nodeObj: {

                nodeUuid: '',
                name: '',
                description: '',
                hostname: '',
                macAddress: '',
                cpu: '',
                gpu: '',
                mem: '',
                disk: '',
                instanceNum: '',
                deviceNum: '',
                state: '',
                labels: '',
                upTime: '',
                updateTime: '',
                createTime: '',
                username: '',
                labels: [],
                userId: ''
            },
            labels: [
                {
                    "id": 1,
                    "name": "测试",
                    "code": ""
                }
            ],
            tenantList: [],
            history: require("history").createHashHistory,
        };
    }
    goback() {
        // if (this.props.onSubmit) {
        //     var { showManage } = this.state;
        //     this.props.onSubmit({ showManage })
        // }
        this.props.history.push('/home/node-manage');
    }
    getTenantList() {
        userService.getAllTenantList().then(res => {
            if (res.code === 200) {
                console.log("所属用户信息", res.users);
                const tenants = res.users;
                const nodeInfo = this.props.location.state?.nodeInfo
                    || JSON.parse(localStorage.getItem('nodeInfo'));
                if (!tenants.find(ele => ele.id === nodeInfo?.userId)) {
                    tenants.push(nodeInfo);
                }
                this.setState({
                    tenantList: this.getTenantItem(tenants),
                });
            }
        }, error => {
            error && message.error(error);
        });
    }
    getTenantItem(tenants) {
        return tenants.map((item, index) => {
            return (
                <Option key={item?.id} value={item?.userId || item?.id}>{item?.realName}/{item?.username}</Option>
            );
        });
    }
    cancel = () => {
        /* this.formRef.current.setFieldsValue({
            name: this.state.roleObj.name || '',
            description: this.state.roleObj.description || '',
        }); */
    }
    componentDidMount() {
        if (this.props.location && this.props.location.state) {
            const nodeId = this.props.location.state.id;
            window.localStorage.setItem('nodeId', this.props.location.state.id);
            window.localStorage.setItem('nodeInfo', JSON.stringify(this.props.location.state.nodeInfo))
            if (nodeId) {
                this.setState({
                    nodeId: nodeId
                });
                this.getLabelList(nodeId)

            }
        } else if (window.localStorage.getItem('nodeId')) {
            this.setState({
                nodeId: window.localStorage.getItem('nodeId')
            });
            this.getLabelList(window.localStorage.getItem('nodeId'))
        }


        //  this.getNodeDetail(nodeId);
        this.getTenantList();
    }
    componentWillUnmount(){
        window.localStorage.removeItem('nodeInfo')
    }

    getTagItem(labels) {
        return labels.map((item, index) => {
            return (
                item ? <Tag color="volcano">{item.name}</Tag> : ''
            );
        });
    }
    getLableItem(labels) {
        return labels.map((item, index) => {
            return (
                <Option key={item.id} value={item.id}>{item.name}</Option>
            );
        });
    }
    handleChange(value) {
        console.log(`selected ${value}`);
    }
    getLabelList(nodeId) {
        labelService.getAllLabels().then(res => {
            if (res.code === 200) {
                const labels = res.labels;
                this.setState({
                    labelList: this.getLableItem(labels),
                    allLabels: labels
                });
                this.getNodeDetail(nodeId);
            }
        }, error => {
            error && message.error(error);
        });
    }

    getNodeDetail(nodeId) {
        nodeService.getNodeDetail(nodeId || this.state.nodeId).then(res => {
            if (res.code === 200) {
                console.log(res);
                const node = res.node;
                const defaultOption = [];
                node.labels = node.labels || [];
                if (node.labels) {
                    node.labels.map(it => {
                        defaultOption.push(it.id)
                    });
                }
                this.setState({
                    logSwitch: node.logSwitch,
                    nodeObj: node,
                    nodeId: node.id,
                    loading: false,
                    tagList: this.getTagItem(node.labels),
                    nodeName: "编辑节点 -- " + node.name,
                    labelItems: defaultOption
                });
                const that = this;
                // setTimeout(() => {
                //     document.getElementById("desc").value = node.description
                that.formRef.current?.setFieldsValue({
                    label: defaultOption || '',
                    description: node.description,
                    seriesNumber: node.seriesNumber,
                    name: node.name,
                    user: node.userId || null,
                    address: node.address,
                })
                // }, 300)
            }
            // else {
            //     message.error('获取节点详情失败');
            // }
        }, error => {
            error && message.error(error);
        });
    }


    getState(record) {
        // f (record.state === 'WAITING') {
        //     return <div><div className="common-yellow"></div> <span>待升级</span></div>
        //   } 
        if (record.state === 'UNCONNECTED') {
            return <div><div className="common-deep-gray"></div> <span>未纳管</span></div>
        } else if (record.state === 'RUNNING') {
            return <div><div className="common-green"></div> <span>运行中</span></div>
        } if (record.state === 'UPGRADEWAITING') {
            return <div><div className="common-yellow"></div> <span>待升级</span></div>
        } else if (record.state === 'UPGRADING') {
            return <div><div className="common-yellow"></div> <span>升级中</span></div>
        } else if (record.state === 'DISABLED') {
            return <div><div className="common-gray"></div> <span>禁用</span></div>
        } else if (record.state === 'OFFLINE') {
            return <div><div className="common-red"></div> <span>不在线</span></div>
        } else if (record.state === 'DELETED') {
            return <div><div className="common-red"></div> <span>已删除</span></div>
        } else {
            return '';
        }
    }
    getLabels(labelList, selectLabels) {
        const option = []
        selectLabels = selectLabels || []
        selectLabels.map(item => {
            labelList.map(it => {
                if (item === it.id) {
                    option.push(it.id)
                }
            })
        })
        return option
    }
    handleLogStart() {
        nodeService.startLogUpload(this.state.nodeId).then(res => {
            if (res.code === 200) {
                message.success("操作成功");
                nodeService.getNodeDetail(this.state.nodeId).then(res => {
                    if (res.code === 200) {
                        this.setState({
                            logSwitch: res.node.logSwitch
                        })
                    }
                })
            }
            // else{
            //     message.success("操作失败")
            // }
        }, error => {
            error && message.error(error);
        })
    }
    handleLogStop() {//FIXME:
        nodeService.stopLogUpload(this.state.nodeId).then(res => {
            if (res.code === 200) {
                message.success("操作成功");
                nodeService.getNodeDetail(this.state.nodeId).then(res => {
                    if (res.code === 200) {
                        this.setState({
                            logSwitch: res.node.logSwitch//FIXME:
                        })
                    }
                })
            }
            // else{
            //     message.success("操作失败")
            // }
        }, error => {
            error && message.error(error);
        })
    }
    render() {
        const onFinish = (values) => {
            message.loading('信息保存中~~', 2500);
            console.log(values);
            let sLabels = this.getLabels(this.state.allLabels, values.label);
            this.props.currentUser?.type === AUTCONFIG.SUB.value && (sLabels = this.state.nodeObj.labels.map(elx => elx.id))
            this.props.currentUser?.type === AUTCONFIG.SUB.value && (values.user = this.state.nodeObj.userId)
            nodeService.editNode(this.state.nodeId, values.name, values.description, sLabels || [], values.seriesNumber || '', values.user || '', values.address || '').then(res => {
                message.destroy();
                if (res.code === 200) {
                    message.success('修改成功');
                    this.props.history.push('/home/node-manage');
                }
                //  else {
                //     message.error('修改失败');
                // }
            }, error => {
                message.destroy();
                error && message.error(error);
            });
        };
        return (
            <><Spin spinning={this.state.loading} delay={500}>
                <div className="common-detail-container">
                    <BackPage onClick={() => this.goback()} headeTitle={this.state.nodeName} itemTitle="边缘节点" itemSubTitle="查看节点" />
                    <div style={{ background: '#fff', padding: "0 20px 20px" }}>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="详情" key="1">
                                <div style={{ marginLeft: 40 }}>
                                    <Form name="basic"
                                        onFinish={onFinish}
                                        labelAlign="left"
                                        ref={this.formRef}
                                        {...formItemLayout} >
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item label="状态" >
                                                    {this.getState(this.state.nodeObj)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item name="name" label="节点名称" rules={[
                                                    { required: true, message: '请输入节点名称' },
                                                    { max: 64, message: '任务名称不允许超过64个字符' },
                                                    { pattern: new RegExp(/^[0-9a-zA-Z-_\u4e00-\u9fa5]{1,}$/, "g"), message: '请输入64位以内中英文/数字/中划线/下划线' }
                                                ]}>
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item name="description" label="描述" >
                                                    <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" id="desc" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Col span={24}>
                                                {
                                                    this.props.currentUser?.type === AUTCONFIG.SUB.value ?
                                                        <Form.Item label="产品序列号" >
                                                            {this.state.nodeObj.seriesNumber}
                                                        </Form.Item> :

                                                        <Form.Item name="seriesNumber" label="产品序列号" rules={[
                                                            { required: true, message: '请输入产品序列号' },
                                                            { max: 64, message: '产品序列号不允许超过64个字符' },
                                                            { pattern: new RegExp(/^[0-9a-zA-Z-_]{1,}$/, "g"), message: '请输入64位以内英文/数字/中划线/下划线' }]}>
                                                            <Input />
                                                        </Form.Item>
                                                }

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                {
                                                    this.props.currentUser?.type === AUTCONFIG.SUB.value ?
                                                        <Form.Item label="节点标签" >
                                                            {this.state.tagList}
                                                        </Form.Item> :
                                                        <Form.Item name="label" label="节点标签">
                                                            <Select mode="multiple" allowClear placeholder="请选择标签"
                                                                onChange={(value) => this.handleChange(value)} showArrow>
                                                                {this.state.labelList}
                                                            </Select>
                                                        </Form.Item>
                                                }

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                {
                                                    this.props.currentUser?.type === AUTCONFIG.SUB.value ?
                                                        <Form.Item label="所属用户" >
                                                            {this.state.nodeObj.username}
                                                        </Form.Item> :
                                                        <Form.Item name="user" label="所属用户">
                                                            <Select allowClear placeholder="请选择所属用户"
                                                                showSearch
                                                                filterOption={(input, option) =>
                                                                    option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {this.state.tenantList}
                                                            </Select>
                                                        </Form.Item>
                                                }

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item label="所在省市区" >
                                                    {this.state.nodeObj.province} {this.state.nodeObj.city} {this.state.nodeObj.district}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item name="address" label="详细地址" >
                                                    <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入详细地址" id="addre" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item label="创建时间" >
                                                    {this.state.nodeObj.createTime || '--'}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item label="更新时间"  >
                                                    {this.state.nodeObj.updateTime || '--'}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item label="上报时间"  >
                                                    {this.state.nodeObj.upTime || '--'}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item label="日志上传云端"  >
                                                    {this.state.logSwitch === 1 ?
                                                        <div>
                                                            <Button style={{ width: 68 }} disabled>已开启</Button>
                                                            <Button type="primary" style={{ marginLeft: '9.5px', width: 68 }} onClick={() => this.handleLogStop()}>关闭</Button>
                                                        </div>
                                                        :
                                                        <div>
                                                            <Button disabled style={{ width: 68 }}>已关闭</Button>
                                                            <Button type="primary" style={{ marginLeft: '9.5px', width: 68 }} onClick={() => this.handleLogStart()}>开启</Button>
                                                        </div>}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <BottomBtnGroup onClick={() => this.goback()} />
                                    </Form>
                                </div>
                                <Divider className="lr-divider" />
                                <div style={{ marginLeft: 40 }}>
                                    <Form name="basic"
                                        onFinish={onFinish}
                                        labelAlign="left"
                                        {...formItemLayout2} >
                                        <Row>
                                            {/* <Col span={12}>
                                                <Form.Item label="状态" >
                                                    {this.getState(this.state.nodeObj.state)}
                                                </Form.Item>
                                            </Col> */}
                                            {/* <Col span={12}>
                                                <Form.Item label="产品ID" >
                                                    {this.state.nodeObj?.stationId || '--'}
                                                </Form.Item>
                                            </Col> */}
                                            <Col span={12}>
                                                <Form.Item label="主机名称" >
                                                    {this.state.nodeObj.hostname || '--'}
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item label="GPU" >
                                                    {this.state.nodeObj.gpu || '--'}
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="CPU" >
                                                    {this.state.nodeObj.cpu || '--'}
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="应用数量" >
                                                    {this.state.nodeObj.instanceNum || '--'}
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row>


                                            <Col span={12}>
                                                <Form.Item label="内存" >
                                                    {this.state.nodeObj.mem || '--'}
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="设备数量" >
                                                    {this.state.nodeObj.deviceNum || '--'}
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item label="硬盘" >
                                                    {this.state.nodeObj.disk || '--'}
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="epack版本" >
                                                    {this.state.nodeObj.firmwareVersion || '--'}
                                                </Form.Item>
                                            </Col>
                                        </Row>


                                    </Form>
                                </div>
                            </TabPane>
                            {/* <TabPane tab="应用" key="2">
                                <Application history={this.props.history} nodeId={this.state.nodeId} />
                            </TabPane>
                            <TabPane tab="设备" key="3">
                                <Equipment history={this.props.history} nodeId={this.state.nodeId} />
                            </TabPane> */}
                        </Tabs>
                    </div>
                </div>
            </Spin>
            </>
        );
    }
}

// export default EditNode;


export default connect(
    (state) => ({
        currentUser: state.currentUser
    })
)(EditNode);