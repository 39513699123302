import React from "react";
import { Table, Button, Modal, message, Pagination, Form } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";
import BackPage from "../../../common/backPage";
import { nodeService } from "../../../../services/node.service";
import { userDebService } from "../../../../services/user-deb.service";

import "./user-software-update.scss";
import store from "../../../../../store";
import { getNodeIdAction } from "../../../../../store/actionCreators";

let page = 1;
let pageSize = 10;

class UserSoftwareUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // 详情
      nodeLogName: "用户软件升级",
      nodeObj: {},
      nodeId: null,
      nodeState: "",
      nodeFirmwareId: "",
      nodeFirmwareName: "",
      nodeFirmwareVersion: "",
      //用户软件包数据列表
      // nodeUserDebs: [{ name: 'a', id: 1, version: '1' }, {
      //   name: 'b', id: 2, version: '2'
      // }],
      nodeUserDebs: [],

      // 历史列表
      updateHistory: [],
      nodeAmount: 0,

      // 弹窗
      isCanUpdateVisible: false,
      canUpdatesList: [],
      canUpdatesAmount: 0,
      newestFirmware: null,
      page: 1,
      pageSize: 10,
      firmwareVersion: "",
    };
  }

  componentDidMount() {
    page = 1;
    pageSize = 10;
    if (this.props.location.state) {
      const nodeId = this.props.location.state.id;
      console.log(nodeId);
      if (nodeId) {
        const action = getNodeIdAction(nodeId);
        store.dispatch(action);
        this.setState({
          nodeId: nodeId,
        });
        this.getNodeDetail(nodeId);
        this.getNodeUserDebList(nodeId);
        this.getUserDebUpgradeHistory(nodeId);
        this.interval = setInterval(() => {
          this.getNodeDetail(nodeId);
          this.getNodeUserDebList(nodeId);
          this.getUserDebUpgradeHistory(nodeId);
        }, 30000);
        //this.getUserUpgradeDebs(nodeId);
      }
    } else {
      const nodeId = store.getState().nodeId;
      console.log(nodeId);
      if (nodeId) {
        this.setState({
          nodeId: nodeId,
        });
        this.getNodeDetail(nodeId);
        this.getNodeUserDebList(nodeId);
        this.getUserDebUpgradeHistory(nodeId);
        this.interval = setInterval(() => {
          this.getNodeDetail(nodeId);
          this.getNodeUserDebList(nodeId);
          this.getUserDebUpgradeHistory(nodeId);
        }, 30000);
        //this.getUserUpgradeDebs(nodeId);
      }
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  getNodeDetail(nodeId) {
    nodeService.getNodeDetail(nodeId || this.state.nodeId).then(
      (res) => {
        if (res.code === 200) {
          console.log(res);
          const node = res.node;
          this.setState({
            nodeObj: node,
            nodeLogName: "用户软件升级 -- " + node.name,
            nodeState: node.state,
            nodeFirmwareId: node.nodeFirmwareId,
            nodeFirmwareName: node.firmwareName,
            nodeFirmwareVersion: node.firmwareVersion,
          });
        }
        //  else {
        //     message.error('获取节点详情失败');
        // }
      },
      (error) => {
        error && message.error(error);
      }
    );
  }

  //用户软件包展示接口
  getNodeUserDebList(nodeId) {
    nodeService.getNodeUserDebList(nodeId || this.state.nodeId).then(res => {
      if (res.code === 200) {
        console.log('用户软件包',res);
        this.setState({
          nodeUserDebs: res?.debs
        })
      }
    },
    (error) => {
      error && message.error(error);
    }
    )
  }

  //更新记录接口
  getUserDebUpgradeHistory(nodeId) {
    this.setState({
      page,
      pageSize,
    });

    nodeService
      .getUserDebUpgradeHistory(nodeId || this.state.nodeId, pageSize, page)
      .then(
        (res) => {
          if (res.code === 200) {
            console.log('更新记录接口',res);
            const nodeUpdates = res.nodeUpgrades || [];
            nodeUpdates.forEach((element) => {
              element.key = element.id;
            });
            this.setState({
              updateHistory: nodeUpdates,
              nodeAmount: res.dataCount,
            });
          }
          // else {
          //     message.error('获取更新记录失败，' + res.message);
          // }
        },
        (error) => {
          error && message.error(error);
        }
      );
  }

  goback() {
    // if (this.props.onSubmit) {
    //   var { showManage } = this.state;
    //   this.props.onSubmit({ showManage });
    // }
    this.props.history.go(-1);
  }

  showUpdate() {
    this.setState({
      isCanUpdateVisible: true,
    });
    this.getUserUpgradeDebs();
  }
  getNodeState(record) {
    if (record.state === "UNCONNECTED") {
      return (
        <div>
          <div className="common-deep-gray"></div> <span>未纳管</span>
        </div>
      );
    } else if (record.state === "RUNNING") {
      return (
        <div>
          <div className="common-green"></div> <span>运行中</span>
        </div>
      );
    }
    if (record.state === "UPGRADEWAITING") {
      return (
        <div>
          <div className="common-yellow"></div> <span>待升级</span>
        </div>
      );
    } else if (record.state === "UPGRADING") {
      return (
        <div>
          <div className="common-yellow"></div> <span>升级中</span>
        </div>
      );
    } else if (record.state === "DISABLED") {
      return (
        <div>
          <div className="common-gray"></div> <span>禁用</span>
        </div>
      );
    } else if (record.state === "OFFLINE") {
      return (
        <div>
          <div className="common-red"></div> <span>不在线</span>
        </div>
      );
    } else if (record.state === "DELETED") {
      return (
        <div>
          <div className="common-red"></div> <span>已删除</span>
        </div>
      );
    } else {
      return "";
    }
  }

  //用户可升级包
  getUserUpgradeDebs(nodeId) {
    nodeService.getUserUpgradeDebs(nodeId || this.state.nodeId).then(
      (res) => {
        if (res.code === 200) {
          console.log('用户可升级包',res);
          const debs = res?.debs;
          debs.forEach((element) => {
            element.key = element.id;
          });
          this.setState(
            {
              canUpdatesList: debs,
              newestFirmware: debs[debs.length - 1],
            },
            function () {
              console.log(this.state.newestFirmware);
            }
          );
        }
        // else {
        //     message.error('获取升级列表失败');
        // }
      },
      (error) => {
        error && message.error(error);
      }
    );
  }
  handleOk() {
    this.getNodeDetail();
    this.getUserDebUpgradeHistory();
    this.handleCancel();
  }
  handleCancel() {
    this.setState({ isCanUpdateVisible: false });
  }
  getState(record) {
    if (record.state === "1" || record.state === 1) {
      return (
        <div>
          <div className="common-green"></div> <span>可用</span>
        </div>
      );
    } else if (record.state === "0" || record.state === 0) {
      return (
        <div>
          <div className="common-red"></div> <span>禁用</span>
        </div>
      );
    } else {
      return "";
    }
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
    };
    const onPageChange = (pageNumber) => {
      page = pageNumber;
      this.getUserDebUpgradeHistory();
    };
    const onShowSizeChange = (current, pageSizeNumber) => {
      pageSize = pageSizeNumber;
      page = 1;
      this.getUserDebUpgradeHistory();
    };
    const columns = [
      {
        title: "软件包名称",
        dataIndex: "debName",
      },
      {
        title: "软件包版本",
        dataIndex: "debVersion",
      },
      {
        title: "描述",
        dataIndex: "description",
        width: "15%",
      },
      {
        title: "状态",
        dataIndex: "state",
        render: (text, record) => (
          <>
            {text === "WAITING" && (
              <div>
                <div className="common-yellow"></div> <span>待升级</span>
              </div>
            )}
            {text === "UPGRADING" && (
              <div>
                <div className="common-yellow"></div> <span>升级中</span>
              </div>
            )}
            {text === "SUCCESS" && (
              <div>
                <div className="common-green"></div> <span>成功</span>
              </div>
            )}
            {text === "FAILED" && (
              <div>
                <div className="common-red"></div> <span>失败</span>
              </div>
            )}
          </>
        ),
      },
      {
        title: "更新时间",
        dataIndex: "updateTime",
      },
    ];

    const userDebColumns = [
      {
        title: "用户软件包名称",
        dataIndex: "name",
      },
      {
        title: "用户软件包版本",
        dataIndex: "version",
      },
    ];

    return (
      <>
        <div className="node-log-container">
          <BackPage
            onClick={() => this.goback()}
            headeTitle={this.state.nodeLogName}
            itemTitle="边缘节点"
            itemSubTitle="节点升级"
          />
          <div
            style={{
              //height: "180px",
              backgroundColor: "#fff",
              marginBottom: "12px",
              padding: "24px",
              paddingBottom: "0px",
            }}
          >
            <Form {...formItemLayout} labelAlign="left">
              <Form.Item name="state" label="状态">
                {this.getNodeState(this.state.nodeObj)}
              </Form.Item>
              <Form.Item name="upgrade" label="用户软件更新">
                {this.state.nodeObj?.state === "RUNNING" ? (
                  <Button
                    className="node-update"
                    onClick={() => this.showUpdate()}
                  >
                    更新
                  </Button>
                ) : (
                    <div
                      style={{
                      fontWeight: 700,
                      color: "#73d13d",
                    }}>
                      运行状态的节点可更新
                    </div>
                  
                )}
              </Form.Item>
              <Form.Item name="messgge" label="">
                <div
                  style={{
                    fontWeight: 700,
                    color: "#73d13d",
                  }}
                >
                  <span>更新成功后，请重启小站</span>
                </div>
              </Form.Item>
            <div  style={{paddingBottom:'20px'}}>
               <Table
              // rowKey={(record) => record.id}
              bordered
              pagination={false}
              columns={userDebColumns}
              dataSource={this.state.nodeUserDebs}
            />
            </div>
          
            </Form>
            
          </div>
          <div className="update-content">
            <div className="update-list">更新记录</div>
            <div className="update-table">
              <Table
                rowKey={(record) => record.id}
                bordered={false}
                pagination={false}
                columns={columns}
                dataSource={this.state.updateHistory}
              />
            </div>
            <div>
              {this.state.updateHistory.length > 0 && (
                <div
                  className="common-bottom-pagination"
                  style={{ paddingBottom: 16 }}
                >
                  <Pagination
                    showSizeChanger
                    onShowSizeChange={onShowSizeChange}
                    defaultCurrent={1}
                    current={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.nodeAmount}
                    onChange={onPageChange}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <UpdateNode
          handleOk={() => this.handleOk()}
          nodeId={this.state.nodeId}
          canUpdatesList={this.state.canUpdatesList}
          isCanUpdateVisible={this.state.isCanUpdateVisible}
          handleCancel={() => this.handleCancel()}
        ></UpdateNode>
      </>
    );
  }
}
export default UserSoftwareUpdate;

function UpdateNode(props) {
  const update = (event) => {
    nodeService.nodeUserDebUpgrade(props.nodeId, event.id).then(
      (res) => {
        if (res.code === 200) {
          message.success("开始更新");
          props.handleOk();
        }
        else {
            message.error('更新失败，' + res.message)
        }
      },
      (error) => {
        error && message.error(error);
      }
    );
  };
  const columns = [
    {
      title: "用户软件包名称",
      dataIndex: "name",
    },
    {
      title: "用户软件包版本",
      dataIndex: "version",
    },
    {
      title: "描述",
      dataIndex: "description",
    },
    {
      title: "操作",
      dataIndex: "action",
      render: (text, record) => (
        <>
          <a href onClick={() => update(record)}>
            更新
          </a>
        </>
      ),
    },
  ];

  return (
    <Modal
      title="可更新版本"
      visible={props.isCanUpdateVisible}
      onCancel={props.handleCancel}
      footer={null}
    >
      <Table
        rowKey={(record) => record.id}
        bordered={false}
        pagination={false}
        columns={columns}
        dataSource={props.canUpdatesList}
      />
    </Modal>
  );
}
