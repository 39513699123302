/* eslint-disable jsx-a11y/anchor-is-valid */
import { Form, Input, Tabs, message, Button, Col, Row, Table, Transfer, ConfigProvider, Modal } from 'antd';
import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { userService } from '../../../../../services/user.service';
import { roleService } from '../../../../../services/role.service';
import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';
import {connect} from 'react-redux';
import './view-user.scss';
import { AUTCONFIG, getDisplayName } from '../../../../../../utils/auth.config';
const { TextArea } = Input;

const { confirm } = Modal;
const formItemLayout = {
    labelCol: { xs: 24, sm: 12, md: 8, lg: 6, xl: 4},
    wrapperCol: { xs: 24, sm: 12, md: 15},
};


class ViewUser extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            showManage: true,
            canEdit: [false, false, false, false, false, false, false, false, false, false],

            userId: null,
            userObj: null,


            userCreateTime: '',
            userUpdateTime: '',
            userName: '查看用户',

            roleLists: [], // 角色所有数据
            // organLists: [], // 企业组织所有数据
            targetRoleKeys: [], // 当前用户已包含的角色id
            // targetOrganKeys: [], // 当前用户已包含的角色id

            isRoleLVisible: false,
            // isOrganVisible: false,

            dataSourceRole: [], // 当前用户已包含的角色列表
            // dataSourceOrgan: [], // 当前用户已包含的角色列表
            type: '',
            username:'',
            realName: '',
            description: '',
            password: '******',
            mobile:'',
            email:'',
            company: '',
            address:'',
            userType: '',
        };
    }

    goback() {
        // this.setState({
        //     canEdit: [false, false, false, false, false, false, false, false, false, false]
        // })
        // if (this.props.onSubmit) {
        //     var { showManage } = this.state;
        //     this.props.onSubmit({ showManage })
        // }
        // this.props.history.go(-1);
        this.props.history.push('/home/sys/user-manage');
    }
    clearPass() {
        this.formRef.current.setFieldsValue({
            password: ''
        });
    }
    setPass(event) {
        const value = event.target.value;
        if (value === '') {
            this.formRef.current.setFieldsValue({
                password: '******',
            })
        }
    }
    cancel = () => {
        this.formRef.current.setFieldsValue({
            username: this.state.userObj.username,
            realName: this.state.userObj.realName,
            password: '******',
            description: this.state.userObj.description,
            mobile: this.state.userObj.mobile,
            email: this.state.userObj.email,
            company: this.state.userObj.company,
            address: this.state.userObj.address,
        });
    }

    componentDidMount() {
      
        if (this.props.location.state) {
            const userId = this.props.location.state.id;
            this.setState({
                userId: userId
            });
            this.getUserDetail(userId);
          
            sessionStorage.setItem('userId', userId);
            // this.getUserOrgan(userId);
        } else {
            if(sessionStorage.getItem('userId')) {
                const userId = sessionStorage.getItem('userId');
                this.setState({
                    userId: userId
                });
                this.getUserDetail(userId);
            
            } else {
                this.goback();
            }
        }
    }

  
  

    getUserDetail(userId) {
        userService.getUserDetail(userId).then(res => {
            if (res.code === 200) {
                const user = res.users;
                const stringName  = getDisplayName(user.type);
                this.setState({
                    userObj: user,
                    userCreateTime: user.createTime,
                    userUpdateTime: user.updateTime,
                    userName: user.username,
                    type: user.type,
                    createBy: user.createBy,
                    username: user.username,
                    realName: user.realName,
                    description: user.description,
                    password: '******',
                    mobile: user.mobile,
                    email: user.email,
                    company: user.company,
                    address: user.address,
                    userType: stringName
                });
   
               
            } 
            // else {
            //     message.error('获取用户详情失败');
            // }
        }, error => {
            error && message.error(error);
        });
    }

   

    

    render() {
     
        return (
            <>
                <div className="common-detail-container">
                    <BackPage onClick={() => this.goback()} headeTitle={"查看用户 -- "+this.state.userName} itemTitle="用户管理" itemSubTitle="查看用户" />
                    <div className="common-edit-card">
                        <Form name="basic"
                           
                            labelAlign="left"
                            ref={this.formRef}
                            {...formItemLayout} >
                            <Row>
                                <Col md={12}>
                                    <Form.Item name="username"  label="账号名" >
                                    {this.state.userName}
                                    </Form.Item>
                                </Col>
                            </Row>
                           

                            <Row>
                                <Col span={12}>
                                    <Form.Item name="realName" label="用户名称"  >
                                    {this.state.realName}
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12}>
                                    <Form.Item name="password" label="密码"  >
                                    {this.state.password}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item name="mobile" label="手机号码" >
                                    {this.state.mobile || '--'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item name="email" label="电子邮箱" >
                                    {this.state.email || '--'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            {this.props.currentUser.type === AUTCONFIG.SUPERADMIN.value ? 
                                <Row>
                                    <Col span={12}>
                                        <Form.Item name="type" label="用户类型"  >
                                        {this.state.userType  || '--'}
                                        </Form.Item>
                                    </Col>
                                </Row> : ''}
                           
                            {(this.state.userObj?.type === AUTCONFIG.MAIN.value 
                            || this.state.userObj?.type === AUTCONFIG.SUB.value) ? 
                            <Row>
                                <Col span={12}>
                                    <Form.Item name="company" label="企业"  >
                                    {this.state.company?.name  || '--'}
                                    </Form.Item>
                                </Col>
                            </Row> : ''}
                            <Row>
                                <Col span={12}>
                                    <Form.Item name="address" label="联系地址" >
                                    {this.state.address  || '--'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item name="description" label="描述" >
                                    {this.state.description  || '--'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item label="创建时间" >
                                        {this.state.userCreateTime  || '--'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item label="更新时间"  >
                                        {this.state.userUpdateTime || '--'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            
                        </Form>
                    </div>

                    
                </div>

               
            </>
        );
    }
}

// export default ViewUser;


export default connect(
    (state) => ({
        currentUser: state.currentUser
    })
)(ViewUser);