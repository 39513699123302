import React from 'react';
import { Form, Input, Select, message, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import BackPage from '../../../common/backPage';
import BottomBtnGroup from '../../../common/bottomBtnGroup';
import store from '../../../../../store';
import { getNodeIdAction } from '../../../../../store/actionCreators';

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

const props = {
  name: 'file',
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} 文件上传成功`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} 文件上传失败`);
    }
  },
};

class EditModel extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      typeList: [],

    };
  }
  goback() {
    this.props.history.push('/home/fringe-market');
  }

  componentDidMount() {
    this.getTypeList();


    if (this.props.location.state) {
      const model = this.props.location.state.result;
      if (model) {
        const action = getNodeIdAction(model);
        store.dispatch(action);
        this.setState({
          modelDetail: model
        });
        this.formRef.current?.setFieldsValue({
          name: model.name,
          version: model.version,
          description: model.description,
        });
      }
    } else {
      const model = store.getState().model;
      if (model) {
        this.setState({
          modelDetail: model
        });
        this.formRef.current?.setFieldsValue({
          name: model.name,
          version: model.version,
          description: model.description,
        });
      }
    }
  }
  getTypeList() {
    const arr = [
      { id: 1, name: '类型一' },
      { id: 2, name: '类型二' },
      { id: 3, name: '类型三' },
      { id: 4, name: '类型四' },
    ];
    this.setState({
      typeList: this.getTypeItem(arr)
    });
  }
  getTypeItem(types) {
    return types.map((item) => {
      return (
        <Option key={item.id} >{item.name}</Option>
      );
    });
  }
  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      this.getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
        }),
      );
    }
  };
  render() {
    const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('只允许上传JPG或PNG格式的图片');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('图片大小必须小于2MB');
      }
      return isJpgOrPng && isLt2M;
    }
    const onFinish = (values) => {
      console.log(values);

      message.error('模型发布失败');
      this.goback();
    };
    const { loading, imageUrl } = this.state;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>上传</div>
      </div>
    );
    return (
      <>
        <div className="common-pd20">
          <BackPage onClick={() => this.goback()} headeTitle="编辑模型" itemTitle="边缘市场" itemSubTitle="编辑模型" />
          <div className="common-create-center-container">
            <div className="common-create-page">
              <Form
                ref={this.formRef}
                name="validate_other"
                labelAlign="left"
                {...formItemLayout}
                onFinish={onFinish} >
                <Form.Item name="name" label="名称" rules={[{ required: true, message: '请输入名称' }]} >
                  <Input placeholder="请输入名称" />
                </Form.Item>
                <Form.Item name="version" label="版本" rules={[{ required: true, message: '请输入版本' }]}>
                  <Input placeholder="请输入版本" />
                </Form.Item>
                <Form.Item name='type' label='分类' >
                  <Select style={{ width: '150px' }} placeholder="请选择分类">
                    {this.state.typeList}
                  </Select>
                </Form.Item>
                <Form.Item label='图标' >
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    beforeUpload={beforeUpload}
                    onChange={(info) => this.handleChange(info)}
                  >
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                  </Upload>
                </Form.Item>
                <Form.Item label='文件' >
                  <Upload {...props}>
                    <Button icon={<UploadOutlined />} danger type="primary" className="">点击上传</Button>

                  </Upload>
                </Form.Item>
                <Form.Item name="description" label="描述" >
                  <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" />
                </Form.Item>
                <BottomBtnGroup onClick={() => this.goback()} />
              </Form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EditModel;
