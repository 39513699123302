import { handleResponse } from './handle-response';
import store from '../../store/index';

export const geographyService = {
    getNodesDistribution,
    getAddressDistribution
};

/**
 * 获取节点分布
 * @returns 
 */
function getNodesDistribution() {
    return fetch(`/v1/console/nodes/distribution`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

/**
 * 获取位置节点分布
 * @returns 
 */
 function getAddressDistribution(city,province,district) {
    return fetch(`/v1/console/nodes/distribution/address?city=${city || ''}&province=${province || ''}&district=${district || ''}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}