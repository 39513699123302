import React from "react";
import {
  Select,
  Form,
  Button,
  Table,
  Pagination,
  ConfigProvider,
  Modal,
  message,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import zhCN from "antd/lib/locale/zh_CN";
import { labelService } from "../../../services/label.service";
import { terminalService } from "../../../services/terminal-equ";
import { epackExclusiveManageService } from "../../../services/epack-exclusive-manage";
import { otaService } from "../../../services/ota.service";
import { firmwareService } from "../../../services/firmware.service";
import { userService } from "../../../services/user.service";
import "../../../../index.scss";
import "./epack-exclusive-manage.scss";

import { connect } from "react-redux";

const { Option } = Select;

const { confirm } = Modal;

let page = 1;
let pageSize = 10;
let searchProductName = "";
let searchProductModel = "";
let searchFirmwareName = "";
let searchVersion = "";
let searchUserId = "";
let otaId = "";

// let firmwareList=[];

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eVersionList: [],
      productModelList: [],
    };
  }
  form = React.createRef();
  //查询epack列表

  onFinish = (values) => {
    page = 1;
    const filterData=this.state.productModelList.filter(ele=>ele.id===values.productModel)
    searchProductName = values.productName;
    searchProductModel = filterData[0]?.productModel;
    searchFirmwareName = values.epackName;
    searchVersion = values.epackVersion;
    searchUserId = values.username;
    this.props.onClick();
  };
  resetForm = () => {
    this.form.current?.resetFields();
    searchProductName = "";
    searchProductModel = "";
    searchFirmwareName = "";
    searchVersion = "";
    searchUserId = "";
    page = 1;
    pageSize = 10;
    this.props.onClick();
    this.props.getNullEpack();
    this.setState({
      eVersionList: [],
      productModelList:[]
    });
  };

  componentDidMount() {
    // this.resetForm(); 
    const initData=this.props?.initData;
    if(initData){
      this.form?.current.setFieldsValue({
      productName: initData.productName,
      productModel: initData.productModel,
      epackName:initData.firmwareName,
      epackVersion:initData.firmwareVersion,
    });
    }else{
      this.resetForm(); 
    }
  }
  render() {
    const { eVersionList, productModelList } = this.state;
    const { otaListInfo, firmwareList, userList } = this.props;
    console.log(otaListInfo);
    const firmwaresSimpledData = {};
    firmwareList?.forEach((ele) => {
      if (firmwaresSimpledData[ele.firmwareName]) {
        firmwaresSimpledData[ele.firmwareName].push(ele.firmwareVersion);
      } else {
        firmwaresSimpledData[ele.firmwareName] = [ele.firmwareVersion];
      }
    });

    const onSelectChangeProName = (value) => {
      if (value) {
        this.setState({
          eVersionList: [],
          productModelList: otaListInfo[value],
        });
      } else {
        this.setState({
          eVersionList: [],
          productModelList: [],
        });
      }
      this.form?.current.setFieldsValue({
        productModel: undefined,
        epackVersion: undefined,
        epackName: undefined,
      });
      this.props.getNullEpack();
    };
    const onSelectChangeProModle = (value) => {
     
      if (value) {
        otaId = value;
        this.props.getEpack();
      } else {
        this.props.getNullEpack();
      }
      this.form?.current.setFieldsValue({
        epackVersion: undefined,
        epackName: undefined,
      });
      this.setState({
        eVersionList: [],
      });
    };

    const onSelectChangeEName = (value) => {
      if (value) {
        this.setState({
          eVersionList: firmwaresSimpledData[value],
        });
      } else {
        this.setState({
          eVersionList: [],
        });
      }
      this.form?.current.setFieldsValue({
        epackVersion: undefined,
      });
    };
    return (
      <>
        <div className="common-search-form">
          <Form
            ref={this.form}
            name="advanced_search_user"
            className="ant-advanced-search-form"
            onFinish={this.onFinish}
            layout="inline"
          >
            <Form.Item
              name="productName"
              label="产品名称"
              style={{ marginTop: "8px" }}
            >
              <Select
                allowClear
                placeholder="请选择产品名称"
                onChange={onSelectChangeProName}
              >
                {Object.keys(otaListInfo).map((ele) => (
                  <Option value={ele}>{ele}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="productModel"
              label="产品型号"
              style={{ marginTop: "8px" }}
            >
              <Select
                allowClear
                placeholder="请选择产品型号"
                onChange={onSelectChangeProModle}
              >
                {productModelList.map((ele) => (
                  <Option value={ele.id}>{ele.productModel}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="epackName"
              label="epack名称"
              style={{ marginTop: "8px" }}
            >
              <Select
                allowClear
                placeholder="请选择epack名称"
                onChange={onSelectChangeEName}
              >
                {Object.keys(firmwaresSimpledData).map((ele) => (
                  <Option key={ele} value={ele}>
                    {ele}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="epackVersion"
              label="epack版本"
              style={{ marginTop: "8px" }}
            >
              <Select allowClear placeholder="请选择epack版本">
                {eVersionList.map((ele) => (
                  <Option key={ele} value={ele}>
                    {ele}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="username"
              label="使用用户"
              style={{ marginTop: "8px" }}
            >
              <Select allowClear placeholder="请选择使用用户">
                {userList.map((ele) => (
                  <Option key={ele.id} value={ele.id}>
                    {ele.realName}/{ele.username}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item style={{ marginTop: "8px" }}>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
              <Button style={{ margin: "0 9.46px" }} onClick={this.resetForm}>
                重置
              </Button>
            </Form.Item>
          </Form>
        </div>
      </>
    );
  }
}

class EpackExclusiveManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firmwaresUsersList: [],
      otaListInfo: {},
      firmwareList: [],
      userList: [],

      initData:this.props.location?.state,

      isModalVisible: false,
      configIsModalVisible: false,
      devicesList: [],
      loading: true,
      page: 1,
      dataCount: null,
      pageSize: 10,
      actionIds: [],
      labelList: [],
      //  history: require("history").createHashHistory,
    };
  }
  getLabelList() {
    const getLableItem = (labels) => {
      return labels.map((item, index) => {
        return <Option key={item.id}>{item.name}</Option>;
      });
    };
    labelService.getAllLabels().then((res) => {
      if (res.code === 200) {
        const labels = res.labels;
        this.setState({
          labelList: getLableItem(labels),
        });
      }
    });
  }
  showDeleteConfirm(event) {
    confirm({
      title: "确定删除吗?",
      icon: <ExclamationCircleOutlined />,
      content: (
        <p style={{ color: "#ffb051" }}>删除之后不可恢复，请谨慎操作！</p>
      ),
      closable: true,
      okText: "确认",
      cancelText: "取消",
      onOk: () => {
        this.deleteDevice(event.id);
      },
      onCancel() {},
    });
  }
  //调用删除接口
  deleteDevice(id) {
    epackExclusiveManageService
      .deleteFirmwaresUsers(id)
      .then((res) => {
        if (res.code === 200) {
          message.success("删除成功！");
          if (this.state.firmwaresUsersList.length === 1) {
            page = page - 1;
            page = page ? page : 1;
          }
          this.getFirmwaresUsers();
        }
      })
      .catch((error) => {
        error && message.error(error);
      });
  }

  handleOk() {
    this.setState({
      isModalVisible: false,
      configIsModalVisible: false,
    });
  }
  componentDidMount() {
    const obj=this.props.location?.state
    if(obj){
      searchProductName=obj.productName;
      searchProductModel=obj.productModel;
      searchFirmwareName=obj.firmwareName;
      searchVersion=obj.firmwareVersion
    }
    //
    pageSize = 10;
    page = 1;
    this.getLabelList();
    this.getFirmwaresUsers();
    this.getOtaList();
    this.getTenantList();
    //this.getDevices()
  }
  //查询用户列表
  getTenantList() {
    userService.getAllTenantList().then(
      (res) => {
        console.log("所属用户", res);
        if (res.code === 200) {
          const tenants = res.users;
          this.setState({
            userList: tenants,
          });
        }
      },
      (error) => {
        message.error(error);
      }
    );
  }

  getNullEpack() {
    this.setState({
      firmwareList: [],
    });
  }
  //查询epack列表
  getFirmwareList() {
    firmwareService.getFirmwareByOta(otaId, "", "",1,1000,1).then(
      (res) => {
        if (res.code === 200) {
          const lists = res.firmwares;
          console.log("epack列表", lists);
          lists.forEach((element) => {
            element.key = element.id;
          });
          this.setState({
            firmwareList: lists,
          });
        }
      },
      (error) => {
        error && message.error(error);
      }
    );
  }
  //查询产品列表
  getOtaList() {
    otaService.getAllOtaList().then(
      (res) => {
        if (res.code === 200) {
          const products = res.products;
          products.forEach((element) => {
            element.key = element.id;
          });
          this.setState({
            otaListInfo: this.handleProductsList(products),
          });
        }
      },
      (error) => {}
    );
  }

  handleProductsList(val) {
    const otaListInfo_ = {};
    val.forEach((ele) => {
      if (otaListInfo_[ele.productName]) {
        otaListInfo_[ele.productName].push(ele);
      } else {
        otaListInfo_[ele.productName] = [ele];
      }
    });
    return otaListInfo_;
  }

  // 查询epack用户关系列表
  getFirmwaresUsers() {
    this.setState({
      page,
      pageSize,
      loading: true,
    });
    epackExclusiveManageService
      .getFirmwaresUsersList(
        searchProductName,
        searchProductModel,
        searchFirmwareName,
        searchVersion,
        pageSize,
        page,
        searchUserId
      )
      .then(
        (res) => {
          console.log("epack用户关系列表", res);
          if (res.code === 200) {
            this.setState({
              firmwaresUsersList: res.relations,
              dataCount: res.dataCount,
              loading: false,
            });
          }
        },
        (error) => {
          error && message.error(error);
          this.setState({
            loading: false,
          });
        }
      );
  }

  render() {
    const onPageChange = (pageNumber) => {
      page = pageNumber;

      this.getFirmwaresUsers();
    };
    const onShowSizeChange = (current, pageSizeNumber) => {
      pageSize = pageSizeNumber;
      page = 1;

      this.getFirmwaresUsers();
    };
    const registered = () => {
      this.props.history.push("/home/epack-exclusive-manage/add");
    };
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          actionIds: selectedRowKeys,
        });
      },
    };
    const handleState = (text, record) => {
      if (text === -1) {
        return <span>未发布</span>;
      } else if (text === 0) {
        return <span>禁用</span>;
      } else if (text === 1) {
        return <span>已发布</span>;
      }
    };
    const columns = [
      {
        title: "产品名称",
        dataIndex: "productName",
      },
      {
        title: "产品型号",
        dataIndex: "productModel",
      },
      {
        title: "epack名称",
        dataIndex: "firmwareName",
      },
      {
        title: "epack版本",
        dataIndex: "firmwareVersion",
      },
      {
        title: "epack发布状态",
        dataIndex: "firmwareState",
        render: (text, record) => handleState(text, record),
      },
      {
        title: "epack发布时间",
        dataIndex: "firmwareCreateTime",
      },
      {
        title: "使用用户",
        dataIndex: "username",
      },
      {
        title: "用户加入时间",
        dataIndex: "createTime",
      },
      {
        title: "操作",
        dataIndex: "action",
        width: "20%",
        render: (text, record) => (
          <>
            <a href onClick={() => this.showDeleteConfirm(record)}>
              删除
            </a>
          </>
        ),
      },
    ];
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    return (
      <>
        <div className="common-pd20">
          <div>
            <h1 className="common-top-title0">epack专属管理</h1>
            <div className="common-center-container">
              <Search
                onClick={() => this.getFirmwaresUsers()}
                getEpack={() => this.getFirmwareList()}
                getNullEpack={() => this.getNullEpack()}
                currentUser={this.props.currentUser}
                otaListInfo={this.state.otaListInfo}
                labelList={this.state.labelList}
                firmwareList={this.state.firmwareList}
                userList={this.state.userList}

                initData={this.state.initData}
              />
              <div className="oper-button">
                <Button
                  type="primary"
                  onClick={registered}
                  style={{ width: "78px" }}
                >
                  添加用户
                </Button>
              </div>
              <div className="user-table-card">
                <Table
                  // rowSelection={{
                  //   ...rowSelection,
                  // }}
                  rowKey={(record) => record.id}
                  bordered={false}
                  tableLayout="fixed"
                  pagination={false}
                  loading={this.state.loading}
                  columns={columns}
                  dataSource={this.state.firmwaresUsersList}
                />
              </div>
              <div>
                <div className="common-bottom-pagination">
                  <ConfigProvider locale={zhCN}>
                    <Pagination
                      showSizeChanger
                      onShowSizeChange={onShowSizeChange}
                      defaultCurrent={1}
                      current={this.state.page}
                      pageSize={this.state.pageSize}
                      total={this.state.dataCount}
                      onChange={onPageChange}
                    />
                  </ConfigProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect((state) => ({
  currentUser: state.currentUser,
}))(EpackExclusiveManage);
