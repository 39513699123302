import { handleResponse } from './handle-response';
import store from '../../store/index';

export const equipmentService = {
    getDevicesList,
    getDevicesDetail
};

/* 获取设备列表 */
function getDevicesList(name, nodeName, state,userId,labelIds, type, limit, offset) {
    return fetch(`/v1/console/devices?name=${name || ''}&nodeName=${nodeName || ''}&state=${state || ''}&userId${userId || ''}&labelIds=${labelIds || ''}&type=${type || ''}&limit=${limit || 10}&offset=${offset || 1}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}
/* 获取设备详情 */
function getDevicesDetail(id) {
    return fetch(` /v1/console/devices/${id}`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    })
}
 