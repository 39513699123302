import React from 'react';
import './ota-factory.scss';
import { Form, Select, Input, Button, Table, Pagination, ConfigProvider, message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { otaService } from '../../../services/ota.service';
import { BrowserRouter as Router } from 'react-router-dom';
// import CreateOta from './create-ota/create-ota';

import { AUTCONFIG } from '../../../../utils/auth.config';
import { userService } from '../../../services/user.service'
import { connect } from "react-redux";
import './../../../../index.scss';
import { searchRule } from '../../../../utils/search-util'

const { confirm } = Modal;
const { Option } = Select;

let searchName = '';
let searchType = '';
let searchAdminId = '';
let page = 1;
let pageSize = 10;

function Search(props) {
    const [form] = Form.useForm();
    const onFinish = (values) => {
        let name = values.name || '';
        let str = searchRule(name)
        searchName = str || '';

        let type = values.type || '';
        let str2 = searchRule(type)
        searchType = str2 || '';

        searchAdminId = values.adminId || '';
        page = 1;
        props.onClick();
    };
    const resetForm = () => {
        form.resetFields();
        searchName = '';
        searchType = '';
        searchAdminId = '';
        page = 1;
        props.onClick();
    }
    return (
        <>
            <div className="common-search-form">
                <Form
                    form={form}
                    name="advanced_search"
                    layout="inline"
        //   style={{ justifyContent: 'center' }}
                    className="ant-advanced-search-form"
                    onFinish={onFinish} 
                    >
                        <Form.Item name='name' label='产品名称' style={{ marginTop: '8px' }}>
                            <Input placeholder="请输入关键字" />
                        </Form.Item>
                        <Form.Item name='type' label='产品型号' style={{marginTop: '8px' }}>
                            <Input placeholder="请输入关键字" />
                        </Form.Item>
                        {props.currentUser?.type === AUTCONFIG.SUPERADMIN.value ?
                            <Form.Item  name='adminId' label='&nbsp;&nbsp;&nbsp;&nbsp;管理员' style={{marginTop: '8px' }}>
                                <Select
                                    showSearch  allowClear
                                    filterOption={(input, option) =>
                                    option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    placeholder="请选择管理员" >
                                        {
                                            props.adminList.map((item, index) => {
                                                return (
                                                <Option key={item.id} value={item.id}>{item.realName}/{item.username}</Option>
                                                );
                                            })
                                        }
                                
                                </Select>
                            </Form.Item> : ''}
                        <Form.Item style={{ marginTop: '8px' }} >
                            <Button type="primary" htmlType="submit"> 查询 </Button>
                            <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
                        </Form.Item>
                    
                </Form>
            </div>
        </>
    );
}

class OtaFactory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            otaList: [],
            otaAmount: 0,
            showManage: true,
            showRegistered: false,
            loading: true,
            page: 1,
            pageSize: 10,
            adminList: [],
           // history: require("history").createHashHistory,
        };
    }
    searchList() {
        this.setState({
            loading: true,
            page,
            pageSize
        })
        otaService.getOtaList(searchName.replace(/(^\s*)|(\s*$)/g,''),
         searchType.replace(/(^\s*)|(\s*$)/g,''), page, pageSize, searchAdminId).then(res => {
            if (res.code === 200) {
                const products = res.products;
                products.forEach(element => {
                    element.key = element.id;
                });
                this.setState({
                    otaList: products,
                    otaAmount: res.dataCount,
                    loading: false
                });
            } 
            // else {
            //     message.error('获取产品列表失败');
            //     this.setState({
            //         loading: false
            //     })
            // }
        }, error => {
            error && message.error(error);
            this.setState({
                loading: false
            })
        });
    }
    componentDidMount() {
        searchName = '';
        searchType = '';
        page = 1;
        pageSize = 10;
        searchAdminId = '';
        this.searchList();
        this.getUserList();
    }
    printContent(comment) {
        this.setState({
            showManage: true,
            showRegistered: false,
        });
        if (comment.showManage) {
            this.searchList();
        }
    }
    getUserList() {
        userService.getAllUsers().then(item => {
          
            const adminList = item.users.filter(elx => {
                return elx.type === AUTCONFIG.SUPERADMIN.value 
                      || elx.type === AUTCONFIG.ADMIN.value;
            })
            this.setState({
                adminList
            })
           
        })
    }

    gotoDetail = (text, key) => {
        this.props.history.push({ pathname: '/home/ota-factory/view/detail', state: { id: text } });
    }
    gotoEdit = (text, key) => {
        this.props.history.push({ pathname: '/home/ota-factory/edit/detail', state: { id: text } });
    }
    showDeleteConfirm(event) {
        confirm({
            title: '确定删除吗?',
            icon: <ExclamationCircleOutlined />,
            content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
            closable: true,
            onOk: () => {
                otaService.removeOta(event).then(res => {
                    if (res.code === 200) {
                        message.success('删除成功');
                        page = 1;
                        this.searchList();
                    } 
                    // else {
                    //     message.error('删除失败，' + res.message);
                    // }
                }).catch(error => {
                    error && message.error(error);
                })
            },
            onCancel() {

            },
        });
    }
    goToTestShell(key) {
        this.props.history.push({ pathname: '/home/ota-factory/test-shell', state: { id: key } });
    }
    goToTestRecord(key) {
        this.props.history.push({ pathname: '/home/ota-factory/test-record', state: { id: key } });
    }
    render() {
        const columns = [
            {
                title: '产品名称',
                dataIndex: 'productName',
                render: (text, record) => <a href onClick={this.gotoDetail.bind(text, record.key)}>{text}</a>,
            },
            {
                title: '产品型号',
                dataIndex: 'productModel',
            },
            {
                title: 'epack数目',
                dataIndex: 'firmwareNum',
            },
            {
                title: '描述',
                dataIndex: 'description',
                width: '15%'
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
            },
            {
                title: '操作',
                dataIndex: 'action',
                render: (text, record) => (
                    <>
                     <a href onClick={() => this.goToTestShell(record.key)} style={{marginRight: 5}}>测试脚本</a>
                     <a href onClick={() => this.goToTestRecord(record.key)} style={{marginRight: 8}}>测试记录</a>
                     {/* <a href onClick={this.gotoEdit.bind(text, record.key)} style={{marginRight: 8}}>编辑</a>
                     <a href onClick={() => this.showDeleteConfirm(record.key)}>删除</a> */}
                  
                    </>
                )
            },
        ];
        const columnsys = [
            {
                title: '产品名称',
                dataIndex: 'productName',
                render: (text, record) => <a href onClick={this.gotoDetail.bind(text, record.key)}>{text}</a>,
            },
            {
                title: '产品型号',
                dataIndex: 'productModel',
            },
            {
                title: 'epack数目',
                dataIndex: 'firmwareNum',
            },
            {
                title: '描述',
                dataIndex: 'description',
                width: '15%'
            },
            {
                title: '管理员',
                dataIndex: 'username',
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
            },
            {
                title: '操作',
                dataIndex: 'action',
                render: (text, record) => (
                    <>
                     <a href onClick={() => this.goToTestShell(record.key)} style={{marginRight: 5}}>测试脚本</a>
                     <a href onClick={() => this.goToTestRecord(record.key)} style={{marginRight: 8}}>测试记录</a>
                     <a href onClick={this.gotoEdit.bind(text, record.key)} style={{marginRight: 8}}>编辑</a>
                     <a href onClick={() => this.showDeleteConfirm(record.key)}>删除</a>
                  
                    </>
                )
            },
        ];
        const onPageChange = (pageNumber,ps) => {
            page = pageNumber;
            if(ps!=pageSize) {
              page = 1
            }
            pageSize = ps
            this.searchList();
          }
          const registered = () => {
            this.props.history.push({ pathname: '/home/ota-factory/create' });
            // this.setState({
            //   showManage: false,
            //   showRegistered: true,
            // })
          };

        return (
            <>
                <div className="common-pd20">
                    <div style={{ display: (this.state.showManage === true) ? "block" : "none" }}>
                        <h1 className="common-top-title0">产品管理</h1>
                        <div className="common-center-container">
                            <Search onClick={() => this.searchList()} adminList={this.state.adminList} currentUser={this.props.currentUser}/>
                            <div className="oper-button">
                            {this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value ?
                                <Router>
                                    <Button type="primary" onClick={registered} style={{ width: 88 }}>  新增产品  </Button>
                                </Router> : ''}
                            </div>
                            <div className="user-table-card">
                                {this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value ? 
                                <Table
                                    rowKey={record => record.id}
                                    bordered={false}
                                    pagination={false}
                                    columns={columnsys}
                                    dataSource={this.state.otaList}
                                    loading={this.state.loading}
                                />
                                    :   <Table
                                    rowKey={record => record.id}
                                    bordered={false}
                                    pagination={false}
                                    columns={columns}
                                    dataSource={this.state.otaList}
                                    loading={this.state.loading}
                                />}
                            </div>
                            <div>
                                {
                                    this.state.otaList.length > 0 &&
                                    <div className="common-bottom-pagination">
                                        <ConfigProvider locale={zhCN}>
                                            <Pagination showSizeChanger 
                                            defaultCurrent={1} 
                                            current={this.state.page} 
                                            pageSize={this.state.pageSize} 
                                            total={this.state.otaAmount} 
                                            onChange={onPageChange} />
                                        </ConfigProvider>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {/* <div style={{ display: (this.state.showRegistered === true) ? "block" : "none" }}>
                        <CreateOta onSubmit={this.printContent.bind(this)} />
                    </div> */}
                </div>
            </>
        );
    }
}
// export default OtaFactory;


export default connect(
    (state) => ({
      currentUser: state.currentUser
    })
  )(OtaFactory);