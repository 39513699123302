import React from 'react';
import { Select, Form, Col, Input, Button, Table, Pagination, ConfigProvider, Modal, message, Switch, Space, Menu, Dropdown, Radio, TreeSelect } from 'antd';
import { ExclamationCircleOutlined , PlusOutlined, DownOutlined, AppstoreTwoTone , SettingTwoTone } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from 'react-router-dom';
import { menuService } from '../../../../services/menu.service'
import './../../../../../index.scss';
import './menu-manage.scss'
import { connect } from "react-redux";
import store from '../../../../../store';
import { AUTCONFIG } from '../../../../../utils/auth.config';

const { Option } = Select;
const { TreeNode } = TreeSelect;
const { confirm } = Modal;

let flag = false //用来判断添加操作时，是否是往功能里添加菜单（菜单下可添加菜单与功能，功能下仅可以添加功能）

class MenuManage extends React.Component{
  formRef = React.createRef();
    state = {
        // checkStrictly: false,
        // setCheckStrictly: false,
      resources: [],//菜单数组
      actions: [],//功能数组
      treeData: [],//下拉菜单数组列表
      isModalVisible: false, //单个添加菜单是否可见
      isAllModalVisible: false, //总添加菜单是否可见
      isEditMenu: false,//修改菜单弹窗是否可见
      menuItems: [],//功能菜单列表
      value: 0,//默认的单选框选择
      valueAll: 0,//默认的单选框选择
      valueTree: undefined,//树形下拉值的选择
      parentName: '',//父级菜单名称
      parentId: 0,//父级菜单id

      parentEditName: '',//修改操作时的父级菜单名称
      urlEdit: '',//修改操作保存的url
      nameEdit: '',//修改操作保存的名字
      typeEdit: 0,//修改操作保存的类型
      parentIdEdit: 0,//修改操作保存的父级id
      idEdit: 0,//修改应用的id
      loading: true,
    }

    componentDidMount() {
      this.getMenuList();
      this.getMenuActions();
      //this.getSelectMenuList()
    }

    //合并树，向arr1里加入单个对象
    handleData (data, obj) {
      data.forEach(item => {
        if (item.id === obj.id) {
          item.childResource ? item.childResource.push(...obj.childResource) : item.childResource = [...obj.childResource]
        } else {
          if (item.childResource) {
            this.handleData(item.childResource, obj)
          }
        }
      })
      return data
    }

    //合并树，向arr1里加入arr2
    mergeTree(arr1, arr2) {
      arr2.forEach(item => {
        // console.log(JSON.stringify(this.handleData(arr1, item)));
        this.handleData(arr1, item)
      }) 
    }
  
    //调用菜单接口
    getMenuList() {
        let resources = []
        menuService.getMenus().then(res => {
            if (res.code === 200) {
                console.log("菜单信息",res);
              //resources = this.filterTree(res.resources)
              resources = res.resources
              console.log(resources);

              menuService.getAllMenus().then(res => {
                if (res.code === 200) {
                  console.log("菜单功能信息", res);
                  let actions = res.resources
                  this.mergeTree(resources, actions)
                  let newReources = this.filterTree(resources)
                  console.log(newReources);

                  //调用总的菜单接口
                  menuService.getMenus().then(res => {
                    if (res.code === 200) {
                        console.log("下拉菜单信息",res);
                      let treeData1 = this.filterTree(res.resources)
                      let treeData2 = this.handleTreeData(treeData1)
        
                      let treeData = []
                      let obj = {}
                      obj.title = "主目录";
                      obj.value = '';
                      obj.id = '';
                      //obj.children = treeData2
                      obj.children = this.handleTreeData(newReources)
                      treeData.push(obj)
                      console.log(treeData);
        
                        this.setState({
                          treeData
                        })
                    }
                })

                  this.setState({
                    actions,
                    resources: newReources,
                    loading: false
                  })
                }
              })
                // this.setState({
                //     resources,
                // })
            }
        }).catch(error => {
          error && message.error(error);
          this.setState({
            loading: false
          });  
        })
        
  }
  
    //调用总的菜单接口
    getSelectMenuList() {
        
        
    }
    
    //查询菜单相关功能接口
    getMenuActions(){
      menuService.getAllMenus().then(res => {
        if (res.code === 200) {
          console.log("菜单功能信息", res);
          let actions = res.resources
          this.setState({
            actions
          })
        }
      })
    }
    
    //添加菜单接口
    addMenu(name, url, type, parentId, odr){
      menuService.addMenu(name, url, type, parentId, odr).then(res => {
        if (res.code === 200) {
          console.log(res);
          message.success('创建成功');
            this.getMenuList();
            //window.location.reload(true); //刷新页面
          }
        }).catch(error => {
          error && message.error(error);
        })
  }
  
    //修改菜单接口
    editMenu(id, name, url, type, parentId, odr) {
      menuService.editMenu(id, name, url, type, parentId, odr).then(res => {
        if (res.code === 200) {
          console.log(res);
          this.getMenuList();
        }
        }).catch(error => {
          error && message.error(error);
        })
  }
  
    //删除菜单接口
    deleteMenu(id) {
      menuService.deleteMenu(id).then(res => {
        if (res.code === 200) {
          console.log(res);
          this.getMenuList();
          //window.location.reload(true); //刷新页面
        }
        }).catch(error => {
          error && message.error(error);
        })
    }
  
    //去除childResource为空的情况
    filterTree (data) {
      //var newTree = data.filter(x => JSON.stringify(x.children) !== "[]")
      data.forEach(x => {
         if(JSON.stringify(x?.childResource) === "[]"){
            delete x.childResource
         }
      })
      console.log(data);
   
      data.forEach(x => x.childResource && (x.childResource = this.filterTree(x.childResource)))
      return data
    }
    
    //形成树形下拉选择数组
    handleTreeData = (data) => {
      const item = [];

      data.map((list) => {
        const newData = {};
        newData.value = list.id;
        newData.id = list.id
        newData.title = list.name;

        newData.children = list.childResource ? this.handleTreeData(list.childResource) : []; // 如果还有子集，就再次调用自己
        item.push(newData);
        return false
      });
      return item;

    }

    //添加菜单
  showAddMenu(record) {
      console.log(record);
      flag = false
      if(record.type===1){
        flag = true
      }
      
      this.formRef.current?.setFieldsValue({
        parentName: record.name,
      })
      console.log(flag);
      
      this.setState({
        isModalVisible: true,
        parentName: record.name,
        parentId: record.id
      })
  }

  //总的添加菜单的执行
  showAllAddMenu() {
    this.setState({
      isAllModalVisible: true,
    })
  }

  //根据parentId,找到父级id,返回父级名称
  getTreeName(list,id){
    let _this=this
   for (let i = 0; i < list.length; i++) {
     let a=list[i]
     if(a.id===id){
       return a.name
     }else{
       if(a.childResource && a.childResource.length>0){
         let res = _this.getTreeName(a.childResource, id)
         if(res){
           return res
         }
       }
     }
   }
 }
  
  //修改菜单
  showEditMenu(record) {
    console.log(record);
    console.log(this.state.parentIdEdit);
    console.log(this.state.resources);

    let nameData = ''
    if(record.parentId===null){
      nameData = '主目录'
    } else {
      nameData = this.getTreeName(this.state.resources, record.parentId)
    }
    this.formRef.current?.setFieldsValue({
      parentEditName: nameData,
      typeEdit: record.type,
      nameEdit: record.name,
      urlEdit: record.url
    })
    this.setState({
      isEditMenu: true,
      parentEditName: nameData,
      typeEdit: record.type,
      nameEdit: record.name,
      urlEdit: record.url,
      parentIdEdit: record.parentId,
      idEdit: record.id,
    })
  }

  //删除菜单
  showDeleteMenu(record) {
    console.log(record);
    confirm({
      title: '确定删除吗?',
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        //调用删除接口
        this.deleteMenu(record.id)
        message.success('操作成功！删除中');
      },
      onCancel() {

      },
    });
    //this.deleteMenu(record.id)
    
  }

  //取消按钮关闭弹窗
  handleOk() {
    this.setState({
        isModalVisible: false,
        isAllModalVisible: false,
        isEditMenu: false,
    })
  }

  //找到有操作功能的菜单id
  findId(arr, id) {
    for (let i = 0; i < arr.length; i++){
      if (arr[i].id === id) {
        return true
      }
    }
    return false
  }
  
  //  menu = (
  //   <Menu>
  //     <Menu.Item key="0">
  //       升级版本
  //     </Menu.Item>
  //     <Menu.Item key="1">
  //       创建升级任务
  //     </Menu.Item>
  //     {/* <Menu.Divider /> */}
  //      <Menu.Item key="3">
  //      查询升级任务
  //      </Menu.Item>
  //   </Menu>
  // );
   

  //组成功能菜单列表
  getItem(id) {
    console.log(id);
    console.log(this.state.actions);
    let newActions =  this.state.actions.find(item =>
      item.id === id)
    console.log(newActions);
    let menuItems = []
    newActions.childResource.forEach(item => {
      menuItems.push(
        <Menu.Item key={item.id}  >
            {item.name}
        </Menu.Item>
      )
    })
    this.setState({
      menuItems,
    })

  }

  // getOperByRecordId(text, record) {
  //   return (
  //   <>
  //       <a href onClick={() => this.showAddMenu(record)}  style={{ marginRight: '5px' }}>添加</a> 
  //       <a href   style={{ marginRight: '5px' }}>修改</a>
  //       <a href style={{ marginRight: '5px' }}>删除</a>
  //       {
  //         this.findId(this.state.actions, record.id)?
  //           // <a href style={{ marginRight: '5px' }}>相关功能操作</a>
  //           <Dropdown overlay={this.menu}  trigger={['click']} >
  //             <a className="ant-dropdown-link" onClick={()=>this.getItem(record.id)}>
  //             相关功能操作 <DownOutlined />
  //             </a>
  //           </Dropdown>
  //           :''
  //       }
        
  //     </>
  //   );    
  // }

  getType(record) {
    if (record.type === 0) {
      return <span><AppstoreTwoTone  /> 菜单</span>
    } else if (record.type === 1) {
      return <span><SettingTwoTone  twoToneColor="#eb2f96"/> 功能</span>
    }
  }

  //单选按钮改变
  onChange = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value,
    });
  };

  //单选按钮改变
  onChangeAll = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      valueAll: e.target.value,
    });
  };

  //获取树形下拉框的值
  getValue = value => {
    console.log(value);
    this.setState({
      valueTree: value
    });
  };


    render() {
        console.log(this.state.resources);
        //const { checkStrictly, setCheckStrictly } = this.state;
        //const [checkStrictly, setCheckStrictly] = React.useState(false);
        
        const menu = (
          <Menu>
            {this.state.menuItems}
          </Menu>
        );
      
        const columns = [
            {
              title: 'name',
                dataIndex: 'name',
              key: 'name'
            },
            {
              title: '类型',
              dataIndex: 'type',
              width: '20%',
              render: (text, record) => (
                this.getType(record)
              )
            },
            {
              title: 'url',
              dataIndex: 'url',
                width: '30%',
              //key: 'id'
            },
            {
              title: '操作',
              dataIndex: 'action',
              width: '20%',
              //参数分别为当前行的值，当前行数据，行索引
              render: (text, record) => (
                <>
                   {getOperByRecordId(text, record)}
                   
                </>
              )
            },
      ];
      

      const getOperByRecordId = (text, record)=>{
        return (
          <>
              <a href onClick={() => this.showAddMenu(record)}  style={{ marginRight: '5px' }}>添加</a> 
              <a href onClick={() => this.showEditMenu(record)}  style={{ marginRight: '5px' }}>修改</a>
              <a href onClick={() => this.showDeleteMenu(record)} style={{ marginRight: '5px' }}>删除</a>
              {
                this.findId(this.state.actions, record.id)?
                  // <a href style={{ marginRight: '5px' }}>相关功能操作</a>
                  <Dropdown overlay={menu}  trigger={['click']} >
                    <a className="ant-dropdown-link" onClick={()=>this.getItem(record.id)}>
                    相关功能操作 <DownOutlined />
                    </a>
                  </Dropdown>
                  :''
              }
              
            </>
          );    
      }
          
        
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
              console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected, selectedRows) => {
              console.log(record, selected, selectedRows);
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              console.log(selected, selectedRows, changeRows);
            },
          };

          const layout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
      };
      
      //添加完成函数
      const onFinish = (e) => {
        e.parentName = this.state.parentName
        console.log(e);
        console.log(this.state.parentId);
        if (flag && e.type === 0) {
          message.warning("功能下面只能添加功能")
        } else {
          this.addMenu(e.menuName, e.url, e.type, this.state.parentId, '')
        }

        this.setState({
          isModalVisible: false
          })
      }

      //添加带有主目录的完成函数
      const onFinishAll = (e) => {
        //e.parentName = this.state.parentName
        console.log(e);
        console.log(e.parentNameAll);
        if (e.parentNameAll === '主目录') {
          e.parentNameAll = null
        }
        console.log(e.parentNameAll);
        this.addMenu(e.menuNameAll, e.urlAll, e.typeAll, e.parentNameAll, '')

        this.setState({
          isAllModalVisible: false
          })
      }

      //修改完成函数
      const onFinishEdit = (e) => {
        //e.parentName = this.state.parentName
        console.log(e);
        console.log(this.state.parentIdEdit);
        console.log(this.state.idEdit);
        console.log(this.state.valueTree);
        if (typeof this.state.valueTree === 'undefined') {
          let newParentIdEdit = null
          if (this.state.parentIdEdit === 0) {
            console.log(newParentIdEdit);
            this.editMenu(this.state.idEdit, e.nameEdit, e.urlEdit, e.typeEdit, newParentIdEdit, '')
          }
          this.editMenu(this.state.idEdit, e.nameEdit, e.urlEdit, e.typeEdit, this.state.parentIdEdit, '')
        } else {
          this.editMenu(this.state.idEdit, e.nameEdit, e.urlEdit, e.typeEdit, this.state.valueTree, '')
        }
        

        this.setState({
          isEditMenu: false,
          valueTree: undefined,
          })
      }
      
        return (
          <div className="common-pd20">
            <h1 className="common-top-title0">菜单管理</h1>
                <div className="common-center-container">

                    <div className="oper-button">
                <Button type="primary" onClick={() => this.showAllAddMenu()}> 新增</Button>
                    </div>

                    <div className="user-table-card">
                        <Table
                            rowKey={record=>record.id}
                            columns={columns}
                            //rowSelection={{...rowSelection}}
                            rowSelection={rowSelection}
                            dataSource={this.state.resources}
                            childrenColumnName="childResource"
                            loading={this.state.loading}
                            //pagination={false}    //分页器
                        />
                    </div> 
            </div>
            


          <Modal title="添加菜单" visible={this.state.isModalVisible} cancelText okText closable={false}>
          {/* <Modal title="添加菜单" visible={true} cancelText okText closable={false}> */}
          <Form {...layout}
            ref={this.formRef}
            name="control-ref"
            onFinish={onFinish}
            initialValues={{ parentName: "male", type: 0, version: this.state.imageVersion }}
          >
             
            <Form.Item name="parentName" label="父级菜单">
            <Select disabled style={{width: 220}}>
                    <Option value="male">{this.state.parentName}</Option>
                </Select>
            </Form.Item>
            
            <Form.Item name="type" label="资源类型" >
              <Radio.Group onChange={this.onChange} value={this.state.value}>
                <Radio value={0}>菜单</Radio>
                <Radio value={1}>功能</Radio>
                
              </Radio.Group>
            </Form.Item>
            
            <Form.Item name="menuName" label="资源名称">
                <Input></Input>
            </Form.Item>
            
            <Form.Item name="url" label="资源路径" >
                <Input></Input>
            </Form.Item>
            
            <br/>
              <Form.Item  style={{marginLeft:280,marginBottom:-70}}>
                <Button type="primary" htmlType="submit"    > 确定 </Button>
                <Button onClick={this.handleOk.bind(this)} style={{marginLeft:10}} >取消</Button>
                </Form.Item>
                </Form>
            </Modal>
            
          {/* 总的添加菜单操作 */}
          <Modal title="添加菜单" visible={this.state.isAllModalVisible} cancelText okText closable={false}>
          {/* <Modal title="添加菜单" visible={true} cancelText okText closable={false}> */}
          <Form {...layout}
            ref={this.formRef}
            name="control-ref"
            onFinish={onFinishAll}
            initialValues={{ parentNameAll: "主目录", typeAll: 0, version: this.state.imageVersion }}
          >
             
            <Form.Item name="parentNameAll" label="父级菜单">
              <TreeSelect
                showSearch
                style={{ width: '100%' }}
                //value={this.state.valueAll}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="请选择上级菜单"
                allowClear
                treeDefaultExpandAll={false}
                dropdownMatchSelectWidth={false}
                //virtual={false}
                onChange={this.getValue}
                treeData={this.state.treeData}
                treeNodeFilterProp="name" 
              >     
              </TreeSelect>
            </Form.Item>
            
            <Form.Item name="typeAll" label="资源类型" >
              <Radio.Group onChange={this.onChangeAll} value={this.state.valueAll}>
                <Radio value={0}>菜单</Radio>
                <Radio value={1}>功能</Radio>
                
              </Radio.Group>
            </Form.Item>
            
            <Form.Item name="menuNameAll" label="资源名称">
                <Input></Input>
            </Form.Item>
            
            <Form.Item name="urlAll" label="资源路径" >
                <Input></Input>
            </Form.Item>
            
            <br/>
              <Form.Item  style={{marginLeft:280,marginBottom:-70}}>
                <Button type="primary" htmlType="submit"    > 确定 </Button>
                <Button onClick={this.handleOk.bind(this)} style={{marginLeft:10}} >取消</Button>
                </Form.Item>
                </Form>
            </Modal>
            
            {/* 修改菜单 */}
            <Modal title="修改菜单" visible={this.state.isEditMenu} cancelText okText closable={false}>
          <Form {...layout}
            ref={this.formRef}
            name="control-ref"
            onFinish={onFinishEdit}
            initialValues={{ parentEditName: this.state.parentEditName, typeEdit: this.state.typeEdit, nameEdit: this.state.nameEdit, urlEdit: this.state.urlEdit }}
          >
             
             <Form.Item name="parentEditName" label="父级菜单">
              <TreeSelect
                showSearch
                style={{ width: '100%' }}
                //value={this.state.valueAll}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="请选择上级菜单"
                allowClear
                treeDefaultExpandAll={false}
                dropdownMatchSelectWidth={false}
                //virtual={false}
                onChange={this.getValue}
                treeData={this.state.treeData}
                treeNodeFilterProp="name" 
              >     
              </TreeSelect>
            </Form.Item>
            
            <Form.Item name="typeEdit" label="资源类型" >
              <Radio.Group onChange={this.onChange} value={this.state.value}>
                <Radio value={0}>菜单</Radio>
                <Radio value={1}>功能</Radio>
                
              </Radio.Group>
            </Form.Item>
            
            <Form.Item name="nameEdit" label="资源名称">
                <Input></Input>
            </Form.Item>
            
            <Form.Item name="urlEdit" label="资源路径" >
                <Input></Input>
            </Form.Item>
            
            <br/>
              <Form.Item  style={{marginLeft:280,marginBottom:-70}}>
                <Button type="primary" htmlType="submit"    > 确定 </Button>
                <Button onClick={this.handleOk.bind(this)} style={{marginLeft:10}} >取消</Button>
                </Form.Item>
                </Form>
            </Modal>
            </div>
       
        );
    }
}

export default connect(
    (state) => ({
      currentUser: state.currentUser
    })
  )(MenuManage);