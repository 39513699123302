/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Form, Input, Select, message, Row, Col, InputNumber,     Card , Divider  } from 'antd';
import './../../../../../../index.scss';

import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';

import {connect} from 'react-redux'
const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

class CreateEdgeEffectManagement extends React.Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      showManage: true,
      hasSubmit: false
    };
  }
  goback() {
   
    this.props.history.push('/home/effect-edge-m/list');
  }
  onSelectTypeChange(value) {
    console.log(value)
  }
  componentDidMount() {
    
  }
  
  render() {
    const onFinish = (values) => {
        message.success('创建应用成功');
        this.goback();
    };
    return (
      <>
        <div className="common-pd20">
        <BackPage onClick={() => this.goback()} headeTitle="创建应用" itemTitle="应用管理" itemSubTitle="创建用户" />
        <div className="common-create-center-container">
         
          <div className="common-create-page">
          
       
            <Form
              ref={this.formRef}
              name="validate_other"
              labelAlign="left"
              {...formItemLayout}
              onFinish={onFinish} >
              <Form.Item name="userName" label="应用名称" rules={[{ required: true, message: '请输入应用名称' }]} >
                <Input placeholder="请输入应用名称" />
              </Form.Item>
              <Form.Item name="description" label="描述" >
                    <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" />
                </Form.Item>
              <Form.Item name="name" label="资源配置" rules={[{ required: true, message: '请输入资源配置' }]}>
                  <Row gutter={16}>
                      <Col span={8}>CPU&nbsp;&nbsp;&nbsp;<InputNumber min={1} max={100000} defaultValue={1}  />&nbsp;&nbsp;&nbsp; 个</Col>
                      <Col span={8}>GPU &nbsp;&nbsp;&nbsp;<InputNumber min={1} max={100000} defaultValue={1}  />&nbsp;&nbsp;&nbsp; 个</Col>
                      <Col span={8}>内存&nbsp;&nbsp;&nbsp; <InputNumber min={1} max={100000} defaultValue={1}  /> &nbsp;&nbsp;&nbsp;G</Col>
                  </Row>
                     
              </Form.Item>
              <Form.Item name="gender" label="应用镜像">
                <Select
                placeholder="请选择应用镜像"
                      initialvalue="male"  >
                    <Option value="male">unbantu-app</Option>
                    <Option value="female">linux</Option>
                    <Option value="other">other</Option>
                </Select>
                </Form.Item>
                <Form.Item name="version" label="镜像版本" >
                <Select
                    initialvalue="male" placeholder="请选择镜像版本">
                    <Option value="male">18.12</Option>
                    <Option value="female">20.12</Option>
                    <Option value="other">13.15</Option>
                </Select>
                </Form.Item>
          
            
              <BottomBtnGroup onClick={() => this.goback()} />
           
            </Form>
        
       
          </div>
        </div>
        </div>
      </>
    );
  }
}

//export default CreateUser;


export default connect(
    (state) => ({
        currentUser: state.currentUser
    })
)(CreateEdgeEffectManagement);