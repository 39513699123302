import React from 'react';
import { Card, Form, Input, Button, Select, Cascader, Row, Col, Table, Tag, Space, Breadcrumb, InputNumber, message } from 'antd';
import './../../../../../../index.scss';
import BackPage from '../../../../common/backPage';
import { labelService } from '../../../../../services/label.service';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';


const { TextArea } = Input;


const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

class CreateLabel extends React.Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      showManage: true,
      hasSubmit: false,
    };
  }
  goback() {
    // this.formRef.current.resetFields();
    // if (this.props.onSubmit) {
    //   var { showManage } = this.state;
    //   this.props.onSubmit({ showManage })
    // }
    this.props.history.push('/home/sys/label-manage');
  }
  componentDidMount() {
    this.setState({
        hasSubmit: false
    })
  }
  render() {
    const onFinish = (values) => {
        if(this.state.hasSubmit) {
            return ;
        }
        this.setState({
            hasSubmit: true
        })
      message.loading('标签创建中~~', 2500);
      labelService.addLabel(values.name, values.code, values.description).then(res => {
        message.destroy();
        this.setState({
            hasSubmit: false
        })
        if (res.code === 200) {
          message.success('创建标签成功');
          this.goback();
        } 
        // else {
        //   message.success('创建标签失败，' + res.message);
        // }
      }, error => {
        message.destroy();
        this.setState({
            hasSubmit: false
        })
        error && message.error(error);
      });
    };


    return (
      <>
         <div className="common-pd20">
        <BackPage onClick={() => this.goback()} headeTitle="创建标签" itemTitle="标签管理" itemSubTitle="创建标签" />
        <div className="common-create-center-container">
          <div className="common-create-page">
            <Form
              ref={this.formRef}
              name="validate_other"
              labelAlign="left"
              {...formItemLayout}
              onFinish={onFinish} >
              <Form.Item name="name" label="标签名称" rules={[
                { required: true, message: '请输入标签名称' },
                {max: 64, message: '标签名称不允许超过64个字符'}, 
                { pattern: new RegExp(/^[0-9a-zA-Z-_\u4e00-\u9fa5]{1,}$/, "g") , message: '请输入64位以内中英文/数字/中划线/下划线'}]}>
                <Input placeholder="请输入标签名称" />
              </Form.Item>
              {/* <Form.Item name="code" label="标签编码" rules={[{ required: true, message: '请输入标签编码' }]}>
                <Input placeholder="请输入标签编码" />
              </Form.Item> */}
              <Form.Item name="description" label="描述"  rules={[{ pattern: /^(a-z|A-Z|0-9)*[^$%^&*;:,<>?()\""\']{0,255}$/, message: '请输入255位以内字符' }]}>
                <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" />
              </Form.Item>

              <BottomBtnGroup onClick={() => this.goback()} />
            </Form>
          </div>
        </div>
        </div>
      </>
    );
  }
}

export default CreateLabel;