import React from 'react';
import { Breadcrumb } from 'antd';

class BackPage extends React.Component {
    goBack = () => {
        this.props.onClick();
    }
    render() {
        return (
            <>
                <div className="top-title">
                    <div onClick={this.goBack.bind(this)} style={{ cursor: 'pointer' }} className="common-top-title0">  <img src="/images/vector.png" style={{ width: '5px', height: '8px', marginRight: '15.73px' }} alt="" />{this.props.headeTitle || '返回'}</div>
                    {/* <Breadcrumb>
                        <Breadcrumb.Item>系统管理</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <a href onClick={this.goBack.bind(this)}>{this.props.itemTitle || ''}</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{this.props.itemSubTitle || ''}</Breadcrumb.Item>
                    </Breadcrumb> */}
                </div>
            </>
        );
    }
}
export default BackPage