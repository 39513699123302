import {
    handleResponse
} from './handle-response';
import store from '../../store/index';

export const epackExclusiveManageService = {
    getFirmwaresUsersList,
    addFirmwaresUsers,
    deleteFirmwaresUsers
}

//1.1.	查询epack用户关系列表
function getFirmwaresUsersList(productName,
    productModel,
    firmwareName,
    version,
    limit,
    offset,
    userId,
) {
    return fetch(`/v1/console/products/firmwares/users?productName=${productName || ''}&productModel=${productModel|| ''}&firmwareName=${firmwareName || ''}&version=${version || ''}&limit=${limit || 10}&offset=${offset || 1}&userId=${userId || ''}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}


//1.2.	新增epack用户关系
function addFirmwaresUsers(firmwareId,userIds) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            firmwareId,
            userIds
        })
    };
    return fetch(`/v1/console/products/firmwares/users`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}
//	1.3.	删除epack用户关系
function deleteFirmwaresUsers(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/products/firmwares/users/${id}`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}