import { Button, Card, Form, Input, message } from 'antd';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './safe-setting.scss';
import { loginService } from '../../../../services/login';
import store from '../../../../../store';
import { getTokenChangeAction } from '../../../../../store/actionCreators';
const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 13 },
};

class SafeSetting extends React.Component {
    constructor(props) {
        super(props);
        // console.log(props)
        this.state = {
            history: require("history").createHashHistory,
        }
    }
    componentDidMount() {
      //  console.log(this.props.key)

    }
    goBack() {
        this.props.history.push( sessionStorage.getItem('lastPath') || '/home/first-page')
    }
    onFinish = (values) => {
        if (values.newPass !== values.confirmPass) {
            message.error('新密码两次不一致');
            return;
        }
        if (values.newPass === values.oldPass) {
            message.error('新密码与旧密码不能重复');
            return;
        }
        loginService.changePassword(values.oldPass, values.newPass).then(res => {
            if (res.code === 200) {
                message.success("修改密码成功，请使用新密码重新登录");
                localStorage.clear();
                const action = getTokenChangeAction('');
                store.dispatch(action);
            } 
            // else {
            //     message.error("修改密码失败" + res.message);
            // }
        }, error => {
            message.error('修改密码失败，' + error);
        });
    };

    render() {
        const  regexp = /^(?![a-z]+$)(?![A-Z]+$)(?!\d+$)(?![!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]{6,23}$/;
        const lengReg = /^\S{6,23}$/;
        return (
            <>
                <Form
                    name="validate_other"
                    labelAlign="left"
                    {...formItemLayout}
                    onFinish={this.onFinish}
                    initialValues={{
                        'input-number': 3,
                        'checkbox-group': ['A', 'B'],
                        rate: 3.5,
                    }}
                >
                    <Form.Item name="oldPass" label="原密码" rules={[{ required: true, message: '请输入原密码' }]} >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item name="newPass" label="新密码"  dependencies={['oldPass']}  rules={[
                        { required: true, message: '请输入新密码' },
                        { pattern: /^(?![a-z]+$)(?![A-Z]+$)(?!\d+$)(?![!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]{6,23}$/, message: '6-23位，至少包含以下两种：大写字母、小写字母、数字、特殊字符，且不能输入中文与空格' },
 
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('oldPass') !== value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('原密码与新密码不能相同！'));
                            },
                        })
                        ]} >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item name="confirmPass"   dependencies={['newPass']} label="确认密码" rules={[
                        { required: true, message: '请输入新密码' },
                        { pattern: /^(?![a-z]+$)(?![A-Z]+$)(?!\d+$)(?![!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]{6,23}$/, 
                        message: '6-23位，至少包含以下两种：大写字母、小写字母、数字、特殊字符，且不能输入中文与空格' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPass') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('确认密码与新密码保持一致！'));
                            },
                        })]} >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 12, offset: 3 }}>
                        <Button type="primary" htmlType="submit"> 确定 </Button>
                        <Button style={{ marginLeft: 24 }} onClick={() => this.goBack()}>取消</Button>
                    </Form.Item>
                </Form>
            </>
        );
    }
}

export default SafeSetting;