import React from 'react';
import { Form, Input, Col, Row, Tabs, Button, Table, Divider, message, Select, Tag, Spin } from 'antd';
import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';
import { nodeService } from '../../../../../services/node.service';
import { labelService } from '../../../../../services/label.service';
import './view-node.scss';
import { AUTCONFIG } from '../../../../../../utils/auth.config';
import { connect } from 'react-redux';

const { TabPane } = Tabs;

const { TextArea } = Input;
const { Option } = Select;
const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 20 },
};
const formItemLayout2 = {
    labelCol: { span: 4 },
    wrapperCol: { span: 18 },
};
let dfLabel = []

class NodeView extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            showManage: true,
            nodeId: null,
            nodeName: '查看节点',
            labelList: [],
            allLabels: [],
            loading: true,
            nodeObj: {
                logSwitch: '',
                name: '',
                nodeUuid: '',
                description: '',
                hostname: '',
                macAddress: '',
                cpu: '',
                gpu: '',
                mem: '',
                disk: '',
                instanceNum: '',
                deviceNum: '',
                state: '',
                labels: '',
                upTime: '',
                updateTime: '',
                createTime: '',
                username: '',
                tenantName: '',
                userId: ''
            },
            labels: [
                {
                    "id": 1,
                    "name": "测试",
                    "code": ""
                }
            ],
            history: require("history").createHashHistory,
        };
    }
    goback() {
        // if (this.props.onSubmit) {
        //     var { showManage } = this.state;
        //     this.props.onSubmit({ showManage })
        // }
        this.props.history.go(-1);
    }

    componentDidMount() {// window.localStorage to FIXME:
        if (this.props.location && this.props.location.state) {
            const nodeId = this.props.location.state.id;
            window.localStorage.setItem('nodeId', nodeId);
            if (nodeId) {
                this.setState({
                    nodeId: nodeId,
                });
                this.getLabelList(nodeId)
            }
        } else if (window.localStorage.getItem('nodeId')) {
            this.setState({
                nodeId: window.localStorage.getItem('nodeId'),
            });
            this.getLabelList(window.localStorage.getItem('nodeId'))
        } else {
            this.goback();
        }
    }


    getLableItem(labels) {
        return labels.map((item, index) => {

            return (
                item ? <Tag color="volcano">{item.name}</Tag> : ''
            );
        });
    }
    handleChange(value) {
        console.log(`selected ${value}`);
    }
    getLabelList(nodeId) {
        labelService.getAllLabels().then(res => {
            if (res.code === 200) {
                const labels = res.labels;
                this.setState({

                    allLabels: labels
                });
                this.getNodeDetail(nodeId);
            }
        }, error => {
            error && message.error(error);
        });
    }
    getNodeDetail(nodeId) {
        nodeService.getNodeDetail(nodeId || this.state.nodeId).then(res => {
            if (res.code === 200) {
                console.log(res);
                const node = res.node;
                const defaultOption = []
                node.labels = node.labels || [];
                if (node.labels) {
                    node.labels.map(it => {
                        defaultOption.push(it.name)
                    });
                }

                this.setState({
                    nodeObj: node,
                    nodeId: node.id,
                    loading: false,
                    nodeName: "查看节点 -- " + node.name,
                    labelItems: defaultOption,
                    labelList: this.getLableItem(node.labels),
                    description: node.description,
                });


            }
            //  else {
            //     message.error('获取节点详情失败, ' + res.message);
            // }
        }, error => {
            error && message.error(error);
        });
    }


    getState(record) {

        if (record.state === 'UNCONNECTED') {
            return <div><div className="common-deep-gray"></div> <span>未纳管</span></div>
        } else if (record.state === 'RUNNING') {
            return <div><div className="common-green"></div> <span>运行中</span></div>
        } if (record.state === 'UPGRADEWAITING') {
            return <div><div className="common-yellow"></div> <span>待升级</span></div>
        } else if (record.state === 'UPGRADING') {
            return <div><div className="common-yellow"></div> <span>升级中</span></div>
        } else if (record.state === 'DISABLED') {
            return <div><div className="common-gray"></div> <span>禁用</span></div>
        } else if (record.state === 'OFFLINE') {
            return <div><div className="common-red"></div> <span>不在线</span></div>
        } else if (record.state === 'DELETED') {
            return <div><div className="common-red"></div> <span>已删除</span></div>
        } else {
            return '';
        }
    }
    getLabels(labelList, selectLabels) {
        const option = []
        selectLabels.map(item => {
            labelList.map(it => {
                if (item === it.name) {
                    option.push(it.id)
                }
            })
        })
        return option
    }
    handleLogUpload() {

    }
    render() {
        return (
            <><Spin spinning={this.state.loading} delay={500}>


                <div className="common-detail-container">
                    <BackPage onClick={() => this.goback()} headeTitle={this.state.nodeName} itemTitle="边缘节点" itemSubTitle="查看节点" />
                    <div style={{ background: '#fff', padding: "0 20px 20px" }}>
                                <div style={{ marginLeft: 40 }}>
                                    <Form name="basic"

                                        labelAlign="left"
                                        ref={this.formRef}
                                        {...formItemLayout} >
                                        <Row><Col span={24}>
                                            <Form.Item label="状态" >
                                                {this.getState(this.state.nodeObj)}
                                            </Form.Item>
                                        </Col></Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item name="name" label="节点名称" >
                                                    {this.state.nodeObj.name || '--'}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item name="description" label="描述" >
                                                    {this.state.nodeObj.description || '--'}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item name="seriesNumber" label="产品序列号" >
                                                    {this.state.nodeObj.seriesNumber || '--'}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item name="boardName" label="Board Name" >
                                                    {this.state.nodeObj.boardName || '--'}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item name="label" label="节点标签">

                                                    {this.state.labelList.length > 0 ? this.state.labelList : '--'}

                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row>
                                            {this.props.currentUser?.type !== AUTCONFIG.SUB.value ?
                                                <Col span={24}>
                                                    <Form.Item name="user" label="所属用户">
                                                        {this.state.nodeObj.realName || '--'}
                                                    </Form.Item>
                                                </Col> : ''}

                                        </Row>
                                        <Row>
                                            {(this.props.currentUser?.type === AUTCONFIG.ADMIN.value ||
                                                this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value) ?
                                                <Col span={24}>
                                                    <Form.Item name="user" label="上级用户">
                                                        {this.state.nodeObj.tenantName || '--'}
                                                    </Form.Item>
                                                </Col> : ''}

                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item label="所在省市区" >
                                                    {this.state.nodeObj.province} {this.state.nodeObj.city} {this.state.nodeObj.district}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item label="详细地址" >
                                                    {this.state.nodeObj.address || '--'}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item label="创建时间" >
                                                    {this.state.nodeObj.createTime || '--'}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item label="更新时间"  >
                                                    {this.state.nodeObj.updateTime || '--'}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item label="上报时间"  >
                                                    {this.state.nodeObj.upTime || '--'}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item label="日志上传云端"  >
                                                    {this.state.nodeObj.logSwitch === 1 ?'已开启':'已关闭'}
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                    </Form>
                                </div>
                                <Divider className="lr-divider" />
                                <div style={{ marginLeft: 40 }}>
                                    <Form name="basic"

                                        labelAlign="left"
                                        {...formItemLayout2} >
                                        <Row>

                                            {/* <Col span={12}>
                                                <Form.Item label="产品ID" >
                                                    {this.state.nodeObj.stationId}
                                                </Form.Item>
                                            </Col> */}
                                            <Col span={12}>
                                                <Form.Item label="主机名称" >
                                                    {this.state.nodeObj.hostname || '--'}
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item label="GPU" >
                                                    {this.state.nodeObj.gpu || '--'}
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="CPU" >
                                                    {this.state.nodeObj.cpu || '--'}
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="应用数量" >
                                                    {this.state.nodeObj.instanceNum || '--'}
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row>


                                            <Col span={12}>
                                                <Form.Item label="内存" >
                                                    {this.state.nodeObj.mem || '--'}
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="设备数量" >
                                                    {this.state.nodeObj.deviceNum || '--'}
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="硬盘" >
                                                    {this.state.nodeObj.disk || '--'}
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="epack版本" >
                                                    {this.state.nodeObj.firmwareVersion || '--'}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                          
                    </div>
                </div>

            </Spin>
            </>
        );
    }
}

// export default ViewNode;


export default connect(
    (state) => ({
        currentUser: state.currentUser
    })
)(NodeView);