import React from 'react';
import './../../../../index';
import { Tabs } from 'antd';
import { withRouter } from 'react-router-dom';
import SafeSetting from './safe-setting/safe-setting';
import BasisSetting from './basis-setting/basis-setting';

import './user-center.scss';

const { TabPane } = Tabs;

let tabKey = '1';
function callback(key) {
    tabKey = key;
}

class UserCenter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {};
    }
    render() {
        return (
            <>
                <div className="common-pd20">
                    <h1 className="common-top-title0">个人中心</h1>
                    <div style={{ padding: '15px', backgroundColor: '#fff' }}>
                        <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane tab="基本信息" key="1">
                                <BasisSetting key={tabKey} history={this.props.history}/>
                            </TabPane>
                            <TabPane tab="安全设置" key="2">
                                <SafeSetting key={tabKey} history={this.props.history}/>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </>
        );
    }
}

// export default UserCenter;
export default withRouter(UserCenter);

// import React, {Component} from 'react';
// import ReactDOM from 'react-dom';
// import './user-center.scss';
// import './../../../../index';
// import { Button, Card, Col, Input, Menu, Row, Form } from 'antd';
// import SafeSetting from './safe-setting/safe-setting';
// import BasisSetting from './basis-setting/basis-setting';

// class UserCenter extends React.Component {
//     constructor(props) {
//         super (props)
//         this.state = {
//           showBasis: true
//         };
//       }
//     render() {
//         const toBasis = ()=> {
//             this.setState({
//                 showBasis: true
//             })
//         }
//         const toSafe = ()=> {
//             this.setState({
//                 showBasis: false
//             })
//         }
//         return (
//         <>
//             <h1 className="common-top-title0">个人中心</h1>
//             <div className="user-center">
//             <Row>
//                 <Col span={5}> 
//                     <Menu
//                         defaultSelectedKeys={['1']}
//                         defaultOpenKeys={['sub1']}
//                         mode='inline'
//                         theme= 'light'
//                     >
//                         <Menu.Item key="1" onClick={toBasis}>
//                         基本设置
//                         </Menu.Item>
//                         <Menu.Item key="2" onClick={toSafe}>
//                         安全设置
//                         </Menu.Item>
//                     </Menu>
//                 </Col>
//                 <Col span={19} style={{display:(this.state.showBasis===true?'block':'none')}}> 
//                     <BasisSetting/>
//                 </Col>
//                 <Col span={19} style={{display:(this.state.showBasis===true?'none':'block')}}> 
//                     <SafeSetting/>
//                 </Col>
//             </Row>
//             </div>
//         </>

//         ); 
//     }
// }

// export default UserCenter;