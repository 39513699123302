import React from 'react';
import { Form, Input, message, Spin } from 'antd';
import { otaService } from '../../../../../services/ota.service';

import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';

const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

class EditShell extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      showManage: true,
      productId: '',
      productName: '编辑脚本',
      id: '',
      createTime: '',
      updateTime: '',
      loading: true
    };

  }
  componentDidMount() {
    if (this.props.location.state) {
        const productId = this.props.location.state.productId;
        const id = this.props.location.state.id;
        this.setState({
            productId,
            id
        });
        sessionStorage.setItem('shellId', id)
        this.getShellDetail(id);
    } else {
      if(sessionStorage.getItem('productId') && sessionStorage.getItem('shellId')) {
        const productId = sessionStorage.getItem('productId');
        const id = sessionStorage.getItem('shellId');
            this.setState({
              productId,
              id
          });
          this.getShellDetail(id);
      } else {
        this.props.history.push('/home/ota-factory');
      }
    }
}
  goback() {
    this.props.history.push({ pathname: '/home/ota-factory/test-shell', state: { id: this.state.productId } });
  }
  getShellDetail(id) {
    otaService.getShellDetail(id).then(res => {
        if (res.code === 200) {
            const ota = res.script;
            this.formRef.current?.setFieldsValue({
                scriptName: ota.scriptName,
                url: ota.url,
                param: ota.param,
                order: ota.order,
                description: ota.description,
                loading: false
              });
              this.setState({
                  createTime: ota.createTime,
                  updateTime: ota.updateTime,
                  productName: '编辑脚本 -- ' +  ota.scriptName,
              })
        }  
        //  else {
        //   this.setState({
        //     loading: false
        //   })
        // }
    }).catch(error => {
      this.setState({
        loading: false
      });
      error && message.error(error);
    })
}
  render() {
    const onFinish = (values) => {
        Object.keys(values).forEach(elx => {
            values[elx] =  values[elx] || '';
        });
        message.loading('信息保存中~~', 2500);
      otaService.updateShell(this.state.id, values).then(res => {
          message.destroy();
        if (res.code === 200) {
          message.success('编辑脚本成功');
          this.goback();
        } 
        // else {
        //   message.success('编辑脚本失败，' + res.message);
        // }
      }, error => {
        message.destroy();
        error && message.error(error);
      });
    };
    return (
      <>
      <div className="common-pd20">
        <BackPage onClick={() => this.goback()} headeTitle={this.state.productName} itemTitle="脚本" itemSubTitle="创建产品" />
        <div className="common-create-center-container">
      
          <div className="common-create-page">
            <Form
              ref={this.formRef}
              name="validate_other"
              labelAlign="left"
              {...formItemLayout}
              onFinish={onFinish}>
              <Form.Item name="scriptName" label="脚本名称" rules={[
                { required: true, message: '请输入脚本名称' },
                { max: 64, message: '脚本名称不允许超过64个字符'},  
                { pattern: new RegExp(/^[0-9a-zA-Z-_.\u4e00-\u9fa5]{1,}$/, "g"), message: '请输入64位以内中英文/数字/中划线/下划线/点' }]}  >
                <Input placeholder="请输入脚本名称" />
              </Form.Item>
              <Form.Item name="url" label="URL" rules={[
                  { required: true, message: '请输入URL' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                    if (!value || /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/.test(value)) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('请输入正确的URL'));
                    },
                }),]}  >
                <Input placeholder="请输入URL" />
              </Form.Item>
              <Form.Item name="param" label="运行参数"   >
                <Input placeholder="请输入运行参数" />
              </Form.Item>
              <Form.Item name="order" label="运行顺序" rules={[
                  { required: true, message: '请输入运行顺序' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                    if (!value || /^(0|[1-9][0-9]*)$/.test(value)) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('运行顺序必须为数字'));
                    },
                }),
                  ]} >
                <Input placeholder="请输入运行顺序" />
              </Form.Item>
              <Form.Item name="description" label="描述" >
                <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" />
              </Form.Item>
              <Form.Item name="description" label="创建时间" >
                {this.state.createTime}
              </Form.Item>
              <Form.Item name="description" label="更新时间" >
              {this.state.updateTime}
              </Form.Item>
              <BottomBtnGroup onClick={() => this.goback()} />
            </Form>
          </div>
          
        </div>
      
      </div>
      </>
    );
  }
}

export default EditShell;