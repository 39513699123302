import React from 'react';
import { Form, Input, Button, Col, Row, Table, Modal, message, ConfigProvider, Pagination } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { otaService } from '../../../../services/ota.service';
import './../../../../../index.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import BackPage from '../../../common/backPage';
import { connect } from "react-redux";
import { AUTCONFIG } from '../../../../../utils/auth.config';
const { confirm } = Modal;

let page = 1;
let pageSize = 10;
let searchName = '';

function Search(props) {
    const [form] = Form.useForm();
    const onFinish = (values) => {
        searchName = values.name || '';
        page = 1;
        props.onClick();
    };
    const resetForm = () => {
        form.resetFields();
        searchName = '';
        page = 1;
        props.onClick();
    }
    return (
        <>
            <div className="common-search-form">
                <Form
                    form={form}
                    name="advanced_search"
                    layout="inline"
          style={{ justifyContent: 'center' }}
                    className="ant-advanced-search-form"
                    onFinish={onFinish} >
                   
                        <Form.Item name='name' label='脚本名称' style={{ marginTop: '8px' }}>
                            <Input placeholder="请输入关键字" />
                        </Form.Item>
                        <Form.Item style={{ marginTop: '8px' }}>
                            <Button type="primary" htmlType="submit"> 查询 </Button>
                            <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
                        </Form.Item>
                    
                </Form>
            </div>
        </>
    );
}

class TestShell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shellList: [],
            shellAmount: 0,
            actionIds: [],
            loading: true,
            productId: '',
            page:1,
            pageSize: 10,
            productName: '测试脚本'
        };
    }
    componentDidMount() {
         page = 1;
            pageSize = 10;
            searchName = '';
        if (this.props.location.state) {
            const productId = this.props.location.state.id;
            this.setState({
                productId
            });
            sessionStorage.setItem('productId', productId)
            this.searchList(productId);
            this.getProductDetail(productId)
        }  else {
            if(sessionStorage.getItem('productId'))  {
               const productId = sessionStorage.getItem('productId');
                 this.setState({
                     productId
                 });
                 this.searchList(productId);
                 this.getProductDetail(productId);
            } else {
                this.goBack();
            }
         }
    }
    getProductDetail(productId) {
        otaService.getOtaDetail(productId).then(res => {
            if (res.code === 200) {
                const ota = res.product;
                this.setState({
                   
                    productName: '测试脚本 -- ' +  ota.productName,
                });
            }  
        }, error => {
            error && message.error(error);
        })
    }

    showDeleteConfirm(event) {
        confirm({
            title: '确定删除吗?',
            icon: <ExclamationCircleOutlined />,
            content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
            closable: true,
            onOk: () => {
                otaService.removeShell(event).then(res => {
                    if (res.code === 200) {
                        message.success('删除成功');
                        page = 1;
                        this.searchList();
                    } 
                    // else {
                    //     message.error('删除失败，' + res.message);
                    // }
                }, error => {
                    error && message.error(error);
                })
            },
            onCancel() {

            },
        });
    }
    goBack() {
        this.props.history.push('/home/ota-factory');
    }
    goToAdd() {
        this.props.history.push({ pathname: '/home/ota-factory/test-shell/add', state: { id: this.state.productId } });
       
    }
    gotoDetail(text, event) {
      
        this.props.history.push({ pathname: '/home/ota-factory/test-shell/view', state: { productId: this.state.productId , id: text} });
    }
    goToEdit(event) {
      
        this.props.history.push({ pathname: '/home/ota-factory/test-shell/edit', state: { productId: this.state.productId , id: event} });
    }
    searchList(productId) {
        productId = productId || this.state.productId;
        this.setState({
            loading: true,
            page,
            pageSize
        })
        otaService.getShellList(productId, searchName.replace(/(^\s*)|(\s*$)/g,''), pageSize,  page).then(res => {
            this.setState({
                loading: false
            });
            this.setState({
                shellList: res.scripts,
                shellAmount: res.dataCount
            })
        }).catch(error => {
            this.setState({
                loading: false
            });
            error && message.error(error);
        })
    }
  
    render() {
        const columns = [
            {
                title: '脚本名称',
                dataIndex: 'scriptName',
                render: (text, record) => <a  onClick={() => this.gotoDetail(record.id)}>{text}</a>,
            },
            {
                title: 'URL',
                dataIndex: 'url'
            },
            {
                title: '运行参数',
                dataIndex: 'param'
            },
            {
                title: '运行顺序',
                dataIndex: 'order'
            },
            {
                title: '描述',
                dataIndex: 'description',
                width: '15%'
            },
            {
                title: '创建时间',
                dataIndex: 'createTime'
            },
            {
                title: '操作',
                dataIndex: 'action',
                render: (text, record) => (
                    <>
                    
                     <a href onClick={() => this.goToEdit(record.id)} style={{marginRight: 8}}>编辑</a>
                     <a href onClick={() => this.showDeleteConfirm(record.id)}>删除</a>
                    </>
                )
            },
        ];
        const columAdmin = columns.slice(0, columns.length  -1);
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
              this.setState({
                actionIds: selectedRowKeys
              })
            }
        }
        const onPageChange = (pageNumber,ps) => {
            page = pageNumber;
            if(ps!=pageSize) {
              page = 1
            }
            pageSize = ps
            this.searchList();
          };
       
        return (
            <>
                <div className="common-pd20">
                    <div>
                    <BackPage onClick={() => this.goBack()} headeTitle={this.state.productName} itemTitle="脚本" itemSubTitle="创建产品" />
    
                       <div className="common-center-container">
                           <Search  onClick={() => this.searchList()}/>
                           <div className="oper-button">
                           {this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value ?
                                <Router>
                                    <Button type="primary"  onClick={() => this.goToAdd()}> 创建 </Button>
                                </Router> : ''}
                            </div>
                            <div className="user-table-card">
                            {this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value ? 
                                <Table
                                 
                                    rowKey={record => record.id}
                                    bordered={false}
                                    pagination={false}
                                    columns={columns}
                                    dataSource={this.state.shellList}
                                    loading={this.state.loading}
                                /> : 
                                <Table
                                rowKey={record => record.id}
                                bordered={false}
                                pagination={false}
                                columns={columAdmin}
                                dataSource={this.state.shellList}
                                loading={this.state.loading}
                            />  }
                            </div>
                            <div>
                                {
                                    this.state.shellList.length > 0 &&
                                    <div className="common-bottom-pagination">
                                        <ConfigProvider locale={zhCN}>
                                            <Pagination showSizeChanger defaultCurrent={1} 
                                            current={this.state.page} pageSize={this.state.pageSize} 
                                            total={this.state.shellAmount} onChange={onPageChange} />
                                        </ConfigProvider>
                                    </div>
                                }
                            </div>
                       </div>
                    </div>

              
                </div>
            </>
        );
    }
}


// export default TestShell;


export default connect(
    (state) => ({
      currentUser: state.currentUser
    })
  )(TestShell);