import React from 'react';
import ReactDOM from 'react-dom';
import './no-match.scss'
import { Button } from 'antd';
import { withRouter } from 'react-router-dom';

class NoMatch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    };
  }
  render() {
    return (
      <div className="error404">
        <div>
          <img src="/images/404.png" alt="404 img" />
          <Button type="primary"><a href="/home#/first-page">返回首页</a></Button>
        </div>
      </div>
    )
  }
}
ReactDOM.render(<NoMatch />, document.getElementById('root'));
export default withRouter(NoMatch);