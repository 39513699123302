import React from 'react';
import { Select, Form, Col, Input, Button, Table, Pagination, Badge, InputNumber, ConfigProvider, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from 'react-router-dom';
import { labelService } from '../../../services/label.service';
import { terminalService } from '../../../services/terminal-equ';

import '../../../../index.scss';
import './list.scss';

import { connect } from "react-redux";
import { searchRule } from '../../../../utils/search-util'

const { Option } = Select;


const { confirm } = Modal;


let page = 1;
let pageSize = 10;
let searchName = '';
let searchNodeName = '';
let searchState = '';
let searchUserId = '';
let searchLabel = [];
let searchType = '';

class Search extends React.Component {
  constructor(props) {
    super(props);

  }
  form = React.createRef();

  onFinish = (values) => {
    console.log(values);
    let name = values.deviceName || '';
    let str = searchRule(name)
    searchName = str || '';

    let nodeName = values.nodeName || '';
    let str1 = searchRule(nodeName)
    searchNodeName = str1 || '';

    searchState = values.state || ''
    searchLabel = values.label || []
    searchType = values.type || ''
    page = 1;
    this.props.onClick();
  };
  resetForm = () => {
    this.form.current.resetFields();
    searchName = '';
    searchNodeName = '';
    searchState = '';
    searchLabel = [];
    searchType = '';
    page = 1;
    pageSize = 10;
    this.props.onClick();
  }

  componentDidMount() {
    this.resetForm();
  }
  render() {
    return (
      <>
        <div className="common-search-form">
          <Form
            ref={this.form}
            name="advanced_search_user"
            className="ant-advanced-search-form"
            onFinish={this.onFinish}
            layout="inline"

            // style={{ justifyContent: 'center' }}
          >

            <Form.Item name='deviceName' label='设备别名' style={{ marginTop: '8px' }}>
              <Input placeholder="请输入设备别名" />
            </Form.Item>
            <Form.Item name='state' label='设备状态' style={{ marginTop: '8px' }}>
              <Select allowClear
                placeholder="请选择设备状态" >
                <Option value="RUNNING">运行中</Option>
                <Option value="FAILED">异常</Option>
                <Option value="DISABLED">禁用</Option>
                <Option value="WATING">操作中</Option>
                <Option value="STOPPED">关闭</Option>
              </Select>
            </Form.Item>
            <Form.Item name='nodeName' label='节点名称' style={{ marginTop: '8px' }}>
              <Input placeholder="请输入节点名称" />
            </Form.Item>
            <Form.Item name='label' label='节点标签' style={{ marginTop: '8px' }}>
              <Select mode="multiple" allowClear style={{ width: '200px', }} placeholder="请选择节点标签" showArrow>
                {this.props.labelList}
              </Select>
            </Form.Item>
            <Form.Item name='type' label='设备类型' style={{ marginTop: '8px' }}>
              <Select allowClear
                placeholder="请选择设备类型" >
                <Option value="0">视频摄像头</Option>
                <Option value="1">音频</Option>
                <Option value="2">屏幕</Option>
                <Option value="3">麦克风</Option>
                <Option value="4">其他</Option>
              </Select>
            </Form.Item>

            <Form.Item style={{ marginTop: '8px' }}>
              <Button type="primary" htmlType="submit"> 查询 </Button>
              <Button style={{ margin: '0 9.46px' }} onClick={this.resetForm}>重置 </Button>
            </Form.Item>

          </Form>
        </div>
      </>
    );
  }
}

class TerminalEquManagement extends React.Component {

  constructor(props) {
    super(props)
    this.state = {

      isModalVisible: false,
      configIsModalVisible: false,
      devicesList: [],
      loading: true,
      page: 1,
      dataCount: null,
      pageSize: 10,
      actionIds: [],
      labelList: []
      //  history: require("history").createHashHistory,
    };
  }
  getLabelList() {
    const getLableItem = (labels) => {
      return labels.map((item, index) => {
        return (
          <Option key={item.id} >{item.name}</Option>
        );
      });
    }
    labelService.getAllLabels().then(res => {
      if (res.code === 200) {
        const labels = res.labels;
        this.setState({
          labelList: getLableItem(labels)
        });
      }
    });
  }
  showDeleteConfirm(event) {
    confirm({
      title: '确定删除吗?',
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        this.deleteDevice(event.id)

      },
      onCancel() {

      },
    });
  }

  showEnaConfirm(event) {
    confirm({
      title: `确定检查${event.name}吗?`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        message.success('检查成功');
        this.changeType();

      },
      onCancel() {
      },
    });
  }
  showDisConfirm(event) {
    confirm({
      title: `确定停止${event}?`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        message.success('停止成功');

        this.changeType();
      },
      onCancel() {
      },
    });
  }
  showShenjiWin() {
    this.setState({
      isModalVisible: true
    })
  }
  showAdjust() {
    this.setState({
      configIsModalVisible: true
    })
  }
  showMultiDeleteConfirm() {
    if (this.state.actionIds.length === 0) {
      message.warning('请先选择要操作的应用');
      return;
    }
    confirm({
      title: '确定批量操作吗?',
      icon: <ExclamationCircleOutlined />,
      //  content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      onOk: () => {
        message.success('操作成功');

        this.changeType();
      },
      onCancel() {

      },
    });
  }


  changeType() {
    this.setState({
      page,
      pageSize
    })

    this.setState({
      loading: true
    });
    // setTimeout(() => {
    //   this.setState({
    //     loading: false
    //   });
    // }, 200)

    //查找
    this.getDevices()
  }

  componentDidMount() {
    //  
    pageSize = 10;
    page = 1;
    this.getLabelList();
    this.changeType();
    //this.getDevices()
  }

  //调用查询接口
  getDevices() {
    terminalService.getDevices(searchName, searchNodeName, searchState, searchUserId, searchLabel, searchType, pageSize, page).then(res => {
      if (res.code === 200) {
        console.log(res);
        this.setState({
          devicesList: res.devices,
          dataCount: res.dataCount,
          loading: false
        })
      }
    //   else {
    //     message.error('获取设备列表失败');
    //     this.setState({
    //         loading: false
    //     })
    // }
  }, error => {
    error && message.error(error);
    this.setState({
        loading: false
    })
});
  }

  //调用删除接口
  deleteDevice(id) {
    terminalService.deleteDevice(id).then(res => {
      if (res.code === 200) {
        message.success("删除成功！")
        this.changeType();
      }
    }).catch(error => {
      error && message.error(error);
    })
  }


  gotoEdit = (text) => {
    this.props.history.push({ pathname: '/home/terminal-equ/edit', state: { id: text.id } });
  }
  gotoDetail = (text, record) => {
    console.log(text);
    console.log(record);
    this.props.history.push({ pathname: '/home/terminal-equ/view', state: { id: text.id } });
  }

  getDeviceType = (record) => {
    if (record.deviceType === 0) {
      return <span>视频摄像头</span>
    } else if (record.deviceType === 1) {
      return <span>音频</span>
    } else if (record.deviceType === 2) {
      return <span>屏幕</span>
    } else if (record.deviceType === 3) {
      return <span>麦克风</span>
    } else if (record.deviceType === 4) {
      return <span>其他</span>
    }
  }

  getDeviceInterface = (record) => {
    if (record.deviceInterface === 'HDMI') {
      return <span>HDMI</span>
    } else if (record.deviceInterface === 'USB') {
      return <span>USB</span>
    } else if (record.deviceInterface === 'NET') {
      return <span>网络</span>
    }
  }

  getDeviceState = (record) => {
    if (record.deviceStatus === 'RUNNING') {
      return <span> <Badge status="success" />运行中</span>
    } else if (record.deviceStatus === 'FAILED') {
      return <span> <Badge status="error" />异常</span>
    } else if (record.deviceStatus === 'DISABLED') {
      return <span><Badge status="error" />禁用</span>
    } else if (record.deviceStatus === 'WATING') {
      return <span><Badge status="processing" />操作中</span>
    } else if (record.deviceStatus === 'STOPPED') {
      return <span><Badge status="default" />关闭</span>
    }
  }

  handleOk() {
    this.setState({
      isModalVisible: false,
      configIsModalVisible: false
    })
  }

  render() {
    const onPageChange = (pageNumber) => {

      page = pageNumber;

      this.changeType();
    }
    const onShowSizeChange = (current, pageSizeNumber) => {
      pageSize = pageSizeNumber;
      page = 1;

      this.changeType();
    }
    const registered = () => {

      this.props.history.push('/home/terminal-equ/add');
    };
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          actionIds: selectedRowKeys
        })
      },

    };

    const columns = [
      {
        title: '设备别名',
        dataIndex: 'aliasName',
        render: (text, record) => <a href onClick={this.gotoDetail.bind(text, record)}>{text}</a>,
      },
      {
        title: '设备名称',
        dataIndex: 'deviceName',
        
      },
      {
        title: '设备类型',
        dataIndex: 'deviceType',
        render: (text, record) => (
          <>
            {
              this.getDeviceType(record)
            }
          </>
        ),
      },
      {
        title: '设备接口',
        dataIndex: 'deviceInterface',
        render: (text, record) => (
          <>
            {
              this.getDeviceInterface(record)
            }
          </>
        ),
      },
      {
        title: '所属节点',
        dataIndex: 'nodeName',
      },
      {
        title: '设备状态',
        dataIndex: 'deviceStatus',
        render: (text, record) => (
          <>
            {
              this.getDeviceState(record)
            }
          </>
        ),

      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
      },
      {
        title: '更新时间',
        dataIndex: 'updateTime',
      },
      {
        title: '操作',
        dataIndex: 'action',
        width: '20%',
        render: (text, record) => (
          <>
            {
              record.deviceStatus != 'WATING' ?
                <a href onClick={() => this.gotoEdit(record)} style={{ marginRight: '5px' }}>编辑</a>
                : ''
            }
            
            
            {
              (record.deviceInterface === 'NET' && record.deviceStatus === 'RUNNING') || record.deviceStatus === 'FAILED' ?
              <a href onClick={() => this.showDeleteConfirm(record)} style={{ marginRight: '5px' }}>删除</a>
                : ''
            }

          </>
        )
      },
    ];
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    return (
      <>
        <div className="common-pd20">
          <div >
            <h1 className="common-top-title0">终端设备</h1>
            <div className="common-center-container">
              <Search onClick={() => this.changeType()}
                currentUser={this.props.currentUser}
                labelList={this.state.labelList} />
              <div className="oper-button">
                <Router>
                  <Button type="primary" onClick={registered} >  注册  </Button>
                </Router>
                {/* <Router>
                  <Button type="primary" style={{ marginLeft: 10 }} onClick={registered}>  升级  </Button>
                </Router> */}
                {/* <Button style={{ marginLeft: 10 , width: 80, textAlign:"center"}} onClick={() => this.showMultiDeleteConfirm()}> 批量操作 </Button> */}
              </div>
              <div className="user-table-card">
                <Table
                  // rowSelection={{
                  //   ...rowSelection,
                  // }}
                  rowKey={record => record.id}
                  bordered={false}
                  tableLayout="fixed"
                  pagination={false}
                  loading={this.state.loading}
                  columns={columns}
                  dataSource={this.state.devicesList}
                />
              </div>
              <div>
                {
                  this.state.devicesList.length > 0 &&
                  <div className="common-bottom-pagination">
                    <ConfigProvider locale={zhCN}>
                      <Pagination
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        defaultCurrent={1}
                        current={this.state.page}
                        pageSize={this.state.pageSize}
                        total={this.state.dataCount}
                        onChange={onPageChange} />
                    </ConfigProvider>
                  </div>
                }
              </div>
            </div>
          </div>


        </div>
      </>

    );
  }
}


export default connect(
  (state) => ({
    currentUser: state.currentUser
  })
)(TerminalEquManagement);
