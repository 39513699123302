import React from 'react';
import { Form, Input, Button, Col, Row, Select, Modal, message, ConfigProvider, Pagination } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';

import { otaService } from '../../../../../services/ota.service';
import { firmwareService } from '../../../../../services/firmware.service';
import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';
import { AUTCONFIG } from '../../../../../../utils/auth.config';

import { userService } from '../../../../../services/user.service';
import { connect } from "react-redux";

import './edit-ota.scss';
import { getOtaIdAction } from '../../../../../../store/actionCreators';
import store from '../../../../../../store';

const { Option } = Select;
const { TextArea } = Input;


const { confirm } = Modal;
const formItemLayout = {
    labelCol: { xs: 24, sm: 12, md: 8, lg: 6, xl: 4 },
    wrapperCol: { xs: 24, sm: 12, md: 15 },
};
const formAddFirItemLayout = {
    labelCol: { xs: 24, sm: 12, md: 8, lg: 6, xl:6},
    wrapperCol: { xs: 24, sm: 12, md: 15},
};

class EditOta extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            showManage: true,
            otaId: null,
            otaObj: null,

            otaCreateTime: '',
            otaUpdateTime: '',
            otaName: '编辑产品',

            searchFirwarePageSize: 10,
            firmwareList: [],
            firmwareAmout: 0,
            adminList: [],
            isAddFirmwareModalVisible: false,
            history: require("history").createHashHistory,
        };
    }
   
    goback() {
        // if (this.props.onSubmit) {
        //     var { showManage } = this.state;
        //     this.props.onSubmit({ showManage })
        // }
        // this.props.history.go(-1);
        this.props.history.push('/home/ota-factory');
    }
    getUserList() {
        userService.getAllUsers().then(item => {
          
            const adminList = item.users.filter(elx => {
                return elx.type === AUTCONFIG.SUPERADMIN.value 
                      || elx.type === AUTCONFIG.ADMIN.value;
            })
            this.setState({
                adminList
            })
           
        })
    }
 
    componentDidMount() {
       
        if (this.props.location.state) {
            const otaId = this.props.location.state.id;
            if (otaId) {
                const action = getOtaIdAction(otaId);
                store.dispatch(action);
                this.setState({
                    otaId: otaId,
                });
                this.getOtaDetail(otaId);
               
            }
        } else {
            const otaId = store.getState().otaId;
            if (otaId) {
                this.setState({
                    otaId: otaId,
                });
                this.getOtaDetail(otaId);
              
            }
        }
        this.getUserList();

    }
    // getVerisonList() {
    //     versionList = [{ id: 1, name: 'v1.0.0', key: 1 }, { id: 2, name: 'v2.0.0', key: 2 }];
    // }
   
    getOtaDetail(otaId) {
        otaService.getOtaDetail(otaId).then(res => {
            if (res.code === 200) {
                const ota = res.product;
                this.setState({
                    otaObj: ota,
                    otaName: '编辑产品 -- ' + ota.productName,
                    otaCreateTime: ota.createTime,
                    otaUpdateTime: ota.updateTime
                });
                console.log(this.formRef)
                console.log(this.formRef.current)
                this.formRef.current.setFieldsValue({
                    name: ota.productName || '',
                    model: ota.productModel || '',
                    description: ota.description || '',
                    adminId: ota.userId || ''
                });
            }
            //  else {
            //     message.error('获取产品详情失败,' + res.message);
            // }
        }, error => {
            error && message.error( error);
        });
    }
    gotoFirmwareDetail(text, key, detail) {
        this.props.history.push({ pathname: '/home/ota-factory/view/firmware', state: { id: key , mode: detail} });
    }
    getState(record) {
        if (record.state === '1' || record.state === 1) {
            return <div><div className="common-green"></div> <span>可用</span></div>
        } else if (record.state === '0' || record.state === 0) {
            return <div><div className="common-red"></div> <span>禁用</span></div>
        } else {
            return '';
        }
    }
    getButton(record) {
        if (record.state === 0 || record.state === '0') {
            return <a href onClick={() => this.showEnaConfirm(record.id)} style={{ marginRight: '5px' }}>启用</a>
        } else if (record.state === '1' || record.state === 1) {
            return <a href onClick={() => this.showDisConfirm(record.id)} style={{ marginRight: '5px' }}>禁用</a>
        } else {
            return '';
        }
    }
    render() {
        const onFinish = (values) => {
            otaService.editOta(this.state.otaId, 
                 values.name, 
                 values.model,
                 values.description, 
                 values.adminId).then(res => {
                if (res.code === 200) {
                    message.success('修改成功');
                    this.goback();
                } 
                // else {
                //     message.error('修改失败，' + res.message);
                // }
            }, error => {
                message.error('修改失败，' + error);
            });
        };
       
        return (
            <>
                <div className="ota-detail-container">
                    <BackPage onClick={() => this.goback()} headeTitle={this.state.otaName} itemTitle="产品管理" itemSubTitle="查看产品" />
                    <div className="common-edit-card">
                        <Form name="basic"
                            onFinish={onFinish}
                            labelAlign="left"
                            ref={this.formRef}
                            {...formItemLayout} >
                            <Row>
                                <Col span={24}>
                                    <Form.Item name="name" label="产品名称" rules={[
                                        { required: true, message: '产品名称不能为空' },
                                        { pattern: /^[\u0391-\uFFE5\w_-]{0,64}$/, message: '请输入64位以内中英文/数字/中划线/下划线' }
                                        ]} >
                                        <Input style={{ width: 300 }} placeholder="请输入产品名称" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item name="description" label="描述" >
                                        <TextArea style={{ width: 800 }} autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" showCount={true} maxLength={255}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item name="model" label="产品型号" rules={[
                                        { required: true, message: '产品型号不能为空' },
                                        { pattern: /^[[\u0391-\uFFE5\w_-]{0,64}$/, message: '请输入64位以内中英文/数字/中划线/下划线' }]} >
                                        <Input style={{ width: 300 }} placeholder="请输入产品型号" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {this.props.currentUser?.type === AUTCONFIG.SUPERADMIN.value ?
                            <Form.Item name='adminId' label='管理员' >
                                <Select
                                    showSearch  allowClear
                                    filterOption={(input, option) =>
                                    option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    placeholder="请选择管理员" >
                                        {
                                            this.state.adminList.map((item, index) => {
                                                return (
                                                <Option key={item.id} value={item.id}>{item.realName}/{item.username}</Option>
                                                );
                                            })
                                        }
                                
                                </Select>
                            </Form.Item> : ''}
                            <Row>
                                <Col span={24}>
                                    <Form.Item label="创建时间" >
                                        {this.state.otaCreateTime || '暂无数据'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item label="更新时间"  >
                                        {this.state.otaUpdateTime || '暂无数据'}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <BottomBtnGroup onClick={() => this.goback()} />
                        </Form>
                    </div>

                    
                </div>
               
            </>
        );
    }
}

// export default EditOta;

// let versionList = [];

// const { Option } = Select;

export default connect(
    (state) => ({
      currentUser: state.currentUser
    })
  )(EditOta);