import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import store from './store/index.js';
import { getCurrentAction } from './store/actionCreators';
import { loginService } from './pages/services/login';
import { connect } from 'react-redux';

class FrontendAuth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }
    componentDidMount() {
       
    }
    componentWillReceiveProps() {
     //   this.props.authToken && !this.props.currentUser && !this.state.loading && this.getCurrentUser();
    }
    async getCurrentUser() {
        this.setState({
            loading: true
        })
        const response  = await fetch(`/v1/console/users/current`, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${this.props.authToken}`
            }
        });
        const data = await response.json();
        const currentUser = data.users;
         const action2 = getCurrentAction(currentUser);
         store.dispatch(action2);
         this.setState({
            loading: true
        })
    }

    render() {
        const { routerConfig, location } = this.props;
        const { pathname, hash } = location;
        // const isLogin = sessionStorage.getItem("username");
        const isLogin =  this.props.authToken && (document.cookie)  ? true : false;
        // const isLogin = localStorage.getItem("auth_token");
        // console.log("pathname:")
        // console.log(pathname);
        // console.log("location:")
        // console.log(location);
      //  debugger
        const targetRouterConfig = routerConfig.find(
            (item) => item.path === pathname
        );
        if (targetRouterConfig && !targetRouterConfig.auth && !isLogin) {
            const { component } = targetRouterConfig;
            return <Route exact path={pathname} component={component} />;
        }
        if (isLogin) {
            
            if (pathname === "/login") { // 登录状态不允许跳转到登录页
               
                return <Redirect to="/home/first-page" />;
            } else {
                if (targetRouterConfig ) { // 路由合法
                    return (
                        <Route path={pathname} component={targetRouterConfig.component} />
                    );
                    // return (
                    //     <Redirect to={pathname} />
                    // );
                } else {
                   //  console.log('已登录 路由不合法：' + pathname)
                   // window.location.href = '/home#/home/first-page';
                    return hash ?  <Redirect to="/home/first-page" /> : <Redirect to="/home#/home/first-page" />;
                }
            }
        } else {
            if (targetRouterConfig && targetRouterConfig.auth) {
                // return ;

                return <Redirect to="/login" />;
            } else {
                console.log('未登录 路由不合法：' + pathname)
                // return ;
                return <Redirect to="/login" />;
            }
        }
    }
}

export default connect(
    (state) => ({
        authToken: state.authToken,
        currentUser: state.currentUser
    })
)(FrontendAuth);