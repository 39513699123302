import {
    CHANGE_INPUT_VALUE, ADD_LIST_ITEM, CHANGE_AUTH_TOKEN, DELETE_LIST_ITEM, INIT_LIST_ACTION, CHANGE_CURRENT_USER, CHANGE_OTA_ID,
    CHANGE_OTA_FIRMWARE_ID, CHANGE_JOB_ID, CHANGE_NODE_ID, CHANGE_MODEL_ID
} from './actionTypes';


// let authToken = sessionStorage.getItem('auth_token') ? JSON.parse(sessionStorage.getItem('auth_token')) : {};

const defaultState = {
    inputValue: '',
    list: new Array(),
    authToken: ''
};



// reduce 可以接收state，但不能修改state
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
    if (action.type === CHANGE_INPUT_VALUE) {
        const newState = JSON.parse(JSON.stringify(state));//确保每次都是新的对象，没有引用关系，保证State的独立性
        newState.inputValue = action.value;
        return newState;
    } else if (action.type === CHANGE_AUTH_TOKEN) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.authToken = action.value;
        return newState;
    } else if (action.type === CHANGE_CURRENT_USER) { // cccc
        const newState = JSON.parse(JSON.stringify(state));
        newState.currentUser = action.value;
        return newState;
    } else if (action.type === CHANGE_OTA_ID) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.otaId = action.value;
        return newState;
    } else if (action.type === CHANGE_OTA_FIRMWARE_ID) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.otaFirId = action.value;
        return newState;
    } else if (action.type === CHANGE_JOB_ID) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.jobId = action.value;
        return newState;
    } else if (action.type === CHANGE_NODE_ID) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.nodeId = action.value;
        return newState;
    } else if (action.type === CHANGE_MODEL_ID) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.model = action.value;
        return newState;
    } else if (action.type === ADD_LIST_ITEM) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.list.push(newState.inputValue);
        newState.inputValue = '';
        return newState;
    } else if (action.type === DELETE_LIST_ITEM) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.list.splice(action.index, 1);
        return newState;
    } else if (action.type === INIT_LIST_ACTION) {
        const newState = JSON.parse(JSON.stringify(state));
        newState.list = action.value;
        return newState;
    }
    return state;
}