import React from 'react';
import { Form, Input, Select, message, Row, Col, InputNumber, Card , Divider, Button, Table, Modal, Badge } from 'antd';

import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';
import { algorithmService } from '../../../../../services/algorithm-manage';
import {connect} from 'react-redux'
const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 13 },
};

class EditAlgorithmVersionMangement extends React.Component{
    formRef = React.createRef();
    state = {
        id: -1,
        algorithmName: '',
        algorithmVersion: '',
        description: '',
        createTime: '',
        updateTime: '',
    }

    componentDidMount() {
        if (this.props.location.state) {
            const id = this.props.location.state.id;
            const algorithmName = this.props.location.state.algorithmName;
            console.log("修改版本详情的id为：", id);
            console.log("修改版本详情的algorithmName为：", algorithmName);
            this.getgetAlgorithmDetail(id)
            sessionStorage.setItem('editAlgorithmVersionId', id)
            this.setState({
              id,
          });
          } else {
            if(sessionStorage.getItem('editAlgorithmVersionId')) {
              const id = sessionStorage.getItem('editAlgorithmVersionId');
              this.setState({
                  id,
              });
              this.getgetAlgorithmDetail(id)
            } else {
              this.goback();
            }
          }
    }

    //查询算法详情接口
    getgetAlgorithmDetail(id) {
        algorithmService.getAlgorithmVersionDetial(id).then(res => {
            if (res.code === 200) {
              //console.log(res);
              const algorithm = res.algorithm
              
              this.formRef.current.setFieldsValue({
                  algorithmName: algorithm?.algorithmName,
                  algorithmVersion: algorithm?.algorithmVersion,
                  description: algorithm?.description,
              })
                
              this.setState({
                  //versionNum: res.algorithm.versionNum,
                  createTime: algorithm.createTime,
                  updateTime: algorithm.updateTime,
                  algorithmName: algorithm.algorithmName,
                  algorithmVersion: algorithm.algorithmVersion,
                  description: algorithm.description,
              })
            }
            }).catch(error => {
              error && message.error(error);
            })
    }

    //调用编辑算法接口
    editAlgorithm(id, algorithmName, algorithmVersion, description) {
        algorithmService.editAlgorithm(id, algorithmName, algorithmVersion, description).then(res => {
            if (res.code === 200) {
              message.success("修改成功！")
              this.goback();
            }
            }).catch(error => {
              error && message.error(error);
            })
    }

    goback() {
        this.props.history.push('/home/algorithm-manage/view');
    }

    render() {
        const onFinish = (values) => {
            console.log(values);
            if (this.props.location.state) {
              console.log(this.props.location.state.id)
              this.editAlgorithm(this.props.location.state.id, this.state.algorithmName, values.algorithmVersion, values.description)
            } else {
              console.log(sessionStorage.getItem('editAlgorithmId'))
              this.editAlgorithm(sessionStorage.getItem('editAlgorithmId'), this.state.algorithmName, values.algorithmVersion, values.description)
            }
        };

        return (
            <div className="common-pd20">
        <BackPage onClick={() => this.goback()} headeTitle="编辑算法版本" itemTitle="编辑算法版本" itemSubTitle="编辑算法版本" />
        <div className="common-create-center-container">
           
          <div className="common-create-page">
          
       
            <Form
              ref={this.formRef}
              name="validate_other"
              labelAlign="left"
              {...formItemLayout}
              onFinish={onFinish} >
                <Form.Item name="algorithmName" label="算法名称" rules={[{ required: true, message: '请输入算法名称' }]} >
                    {this.state.algorithmName}
                </Form.Item>
                  
                <Form.Item name="algorithmVersion" label="算法版本"
                  rules={[
                    { required: true, message: '请输入算法版本' },
                    { max: 64, message: '算法版本不允许超过64个字符'}, 
                  ]} >
                    <Input placeholder="请输入算法版本" />
                </Form.Item>

                <Form.Item name="description" label="描述" >
                    <TextArea autoSize={{ minRows: 3, maxRows: 5 }} showCount maxLength={255} />
                </Form.Item>

                <Form.Item name="createTime" label="创建时间">
                     {this.state.createTime || '--'}
                </Form.Item>

                <Form.Item name="updateTime" label="更新时间" >
                     {this.state.updateTime || '--'}
                </Form.Item>
          
            
              <BottomBtnGroup onClick={() => this.goback()} />
           
            </Form>
        
       
          </div>
        </div>
        </div>
        )
    }
}

export default connect(
    (state) => ({
      currentUser: state.currentUser
    })
  )(EditAlgorithmVersionMangement);