import React from 'react';
import { Form } from 'antd';

import BackPage from '../../../common/backPage';
import BottomBtnGroup from '../../../common/bottomBtnGroup';
import { getModelIdAction } from '../../../../../store/actionCreators';
import store from '../../../../../store';


const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};


class ModelDetail extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      modelDetail: null
    };
  }
  goback() {
    this.props.history.push('/home/fringe-market');
  }

  componentDidMount() {
    if (this.props.location.state) {
      const model = this.props.location.state.result;
      if (model) {
        const action = getModelIdAction(model);
        store.dispatch(action);
        this.setState({
          modelDetail: model
        });
      }
    } else {
      const model = store.getState().model;
      if (model) {
        this.setState({
          modelDetail: model
        });
      }
    }
  }


  render() {
    return (
      <>
        <div className="common-pd20">
          <BackPage onClick={() => this.goback()} headeTitle="模型详情" itemTitle="边缘市场" itemSubTitle="发布模型" />
          <div className="common-create-center-container">
            <div className="common-create-page">
              <Form
                ref={this.formRef}
                name="validate_other"
                labelAlign="left"
                {...formItemLayout} >
                <Form.Item label="名称" >
                  {this.state.modelDetail?.name || '暂无数据'}
                </Form.Item>
                <Form.Item label="版本">
                  {this.state.modelDetail?.version || '暂无数据'}
                </Form.Item>
                <Form.Item>
                </Form.Item>
                <Form.Item label='图标' >
                  {this.state.modelDetail?.img || '暂无数据'}
                </Form.Item>
                <Form.Item label='文件' >
                  {this.state.modelDetail?.img || '暂无数据'}
                </Form.Item>
                <Form.Item label="描述" >
                  {this.state.modelDetail?.description || '暂无数据'}
                </Form.Item>
                <BottomBtnGroup onClick={() => this.goback()} />
              </Form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ModelDetail;
