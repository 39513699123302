import React, {Component} from 'react';
import { Select, Form, Col, Input, Button, Table, Pagination,Badge , InputNumber, ConfigProvider, Modal, message, Menu,  Dropdown} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter as Router } from 'react-router-dom';

import { edgeService } from '../../../../services/edge.service';
import { labelService } from '../../../../services/label.service';
import { userService } from '../../../../services/user.service';
import { AUTCONFIG } from '../../../../../utils/auth.config'

import './../../../../../index.scss';
import './list.scss';
import store from '../../../../../store';

import { connect } from "react-redux";
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { searchRule } from '../../../../../utils/search-util'

const { Option } = Select;


const { confirm } = Modal;

let page = 1;
let pageSize = 10;
let searchEdgeName = '';
let searchStatus = '';
let searchCodeName = '';
let searchCodeLabel = [];
let searchUser = '';

let flag = false;

class Search extends Component {
  constructor(props) {
    super(props);
   
  }
  
  form = React.createRef();

  onFinish = (values) => {
  
    let str=''
    if (values.edgeName != null) {
      str = searchRule(values.edgeName)
      console.log(str); 
    }
    
    // const strr = values.edgeName.replace(/[\^\%\\\{\}\#\&]/g, '@')
    // const str = strr.split("\\")
    // console.log(strr);
    // console.log(...str);
    // console.log(str.length);

    // let newStr=''
    // if (str.length != 1) {
      
    //   for (let i = 0; i < str.length; i++){
    //       newStr+=str[i]+'@'
    //   }
    // } else {
    //   for (let i = 0; i < str.length; i++){
    //     newStr+=str[i]
    //   }
    // }
    
    
    // console.log(newStr);

    // const str2 = newStr.split(" ")
    // let newStr2=''
    // for (let i = 0; i < str2.length; i++){
    //     newStr2+=str2[i]
    // }
    
    // console.log(newStr2);

    let newCodeLabel = [];
    if (values.codeLabel != null) {
      newCodeLabel=values.codeLabel.map(ele => {
        return ele = ele - 0;
      })
    }

    let newUsername = 0;
    if (values.username != null) {
      newUsername = values.username - 0;
    }
    
    // let newCodeName = '';
    // let reg = /^[0-9a-zA-Z-_\u4e00-\u9fa5]{1,}$/;
    // if (!reg.test(values.codeName)) {
    //   message.warning('请输入64位以内中英文/数字/中划线/下划线的节点名称格式',10)
    // } else {
    //   newCodeName = values.codeName;
    // }

    let newCodeName = '';
    if (values.codeName != null) {
      newCodeName = searchRule(values.codeName)
      console.log(newCodeName); 
    }

    console.log(newCodeLabel);
    console.log(newUsername);
    console.log(newCodeName);
    searchEdgeName = str || '';
    searchStatus = values.status || '';
    searchCodeName = newCodeName || '';
    searchCodeLabel = newCodeLabel || [];
    searchUser = newUsername || 0;
    console.log(values);
    page = 1;
    this.props.onClick();
    
  };
 
  resetForm = () => {
    this.form.current.resetFields();
    searchEdgeName = '';
    searchStatus = '';
    searchCodeLabel = [];
    searchCodeName = '';
    searchUser = '';
    page = 1;
    pageSize = 10;
    flag = true;
    this.props.onClick();
  }

  componentDidMount() {
    this.resetForm();
  }

  render() {
    return (
      //className="common-search-form"这个属性在index.scss的193行，输入框宽高(209行)，下拉选择框宽高(223行)
      <div className="common-search-form"> 
        <Form layout="inline"
          ref={this.form}
          name="advanced_search_user"
          className="ant-advanced-search-form"
          onFinish={this.onFinish}
          //在容器中央对齐弹性项目
          // style={{ justifyContent: 'center' }}
          >
          
          <Form.Item name='edgeName' label='应用名称' style={{ marginTop: '8px' }} >
            <Input placeholder="请输入账号名称"  />
          </Form.Item>

          <Form.Item name='status' label='应用状态' style={{ marginTop: '8px' }}>
              <Select allowClear
                      placeholder="请选择应用状态" >
                      {this.props.stateList}
              </Select>
          </Form.Item>

          <Form.Item name='codeName' label='节点名称' style={{ marginTop: '8px' }}>
                <Input placeholder="请输入节点名称" />
          </Form.Item>

          <Form.Item name='codeLabel' label='节点标签' style={{ marginTop: '8px' }}>
              <Select mode="multiple" allowClear showArrow
                      placeholder="请选择节点标签" >
                      {this.props.labelList}
              </Select>
          </Form.Item>

          {
              store.getState().currentUser.type !== AUTCONFIG.SUB.value ?
                <Form.Item name='username' label='所属用户' style={{ marginTop: '8px' }}>
                  <Select style={{ width: '200px' }} placeholder="请选择所属用户" showSearch  
                  allowClear 
                  filterOption={(input, option) =>
                    option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                    {this.props.tenantList}
                  </Select>
                </Form.Item>
                : ''
            }

          <Form.Item style={{ marginTop: '8px' }}>
                    <Button type="primary" htmlType="submit"> 查询 </Button>
                    <Button style={{ margin: '0 9.46px' }} onClick={this.resetForm}>重置 </Button>
          </Form.Item>
        </Form>
      </div>
      )
    }
}


// let defineArr = [{ name: 'ubuntu', things: ['4.0', '3.0', '2.0'], doings: ["aaa", 'bbb', 'ccc'] },
//   { name: 'simple', things: ['20.10', '20.11', '20.12'], doings: ['111', '222', '333'] }];

//联选镜像数组
let defineArr = [{ name: '', things: [], doings: [] },];
                  
let selectVersion = [];//升级的镜像对应的版本数组
let selectVersionApps = [];//批量升级
let ids = [];//升级，对应镜像版本id的数组，找到所要升级的镜像id
let idsApps = [];//批量升级

class EdgeEffectListManagementDemo extends Component {
  formRef = React.createRef();
 
  state = {
    edgeList: [],

    loading: false,
    actionIds: [], //用来保存可选框的状态
    actionApps: [],//用来保存选择的应用
    page: 1,
    pageSize: 10,
    edgeAmount: 10,

    isModalVisible: false, //单应用升级弹窗是否可见
    configIsModalVisible: false,  //单应用调整资源弹窗是否可见
    appsModalVisible: false, //多应用升级弹窗是否可见

    id: 0,//用来升级发送的应用id
    imageName: '', //升级获取应用镜像
    imageVersion: '',//升级获取应用镜像版本
    images: [],

    cpuLimit: 0,
    gpuLimit: 0,
    memLimit: 0,

    //state: false,
   
    stateList: [],//状态下拉框选项集合
    labelList: [],//节点标签下拉框选项集合
    tenantList: [],//所属用户下拉框选项集合
  }

  //所属用户接口
  getTenantList() {
    userService.getAllTenantList().then(res => {
      if (res.code === 200) {
        const tenants = res.users;
        this.setState({
          tenantList: this.getTenantItem(tenants),
          //companyUserList: this.getTenantItem(tenants),
        });
      }
    }, error => {
      message.error(error);
    });
  }

  //所属用户下拉选择
  getTenantItem(tenants) {
    return tenants.map((item, index) => {
      return (
        <Option key={item.id} >{item.realName}/{item.username}</Option>
      );
    });
  }

  //启动的弹框提示
  showEnaConfirm(event) {
      if (event.oprCode === 25) {
          message.warning('已经启用了')
      } else {
      confirm({
        title: `确定启动${event.name}吗?`,
        icon: <ExclamationCircleOutlined />,
        onOk: () => {
          //调用启用接口
          this.getStart(event.id)

          console.log("25表示启动，此时的操作码为：",event.oprCode);
          message.success('操作成功！');
          this.changeType();
          this.searchList()
      },
      onCancel() {
      },
    });
    }
    
  }

  //调用启动接口
  getStart(id) {
    this.setState({
      loading: true
    });

    edgeService.getEdgeStart(id).then(res => {
      if (res.code === 200) {
        console.log("启动的反馈：", res);
        this.setState({
          actionIds: [],
          actionApps: []
        });
      }
    }).catch(error => {
      error && message.error(error);
      this.setState({
        actionIds: [],
        actionApps: []
      });
    })
  }

  //停止的弹框提示
  showDisConfirm(event) {
    if (event.oprCode === 24) {
      console.log("hdedfheufhe");
      message.warning('已经停止了')
    } else {
      confirm({
        title: `确定停止${event.name}?`,
        icon: <ExclamationCircleOutlined />,
        onOk: () => {
          //调用停止接口
          this.getStop(event.id)

          console.log("24表示停止，此时的操作码为:",event.oprCode);
          message.success('操作成功！');
          this.changeType();
          this.searchList()
        },
        onCancel() {
        },
      });
    }
  }

  //调用停止接口
  getStop(id) {
    this.setState({
      loading: true
    });
    
    edgeService.getEdgeStop(id).then(res => {
      if (res.code === 200) {
        console.log("停止的反馈：", res);
        this.setState({
          actionIds: [],
          actionApps: []
        });
      }
    }).catch(error => {
      error && message.error(error);
      this.setState({
        actionIds: [],
        actionApps: []
      });
    })
  }

  //删除的弹框提示
  showDeleteConfirm(event) {
    confirm({
      title: '确定删除吗?',
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ color: '#ffb051' }}>删除之后不可恢复，请谨慎操作！</p>,
      closable: true,
      onOk: () => {
        console.log("要删除的id为：",event.id);
        this.deleteApp(event.id)
        message.success('操作成功！');
        page = 1;
        this.changeType();
        this.searchList()
      },
      onCancel() {

      },
    });
  }

  //调用删除接口
  deleteApp(id) {
    this.setState({
      loading: true
    });

    edgeService.deleteApp(id).then(res => {
      if (res.code === 200) {
        console.log("删除的反馈", res);
        this.setState({
          actionIds: [],
          actionApps: []
        });
      }
    }).catch(error => {
      error && message.error(error);
      this.setState({
        actionIds: [],
        actionApps: []
      });
    })
  }

  getStateList() {
    const arr = [
      { id: 1, state: 'RUNNING', name: '运行中' },
      { id: 2, state: 'STOPPED', name: '已停止' },
      { id: 3, state: 'FAILED', name: '异常' },
      { id: 4, state: 'WAITING', name: '等待' },
      { id: 5, state: 'UPGRADEWAITING', name: '待升级' },
      { id: 6, state: 'UPGRADING', name: '升级中' },
      { id: 7, state: 'OFFLINE', name: '不在线' },
    ];
    this.setState({
      stateList: this.getStateItem(arr)
    });
  }
  getStateItem(states) {
    return states.map((item) => {
      return (
        <Option key={item.state} >{item.name}</Option>
      );
    });
  }

  //匹配应用状态
  getAppType = (record) => {
    if (record.state === 'RUNNING') {
      return <span><Badge status="success" />运行中</span>
    }
    else if (record.state === 'STOPPED') {
      return <span><Badge status="default" />已停止</span>
    }
    else if (record.state === 'FAILED') {
      return <span><Badge status="error" />异常</span>
    }
    else if (record.state === 'WAITING') {
      return <span><Badge status="warning" />等待</span>
    }
    else if (record.state === 'UPGRADEWAITING') {
      return <span><Badge status="warning" />待升级</span>
    }
    else if (record.state === 'UPGRADING') {
      return <span><Badge status="warning" />升级中</span>
    }
    else if (record.state === 'OFFLINE') {
      return <span><Badge status="error" />不在线</span>
    }
  }

  getOperByRecordState(text, record) {
    return (
      <>
        {
          record.state === 'STOPPED' ?
            <a href onClick={() => this.showEnaConfirm(record)} style={{ marginRight: '5px' }}>启用</a>
            : ''
        }
        
        {
          record.state === 'RUNNING' ?
            <a href onClick={() => this.showDisConfirm(record)} style={{ marginRight: '5px' }}>停止</a>
            : ''
        }
        
        {
          record.state === 'RUNNING' || record.state === 'FAILED'?
            <a href onClick={this.editEdge.bind(text, record.id)} style={{ marginRight: '5px' }}>编辑</a>
            : ''
        }
        
        {
          record.state === 'RUNNING' || record.state === 'FAILED'?
            <a href onClick={() => this.showShenjiWin(record)} style={{ marginRight: '5px' }}>升级</a>
            : ''
        }
        
        {
          record.state === 'RUNNING' || record.state === 'FAILED'?
            <a href onClick={() => this.showAdjust(record)} style={{ marginRight: '5px' }}>调整资源</a>
            : ''
        }
        {/* <a href onClick={() => this.showDeleteConfirm(record)} style={{ marginRight: '5px' }}>删除</a> */}
        {
          record.state === 'STOPPED' || record.state === 'FAILED'  ?
            <a href onClick={() => this.showDeleteConfirm(record)} style={{ marginRight: '5px' }}>删除</a>
            : ''
        }
        
      </>
    );
  }

  //匹配网络类型
  getNetType = (record) => {
    if (record.networkType === 0) {
      return <span>主机网络</span>
    } else if (record.networkType === 1) {
      return <span>非主机网络</span>
    }
  }

  //设置分页与加载
  changeType() {
    if (flag) {
      this.setState({
        actionIds: [],
        actionApps: [],
      })
      flag = false
    }

    this.setState({
        page,
        pageSize
    })

    this.setState({
      loading: true
    });
    // setTimeout(() => {
    //   this.setState({
    //       loading: false
    //     }); 
    // }, 200)

    //查找
    this.searchList()
  }

  //调用查找接口
  searchList() {
    this.setState({
      loading: true
    });

    //console.log(searchUser);
    edgeService.getEdgeList(searchEdgeName, searchStatus, searchCodeName, searchCodeLabel, searchUser, page, pageSize).then(res => {
      if (res.code === 200) {
        console.log("查找的列表信息为:",res);
        const apps = res.apps;
        this.setState({
          edgeList: apps,
          loading: false,
          edgeAmount: res.dataCount
        })
        // this.setState({
        //   actionIds: [],
        //   actionApps: [],
        // })
        console.log(this.state.actionIds);
        let arr = this.merge(apps, this.state.actionIds)
        console.log(arr);
        this.setState({
          actionApps: arr
        })
      }
    }).catch(error => {
      error && message.error(error);
      this.setState({
        loading: false,
      })
      // this.setState({
      //   actionIds: [],
      //   actionApps: [],
      // })
    })
  }

  //每次刷新获取最新状态的选择的应用
  merge(apps, ids) {
    let arr = []
    for (let item of apps) {
      if (ids.includes(item.id)) {
        arr.push(item)
      }
    }
    return arr
  }

  //调用升级接口
  upAPP(id, imageId) {
    edgeService.upgradeEdge(id, imageId).then(res => {
      if (res.code === 200) {
        console.log(res);
        message.success("操作成功！")
        this.setState({
          actionIds: [],
          actionApps: []
        });
      }
    }).catch(error => {
      error && message.error(error);
      this.setState({
        actionIds: [],
        actionApps: []
      });
    })
  }

  //调用调整资源接口
  adjustAPP(id, mem, cpu, gpu) {
    edgeService.adjustSourceEdge(id, mem, cpu, gpu).then(res => {
      if (res.code === 200) {
        console.log(res);
        message.success("操作成功！")
        this.setState({
          actionIds: [],
          actionApps: []
        });
      }
    }).catch(error => {
      error && message.error(error);
      this.setState({
        actionIds: [],
        actionApps: []
      });
    })
  }

  //详情查看
  gotoDetail = (text, key) => {
    //console.log(text);
    this.props.history.push({ pathname: '/home/effect-edge-m/detail', state: { id: text } });
  }

  //编辑应用
  editEdge = (text, key) => {
    this.props.history.push({ pathname: '/home/effect-edge-m/edit', state: { id: text } });
  }

  //详情获取
  getShellDetail(appId) {
    edgeService.getEdgeDetail(appId).then(res => {
        if (res.code === 200) {
            const app = res.app;
            console.log("应用详情：",app);
          
          console.log(appId);
          console.log(this.state.edgeList);

          if (this.state.edgeList.length != 0) {
            for (let i = 0; i < this.state.edgeList.length; i++){
            if (this.state.edgeList[i].id === appId) {
              this.state.edgeList[i].state='WAITING'
            }
            }
          }

          // this.setState({
          //     state: app.state,
          // })

        }  
    }).catch(error => {
      error && message.error(error);
    })
  }

  componentDidMount() {
    searchEdgeName = '';
    searchStatus = '';
    searchCodeLabel = [];
    searchCodeName = '';
    searchUser = '';
    // page = 1;
    // pageSize = 10;
    // this.searchList();
    this.getStateList();
    this.getLabelList();
    this.getTenantList();

    this.getImage()
    this.backInterval = setInterval(()=>{
      this.searchList()
    }, 30000)
    
  }

  //页面跳转清除定时器
  componentWillUnmount() {
    clearInterval(this.backInterval)
  }

  getLableItem(labels) {
    return labels.map((item, index) => {
      return (
        <Option key={item.id} >{item.name}</Option>
      );
    });
  }
  getLabelList() {
    labelService.getAllLabels().then(res => {
      if (res.code === 200) {
        const labels = res.labels;
        this.setState({
          labelList: this.getLableItem(labels)
        });
      }
    }, error => {
      error && message.error(error);
    });
  }

  //镜像数组的合并
  mergeArr(images) {
    let testArr = [];
    let resultArr = [];
    for (let i = 0; i < images.length; i++) {
      if (testArr.indexOf(images[i].name) === -1) {
        resultArr.push({
        name: images[i].name,
        things: [images[i].version],
        doings: [images[i].imageUrl],
        ids: [images[i].id],
      });
      testArr.push(images[i].name);
      }
      else {
        for (let j = 0; j < resultArr.length; j++) {
          if (resultArr[j].name == images[i].name) {
            resultArr[j].things.push(images[i].version);
            resultArr[j].doings.push(images[i].imageUrl);
            resultArr[j].ids.push(images[i].id);
            break;
          }
        }
      }
    }
    return resultArr
  }

  //镜像查询接口
  getImage() {
    edgeService.getImageList().then(res => {
      if (res.code == 200) {
        console.log("查找的镜像信息为：", res);
        if (res.images.length != 0) {
          defineArr = this.mergeArr(res.images)
        }
        
        console.log(defineArr);
        
        // this.setState({
        //   //images: res.images,
        // })
      }
    }).catch(error => {
      error && message.error(error);
    })
  }

  //升级
  showShenjiWin(event) {
    console.log("该应用信息",event);
    this.getImage()

    console.log(defineArr);

    for (let i = 0; i < defineArr.length; i++){
      if (defineArr[i].name === event.imageName) {
        selectVersion = defineArr[i].things;
        ids = defineArr[i].ids

        this.formRef.current?.setFieldsValue({
        version: event.imageVersion,
        })
        
        break;
      } else {
          selectVersion = []
          this.formRef.current?.setFieldsValue({
            version: '',
        })
      }
    }
    console.log(selectVersion);
    console.log(ids);

    console.log(event.imageVersion);


    this.setState({
      isModalVisible: true,
      id: event.id,
      imageName: event.imageName,
      imageVersion: event.imageVersion,
    })

    console.log("该应用信息",event);
  }

  //调整资源
  showAdjust(event) {
    console.log(event);

    this.formRef.current?.setFieldsValue({
      CPU: event.cpuLimit,
      GPU: event.gpuLimit,
      MEM: event.memLimit,
    })

    this.setState({
      configIsModalVisible: true,
      id: event.id,
      cpuLimit: event.cpuLimit,
      gpuLimit: event.gpuLimit,
      memLimit: event.memLimit,
    })
  }

  //取消按钮关闭弹窗
  handleOk() {
    this.setState({
        isModalVisible: false,
        configIsModalVisible: false,
        appsModalVisible: false,
    })
  }

  //批量启动接口
  getStartApps(ids) {
    this.setState({
      loading: true
    });

    edgeService.startApps(ids).then(res => {
      if (res.code === 200) {
        console.log("批量启动：", res);
        this.setState({
          actionIds: [],
          actionApps: []
        });
      }
    }).catch(error => {
      error && message.error(error);
      this.setState({
        actionIds: [],
        actionApps: []
      });
    })
  }

  //批量停止接口
  getStopApps(ids) {
    this.setState({
      loading: true
    });

    edgeService.stopApps(ids).then(res => {
      if (res.code === 200) {
        console.log("批量停止：", res);
        this.setState({
          actionIds: [],
          actionApps: []
        });
      }
    }).catch(error => {
      error && message.error(error);
      this.setState({
        actionIds: [],
        actionApps: []
      });
    })
  }

  //批量删除接口
  getDeleteApps(ids) {
    this.setState({
      loading: true
    });

    edgeService.deleteApps(ids).then(res => {
      if (res.code === 200) {
        console.log("批量删除：", res);
        this.setState({
          actionIds: [],
          actionApps: []
        });
      }
    }).catch(error => {
      error && message.error(error);
      this.setState({
        actionIds: [],
        actionApps: []
      });
    })
  }

  //批量升级接口
  getUpgradeApps(ids, imageId) {
    this.setState({
      loading: true
    });

    edgeService.upgradeApps(ids, imageId).then(res => {
      if (res.code === 200) {
        console.log("批量升级：", res);
        this.setState({
          actionIds: [],
          actionApps: []
        });
      }
    }).catch(error => {
      error && message.error(error);
      this.setState({
        actionIds: [],
        actionApps: []
      });
    })
  }


  //批量启动
  runApps = () => {
    console.log("选择批量启动的应用id", this.state.actionIds);
    let { actionIds, actionApps } = this.state;
    let flages = true;

    if (actionApps.length != 0) {
      for (let item of actionApps) {
        if (item.state != 'STOPPED') {
          flages = false
        }
      }

      if (flages) {
        confirm({
          title: '确定批量启动吗?',
          icon: <ExclamationCircleOutlined />,
          content: <p style={{ color: '#ffb051' }}>批量启动之后不可恢复，请谨慎操作！</p>,
          closable: true,
          onOk: () => {
            console.log(actionIds);
            //调用批量启动接口
            this.getStartApps(actionIds)
            message.success('操作成功！批量启动中');
            // this.setState({
            //   actionIds: [],
            //   actionApps: []
            // });
            page = 1;
            this.changeType();
            this.searchList()
          },
          onCancel() {
    
          },
        });
        
      } else {
        message.error("选择的应用都为‘已停止’状态才可进行批量启动操作！",5);
      }
    } else {
      message.warning("请选择应用！")
    }
    
    
  }

  //批量停止
  stopApps = () => {
    console.log("选择批量停止的应用id", this.state.actionIds);
    let { actionIds, actionApps } = this.state;
    let flages = true;

    if (actionApps.length != 0) {
      for (let item of actionApps) {
        if (item.state != 'RUNNING') {
          flages = false
        }
      }
  
      if (flages) {
        confirm({
          title: '确定批量停止吗?',
          icon: <ExclamationCircleOutlined />,
          content: <p style={{ color: '#ffb051' }}>批量停止之后不可恢复，请谨慎操作！</p>,
          closable: true,
          onOk: () => {
            console.log(actionIds);
            //调用批量停止接口
            this.getStopApps(actionIds)
            message.success('操作成功！批量停止中');
            page = 1;
            this.changeType();
            this.searchList()
          },
          onCancel() {
    
          },
        });

      } else {
        message.error("选择的应用都为‘运行中’状态才可进行批量停止操作！",5);
      } 
    } else {
      message.warning("请选择应用！")
    }
    
  }

  //批量删除
  deleteApps = () => {
    console.log("选择批量删除的应用id", this.state.actionIds);
    let { actionIds, actionApps } = this.state;
    let flages = true;

    if (actionApps.length != 0) {
      for (let item of actionApps) {
        if (item.state != 'FAILED' && item.state != "STOPPED") {
          flages = false
        }
      }
  
      if (flages) {
        confirm({
          title: '确定批量删除吗?',
          icon: <ExclamationCircleOutlined />,
          content: <p style={{ color: '#ffb051' }}>批量删除之后不可恢复，请谨慎操作！</p>,
          closable: true,
          onOk: () => {
            console.log(actionIds);
            //调用批量删除接口
            this.getDeleteApps(actionIds)
            message.success('操作成功！批量删除中');
            page = 1;
            this.changeType();
            this.searchList()
          },
          onCancel() {
    
          },
        });

      } else {
        message.error("选择的应用都为‘异常’状态或者‘已停止’状态才可进行批量删除操作！",5);
      }
    } else {
      message.warning("请选择应用！")
    }
    
  }

  //批量升级准备镜像版本
  prepImageVersion() {
    this.getImage()
    console.log(defineArr);

    for (let i = 0; i < defineArr.length; i++){
      if (defineArr[i].name === this.state.actionApps[0]?.imageName) {
        selectVersionApps = defineArr[i].things;
        idsApps = defineArr[i].ids

        // this.formRef.current?.setFieldsValue({
        //   version: this.state.actionApps[0]?.imageVersion,
        // })
        
        break;
      } else {
        selectVersionApps = []
          this.formRef.current?.setFieldsValue({
            version: '',
        })
      }
    }
  }

  //批量升级
  upApps = () => {
    this.prepImageVersion()
    console.log("选择批量升级的应用id", this.state.actionIds);
    let { actionIds, actionApps } = this.state;
    let flages = true;

    if (actionApps.length != 0) {
      let demo = actionApps[0]?.imageName //判断是否为同一镜像的参照
      let sameImageName = actionApps.every(item => item?.imageName === demo)  //逐个比对镜像
      console.log(sameImageName);
      for (let item of actionApps) {
        if (!['RUNNING','FAILED'].includes(item.state)|| !sameImageName) {
          flages = false
        }
        // if (!sameImageName) {
        //   flages = false
        // }
      }

  
      if (flages) {
        this.setState({
          appsModalVisible: true,
        })
        
      } else {
        message.error("选择的应用都为‘运行中’或者‘异常’状态且为‘同一镜像’才可进行批量升级操作！",5);
      }
    } else {
      message.warning("请选择应用！")
    }

  }

  //表格选择框的改变
  onSelectChange = (actionIds,actionApps) => {
    console.log('selectedRowKeys changed: ', actionIds);
    console.log(actionApps);
    this.setState({ actionIds, actionApps });
  };

  render() {
    //console.log(this.state.imageName);
    //console.log(this.state.edgeList);
    const {appsModalVisible}=this.state

    //批量操作菜单
    const menu = (
      <Menu>
        <Menu.Item key="1" onClick={this.runApps}>启动</Menu.Item>
        <Menu.Item key="2" onClick={this.stopApps}>停止</Menu.Item>
        <Menu.Item key="3" onClick={this.deleteApps}>删除</Menu.Item>
        <Menu.Item key="4" onClick={this.upApps}>升级</Menu.Item>
      </Menu>
    );

    //分页下拉框获取分页的大小
    const onPageChange = (pageNumber) => {
      page = pageNumber;
      this.changeType();
    }

    //pageSize 变化的回调
    const onShowSizeChange = (current, pageSizeNumber) => {
      pageSize = pageSizeNumber;
      page = 1;
  
      this.changeType();
    }

    // //对象变量，用来保存对行数据的选中
    // const rowSelection = {
    //   //指定选中项的 key 数组，需要和 onChange 进行配合
    //   onChange: (selectedRowKeys, selectedRows) => {
    //     selectId = selectedRowKeys
    //     //console.log(selectedRows);

    //     this.setState({
    //       actionIds: selectedRowKeys
    //     })
    //   }
    // }
    

    //对象变量，用来保存对行数据的选中
    const { actionIds } = this.state
    const rowSelection = {
      selectedRowKeys: actionIds,
      onChange: this.onSelectChange,
    }

    //创建应用
    const registered = () => {
      this.props.history.push('/home/effect-edge-m/add');
    };

    //表格列的描述
    const columns = [
      {
        title: '应用名称',
        dataIndex: 'name',
        //参数分别为当前dataIndex的值，当前行整条的数据(对象类型)，行索引
        render: (text, record) => (
          <a onClick={this.gotoDetail.bind(text, record.id)}>{text}</a>
        )
      },
      {
        title: '应用镜像',
        dataIndex: 'imageName',
      },
      {
        title: '镜像版本',
        dataIndex: 'imageVersion',
      },
      {
        title: '节点名称',
        dataIndex: 'nodeName',
      },
      {
        title: '状态',
        dataIndex: 'state',
        render: (text, record) => (
          this.getAppType(record)
        )
      },
      {
        title: '所属用户',
        dataIndex: 'createBy',
      },
      {
        title: '网络类型',
        dataIndex: 'networkType',
        render: (text, record) => (
          this.getNetType(record)
        )
      },
    
      {
        title: '创建时间',
        dataIndex: 'createTime',
      },
      {
        title: '更新时间',
        dataIndex: 'updateTime',
      },
      {
        title: '操作',
        dataIndex: 'action',
        width: '20%',
        //参数分别为当前行的值，当前行数据，行索引
        render: (text, record) => (
          <>
            {/* <a href onClick={() => this.showEnaConfirm(record)} style={{ marginRight: '5px' }}>启用</a>
            <a href onClick={() => this.showDisConfirm(record)} style={{ marginRight: '5px' }}>停止</a>
            <a href onClick={this.editEdge.bind(text, record.id)} style={{ marginRight: '5px' }}>编辑</a>
            <a href onClick={() => this.showShenjiWin(record)} style={{ marginRight: '5px' }}>升级</a>
            <a href onClick={() => this.showAdjust(record)} style={{ marginRight: '5px' }}>调整资源</a>
            <a href onClick={() => this.showDeleteConfirm(record)} style={{ marginRight: '5px' }}>删除</a> */}
            {this.getOperByRecordState(text, record)}
          </>
        )
      },
    ];

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const layout1 = {
      labelCol: { span: 7 },
      wrapperCol: { span: 16 },
    };

    //升级下拉选项
    const things = selectVersion.map((item, index)=> {
      return <Option key={index} value={item}>{item}</Option>
    })

    //批量升级下拉选项
    const thingsApps = selectVersionApps.map((item, index)=> {
      return <Option key={index} value={item}>{item}</Option>
    })

    //确定升级
    const onFinish = (e) => {
      e.imageName=this.state.imageName
      console.log(e);
      console.log(this.state.imageVersion);
      console.log(selectVersion);
      console.log(ids);
      console.log(this.state.id);

      let imageId = 0
      if (e.version === this.state.imageVersion) {
        message.warning("修改版本为当前版本！")
      } else {
        imageId = ids[selectVersion.indexOf(e.version)]
        console.log(selectVersion.indexOf(e.version));
        console.log(ids[selectVersion.indexOf(e.version)]);
        this.upAPP(this.state.id, imageId)
        this.searchList()
      }
      this.setState({
        isModalVisible: false
      })
    }

    //确定批量升级
    const appsOnFinish = (e) => {
      e.imageName=this.state.actionApps[0]?.imageName
      console.log("弹框选择的数据", e);
      console.log(selectVersionApps);
      console.log("版本对应的所有id集合",idsApps);
      console.log("需要批量升级的应用：", this.state.actionIds);
      
      let imageId = 0

      //对为选择升级版本进行限制
      if (e.versionApps === undefined || e.versionApps === '') {
        message.error("请选择升级的版本！")
      } else {
        //过滤已在当前版本的应用
        console.log(this.state.actionApps);
        const { actionApps } = this.state
        let newActionApps = actionApps.filter((item) => {
          return item.imageVersion === e.versionApps ? false : true;
        })

        console.log("过滤后的应用",newActionApps);
        let newIds = []
        if (newActionApps.length != 0) {
          newIds = newActionApps.map(item => item.id)
        }
        console.log("需要升级应用的id数组",newIds);

        let imageId = 0
        imageId = idsApps[selectVersionApps.indexOf(e.versionApps)]
        console.log("镜像版本id",imageId);

        if (newIds.length != 0) {
          this.getUpgradeApps(newIds, imageId)
          message.success("批量升级中");
          this.searchList()
        } else {
          message.warning("所选应用镜像当前版本与要升级的版本一致！", 5)
        }
        
        this.setState({
        appsModalVisible: false
        })
         
      }
      
      // if (e.version === this.state.imageVersion) {
      //   message.warning("修改版本为当前版本！")
      // } else {
      //   imageId = ids[selectVersion.indexOf(e.version)]
      //   console.log(selectVersion.indexOf(e.version));
      //   console.log(ids[selectVersion.indexOf(e.version)]);
      //   this.upAPP(this.state.id, imageId)
      //   this.searchList()
      // }
      
    }

    //确定调整资源
    const adjustFinish = (e) => {
      e.CPU=parseFloat(e.CPU)
      e.GPU=parseFloat(e.GPU)
      e.MEM=parseFloat(e.MEM)
      console.log(e);
      console.log(this.state.cpuLimit);
      console.log(this.state.id);
      
      if (e.CPU === this.state.cpuLimit && e.GPU === this.state.gpuLimit && e.MEM === this.state.memLimit) {
        message.warning("未对资源进行修改！")
      } else {
        this.adjustAPP(this.state.id, e.MEM, e.CPU, e.GPU)
        this.searchList()
      }

      this.setState({
        configIsModalVisible: false,
      })
      
    }

    return (
      //className="common-pd20"在index.scss第15行
      <div className="common-pd20"> 
        <h1 className="common-top-title0">应用管理</h1>

        <div className="common-center-container">
          <Search stateList={this.state.stateList} labelList={this.state.labelList} tenantList={this.state.tenantList}
            onClick={() => this.changeType()} />

          <div className="oper-button">
                <Router>
                  <Button type="primary" onClick={registered}>创建</Button>
                </Router>
                {/* <Router>
                  <Button type="primary" style={{ marginLeft: 10 }} >升级</Button>
               </Router> */}
            {/* <Dropdown overlay={menu} style={{ left: 20, width: 50, textAlign: "left" }} ><Button style={{ marginLeft: 10 }}>批量<DownOutlined /></Button></Dropdown > */}
            <Dropdown.Button overlay={menu} style={{ left:10, width: 13, textAlign:"left" }} >批量</Dropdown.Button>
          </div>
          
          <div className="user-table-card">
            <Table
              rowKey={record => record.id}  //用于标识唯一id，不然会造成选一个全选的问题
              columns={columns}  //表格列的配置描述
              dataSource={this.state.edgeList}    //数据填充
              tableLayout="fixed"   //内容不会影响列的布局
              pagination={false}    //分页器
              loading={this.state.loading}
              rowSelection={{
                ...rowSelection
              }
                
              }
          />
          </div>

          <div className="common-bottom-pagination">
              <ConfigProvider locale={zhCN}>
					      <Pagination
      				    showSizeChanger //展示分页切换器
	  				      defaultCurrent={1} 
	  				      current={this.state.page} 
	  				      pageSize={this.state.pageSize} 
	  				      total={this.state.edgeAmount} 
					        // showTotal={total => `共有${total}条`}
                  onShowSizeChange={onShowSizeChange} //	pageSize 变化的回调
					        onChange={onPageChange}   //页码或 pageSize 改变的回调，参数是改变后的页码及每页条数
					      >
					      </Pagination>
				      </ConfigProvider>
          </div>

        </div>
        
        <Modal title="应用升级" visible={this.state.isModalVisible} cancelText okText closable={false}>
          <Form {...layout}
            ref={this.formRef}
            name="control-ref"
            onFinish={onFinish}
            initialValues={{ imageName: "male", version: this.state.imageVersion }}
          >
             
            <Form.Item name="imageName" label="应用镜像">
                <Select disabled style={{width: 220}}>
                    <Option value="male">{this.state.imageName}</Option>
                </Select>
            </Form.Item>
            
            <Form.Item name="version" label="镜像版本" >
                <Select  
                    style={{width: 220}}>
                    {/* <Option value="male">{defineArr[1].things[0]}</Option>
                    <Option value="female">{defineArr[1].things[1]}</Option>
                    <Option value="other">{defineArr[1].things[2]}</Option> */}
                  {things}
                </Select>
            </Form.Item>
            <br/>
              <Form.Item  style={{marginLeft:250,marginBottom:-70}}>
                <Button type="primary" htmlType="submit"    > 确定 </Button>
                <Button onClick={this.handleOk.bind(this)} style={{marginLeft:10}} >取消</Button>
                </Form.Item>
                </Form>
        </Modal>

        {/* 批量升级 */}
        <div>{
          appsModalVisible&&
          <Modal title="批量升级" visible={this.state.appsModalVisible} cancelText okText closable={false}>
          <Form {...layout}
            ref={this.formRef}
            name="control-ref"
            onFinish={appsOnFinish}
            initialValues={{ imageName: "male" }}
          >
             
            <Form.Item name="imageName" label="应用镜像">
                <Select disabled style={{width: 220}}>
                    <Option value="male">{this.state.actionApps[0]?.imageName}</Option>
                </Select>
            </Form.Item>
            
            <Form.Item name="versionApps" label="镜像版本" >
                <Select  placeholder="请选择镜像版本" 
                    style={{width: 220}}>
                    {/* <Option value="male">{defineArr[1].things[0]}</Option>
                    <Option value="female">{defineArr[1].things[1]}</Option>
                    <Option value="other">{defineArr[1].things[2]}</Option> */}
                  {thingsApps}
                </Select>
            </Form.Item>
            <br/>
              <Form.Item  style={{marginLeft:250,marginBottom:-70}}>
                <Button type="primary" htmlType="submit"    > 确定 </Button>
                <Button onClick={this.handleOk.bind(this)} style={{marginLeft:10}} >取消</Button>
                </Form.Item>
                </Form>
        </Modal>
        }
          
        </div>
        
        

        <Modal title="调整应用资源配置" visible={this.state.configIsModalVisible} cancelText okText closable={false}>
          <Form {...layout1}
            ref={this.formRef}
            name="control-refd"
            onFinish={adjustFinish}
            initialValues={{ CPU: this.state.cpuLimit, GPU: this.state.gpuLimit,  MEM: this.state.memLimit}}
          >
            <Form.Item name="CPU" label="CPU">
              <Input type="number" min={0} max={10} step="0.01"
                  //defaultValue={this.state.memory}
                  addonAfter="核"
                  style={{ width: 220 }}
                />
            </Form.Item>

            <Form.Item name="MEM" label="内存" >
                <Input type="number" min={0} max={8192} step="0.01" 
                  //defaultValue={this.state.memory}
                  addonAfter="M"
                  style={{ width: 220 }}
                />
            </Form.Item>

            <Form.Item name="GPU" label="GPU" >
                <Input type="number" min={0} max={10} disabled={true} step="0.01"
                  //defaultValue={this.state.memory}
                  addonAfter="核"
                  style={{ width: 220 }}
                />
            </Form.Item>

            <br/>
              <Form.Item  style={{marginLeft:230,marginBottom:-70}}>
                <Button type="primary" htmlType="submit" > 确定 </Button>
                <Button onClick={this.handleOk.bind(this)} style={{marginLeft:10}} >取消</Button>
                </Form.Item>
            </Form>
        </Modal>
        
      </div>
    )
  }
}

export default connect(
  (state) => ({
    currentUser: state.currentUser
  })
)(EdgeEffectListManagementDemo);