import React from 'react';
import { Select, Form,  Input, Button, Table, Pagination, ConfigProvider, DatePicker, message, } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';

import { mirrorService } from '../../../../services/operation.service';
import { connect } from 'react-redux';
import moment from 'moment';
import { AUTCONFIG } from '../../../../../utils/auth.config'
import './ai-check-record.scss';
import './../../../../../index.scss';
import store from '../../../../../store';
import { searchRule } from '../../../../../utils/search-util'


let searchNodeName = '';
let searchStationId = '';
let searchAlgorithmName='';
let searchCheckSource='';
let searchResult='';
// let searchStartTime='';
// let searchEndTime='';


//获取当前时间
let date = new Date();
let year = date.getFullYear();
let month = date.getMonth() + 1;
let day = date.getDate();
if (month < 10) {
    month = "0" + month;
}
if (day < 10) {
    day = "0" + day;
}
let nowDate = year + "-" + month + "-" + day;

let searchStartTime = nowDate + " 00:00:00";
let searchEndTime = nowDate + " 23:59:59";
//select
const { Option } = Select;

function Search(props) {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log(values);
    let name = values.name || '';
    let str = searchRule(name)
    searchNodeName = str || '';

    let stationId = values.stationId || '';
    let str2 = searchRule(stationId)
    searchStationId = str2 || '';

    let algorithmName= values.algorithmName || '';
    // let str3 = searchRule(algorithmName)
    algorithmName = algorithmName.replace(/[\^\+\|\%\\\{\}\#\&\|\+]/g, '5a0c511f-3cc8-428c-860b-f747c16b2341')
    searchAlgorithmName = algorithmName || '';

    searchCheckSource=values.checkSource;
    searchResult=values.result;

    page = 1;
    props.onClick();
  };
  const resetForm = () => {
    form.resetFields();
    searchNodeName = '';
    searchStationId = '';
    searchAlgorithmName='';
    searchCheckSource='';
    searchResult='';
    // searchStartTime='';
    // searchEndTime='';
    searchStartTime = nowDate + " 00:00:00";
    searchEndTime = nowDate + " 23:59:59";
    page = 1;
    props.onClick();
  };

  const onChange = (values, datastring) => {
    //setValue(values)
    console.log(values, datastring);
    searchStartTime = datastring + " 00:00:00";
    searchEndTime = datastring + " 23:59:59";
    //this.props.onClick();
  };

  const timeStart=(value, dateString) =>{
    console.log('Formatted Selected Time: ', dateString);
    searchStartTime=dateString;
    
  }
  const timeEnd=(value, dateString) =>{
    searchEndTime=dateString;
    console.log('Formatted Selected Time: ', dateString);
  }
  // const onGenderChange = (value) => {
  //   const keys = Object.keys(value);
  //   if (keys.find(elx => elx === 'company')) {
  //     props.changeUserList(value.company);
  //     form.setFieldsValue({ username: null })
  //   }
  // }

  return (
    <>
      <div className="common-search-form node-mm">
        <Form
          form={form}
          name="advanced_search"
          layout="inline"
          // style={{ justifyContent: 'center' }}
          className="ant-advanced-search-form"
          // onValuesChange={onGenderChange}
          onFinish={onFinish} >

          <Form.Item name='name' label='节点名称' style={{ marginTop: '8px' }}>
            <Input placeholder="请输入节点名称" />
          </Form.Item>
          <Form.Item name='stationId' label='序列号' style={{ marginTop: '8px' }}>
            <Input placeholder="请输入序列号" />
          </Form.Item>
          <Form.Item name='algorithmName' label='算法名称' style={{ marginTop: '8px' }}>
            <Input placeholder="请输入算法名称" />
          </Form.Item>
          <Form.Item name='checkSource' label='鉴权来源' style={{ marginTop: '8px' }} >
              <Select placeholder="请选择鉴权来源" allowClear>
              <Option value="0">MA</Option>
              <Option value="1">算法</Option>
              </Select>
          </Form.Item>
          <Form.Item name='result' label='鉴权结果' style={{ marginTop: '8px' }} >
            <Select placeholder="请选择鉴权结果" allowClear>
              <Option value="0">失败</Option>
              <Option value="1">成功</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item name='startTime' label='开始时间' style={{ marginTop: '8px' }}>
            <DatePicker style={{width:200}} showTime onChange={timeStart}/>
          </Form.Item>
          <Form.Item name='endTime' label='结束时间' style={{ marginTop: '8px' }}>
            <DatePicker style={{width:200}} showTime  onChange={timeEnd}/>
          </Form.Item> */}
           <Form.Item name='time' label='日期选择' style={{ marginTop: '8px' }}>
              <DatePicker style={{width:200}} onChange={onChange} defaultValue={moment(nowDate, 'YYYY-MM-DD')} allowClear={ false}/>
            </Form.Item> 
          <Form.Item style={{ marginTop: '8px' }} label=''>
            <div style={{ width: '262px' }}>
              <Button type="primary" htmlType="submit"> 查询 </Button>
              <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
            </div>
          </Form.Item>

        </Form>
      </div>
    </>
  );
}

let page = 1;
let pageSize = 10;
class AiCheckRecord extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      firmLoading: false,
      recordsList: [],
      recordsAmount: 0,
      // deleteIds: [],
      showManage: true,
      loading: true,
      page: 1,
      pageSize: 10,
      // selectedLable: [],
      history: require("history").createHashHistory,
      tenantList: [],
      stateList: []
    };
  }
  form = React.createRef();
   //查询ai算法鉴权记录
  searchList() {
    this.setState({
      loading: true,
      page,
      pageSize
    })
    mirrorService.getAiCheckRecord(searchNodeName,searchStationId,searchAlgorithmName,searchCheckSource,searchResult,searchStartTime, searchEndTime,pageSize, page).then(res => {
        this.setState({
          loading: false
        })
        if (res.code === 200) {
          const nodes = res.records;
          console.log(nodes);
          this.setState({
            recordsList: nodes,
            recordsAmount: res.dataCount
          });
        }
        // else {
        //   message.error(res.message);
        // }
      }, error => {
        this.setState({
          loading: false
        })
        error && message.error(error);
      });
  }
  componentDidMount() {
    searchNodeName = '';
    searchStationId = '';
    searchAlgorithmName='';
    searchCheckSource='';
    searchResult='';
    searchStartTime = nowDate + " 00:00:00";
    searchEndTime = nowDate + " 23:59:59";
    page = 1;
    pageSize = 10;
    this.searchList();
  }

  componentWillUnmount() {
  
  }

  render() {
    const onPageChange = (pageNumber, ps) => {
      page = pageNumber;
      if (ps != pageSize) {
        page = 1
      }
      pageSize = ps
      this.searchList();
    }
    const sysColumns = [
      {
        title: '算法名称',
        dataIndex: 'algorithmName',
      },
      {
      title: '节点名称',
      dataIndex: 'nodeName',
    },
    {
      title: '节点序列号',
      dataIndex: 'nodeSeriesNumber',
    },
    {
      title: '鉴权来源',
      dataIndex: 'checkSource',
      render: (text, record) => (
        <>
          {
            record.checkSource?<span>算法</span>:<span>MA</span>
          }
        </>
      ),
    },
    {
      title: '鉴权结果',
      dataIndex: 'result',
      render: (text, record) => (
        <>
          {
            record.result?<span>成功</span>:<span>失败</span>
          }
        </>
      ),
    },
    {
      title: '检测时间',
      dataIndex: 'upTime',
    //   render: (text, record) => (
    //     <>
    //       {
    //         // this.getState(record)
    //       }
    //     </>
    //   ),
    },
    ];
    const columns = [
      {
        title: '算法名称',
        dataIndex: 'algorithmName',
      },
      {
      title: '节点名称',
      dataIndex: 'nodeName',
    },
    {
      title: '节点序列号',
      dataIndex: 'seriesNumber',
    },
    {
      title: '鉴权来源',
      dataIndex: 'checkSource',
    },
    {
      title: '鉴权结果',
      dataIndex: 'result',
      render: (text, record) => (
            <>
              {
                record.result?<span>MA</span>:<span>算法</span>
              }
            </>
          ),
    },
    {
      title: '检测时间',
      dataIndex: 'upTime',
    //   render: (text, record) => (
    //     <>
    //       {
    //         // this.getState(record)
    //       }
    //     </>
    //   ),
    },
    ];
    return (
      <>
        <div className="common-pd20">
          <div style={{ display: (this.state.showManage === true) ? "block" : "none" }}>
            <h1 className="common-top-title0">鉴权记录</h1>
            <div className="common-center-container no-search">
              <Search stateList={this.state.stateList}
                tenantList={this.state.tenantList}
                onClick={() => this.searchList()}
                />
           
              <div className="user-table-card">
                {/* 
                    rowSelection={{
                    ...rowSelection,
                  }} */}
                {
                  store.getState().currentUser.type !== AUTCONFIG.SUB.value ?

                    <Table
                      // rowSelection={{
                      //   ...rowSelection,
                      // }}
                      rowKey={record => record.id}
                      bordered={false}
                      pagination={false}
                      tableLayout="fixed"
                      columns={sysColumns}
                      loading={this.state.loading}
                      dataSource={this.state.recordsList}
                    />
                    :
                    <Table
                      rowKey={record => record.id}
                      bordered={false}
                      tableLayout="fixed"
                      loading={this.state.loading}
                      pagination={false}
                      columns={columns}
                      dataSource={this.state.recordsList}
                    />
                }
              </div>
              <div>
                {
                  this.state.recordsList.length > 0 &&
                  <div className="common-bottom-pagination">
                    <ConfigProvider locale={zhCN}>
                      <Pagination showSizeChanger defaultCurrent={1}
                        current={this.state.page} pageSize={this.state.pageSize} total={this.state.recordsAmount}
                        onChange={onPageChange} />
                    </ConfigProvider>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
// export default NodeManage;

export default connect(
  (state) => ({
    currentUser: state.currentUser
  })
)(AiCheckRecord);

