import { handleResponse } from './handle-response';
import store from '../../store/index';

export const labelService = {
    getLabelList,
    getLabelDetail,
    addLabel,
    removeLabel,
    editLabel,

    getAllLabels,
    getUserList,
    addUsersToLabel,
    removeUserFromLabel,
    getAllBindLabels
};

function getUserList(labelId, username, realName ) {
    // {labelId}/users
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/labels/${labelId}/users?username=${username}&realName=${realName}`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}
function getLabelList(name, tenantId, offset, limit, type) { // 接口加tenantId
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    // 普通用户
    let url  = `/v1/console/labels?name=${name || ''}&userId=${tenantId || ''}&offset=${offset || 1}&limit=${limit || 20}`
    
    //系统管理员  tenant
    // if(store.getState().currentUser?.type === 'SYS') {
    //     url  = `/v1/console/labels?name=${name || ''}&userId=${tenantId || ''}&offset=${offset || 1}&limit=${limit || 20}`
    // }
    return fetch(url, requestOptions).then(handleResponse).then(res => {
        return res;
    });
}
function getLabelDetail(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/labels/${id}`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}
function addUsersToLabel(labelid, userInfos) {
   const users =  userInfos.map(ids => {
       return {id:ids};
   });
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify(users)
    };
    return fetch(`v1/console/labels/${labelid}/users`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}


function addLabel(name, code, description) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            name: name || '',
          //  code: code || '',
            description: description || '',
        })
    };
    return fetch(`/v1/console/labels`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}
function removeUserFromLabel(id, userId) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: {userId}
    };
    return fetch(`/v1/console/labels/${id}/users?userId=${userId}`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}


function removeLabel(ids) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/labels/${ids}`, requestOptions).then(handleResponse).then(res => {
        return res;
    })
}

function editLabel(id, name, code, desc) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            name: name || '',
           // code: code || '',
            description: desc || '',
        })
    };
    return fetch(`/v1/console/labels/${id}`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}
function getAllLabels() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/labels?limit=1000`, requestOptions).then(handleResponse).then(res => { return res });
}


function getAllBindLabels(userid) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
    };
    return fetch(`/v1/console/labels/users/${userid}`, requestOptions).then(handleResponse).then(res => { return res });
}