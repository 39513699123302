import React from 'react';
import { Form, Input, message } from 'antd';
import './../../../../../../index.scss';
import { roleService } from '../../../../../services/role.service';
import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';

const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

class CreateAccess extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      showManage: true
    };

  }
  goback() {
    // this.formRef.current.resetFields();
    // if (this.props.onSubmit) {
    //   var { showManage } = this.state;
    //   this.props.onSubmit({ showManage })
    // }
    this.props.history.push("/home/sys/access-policy");
  }
  render() {
    const onFinish = (values) => {
      roleService.addRole(values.name, values.description).then(res => {
        if (res.code === 200) {
          message.success('创建角色成功');
          this.goback();
        } 
        // else {
        //   message.success('创建角色失败，' + res.message);
        // }
      }, error => {
        message.error('创建角色失败，' + error);
      });
    };
    return (
      <>
      <div className="common-pd20">
        <BackPage onClick={() => this.goback()} headeTitle="创建角色" itemTitle="角色" itemSubTitle="创建角色" />
        <div className="common-create-center-container">
          <div className="common-create-page">
            <Form
              ref={this.formRef}
              name="validate_other"
              labelAlign="left"
              {...formItemLayout}
              onFinish={onFinish}>
              <Form.Item name="name" label="角色名称" rules={[{ required: true, message: '请输入角色名称' }]}  >
                <Input placeholder="请输入角色名称" />
              </Form.Item>
              
              <Form.Item name="description" label="描述" >
                <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" />
              </Form.Item>
              <BottomBtnGroup onClick={() => this.goback()} />
            </Form>
          </div>
        </div>
        </div>
      </>
    );
  }
}

export default CreateAccess;