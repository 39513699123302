import {
    handleResponse
} from './handle-response';
import store from '../../store/index';

export const mirrorWarehouseService = {
    getImagesList,
    getImages,
    getDescription,
    deleteImage,
    editDescription,
    deleteImageOverview
};

/**
 * 查询镜像概览列表
 * @param {*} name 
 * @param {*} limit 
 * @param {*} offset 
 * @returns 
 */
function getImagesList(name, limit, offset) {
    return fetch(`/v1/console/docker/images/overview?name=${name || ''}&offset=${offset || 1}&limit=${limit || 10}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}
/**
 * 查询镜像列表
 * @param {*} name 
 * @returns images
 */
function getImages(name) {
    return fetch(`/v1/console/docker/images/${name}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}
/**
 * 查看镜像描述
 * @param {*} name 
 * @returns description
 */
function getDescription(name) {
    return fetch(`/v1/console/docker/images/${name}/description`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}
/**
 * 删除镜像
 * @param {*} id
 * @returns
 */
function deleteImage(id) {
    return fetch(`/v1/console/docker/images/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}
/**
 * 删除镜像概览
 * @param {*} name
 * @returns
 */
function deleteImageOverview(name) {
    return fetch(`/v1/console/docker/images/overview/${name}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}
/**
 * 修改镜像描述
 * @param {*} description    {type name}为非必填项，之后按需加入
 * @returns 
 */
function editDescription(descriptionId, description) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        },
        body: JSON.stringify({
            description: description,
        })
    };
    return fetch(`/v1/console/docker/images/description/${descriptionId}`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}