import React from 'react';
import { Form, Input, message } from 'antd';
import { companyService } from '../../../../../services/company.service';

import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';

const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

class CreateCompany extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      showManage: true,
      companyId: '',
      title: '创建企业'
    };

  }
  componentDidMount() {
   
 }
  goback() {
    this.props.history.push('/home/sys/company-manage');
  }

  render() {
    const onFinish = (values) => {
        Object.keys(values).forEach(elx => {
            values[elx] =  values[elx] || '';
        });
        message.loading('信息保存中~~', 2500);
        companyService.addCompany(values).then(res => {
          message.destroy();
        if (res.code === 200) {
          message.success('创建企业成功');
          this.goback();
        } 
        // else {
        //   message.success('创建企业失败，' + res.message);
        // }
      }, error => {
        message.destroy();
        error && message.error(error);
      });
    };
    return (
      <>
      <div className="common-pd20">
        <BackPage onClick={() => this.goback()} headeTitle={this.state.title} itemTitle="公司" itemSubTitle="创建公司" />
        <div className="common-create-center-container">
          <div className="common-create-page">
            <Form
              ref={this.formRef}
              name="validate_other"
              labelAlign="left"
              {...formItemLayout}
              onFinish={onFinish}>
              <Form.Item name="name" label="企业名称" rules={[{ required: true, message: '请输入企业名称' },
              { max: 64, message: '企业名称不允许超过64个字符'}, 
              { pattern: new RegExp(/^[0-9a-zA-Z-_.\u4e00-\u9fa5]{1,}$/, "g") , message: '请输入64位以内中英文/数字/中划线/下划线/点'}]}>
                <Input placeholder="请输入企业名称" />
              </Form.Item>
              <Form.Item name="contactPerson" label="联系人" rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                    if (!value || /^[\u0391-\uFFE5\w_-](.*){0,64}$/.test(value)) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('请输入64位以内中英文/数字/中划线/下划线/点'));
                    },
                })]}  >
                <Input placeholder="请输入联系人" />
              </Form.Item>
              <Form.Item name="mobile" label="电话"  rules={[{ pattern: /^[1][3,4,5,7,8][0-9]{9}$/, message: '请输入正确的手机号' }]} >
                <Input placeholder="请输入电话" />
              </Form.Item>
              <Form.Item name="email" label="邮箱" rules={[{ type: 'email', message: '请输入正确的电子邮箱' }]} >
                <Input placeholder="请输入邮箱" />
              </Form.Item>
              <Form.Item name="address" label="地址"  rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                    if (!value || /^[\u0391-\uFFE5\w_-](.*){0,64}$/.test(value)) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('请输入64位以内中英文/数字/中划线/下划线/点'));
                    },
                })]}>
                 <Input placeholder="请输入地址" />
              </Form.Item>
              <Form.Item name="description" label="描述" >
                <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" />
              </Form.Item>
              <BottomBtnGroup onClick={() => this.goback()} />
            </Form>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default CreateCompany;