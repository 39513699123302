import React from 'react';
import { Form, Input, message, Space, Select, Button, Modal, Divider } from 'antd';
import BackPage from '../../../../common/backPage';
import BottomBtnGroup from '../../../../common/bottomBtnGroup';
import { algorithmService } from '../../../../../services/algorithm-manage';
import { MinusCircleOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import AlgorithmGroup from '../algorithm-group';

const { Option } = Select;
const { confirm } = Modal;
const { TextArea } = Input;
const formItemLayout1 = {
    labelCol: { span: 3 },
    wrapperCol: { span: 13 },
};

class CreatAlgorithmGroup extends React.Component{
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            title: '新增算法组',
            provinceData: [], //形如provinceData = ['Zhejiang', 'Jiangsu'];
            cityData: {},     //形如const cityData = {Zhejiang: ['Hangzhou', 'Ningbo', 'Wenzhou'],Jiangsu: ['Nanjing', 'Suzhou', 'Zhenjiang'],};
            isAddVisible: false,
            status: '',
            nodeName: this.props?.location.state?.name,
            nodeId: this.props?.location.state?.id,
            licencesList: [],
            initCities: [],
            initSecondCity: '',
            citiesInfo: {},
            secondCityInfo: {},
            selectLicences: {}//算法授权选择
        };
    }

    componentDidMount() {
        //this.handleAdd();
        this.getTotalAlgorithms();
    }

    //查询算法列表
    getalgorithmsList(id, name, index) {
        algorithmService.getAlgorithm(id, '', '', 100, 1).then(res => {
            if (res.code === 200) {
                const data = res.algorithms;
                let provinceData = this.state.provinceData
                //console.log(data);
                if (data.length === 0) {
                    provinceData[index].has = true
                    this.setState({
                        provinceData: provinceData
                    })
                }
                
                this.handleListData(data, name);
            }
            // else {
            //     message.error('算法列表查询失败' + res.message);
            // }
        }, error => {
            error && message.error(error);
        })
    }
    handleListData(data, name) {
        const arr = [];
        data.map(ele => {
            let obj = {}
            obj.algorithmVersion = ele?.algorithmVersion
            obj.id = ele?.id
            arr.push(obj)
        })
        this.state.cityData[name] = arr;
        //console.log(this.state.cityData);
    }

    //查询算法概览列表
    getTotalAlgorithms() {
        algorithmService.getAlgorithmOverview('', 100, 1).then(res => {
            if (res.code === 200) {
                const data = res.namespaces;
                this.handleOverviewData(data);
                //console.log('算法概览查询', res);
            }
            // else {
            //     message.error('算法概览查询失败' + res.message);
            // }
        }, error => {
            error && message.error(error);
        })
    }
    handleOverviewData(data) {
        let tempData = []
        data.map((ele, index) => {
            let obj = {}

            obj.name = ele.name
            obj.id = ele.id
            obj.has = false
            tempData.push(obj);
            
            this.getalgorithmsList(ele.id, ele.name, index);
            
        })

        this.setState({
            provinceData:tempData
        })
        console.log(this.state.provinceData);
        //this.state.provinceData = tempData
    }

    // 删除算法处理
    handleRemove(key) {
        const selectLicences = this.state.selectLicences;
        // const citiesInfo = this.state.citiesInfo;
        // const secondCityInfo = this.state.secondCityInfo;
        delete selectLicences[key];
        // delete citiesInfo[key];
        // delete secondCityInfo[key];
        this.setState({
            selectLicences,
            // citiesInfo,
            // secondCityInfo
        })
    }

    // 添加算法处理
    handleAdd() {
        const citiesInfo = this.state.citiesInfo;
        const secondCityInfo = this.state.secondCityInfo;
        const len_1 = (Object.keys(citiesInfo)).length;
        citiesInfo[len_1] = [];
        secondCityInfo[len_1] = '';
        this.setState({
            citiesInfo,
            secondCityInfo
        })
    }

    // 固定第一条 选择框联动
    handleChange_1 = value => {
        //console.log(value);
        const dataArr = [value, this.state.cityData[value][0]?.algorithmVersion];
        const selectLicences = this.state.selectLicences;//selectLicences={}
        selectLicences.init = dataArr;
        this.setState({
            initCities: this.state.cityData[value],
            initSecondCity: this.state.cityData[value][0]?.algorithmVersion,
            selectLicences
        });
    }
    handleChange_2 = value => {
        const selectLicences = this.state.selectLicences;//selectLicences={}
        selectLicences.init[1] = value;
        this.setState({
            initSecondCity: value,
            selectLicences
        });
    }

    //动态 选择框联动
    handleProvinceChange = (value, num) => {
        const dataArr = [value, this.state.cityData[value][0]?.algorithmVersion];//形如selectLicences={[province,city],{dataArr},...}
        const citiesInfo = this.state.citiesInfo;
        const secondCityInfo = this.state.secondCityInfo;
        const selectLicences = this.state.selectLicences;

        selectLicences[num] = dataArr;

        citiesInfo[num] = this.state.cityData[value];
        secondCityInfo[num] = this.state.cityData[value][0]?.algorithmVersion;
        this.setState({
            citiesInfo,
            secondCityInfo,
            selectLicences
        });
    };

    onSecondCityChange = (value, num) => {
        const selectLicences = this.state.selectLicences;
        const secondCityInfo = this.state.secondCityInfo;
        selectLicences[num][1] = value;
        secondCityInfo[num] = value;
        this.setState({
            secondCityInfo,
            selectLicences
        });
    };

    goback() {
        this.props.history.push('/home/algorithm-manage');
    }

    render() {
        //console.log(this.state.provinceData);
        const onFinish = (values) => {
            //console.log(values.description);
            //console.log(this.state.selectLicences);
            //console.log(this.state.provinceData);
            //console.log(this.state.cityData);

            let flag = true
            const selectInfo = this.state.selectLicences;
            const cityDataInfo = this.state.cityData;
            const algorithmsArr = [];//最终的算法与版本
            const judgeRepeatArr = [];//判断是否重复添加的数组
            const judgeRepeatArr1 = [];//最终传body的数据
            if (!Object.keys(selectInfo).length) {//对象为空
                message.warning("请添加算法!");
            } else {

                // console.log(Object.keys(selectInfo));
                // console.log(Object.values(selectInfo));
                // console.log(Object.values(selectInfo)[0].length);
                // console.log(Object.values(selectInfo)[1].length);
                // if (Object.keys(selectInfo).length != Object.values(selectInfo).length) {
                //     message.warning("请选择算法和算法版本，不要选择为空，请选择完成，不要单一选择！")
                // }

                for (let key in selectInfo) {
                    if (judgeRepeatArr.find(ele => ele === selectInfo[key][0])) {
                        flag = false;
                        //message.warning("请勿添加相同的算法!");
                    } else {
                        const info = {};
                        info.algorithmName = selectInfo[key][0];
                        info.algorithmVersion = selectInfo[key][1];
                        algorithmsArr.push(info);
                        judgeRepeatArr.push(selectInfo[key][0])
                    }
                }
                if (flag) {
                    //this.licenseAlgorithms(this.state.nodeId, algorithmsArr); 
                    console.log(algorithmsArr);
                    for (let i = 0; i < algorithmsArr.length; i++){
                        for (let key in cityDataInfo) {
                            if (key === algorithmsArr[i].algorithmName) {
                                cityDataInfo[key].forEach(item => {
                                    if(item.algorithmVersion===algorithmsArr[i].algorithmVersion)
                                    judgeRepeatArr1.push(item.id)
                                })
                            }
                        }
                    }

                    console.log(judgeRepeatArr1);
                    let algorithmGroup = {}
                    algorithmGroup.algorithmGroupName = values.algorithmName
                    algorithmGroup.description = values.description || ''
                    algorithmGroup.algorithms = judgeRepeatArr1

                    algorithmService.addAlgorithmGroup(values.algorithmName, values.description, judgeRepeatArr1).then(res => {
                        //message.destroy();
                        if (res.code === 200) {
                            message.success('创建算法组成功！');
                            this.goback();
                        } 
                        }, error => {
                        //message.destroy();
                        error && message.error(error);
                    });
                } else {
                    message.warning("请勿添加相同的算法!");
                }
            }
        
        };

        const formItemLayout2 = 
         'inline'
        ;
        return (
            <div className="common-pd20">
                <BackPage onClick={() => this.goback()} headeTitle={this.state.title} itemTitle="算法" itemSubTitle="算法管理" />
                <div className="common-create-center-container">
                    <div className="common-create-page">
                        {/* <Form
                            ref={this.formRef}
                            name="validate_other"
                            labelAlign="left"
                            {...formItemLayout1}
                            onFinish={onFinish}>
                            <Form.Item name="algorithmName" label="分组名称"
                            rules={[
                                { required: true, message: '请输入算法名称' },
                                { max: 64, message: '算法名称不允许超过64个字符'}, 
                                { pattern: new RegExp(/^[0-9a-zA-Z-_\u4e00-\u9fa5]{1,}$/, "g") , message: '请输入64位以内中英文/数字/中划线/下划线'}]}  >
                                <Input placeholder="请输入分组名称" />
                            </Form.Item>
                            <Form.Item name="description" label="描述" >
                                <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" showCount={true} maxLength={255}/>
                            </Form.Item>
                            <BottomBtnGroup onClick={() => this.goback()} />
                        </Form> */}

                        <Form
                            //layout={formItemLayout2}
                            //{...formItemLayout1}
                            ref={this.formRef}
                            labelAlign="left"
                            preserve={false}
                            name="dynamic_form_nest_item"
                            onFinish={onFinish}
                            autoComplete="off" >
                            {/* <Row gutter={5}>
                                <Col span={16}> */}
                                    <Form.Item name="algorithmName" label="分组名称" {...formItemLayout1}
                                        rules={[
                                            { required: true, message: '请输入算法名称' },
                                            { max: 64, message: '算法名称不允许超过64个字符'}, 
                                            { pattern: new RegExp(/^[0-9a-zA-Z-_\u4e00-\u9fa5]{1,}$/, "g") , message: '请输入64位以内中英文/数字/中划线/下划线'}]}  >
                                        <Input placeholder="请输入分组名称" />
                                    </Form.Item>
                                {/* </Col>
                                <Col span={16}> */}
                                    <Form.Item name="description" label="描述" {...formItemLayout1}> 
                                        <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="请输入描述" showCount={true} maxLength={255}/>
                                    </Form.Item>

                                {/* </Col>
                            </Row> */}
                            <Divider/>
                            
                            <Space key={-1} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                <Form.Item 
                                    label="算法名称"
                                    rules={[{ required: true, message: '请选择算法名称' }]}
                                >
                                    <Select
                                        style={{ width: 180, marginLeft:20 }}
                                        onChange={this.handleChange_1}
                                    >
                                        {this.state.provinceData.map(province => (
                                            <Option key={province.name} value={province.name} disabled={province.has}>{province.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item style={{ marginLeft:60 }}
                                    label="算法版本"
                                    rules={[{ required: true, message: '请选择算法版本' }]}
                                >
                                    <Select
                                        style={{ width: 120, marginLeft:20 }}
                                        value={this.state.initSecondCity}
                                        onChange={this.handleChange_2}
                                    >
                                        {this.state.initCities.map(city => (
                                            <Option key={city?.algorithmVersion}>{city?.algorithmVersion}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Space>

                            <Form.List name="users"  >
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                <Form.Item
                                                    {...restField}
                                                    label="算法名称"
                                                    // name={[name, 'first']}
                                                    // initialValue={this.state.provinceData[0]}
                                                    fieldKey={[fieldKey, 'first']}
                                                    rules={[{ required: true, message: '请选择算法名称' }]}
                                                >
                                                    <Select
                                                        // defaultValue={this.state.provinceData[0]}
                                                        style={{ width: 180, marginLeft:20 }}
                                                        onChange={(e) => this.handleProvinceChange(e, key)}
                                                    >
                                                        {this.state.provinceData.map(province => (
                                                            <Option key={province.name} value={province.name} disabled={province.has}>{province.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item style={{ marginLeft:60 }}
                                                    {...restField}
                                                    label="算法版本"
                                                    // initialValue={this.state.secondCity}
                                                    // name={[name, 'last']}
                                                    fieldKey={[fieldKey, 'last']}
                                                    rules={[{ required: true, message: '请选择算法版本' }]}
                                                >
                                                    <Select
                                                        style={{ width: 120, marginLeft:20 }}
                                                        value={this.state.secondCityInfo[key]}
                                                        onChange={(e) => this.onSecondCityChange(e, key)}
                                                    >
                                                        {this.state.citiesInfo[key]?.map(city => (
                                                            <Option key={city?.algorithmVersion}>{city?.algorithmVersion}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => { remove(name); this.handleRemove(key); }} />
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" style={{width:100}} onClick={() => { add(); this.handleAdd() }} block icon={<PlusOutlined />}>
                                                添加
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                            <BottomBtnGroup onClick={() => this.goback()} />
                        </Form>
                    </div> 
                </div>
            </div>
        )
    }
}

export default CreatAlgorithmGroup;
