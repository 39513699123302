import React from 'react';
import { Form, Modal, Button, Table, Pagination, ConfigProvider, DatePicker, Select } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import locale from 'antd/es/date-picker/locale/zh_CN';

import { userService } from '../../../../services/user.service';
import store from '../../../../../store';
import { AUTCONFIG } from '../../../../../utils/auth.config';

import './../../../../../index.scss';
import './task-log.scss';


const { RangePicker } = DatePicker;
const { Option } = Select;

let searchStarTime = '';
let searchEndTime = '';
let searchUser = '';
let page = 1;
let pageSize = 10;

function TopSearch(props) {
  const form = React.createRef();
  const onFinish = (values) => {
    page = 1;
    searchUser = values.username || '';
    props.onClick();
  };
  const resetForm = () => {
    form.current.resetFields();
    searchStarTime = '';
    searchEndTime = '';
    searchUser = '';
    page = 1;
    props.onClick();
  }
  const onChange = (values, datastring) => {
    searchStarTime = datastring[0];
    searchEndTime = datastring[1];
    props.onClick();
  };

  return (
    <>
      <div className="log-search-form ">
        <Form
          ref={form}
          name="advanced_search_user"
          className="ant-advanced-search-form"
          onFinish={onFinish}
          layout="inline"
          // style={{ justifyContent: 'center' }}
          >
          <Form.Item name='time' label='选择时间' style={{ float: 'left', marginRight: '7.14px', marginTop: '8px' }}>
            <RangePicker
              style={{ width: 328, height: 28 }}
              locale={locale} onChange={onChange}
              showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>
          {
            store.getState().currentUser.type !== AUTCONFIG.SUB.value ?
              <Form.Item name='username' label='所属用户' style={{ marginTop: '8px' }}>
                <Select 
                style={{ width: '200px' }} 
                placeholder="请选择所属用户"
                showSearch
                filterOption={(input, option) =>
                  option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                >
                  {props.tenantList}
                </Select>
              </Form.Item>
              : ''
          }
          <Form.Item style={{ marginTop: '8px' }}>
            <Button type="primary" htmlType="submit"> 查询 </Button>
            <Button style={{ margin: '0 9.46px' }} onClick={resetForm}>重置 </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

class TaskLog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      logList: [],
      logAmount: 0,
      loading: false,
      page: 1,
      pageSize: 10,

      tenantList: [],

      isShowLogModal: false,
      logDetailList: []
    };
  }

  searchList() {
    console.log('selected user:' + searchUser);
    console.log('selected start time:' + searchStarTime);
    console.log('selected end time:' + searchEndTime);

    console.log('selected page:' + page);
    console.log('selected pageSize:' + pageSize);

    this.setState({
      logList: [
        { id: 1, name: '应用启动', obj: 'application1', opStartTime: '2021-05-25 10:24:00', opEndTime: '2021-05-25 10:24:00', result: 'SUCCESS', content: '内容内容', username: '实验小学' },
        { id: 2, name: '应用停止', obj: 'application2', opStartTime: '2021-05-25 10:24:00', opEndTime: '2021-05-25 10:24:00', result: 'ACTING', content: '内容内容', username: '实验小学' },
        { id: 3, name: '节点升级', obj: 'node1', opStartTime: '2021-05-25 10:24:00', opEndTime: '2021-05-25 10:24:00', result: 'FAILED', content: '内容内容', username: '实验小学' },
      ],
      logAmount: 3
    })
  }
  componentWillMount() {
    this.searchList();
    this.getTenantList();
  }
  getTenantList() {
    userService.getAllTenantList().then(res => {
      if (res.code === 200) {
        const tenants = res.users;
        this.setState({
          tenantList: this.getTenantItem(tenants),
        });
      }
    });
  }
  getTenantItem(tenants) {
    return tenants.map((item, index) => {
      return (
        <Option key={item.id} >{item.realName}/{item.username}</Option>
      );
    });
  }
  componentDidMount() {
    pageSize = 10;
    page = 1;
    this.searchList();
  }
  showLogModal(id) {
    const options = [];
    const logList = [
      { id: 1, content: '用户a创建node1', upTime: '2021-04-20 09:23:16' },
      { id: 2, content: '用户a启动node1', upTime: '2021-04-20 09:25:41' },
      { id: 3, content: '用户a升级node1', upTime: '2021-05-25 10:23:00' },
      { id: 4, content: 'node1升级失败', upTime: '2021-05-25 10:24:00' },
    ];
    logList.forEach(item => {
      options.push(
        <p><span style={{ marginRight: 12 }}>{item.upTime}</span>{item.content}</p>
      )
    });
    this.setState({
      isShowLogModal: true,
      logDetailList: options
    })
  }
  handleCancel() {
    this.setState({
      isShowLogModal: false,
      logDetailList: []
    })
  }
  // gotoDetail = (text, key) => {
  //   this.props.history.push({ pathname: '/home/sys/user-manage/detail', state: { id: text } });
  // }
  getState(record) {
    if (record.result === 'ACTING') {
      return <div><div className="common-green"></div> <span>执行中</span></div>
    } else if (record.result === 'SUCCESS') {
      return <div><div className="common-blue"></div> <span>成功</span></div>
    } else if (record.result === 'FAILED') {
      return <div><div className="common-red"></div> <span>失败</span></div>
    } else {
      return '';
    }
  }
  render() {
    const onPageChange = (pageNumber) => {
      page = pageNumber;
      this.searchList();
    }
    const onShowSizeChange = (current, pageSizeNumber) => {
      pageSize = pageSizeNumber;
      page = 1;
      this.searchList();
    }

    const columns = [
      {
        title: '任务名称',
        dataIndex: 'name',
      },
      {
        title: '执行对象',
        dataIndex: 'obj',
      },
      {
        title: '开始时间',
        dataIndex: 'opStartTime',
      },
      {
        title: '结束时间',
        dataIndex: 'opEndTime',
      },
      {
        title: '执行状态',
        dataIndex: 'result',
        render: (text, record) => (
          <>
            {
              this.getState(record)
            }
          </>
        ),
      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record) => (
          <>
            <a href style={{ color: '#0070CC' }} onClick={() => this.showLogModal(record.id)}>日志</a>
          </>
        ),
      },
    ];

    return (
      <>
        <div className="common-pd20 task-log-container">
          <div >
            <h1 className="common-top-title0">任务日志</h1>
            <div className="common-center-container">
              <TopSearch onClick={() => this.searchList()} tenantList={this.state.tenantList} />
              <div className="mt32">
                <Table
                  rowKey={record => record.id}
                  bordered={false}
                  tableLayout="fixed"
                  pagination={false}
                  columns={columns}
                  dataSource={this.state.logList}
                />
              </div>
              <div>
                {
                  this.state.logList.length > 0 &&
                  <div className="common-bottom-pagination">
                    <ConfigProvider locale={zhCN}>
                      <Pagination
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        defaultCurrent={1}
                        current={page}
                        pageSize={pageSize}
                        total={this.state.logAmount}
                        onChange={onPageChange} />
                    </ConfigProvider>
                  </div>
                }
              </div>
            </div>
          </div>
          <Modal title="任务日志" width={880} visible={this.state.isShowLogModal} footer={null} onCancel={() => this.handleCancel()}>
            <div className="task-log-content">
              {this.state.logDetailList}
            </div>
          </Modal>
        </div>
      </>

    );
  }
}


export default TaskLog;
