import { handleResponse } from "./handle-response";
import store from "../../store/index";

export const userDebService = {
  getUserDebOverview,
  getUserDebList,
  getUserDebAll,
  getUserDebDetails,
  addUserDeb,
  editUserDeb,
  releaseUserDeb,
  removeUserDeb,
  deleteUserDeb,
  getUserDebsoftwares,
  getUserDebsoftwaresDetails,
  addUserSoftware,
  deleteUserSoftware,
};
//3.26.1.获取用户软件包概览（管理员）
function getUserDebOverview(username, limit, offset) {
  return fetch(
    `/v1/console/user_deb/overview?username=${username || ""}&limit=${
      limit || 10
    }&offset=${offset || 1}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${store.getState().authToken}`,
      },
    }
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
//3.26.2.获取用户软件包列表
function getUserDebList(username,name, limit, offset) {
  return fetch(
    `/v1/console/user_deb?username=${username || ""}&name=${name || ""}&limit=${
      limit || 10
    }&offset=${offset || 1}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${store.getState().authToken}`,
      },
    }
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
//3.26.3.查询用户软件包列表（不带分页）
function getUserDebAll(userId, name) {
  return fetch(`/v1/console/user_deb/${userId}/list?name=${name || ""}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
//3.26.4.查看用户软件包详情
function getUserDebDetails(id) {
  return fetch(`/v1/console/user_deb/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
//3.26.5.新增用户软件包
function addUserDeb(name, version, description, url) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      name,
      version,
      description: description || "",
      url: url || "",
    }),
  };
  return fetch(`/v1/console/user_deb`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
//3.26.6.编辑用户软件包
function editUserDeb(id, name, version, description, url) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      name,
      version,
      description: description || "",
      url: url || "",
    }),
  };
  return fetch(`/v1/console/user_deb/${id}`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
//3.26.7.发布用户软件包版本
function releaseUserDeb(id) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  };
  return fetch(`/v1/console/user_deb/${id}/release`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
//3.26.8.下架用户软件包版本
function removeUserDeb(id) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  };
  return fetch(`/v1/console/user_deb/${id}/remove`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
//3.26.9.删除用户软件包
function deleteUserDeb(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  };
  return fetch(`/v1/console/user_deb/${id}`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
//3.26.10.获取软件列表
function getUserDebsoftwares(id) {
  return fetch(`/v1/console/user_deb/${id}/softwares`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
//3.26.11.查看软件详情
function getUserDebsoftwaresDetails(id) {
  return fetch(`/v1/console/user_deb/software/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
//3.26.12.新增软件
function addUserSoftware(id,data) {
  const requestOptions = {
      method: 'POST',
      body: data,
      headers: {
          // 'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `Bearer ${store.getState().authToken}`
      },
    
  };
  return fetch(`/v1/console/user_deb/${id}/software`, requestOptions)
      .then(handleResponse)
      .then(res => {
          return res;
      });
}
//3.26.13.删除软件
function deleteUserSoftware(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  };
  return fetch(`/v1/console/user_deb/software/${id}`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
