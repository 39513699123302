import { handleResponse } from './handle-response';
import store from '../../store/index';

export const homeService = {
    getOverview,
    getMenuList
};
function getOverview() {
    return fetch(`/v1/console/statistics/states`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}

function getMenuList() {
    return fetch(`/v1/console/resource/menu`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${store.getState().authToken}`
        }
    }).then(handleResponse).then(res => {
        return res;
    });
}