import React from 'react';
import { Form, Button, Col, Select, DatePicker, Card, Divider, message } from 'antd';
import BackPage from '../../../../../common/backPage';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import './node-monitor.scss'
import { edgeService } from '../../../../../../services/edge.service'

import * as echarts from "echarts/lib/echarts";


const { RangePicker } = DatePicker;

let startTime = '';
let endTime = '';

function Search(props) {
    const [form] = Form.useForm();
    // const onFinish = (values) => {
    //     console.log(startTime)
    //     console.log(endTime)
    //     props.onClick();
    // };
    const onChange = (values, datastring) => {
        startTime = datastring[0];
        endTime = datastring[1];
        props.change();
    };
    return (
        <>
            <div className="monitor-search">
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    //onFinish={onFinish} 
                    >
                    <Col>
                        <Form.Item name='uuid' label='时间段' style={{ float: 'left', marginRight: '7.14px' }}>
                            <RangePicker
                                style={{ width: 328, height: 28 }}
                                locale={locale} onChange={onChange}  
                                showTime format="YYYY-MM-DD HH:mm:ss" />
                        </Form.Item>
                    </Col>
                </Form>
            </div>
        </>
    );
}

class  EdgeEffectMonitorDemo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nodeMonitorName: '查看监控',
            stats: '',//保存数组数据
            id: '',
            cpuRate: [],
            gpuRate: [],
            memRate: [],
            diskRate: [],
            upTime: [],

            nodeId: null,
        };
    }
    goback() {
        if (this.props.onSubmit) {
            var { showManage } = this.state;
            this.props.onSubmit({ showManage })
        }
        this.props.history.go(-1);
    }
    drawCharts(upTime, cpuRate, gpuRate, memRate, diskRate) {
        //CPU
        const chartOptionCpu = {
            title: {
                text: '',
                subtext: ''
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                // axisLabel: { 
                //     interval:0,  
                //     rotate:50,
                //     textStyle: {
                //         fontSize:6,               
                //     }   
                //  },
                data: upTime
            },
            yAxis: {
                type: 'value',
                axisTick: { //y轴刻度线
                    show: true
                },
                axisLine: { //y轴
                    show: true
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#2F80ED'
                    }
                }
            },
            series: [{
                data: cpuRate,
                type: 'line',
                itemStyle: {
                    normal: {
                        label: {
                            show: true, //开启显示
                            position: 'top', //在上方显示
                            textStyle: { //数值样式
                                fontSize: 8
                            }
                        }
                    }
                },
                areaStyle: {
                    normal: {
                        color: "#2F80ED"
                    }
                },

                smooth: true
            }]
        }
        
        //GPU
        const chartOptionGpu = {
            title: {
                text: '',
                subtext: ''
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                // axisLabel: { 
                //     interval:0,  
                //     rotate:50,
                //     textStyle: {
                //         fontSize:6,               
                //     }   
                //  },
                data: upTime
            },
            yAxis: {
                type: 'value',
                axisTick: { //y轴刻度线
                    show: true
                },
                axisLine: { //y轴
                    show: true
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#2F80ED'
                    }
                }
            },
            series: [{
                data: gpuRate,
                type: 'line',
                itemStyle: {
                    normal: {
                        label: {
                            show: true, //开启显示
                            position: 'top', //在上方显示
                            textStyle: { //数值样式
                                fontSize: 8
                            }
                        }
                    }
                },
                areaStyle: {
                    normal: {
                        color: "#2F80ED"
                    }
                },

                smooth: true
            }]
        }

        //内存
        const chartOptionMem = {
            title: {
                text: '',
                subtext: ''
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                // axisLabel: { 
                //     interval:0,  
                //     rotate:50,
                //     textStyle: {
                //         fontSize:6,               
                //     }   
                //  },
                data: upTime
            },
            yAxis: {
                type: 'value',
                axisTick: { //y轴刻度线
                    show: true
                },
                axisLine: { //y轴
                    show: true
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#2F80ED'
                    }
                }
            },
            series: [{
                data: memRate,
                type: 'line',
                itemStyle: {
                    normal: {
                        label: {
                            show: true, //开启显示
                            position: 'top', //在上方显示
                            textStyle: { //数值样式
                                fontSize: 8
                            }
                        }
                    }
                },
                areaStyle: {
                    normal: {
                        color: "#2F80ED"
                    }
                },

                smooth: true
            }]
        }

        //磁盘
        const chartOptionDisk = {
            title: {
                text: '',
                subtext: ''
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                // axisLabel: { 
                //     interval:0,  
                //     rotate:50,
                //     textStyle: {
                //         fontSize:6,               
                //     }   
                //  },
                data: upTime
            },
            yAxis: {
                type: 'value',
                axisTick: { //y轴刻度线
                    show: true
                },
                axisLine: { //y轴
                    show: true
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#2F80ED'
                    }
                }
            },
            series: [{
                data: diskRate,
                type: 'line',
                itemStyle: {
                    normal: {
                        label: {
                            show: true, //开启显示
                            position: 'top', //在上方显示
                            textStyle: { //数值样式
                                fontSize: 8
                            }
                        }
                    }
                },
                areaStyle: {
                    normal: {
                        color: "#2F80ED"
                    }
                },

                smooth: true
            }]
        }

        const myChart1 = echarts.init(document.getElementById('chart1'));
        const myChart2 = echarts.init(document.getElementById('chart2'));
        const myChart3 = echarts.init(document.getElementById('chart3'));
        const myChart4 = echarts.init(document.getElementById('chart4'));
        myChart1.setOption(chartOptionCpu);
        myChart2.setOption(chartOptionGpu);
        myChart3.setOption(chartOptionMem);
        myChart4.setOption(chartOptionDisk);
        
        window.addEventListener('resize', ()=> {
            myChart1.resize()
            myChart2.resize()
            myChart3.resize()
            myChart4.resize()
        })
    }
    
    componentDidMount() {
       if (this.props.location.state) {
        const id = this.props.location.state.id;
        console.log("查看监控详情的实例id为：",id);
        //this.setState({ id });
        //console.log(this.state.id);
        // this.getShellDetail(id)
        // this.getAppInstances(id)
        //this.getAppLogs(id, '2021-09-01 14:18:50', '2021-09-10 14:18:50')
        //console.log('startTime',startTime, endTime);
        this.getAppStats(id, '', '')
        sessionStorage.setItem('statsId', id)
        
      } else {
        if(sessionStorage.getItem('statsId')) {
          const id = sessionStorage.getItem('statsId');
          this.setState({
              id
          });
          this.getAppStats(id, '', '')
        //   this.getShellDetail(id)
        //   this.getAppInstances(id)
        } else {
          this.goback();
        }
      }

        //this.getMonitor();
    }

    //实际查询
    getMonitor() {
        //console.log(this.props.location.state.id);
        if (this.props.location.state) {
            const id = this.props.location.state.id;
            console.log("查看监控详情的实例id为：",id);
            this.setState({ id });
            //console.log(this.state.id);
            // this.getShellDetail(id)
            // this.getAppInstances(id)
            //this.getAppLogs(id, '2021-09-01 14:18:50', '2021-09-10 14:18:50')
            //console.log('startTime',startTime, endTime);
            this.getAppStats(id, startTime, endTime)
            sessionStorage.setItem('statsId', id)
            
          } else {
            if(sessionStorage.getItem('statsId')) {
              const id = sessionStorage.getItem('statsId');
              this.setState({
                  id
              });
              this.getAppStats(id, startTime, endTime)
            //   this.getShellDetail(id)
            //   this.getAppInstances(id)
            } else {
              this.goback();
            }
          }
          
        // console.log("喵喵喵",this.state.upTime);
        // this.drawCharts(this.state.upTime, this.state.cpuRate, this.state.gpuRate, this.state.memRate, this.state.diskRate);
    }

    //查询监控
    getAppStats(id, startTime, endTime) {
        edgeService.getEdgeStats(id, startTime, endTime).then(res => {
            if (res.code === 200) {
                console.log("返回的监控数据",res);
                //拼接相应的数组
                const cpuRate = res.stats.map(item=>item.cpuRate)
                const gpuRate = res.stats.map(item=>item.gpuRate)
                const memRate = res.stats.map(item=>item.memRate)
                const diskRate = res.stats.map(item=>item.diskRate)
                const upTime = res.stats.map(item=>item.upTime)
        
                this.setState({
                    stats: res.stats,
                    cpuRate,
                    gpuRate,
                    memRate,
                    diskRate,
                    upTime,
                  })

                //console.log("喵喵喵",this.state.upTime);
                this.drawCharts(this.state.upTime, this.state.cpuRate, this.state.gpuRate, this.state.memRate, this.state.diskRate);
            }  
        }).catch(error => {
          error && message.error(error);
        })
    }


    render() {
        //console.log(this.props.location.state.id);
        console.log("cpukRate",this.state.cpuRate);
        console.log("memRate",this.state.memRate);
        return (
            <>
                <div className="node-log-container">
                    <BackPage onClick={() => this.goback()} headeTitle={this.state.nodeMonitorName} itemTitle="边缘节点" itemSubTitle="查看监控" />
                    <Search change={() => this.getMonitor()} />
    
                    <div className="monitoring-echarts">
                        <Card>
                            <h3>
                                <span>CPU</span>
                            </h3>
                            <Divider />
                            <div id="chart1" style={{ height: 320, width: '100%', overflow: 'hidden' }}></div>
                        </Card>
                        <Card>
                            <h3>
                                <span>GPU</span>
                            </h3>
                            <Divider />
                            <div id="chart2" style={{ height: 320, width: '100%', overflow: 'hidden' }}></div>
                        </Card>
                        <Card className="mt12">
                            <h3>
                                <span>内存(M)</span>
                            </h3>
                            <div id="chart3" style={{ height: 320, width: '100%', overflow: 'hidden' }}></div>
                        </Card>
                        <Card className="mt12">
                            <h3>
                                <span>硬盘(M)</span>
                            </h3>
                            <div id="chart4" style={{ height: 320, width: '100%', overflow: 'hidden' }}></div>
                        </Card>
                    </div>

                </div>
            </>
        )
    }
}
export default EdgeEffectMonitorDemo;